import { LocusLabs, PoiStore as BasePoiStore } from '@move-frontend/locuslabs';
import { computed, makeObservable } from 'mobx';
import { Category } from '../models';

export class PoiStore extends BasePoiStore {
	constructor(api: LocusLabs, vendor: string) {
		super(api, vendor);

		makeObservable(this, {
			filteredResults: computed,
		});
	}

	public get filteredResults() {
		const categoryWhiteList = Object.values(Category);

		return this.results.filter((poi) =>
			categoryWhiteList.includes(poi.category as Category),
		);
	}
}

import { AbstractService } from '@move-frontend/dap';
import { IBusWaitingTimesResponse } from './types/IBusWaitingTimesResponse';

export class BusWaitingTimeService extends AbstractService {
	public static readonly VERSION = '110';

	getBusWaitTimes() {
		return this.client.request<IBusWaitingTimesResponse>(
			'/bus-routes/stops?restricted=true',
			{
				method: 'GET',
				headers: {
					'Api-Version': BusWaitingTimeService.VERSION,
				},
			},
		);
	}
}

export const cognitoConfig = {
	// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
	identityPoolId: process.env.AWS_IDENTITY_POOL_ID,

	// REQUIRED - Amazon Cognito Region
	region: process.env.AWS_REGION,

	// OPTIONAL - Amazon Cognito User Pool ID
	userPoolId: process.env.AWS_USER_POOL_ID,

	// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
	userPoolWebClientId: process.env.AWS_USER_POOL_WEB_CLIENT_ID,

	// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
	mandatorySignIn: false,
};

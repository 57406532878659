import { DialogOverlay } from '@reach/dialog';
import { PropsOf } from '../../../../ui';
import styled from '@emotion/styled';
import { Z_INDEX_DIALOG } from '../../styles';
import { Color } from '../../styles/Color';
import Responsive from '../../../../ui/utils/Responsive';

export type IDialogOverlayProps = PropsOf<typeof DialogOverlay> & {
	onDismiss?: () => void;
};

interface IStyledDialogOverlayProps {
	fullWidthMobile: boolean;
}

export const StyledDialogOverlay = styled(
	DialogOverlay,
)<IStyledDialogOverlayProps>`
	&[data-reach-dialog-overlay] {
		z-index: ${Z_INDEX_DIALOG};

		@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
			${({ fullWidthMobile }) =>
				fullWidthMobile
					? `
			    background: ${Color.BackgroundWhite};
        `
					: `
        display: flex;
        align-items: center;
      `}
		}
	}
`;

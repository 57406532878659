import React from 'react';
import { css, Global } from '@emotion/core';

export const List = () => {
	const styles = css`
		ul {
			padding-left: 1.38889em;
			margin-left: -0.27778em;
		}

		ol {
			padding-left: 1.38889em;
			margin-left: -0.22222em;
		}

		li {
			padding-left: 0.33333em;
		}
	`;

	return <Global styles={styles} />;
};

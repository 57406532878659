import { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { useStores } from '../../../app/root/domain/stores/useStores';
import { IRemoteConfig } from '../domain/models/IRemoteConfig';

export function useRemoteConfigValue<Key extends keyof IRemoteConfig>(
	key: Key,
) {
	const { remoteConfigStore } = useStores();

	useEffect(() => {
		remoteConfigStore.load();
	}, [remoteConfigStore]);

	return useObserver(() => {
		const value = remoteConfigStore.config[key];
		const { error } = remoteConfigStore;

		return { value, error };
	});
}

import { FilterStore, SortStore } from '@move-frontend/utils';
import { AirlineStore, AirportStore, FlightStore } from '@move-frontend/dap';
import { SuggestionStore } from './SuggestionStore';
import { createFlightSuggestionsFilter } from './createFlightSuggestionsFilter';
import { sortFlightSuggestions } from './sortFlightSuggestions';
import { createFlightSuggestionsAdapters } from './createFlightSuggestionsAdapters';
import { IFlightSearchSuggestion } from '../models/IFlightSearchSuggestion';
import { IFlight } from '../../../shared/flight/domain/models/IFlight';

export function createFlightSuggestionStore(
	flightStore: FlightStore<IFlight>,
	airlineStore: AirlineStore,
	airportStore: AirportStore,
) {
	const suggestionSortStore = new SortStore<IFlightSearchSuggestion>();
	const suggestionFilterStore = new FilterStore<IFlightSearchSuggestion>();

	const suggestionStore = new SuggestionStore<IFlightSearchSuggestion>(
		suggestionFilterStore,
		suggestionSortStore,
	);

	const flightSuggestionFilter = createFlightSuggestionsFilter(suggestionStore);
	const flightSuggestionSort = sortFlightSuggestions(suggestionStore);

	suggestionFilterStore.setFilter(
		'filter-flight-suggestions',
		flightSuggestionFilter.includesQuery,
	);
	suggestionSortStore.setSortBy(flightSuggestionSort.byIndexAndResultLength);

	const suggestionAdapters = createFlightSuggestionsAdapters(
		airlineStore,
		airportStore,
		flightStore,
	);
	suggestionStore.setAdapters(suggestionAdapters);

	return suggestionStore;
}

import React, { useState } from 'react';
import {
	StyledAlertBanner,
	StyledAlertBannerTitle as AlertBannerTitle,
} from '../../../../ui';
import styled from '@emotion/styled';
import { INewsAlertEntry, NewsAlertType } from '../../api/contentful/types';
import { NewsAlertContent } from './NewsAlertContent';
import { DesktopNewsAlert } from './DesktopNewsAlert';
import { MobileNewsAlert } from './MobileNewsAlert';
import { useTranslation } from '../../i18n/translate/useTranslation';
import { AlertDialog } from './AlertDialog';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';
import Responsive from '../../../../ui/utils/Responsive';

export interface INewsAlertProps {
	newsAlerts?: INewsAlertEntry[];
	showDialog?: boolean;
}

export const NewsAlerts = ({
	newsAlerts,
	showDialog = false,
}: INewsAlertProps) => {
	const { t } = useTranslation();

	const filterAlertsByType = (type: NewsAlertType) =>
		newsAlerts?.filter((alert) => alert.fields.type === type) || [];

	const emergencyInformations = filterAlertsByType(
		NewsAlertType.EmergencyInformation,
	).sort();

	const warningInformations = filterAlertsByType(NewsAlertType.Warning).sort();

	const isEmergency = emergencyInformations.length > 0;

	const [isDialogOpen, setIsDialogOpen] = useState(showDialog && isEmergency);
	const dialogEmergencyNews = emergencyInformations[0];
	const defaultTitle = isEmergency
		? t('newsroom_emergency_information_title')
		: t('news_alert_title');

	if (!newsAlerts?.length) return null;

	const filteredNewsAlerts = isEmergency
		? emergencyInformations
		: warningInformations;

	const content = filteredNewsAlerts.map((alert) => (
		<NewsAlertContent
			key={alert.id}
			body={alert.fields.body}
			url={alert.fields.link}
		/>
	));

	return (
		<>
			{isDialogOpen && dialogEmergencyNews && (
				<AlertDialog
					newsAlert={dialogEmergencyNews}
					onClose={() => setIsDialogOpen(false)}
				/>
			)}
			<StyledAlertBanner
				isEmergency={isEmergency}
				hasCloseButton={false}
				role="alert"
			>
				<StyledContentWrap>
					<StyledAlertBannerTitle>
						{newsAlerts.length > 1 ? defaultTitle : newsAlerts[0].fields.title}
					</StyledAlertBannerTitle>
					<DesktopNewsAlert noMargin={isEmergency}>{content}</DesktopNewsAlert>
					<MobileNewsAlert>{content}</MobileNewsAlert>
				</StyledContentWrap>
			</StyledAlertBanner>
		</>
	);
};

const StyledContentWrap = styled(ContentWrap)`
	display: flex;
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		flex-direction: column;
	}
`;

const StyledAlertBannerTitle = styled(AlertBannerTitle)`
	min-width: 210px;
	margin-right: 40px;
	flex-grow: 0;
	font-size: 18px;
	line-height: 21px;
	border-radius: 12px;
	display: flex;
	align-items: center;
`;

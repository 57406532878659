import { IOnFailure } from '@move-frontend/auth';
import { useForgotPasswordStore } from '../../domain/stores/useForgotPasswordStore';
import { IForgotPasswordFormData } from '../../domain/models/IForgotPasswordFormData';
import { ISendRecoveryLinkOnSuccess } from '../../domain/models/ISendRecoveryLinkOnSuccess';
import { useSendRecoveryLinkWithCallbacks } from './useSendRecoveryLinkWithCallbacks';

export function useForgotPasswordSubmit(
	onSuccess: ISendRecoveryLinkOnSuccess,
	onFailure: IOnFailure,
) {
	const forgotPasswordStore = useForgotPasswordStore();
	const sendRecoveryLinkWithCallbacks = useSendRecoveryLinkWithCallbacks(
		onSuccess,
		onFailure,
	);

	return (data: IForgotPasswordFormData) => {
		forgotPasswordStore.email = data.email;

		return sendRecoveryLinkWithCallbacks(data);
	};
}

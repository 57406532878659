import { Gateway } from '../../../shared/api/gateway/Gateway';
import { AuthStore } from '../../../auth/shared/store/AuthStore';
import { LoyaltyAccountStore } from './LoyaltyAccountStore';
import { LoyaltyTierStore } from './LoyaltyTierStore';
import { NewBookingStore } from '../../../parking/domain/booking/stores/NewBookingStore';

export function createLoyaltyAccountStores(
	authStore: AuthStore,
	gateway: Gateway,
	bookingStore: NewBookingStore,
) {
	const accountService = gateway.account();

	const loyaltyAccountStore = new LoyaltyAccountStore(
		authStore,
		accountService,
		bookingStore,
	);
	const loyaltyTierStore = new LoyaltyTierStore(loyaltyAccountStore);

	return {
		loyaltyAccountStore,
		loyaltyTierStore,
	};
}

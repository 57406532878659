import { ObjectSchema } from 'yup';
import {
	FieldValues,
	useForm as useReactHookForm,
	UseFormOptions,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export interface IUseFormOptions<
	TFieldValues extends FieldValues,
	TContext extends object,
> extends Omit<UseFormOptions<TFieldValues, TContext>, 'resolver'> {
	validationSchema: ObjectSchema<TFieldValues>;
}

export function useForm<
	TFieldValues extends FieldValues = FieldValues,
	TContext extends object = object,
>(options: IUseFormOptions<TFieldValues, TContext>) {
	const { validationSchema, ...rest } = options;

	return useReactHookForm<TFieldValues, TContext>({
		...rest,
		resolver: yupResolver(validationSchema),
	});
}

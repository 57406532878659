/* eslint-disable no-inner-declarations, @typescript-eslint/no-namespace */
import hash from '@emotion/hash';
import { IStyledComponentSelector } from '../types';

export namespace Css {
	const valueAndUnitRegex = /^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/;

	export function getValueAndUnit(value: string): [number, string] {
		const matchedValue = value.match(valueAndUnitRegex);

		if (!matchedValue) {
			throw new Error(`Can't get value and unit for: ${value}`);
		}

		return [parseFloat(value), matchedValue[2]];
	}

	interface ICalcFunction {
		(value: number): number;
	}

	export function calc(input: string, callback: ICalcFunction) {
		const [value, unit] = getValueAndUnit(input);

		return `${callback(value)}${unit}`;
	}

	export function getHalfOf(value: string) {
		return calc(value, (v) => v * 0.5);
	}

	let sequenceId = 0;

	export function createVariableName(name: string) {
		sequenceId += 1;

		// Add a hash of the name with a sequence number suffix to make sure the variable name is unique.
		return `--${name}-${hash(name + sequenceId)}`;
	}

	export function getSelectorForComponents(
		components: IStyledComponentSelector,
	) {
		if (Array.isArray(components)) {
			return getClassNamesForComponents(components);
		}

		return getClassNameForComponent(components);
	}

	export function getClassNamesForComponents(
		components: IStyledComponentSelector[],
	) {
		return components.map(getClassNameForComponent).join(', ');
	}

	export function getClassNameForComponent(
		component: IStyledComponentSelector,
	) {
		return component.toString();
	}

	export function pxToRem(px: number, baseFontSize = '16px') {
		const [baseSize] = getValueAndUnit(baseFontSize);
		return `${px / baseSize}rem`;
	}
}

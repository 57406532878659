import React from 'react';
import {
	CognitoErrorCode,
	hasCognitoErrorCode,
	MaybeCognitoError,
} from '@move-frontend/auth';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';

interface ISignUpErrorMessage {
	error: MaybeCognitoError;
}

export const SignUpErrorMessage = ({ error }: ISignUpErrorMessage) => {
	const { t } = useTranslation();

	return (
		<>
			{hasCognitoErrorCode(error, CognitoErrorCode.UsernameExistsException)
				? t('user_sign_up_username_exists_error_message')
				: t('user_sign_up_generic_error_message')}
		</>
	);
};

import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { Flex, Shimmer } from '../../../../ui';
import { useAuthStore } from '../../../auth/shared/store/useAuthStore';
import { useLoyaltyAccountStore } from '../../domain/stores/useLoyaltyAccountStore';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { getGradientBackground } from '../../../shared/styles';
import { useLoyaltyTierStore } from '../../domain/stores/useLoyaltyTierStore';
import Responsive from '../../../../ui/utils/Responsive';

export const TierPointsInfoTag = observer(() => {
	const { t } = useTranslation();
	const { isAuthenticated } = useAuthStore();
	const { isLoading, loyaltyDetails } = useLoyaltyAccountStore();

	const { contentfulTierDetails, isLoading: isContentfulLoading } =
		useLoyaltyTierStore();

	if (
		!isAuthenticated ||
		!loyaltyDetails ||
		(!contentfulTierDetails && !isContentfulLoading)
	) {
		return null;
	}

	if (isLoading) {
		return (
			<TierPointsInfoTagShimmer>
				<ShimmerPlaceholderBlockText />
			</TierPointsInfoTagShimmer>
		);
	}

	return (
		<StyledTierPointsInfoTag direction="row" alignItems="center">
			<img
				src={contentfulTierDetails?.image.fields.file.url}
				alt={contentfulTierDetails?.title}
			/>
			<span>{contentfulTierDetails?.title}</span>|
			<span>
				{t('loyalty_offer_points_template', {
					points: loyaltyDetails.redeemablePoints,
				})}
			</span>
		</StyledTierPointsInfoTag>
	);
});

const StyledTierPointsInfoTag = styled(Flex)`
	min-width: 182px;
	height: 38px;

	gap: 6px;

	color: white;
	font-size: 18px;
	font-weight: 700;

	padding: 11px 8px;
	border-radius: 8px;

	${getGradientBackground('purple-blue')}

	img {
		width: 16px;
		height: 16px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		align-self: end;
	}
`;

const TierPointsInfoTagShimmer = styled(Shimmer)`
	width: 182px;
	height: 38px;
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		align-self: end;
	}
`;
const ShimmerPlaceholderBlockText = styled(StyledTierPointsInfoTag)`
	display: block;
`;

import { ISortFunction } from '@move-frontend/utils';
import { SuggestionStore } from './SuggestionStore';
import { IFlightSearchSuggestion } from '../models/IFlightSearchSuggestion';

export function sortFlightSuggestions(
	suggestionStore: SuggestionStore<IFlightSearchSuggestion>,
) {
	const byIndexAndResultLength: ISortFunction<IFlightSearchSuggestion> = (
		a: IFlightSearchSuggestion,
		b: IFlightSearchSuggestion,
	) => {
		const query = suggestionStore.query.toLocaleLowerCase();

		return (
			a.value.toLocaleLowerCase().indexOf(query) -
				b.value.toLocaleLowerCase().indexOf(query) ||
			a.value.length - b.value.length
		);
	};

	return { byIndexAndResultLength };
}

import styled from '@emotion/styled';
import { ArrowIcon } from '../Icon';
import { Color } from '../../../features/shared/styles/Color';
import { ArrowLink } from './ArrowLink';

export const StyledArrowLink = styled(ArrowLink)<{
	isPrevious?: boolean;
	disabled: boolean;
}>`
	display: inline-flex;
	flex-direction: ${({ isPrevious }) => (isPrevious ? 'row-reverse' : 'row')};
	cursor: pointer;
	color: ${({ disabled }) =>
		disabled ? Color.Neutral70 : Color.PrimaryDefault};
	opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

	${ArrowIcon} {
		transform: ${({ isPrevious }) => (isPrevious ? 'rotate(180deg)' : 'none')};
		margin-right: ${({ isPrevious }) => (isPrevious ? '4px' : '0')};

		g {
			stroke: ${({ disabled }) =>
				disabled ? Color.Neutral70 : Color.PrimaryDefault};
		}
	}

	&:hover {
		color: ${({ disabled }) =>
			disabled ? Color.Neutral70 : Color.PrimaryDefault};
		text-decoration: none;

		&:after {
			${({ disabled }) => (disabled ? 'display: none;' : '')};
		}

		${ArrowIcon} {
			g {
				stroke: ${({ disabled }) =>
					disabled ? Color.Neutral70 : Color.PrimaryDefault};
			}
		}
	}

	&:focus-visible {
		outline: none;

		svg {
			outline: 2px solid ${Color.PrimaryDefault};
			border-radius: 2px;
		}
	}
`;

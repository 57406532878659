import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { ITheme, PropsOf } from '../../../../ui';
import { CloseButton } from '../button/CloseButton';
import { useDialogContext } from './DialogContext';
import { FONT_FAMILY_AVENIR_NEXT_LT_PRO } from '../../styles/Fonts';

export const StyledDialogCloseButton = styled(CloseButton)<{}, ITheme>`
	display: block;
	margin-left: auto;
	font-family: ${FONT_FAMILY_AVENIR_NEXT_LT_PRO};
	cursor: pointer;
`;

interface IDialogCloseButtonProps
	extends PropsOf<typeof StyledDialogCloseButton> {}

export const DialogCloseButton = forwardRef<
	HTMLButtonElement,
	IDialogCloseButtonProps
>((props, ref) => {
	const { close } = useDialogContext();

	return <StyledDialogCloseButton ref={ref} {...props} onClick={close} />;
});

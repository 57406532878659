import { IAnalyticsConfig } from './IAnalyticsConfig';

export function getAnalyticsConfig(): IAnalyticsConfig {
	return {
		legacyTrackingId: process.env.GOOGLE_ANALYTICS_ID,
		trackingId: process.env.GOOGLE_ANALYTICS_ID_V4,
		googleAdsTrackingId: process.env.GOOGLE_ADS_ID,
		googleAdsConversionLabel: process.env.GOOGLE_ADS_CONVERSION_LABEL,
		bingTrackingId: process.env.BING_TRACKING_ID,
		adaraTrackingId: process.env.ADARA_TRACKING_ID,
		adaraConvertionLabel: process.env.ADARA_CONVERSION_LABEL,
		adaraRTGLabel: process.env.ADARA_RETARGETING_LABEL,
		monsidoAdaToken: process.env.MONSIDO_ADA_TOKEN,
	};
}

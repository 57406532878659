import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { ITheme, PropsOf } from '../../../../ui';
import { DIALOG_CONTENT_MAX_WIDTH } from './constant';
import themed from '../../../../ui/themer/themed';

export interface IStyledDialogFooterProps {
	variation?: 'error';
}

export const StyledDialogFooter = styled.div<IStyledDialogFooterProps, ITheme>`
	background: ${themed(({ color, background }, { variation }) =>
		variation === 'error' ? color.state.error : background.default,
	)};
	color: ${themed(({ color }, { variation }) =>
		variation === 'error' ? color.text.inverted : color.text.default,
	)};
	padding: 20px;
`;

export const StyledDialogFooterContent = styled.div`
	max-width: ${DIALOG_CONTENT_MAX_WIDTH};
	margin-left: auto;
	margin-right: auto;
`;

// @ts-ignore
export interface IDialogFooterProps extends PropsOf<typeof StyledDialogFooter> {
	variation?: string;
}

export const DialogFooter = ({
	children,
	variation,
	...rest
}: PropsWithChildren<IDialogFooterProps>) => (
	<StyledDialogFooter {...rest}>
		<StyledDialogFooterContent>{children}</StyledDialogFooterContent>
	</StyledDialogFooter>
);

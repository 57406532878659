export const color = {
	brand: '#00778b',
	highlight: '#0077c8',
	background: {
		default: '#fff',
		light: '#efeff4',
		hover: '#f7f7f7',
	},
	text: {
		default: '#000',
		light: '#686868',
		dark: '',
		inverted: '#fff',
		hover: '#008eef',
	},
	border: {
		default: '#e5e5e5',
	},
	state: {
		warning: '#f3d67b',
		error: '#cc1100',
		success: '#008711',
		hover: '#008eef',
	},
};

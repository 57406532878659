import { AirlineStore, AirportStore, FlightStore } from '@move-frontend/dap';
import { IFlight } from '../../../shared/flight/domain/models/IFlight';
import { IFlightSearchSuggestion } from '../models/IFlightSearchSuggestion';
import { ISuggestionAdapter } from '../models/ISuggestionAdapter';

export function createFlightSuggestionsAdapters(
	airlineStore: AirlineStore,
	airportStore: AirportStore,
	flightStore: FlightStore<IFlight>,
): ISuggestionAdapter<IFlightSearchSuggestion>[] {
	return [
		createAirlineNameSuggestionAdapter(airlineStore),
		createAirportNameSuggestionAdapter(airportStore),
		createAirportCitySuggestionAdapter(airportStore),
		createFlightNumberSuggestionAdapter(flightStore),
	];
}

function createAirlineNameSuggestionAdapter(airlineStore: AirlineStore) {
	return () =>
		airlineStore.airlines
			.filter((airline) => airline.name)
			.map((airline) => ({
				type: 'item' as const,
				value: `${airline.name} (${airline.iata})`,
				stringValue: airline.name,
			}));
}

function createAirportNameSuggestionAdapter(airportStore: AirportStore) {
	return () =>
		airportStore.airports
			.filter((airport) => airport.name)
			.map((airport) => ({
				type: 'item' as const,
				value: `${airport.name} (${airport.iata})`,
				stringValue: airport.name,
			}));
}

function createAirportCitySuggestionAdapter(airportStore: AirportStore) {
	return () =>
		airportStore.airports
			.filter((airport) => airport.city)
			.map((airport) => ({
				type: 'item' as const,
				value: `${airport.city!} (${airport.iata})`,
				stringValue: airport.city!,
			}));
}

function createFlightNumberSuggestionAdapter(flightStore: FlightStore) {
	return () => {
		const flightNumbers = flightStore.flights.map(
			(flight) => flight.operatingAirlineFlightNumber,
		);

		// Make sure no duplicate flight numbers are suggested.
		return flightNumbers
			.filter(
				(flightNumber, index) => flightNumbers.indexOf(flightNumber) === index,
			)
			.map((flightNumber) => ({
				type: 'item' as const,
				value: flightNumber,
				stringValue: flightNumber,
			}));
	};
}

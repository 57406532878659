import styled from '@emotion/styled';
import { ITheme, Link } from '../../../../ui';
import themed from '../../../../ui/themer/themed';

export const LinkTextNoDecoration = styled(Link)<{}, ITheme>`
	display: flex;
	align-items: center;
	gap: 6px;
	color: ${themed(({ color }) => color.text.default)};
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
	&:hover:not(:disabled) {
		text-decoration: none;
	}
`;

import React, { FunctionComponent, ReactNode } from 'react';
import Downshift, { DownshiftProps } from 'downshift';
import { SelectRoot } from './SelectRoot';
import {
	getItemDisplayValueDefault,
	getItemKeyDefault,
	IDropdownItem,
} from '../DropdownList';

export interface ISelectProps<T = IDropdownItem> extends DownshiftProps<T> {
	items: T[];
	itemToKey?: (item: T) => string;
	itemToString?: (item: T | null) => string;
	dropdownItemValue?: (item: T | null) => string | JSX.Element;
	dropdownSectionHeaderValue?: (item: T | null) => string | JSX.Element;
	placeholder?: string;
	ariaLabel?: string;
	ariaLabelledBy?: string;
	disabled?: boolean;
	invalid?: boolean;
	allowsRemovingSelection?: boolean;
	onClickRemoveSelection?: () => void;
	className?: string;
	icon?: ReactNode;
	defaultScrollToNthItem?: number;
	placeholderAsLabel?: boolean;
	id?: string;
}

export interface ISelectComponent<Props = ISelectProps>
	extends FunctionComponent<Props> {}

export const Select = <TProps extends ISelectProps = ISelectProps>({
	items,
	itemToKey,
	itemToString,
	dropdownSectionHeaderValue,
	placeholder,
	ariaLabel,
	ariaLabelledBy,
	disabled,
	invalid,
	allowsRemovingSelection,
	onClickRemoveSelection,
	className,
	icon,
	defaultScrollToNthItem,
	placeholderAsLabel,
	id,
	...rest
}: TProps) => {
	const getItemKey = itemToKey || getItemKeyDefault;
	const getItemDisplayValue = itemToString || getItemDisplayValueDefault;

	return (
		<Downshift itemToString={getItemDisplayValue} {...rest}>
			{({ getRootProps, ...props }) => (
				<SelectRoot
					{...getRootProps({ refKey: 'innerRef' })}
					getItemDisplayValue={
						rest.dropdownItemValue
							? rest.dropdownItemValue
							: getItemDisplayValue
					}
					getSectionHeaderDisplayValue={dropdownSectionHeaderValue}
					getItemKey={getItemKey}
					onClickRemoveSelection={onClickRemoveSelection}
					items={items}
					disabled={disabled}
					invalid={invalid}
					allowsRemovingSelection={allowsRemovingSelection}
					className={className}
					ariaLabel={ariaLabel ?? placeholder}
					ariaLabelledBy={ariaLabelledBy}
					placeholder={placeholder}
					icon={icon}
					defaultScrollToNthItem={defaultScrollToNthItem}
					placeholderAsLabel={placeholderAsLabel}
					id={id}
					{...props}
				/>
			)}
		</Downshift>
	);
};

import themed from '../../themer/themed';
import Responsive from '../../utils/Responsive';

export type HeaderLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export const getHeaderStyleCss = (headerType: HeaderLevel) =>
	themed(
		({ typography: { header, mobile } }) => `
	font-size: ${header[headerType].size};
	margin: ${header.margin};
	font-weight: ${header[headerType].fontWeight};
	display: block;
	line-height: 1.388;
	color: ${header[headerType].color};
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			font-size: ${mobile.header[headerType].size};
	}
	`,
	);

import { useMemo } from 'react';
import UrlPattern from 'url-pattern';
import { useHashChange } from './useHashChange';

interface IHashMatch<TMatches extends {} = {}> {
	hash?: string;
	matches?: TMatches;
}

/**
 * @see https://github.com/snd/url-pattern
 */
export function useHashMatch<TMatches extends {} = {}>(
	pattern: string,
): IHashMatch<TMatches> {
	const { hash } = useHashChange();

	const resolver = useMemo(() => new UrlPattern(pattern), [pattern]);

	return {
		hash,
		matches: resolver.match(hash) ?? undefined,
	};
}

import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

interface IChipProps {
	variation?: 'light' | 'highlight' | 'brand' | 'error';
	icon?: React.ReactNode;
	className?: string;
}

export function getChipBackground({
	variation,
	theme,
}: IChipProps & { theme: ITheme }) {
	switch (variation) {
		case 'error':
			return theme.chip.background.error ?? theme.color.state.error;

		case 'brand':
			return theme.chip.background.brand ?? theme.color.brand;

		case 'highlight':
			return theme.chip.background.highlight ?? theme.background.highlight;

		case 'light':
			return theme.chip.background.light ?? theme.background.light;

		default:
			return theme.chip.background.default ?? theme.background.light;
	}
}

export function getChipFontColor({
	variation,
	theme,
}: IChipProps & { theme: ITheme }) {
	const chipFontColor = theme.chip.font.color ?? {};

	switch (variation) {
		case 'error':
			return chipFontColor.error ?? theme.color.text.inverted;

		case 'brand':
			return chipFontColor.brand ?? theme.color.text.inverted;

		case 'highlight':
			return chipFontColor.highlight ?? theme.color.text.inverted;

		case 'light':
			return chipFontColor.light ?? theme.color.text.default;

		default:
			return chipFontColor.default ?? theme.color.text.default;
	}
}

export const StyledBaseChip = styled.span<{}, ITheme>`
	font-size: ${themed(({ typography }) => typography.small.size)};
	border-radius: 1em;
	padding: 0.3em 0.9em;
	white-space: nowrap;
`;

export const StyledChip = styled(StyledBaseChip)<IChipProps, ITheme>`
	background: ${getChipBackground};
	color: ${getChipFontColor};

	svg {
		margin-left: -8px;
		margin-right: 6px;
	}
`;

export const Chip = ({
	children,
	icon,
	...rest
}: PropsWithChildren<IChipProps>) => (
	<StyledChip {...rest} className="chip">
		{icon && icon}
		{children}
	</StyledChip>
);

import React from 'react';
import styled from '@emotion/styled';
import { DropdownItemHint } from './DropdownItemHint';
import { ITheme } from '../../../themer';

export const StyledDropdownItem = styled.div<{}, ITheme>`
	display: flex;
	justify-content: space-between;
	position: relative;
	line-height: ${({ theme: { input } }) => input.lineHeight};
`;

interface IDropdownItemProps {
	displayValue: string | JSX.Element;
	hint?: string;
}

export const DropdownItem = ({ displayValue, hint }: IDropdownItemProps) => (
	<StyledDropdownItem>
		{typeof displayValue === 'string' ? (
			<div>{displayValue}</div>
		) : (
			displayValue
		)}
		{hint && <DropdownItemHint>{hint}</DropdownItemHint>}
	</StyledDropdownItem>
);

import styled from '@emotion/styled';
import React from 'react';
import { NavigationDotButton } from './NavigationDot';
import { useTranslation } from '../../i18n/translate/useTranslation';

interface INavigationDotsProps {
	count: number;
	onActiveChange: (index: number) => void;
	active: number;
	className?: string;
}

export const NavigationDots = ({
	count,
	onActiveChange,
	active,
	className,
}: INavigationDotsProps) => {
	const { t } = useTranslation();
	const items = [...Array(count)].map((_item, index) => index);

	return (
		<Wrapper className={className}>
			{items.map((index) => (
				<NavigationDotButton
					key={index}
					onClick={() => onActiveChange(index)}
					active={index === active}
					aria-label={
						index === active
							? t('alert_banner_header_navigate_slide_active', { index })
							: t('alert_banner_header_navigate_slide_active', {
									index: index + 1,
							  })
					}
				/>
			))}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	text-align: center;
`;

export enum Keyword {
	Shop = 'shop',
	Dine = 'dine',
	Relax = 'relax',
	FullService = 'full service',
	Coffee = 'coffee',
	Bar = 'bar',
	Breakfast = 'breakfast',
	TravelEssentials = 'travel essentials',
}

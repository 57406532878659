export const gradients = {
	'blue-blue': {
		gradient: 'linear-gradient(283deg, #4698cb 0%, #0077c8 100%)',
		fallback: '#2386ca',
	},
	'green-blue': {
		gradient: 'linear-gradient(103deg, #00778b 0%, #0077b9 100%)',
		fallback: '#0077a2',
	},
	'purple-blue': {
		gradient: 'linear-gradient(103deg, #912199 0%, #2f5bb9 100%)',
		fallback: '#5f3fa9',
	},
};

export type IGradients = typeof gradients;
export type IGradientName = keyof IGradients;

export function getGradientBackground(gradientName: IGradientName) {
	const { fallback, gradient } = gradients[gradientName];

	return `
		background: ${fallback};
		background: ${gradient};
	`;
}

import Responsive from '../../utils/Responsive';

export type NumberOfColumns = Responsive.IValueOrResponsiveValue<number>;

const DEFAULT_COLUMNS: Responsive.IValueOrResponsiveValue<number> = [
	2,
	{ tablet: 1 },
];

export const theme = {
	columns: 12,
	gutter: {
		horizontal: {
			default: '24px',
			desktopXL: '',
			tablet: '',
			mobileXL: '16px',
			mobile: '',
		},
		vertical: {
			default: '24px',
			desktopXL: '',
			tablet: '',
			mobileXL: '16px',
			mobile: '',
		},
	},
	row: {
		defaultColumns: DEFAULT_COLUMNS,
	},
};

import React, { MouseEvent, PropsWithChildren } from 'react';
import { IUIComponentProps, PolyComponent } from '../../types';
import { IResponsiveImageSource } from '../Image';
import { isRelativeUrl } from '../Link';
import {
	IStyledCardProps,
	StyledCardChildren,
	StyledCardContent,
	StyledCardLink,
	StyledCardPicture,
	StyledCardTitle,
	withCardContainerContext,
} from '../Card';

import { StyledContactCard } from './StyledContactCard';
import { StyledContactCardPhoneNumber } from './StyledContactCardPhoneNumber';
import { StyledContactCardPhoneLink } from './StyledContactCardPhoneLink';
import { PhoneIcon } from '../Icon';
import { UnstyledButton, UnstyledLi } from '../List';

export interface IContactCardProps extends IUIComponentProps {
	title: string;
	screenReaderLinkTitle?: string;
	phoneNumber?: string;
	imageSource: IResponsiveImageSource;
	imageAlt?: string;
	linkHref?: string;
	linkTitle?: string;
	onClick?: (e: MouseEvent<HTMLLIElement>) => void;
	phoneImageLabel?: string;
	fullyClickable?: boolean;
}

export const ContactCard = withCardContainerContext<
	PropsWithChildren<IContactCardProps>
>(
	({
		title,
		phoneNumber,
		imageSource,
		imageAlt,
		children,
		linkHref,
		linkTitle,
		screenReaderLinkTitle,
		phoneImageLabel,
		fullyClickable,
		...rest
	}) => {
		const titleId = title.trim().toLowerCase().replace(/\s+/g, '-');

		return (
			<PolyStyledContactCard
				{...rest}
				as={fullyClickable ? UnstyledButton : UnstyledLi}
			>
				<StyledCardPicture source={imageSource} alt={imageAlt} />
				<StyledCardContent>
					<div>
						<StyledCardTitle styling="h3" id={`${titleId}-title`}>
							{title}
						</StyledCardTitle>
						<StyledCardChildren>{children}</StyledCardChildren>
						{phoneNumber && (
							<StyledContactCardPhoneNumber>
								{phoneNumber}
							</StyledContactCardPhoneNumber>
						)}
						{linkHref && linkTitle && (
							<StyledCardLink
								href={linkHref}
								target={!isRelativeUrl(linkHref) ? '_blank' : undefined}
								screenReaderTitle={screenReaderLinkTitle}
								aria-describedby={`${titleId}-title`}
							>
								{linkTitle}
							</StyledCardLink>
						)}
					</div>
					{phoneNumber && (
						<StyledContactCardPhoneLink href={`tel:${phoneNumber}`}>
							<PhoneIcon aria-label={phoneImageLabel} />
						</StyledContactCardPhoneLink>
					)}
				</StyledCardContent>
			</PolyStyledContactCard>
		);
	},
);

const PolyStyledContactCard: PolyComponent<'div', IStyledCardProps> =
	StyledContactCard;

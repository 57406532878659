import styled from '@emotion/styled';
import { AuthFormSection as Section } from './AuthFormSection';
import { ITheme } from '../../../../ui';
import themed from '../../../../ui/themer/themed';
import Responsive from '../../../../ui/utils/Responsive';

export const AuthFormCheckboxesSection = styled(Section)<{}, ITheme>`
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		border-top: ${themed(({ border }) => border.default)};
		padding-top: 24px;
	}
`;

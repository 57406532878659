import React from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import { VisuallyHidden } from '..';

const Bar = styled.div<{}, ITheme>`
	content: '';
	display: inline-block;
	position: absolute;
	width: 0;
	height: 100%;
	left: 50%;
	text-align: center;
`;

const StyledLoaderBar = styled.div<{}, ITheme>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: ${themed(({ pageLoader }) => pageLoader.height)};
	background-color: ${themed(({ pageLoader }) => pageLoader.colors[0])};
	z-index: 99999;

	${Bar}:nth-of-type(1) {
		background-color: ${themed(({ pageLoader }) => pageLoader.colors[1])};
		animation: ${themed(
			({ pageLoader }) =>
				`loading ${pageLoader.duration * 3}ms linear infinite`,
		)};
	}

	${Bar}:nth-of-type(2) {
		background-color: ${themed(({ pageLoader }) => pageLoader.colors[2])};
		animation: ${themed(
			({ pageLoader }) =>
				`loading ${pageLoader.duration * 3}ms linear ${
					pageLoader.duration
				}ms infinite`,
		)};
	}

	${Bar}:nth-of-type(3) {
		background-color: ${themed(({ pageLoader }) => pageLoader.colors[0])};
		animation: ${themed(
			({ pageLoader }) =>
				`loading ${pageLoader.duration * 3}ms linear ${
					pageLoader.duration * 2
				}ms infinite`,
		)};
	}

	@keyframes loading {
		from {
			left: 50%;
			width: 0;
			z-index: 100;
		}
		33.3333% {
			left: 0;
			width: 100%;
			z-index: 10;
		}
		to {
			left: 0;
			width: 100%;
		}
	}
`;

interface IPageLoaderBarProps {
	isActive: boolean;
	message?: string;
}

export const PageLoaderBar = ({ isActive, message }: IPageLoaderBarProps) =>
	isActive ? (
		<StyledLoaderBar role="status">
			<Bar />
			<Bar />
			<Bar />
			<VisuallyHidden>{message ?? 'Loading...'}</VisuallyHidden>
		</StyledLoaderBar>
	) : null;

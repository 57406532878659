import {
	AirlineStore,
	AirportStore,
	IAirline,
	IAirport,
	IFlightEnricher,
} from '@move-frontend/dap';
import { getPublicStatus } from '../../../status/domain/use-cases/getPublicStatus';
import { IEnrichedFlightProps } from '../models/IEnrichedFlight';
import { IFlight } from '../models/IFlight';

export function createFlightEnrichers(
	airlineStore: AirlineStore,
	airportStore: AirportStore,
) {
	const airlineAndAirportEnricher: IFlightEnricher<
		IFlight,
		IEnrichedFlightProps
	> = (flight: IFlight) => {
		const {
			icaoOperatingAirline,
			departureAirport,
			arrivalAirport,
			baseAirport,
			viaAirport,
		} = flight;

		const airlineDetails = airlineStore.airlines.find(
			(airline: IAirline) => airline.icao === icaoOperatingAirline,
		);
		const departureAirportDetails = airportStore.airports.find(
			(airport: IAirport) => airport.iata === departureAirport,
		);
		const arrivalAirportDetails = airportStore.airports.find(
			(airport: IAirport) => airport.iata === arrivalAirport,
		);
		const baseAirportDetails = airportStore.airports.find(
			(airport: IAirport) => airport.iata === baseAirport,
		);
		const viaAirportDetails = airportStore.airports.find(
			(airport: IAirport) => airport.iata === viaAirport,
		);

		return {
			...flight,
			airlineDetails,
			departureAirportDetails,
			arrivalAirportDetails,
			baseAirportDetails,
			viaAirportDetails,
		};
	};

	const publicStatusEnricher: IFlightEnricher<IFlight, IEnrichedFlightProps> = (
		flight: IFlight,
	) => {
		const publicStatus = getPublicStatus(flight);

		return { ...flight, publicStatus };
	};

	return { airlineAndAirportEnricher, publicStatusEnricher };
}

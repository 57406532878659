import React, { useEffect, useMemo, useRef } from 'react';
import { LocaleCode } from '../shared/i18n/locale/models/LocaleCode';
import { useLocale } from '../shared/i18n/locale/useLocale';

import { useRemoteConfigValue } from '../shared/remote-config/ui/useRemoteConfigValue';
import { chatbotLanguages } from './chatbotLanguages';
import { Color } from '../shared/styles/Color';
import { css, Global } from '@emotion/core';

const BASE_URL = 'https://external.airport.ai/';

export const Chatbot = () => {
	const { value: chatbotDisabledSpanish } = useRemoteConfigValue(
		'chatbot_disabled_spanish',
	);
	const { value: chatbotEnabled } = useRemoteConfigValue('chatbot_enabled');
	const { code: locale } = useLocale();

	const shouldDisableChatbot = useMemo(
		() => locale === 'es-US' && chatbotDisabledSpanish,
		[locale, chatbotDisabledSpanish],
	);

	const ref = useRef<HTMLScriptElement | null>(null);

	useEffect(() => {
		if (!ref.current || !chatbotEnabled || shouldDisableChatbot)
			return undefined;

		if (window.AirportAI) {
			loadChatbot(locale);
			return undefined;
		}

		ref.current.addEventListener('load', () => loadChatbot(locale));

		return () => {
			if (ref.current)
				ref.current.removeEventListener('load', () => loadChatbot(locale));
		};
	}, [ref.current, chatbotEnabled, shouldDisableChatbot]);

	useEffect(() => {
		window.addEventListener('click', closeChatBot);
		return () => {
			window.removeEventListener('click', closeChatBot);
		};
	}, []);

	return (
		<>
			{/* Chatbot will be loaded here */}
			<div className="airportAI-chatPlugin" />
			<script
				ref={ref}
				id="airportAI-jssdk"
				type="text/javascript"
				defer
				src={`${BASE_URL}js/sdk/web.js`}
			/>
			<Global
				styles={css`
					// To be removed once chatbot releases spanish version
					#airportAI-root {
						display: ${shouldDisableChatbot ? 'none' : 'block'};
					}
					#airportAI-chat-launcher {
						&:focus-visible {
							border-radius: 2px;
							box-shadow: 0 0 0 3px ${Color.AccentDefault};
							-webkit-text-decoration: underline;
							text-decoration: underline;
						}
					}
				`}
			/>
		</>
	);
};

const loadChatbot = (language: LocaleCode) => {
	// airport AI expects the settings in window
	window.AirportAI_settings = {
		language: chatbotLanguages[language],
		airportCode: 'CLT',
	};

	// Call the script that we loaded in the render bellow.
	window.AirportAI?.init({
		clientId: 'c4c8f9d4-deb6-4710-9859-eaecd35098d0',
		url: BASE_URL,
	});
};

const closeChatBot = () => {
	window.AirportAI?.toggleChatPlugin(false);
};

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		AirportAI_settings: {
			language: string;
			airportCode: string;
		};
		AirportAI?: {
			init: (args: { clientId: string; url: string }) => void;
			toggleChatPlugin: (open: boolean) => void;
		};
	}
}

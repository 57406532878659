import { contentful } from '../../../shared/api/contentful';
import { LOYALTY_TIER_TYPE_ID } from '../../../shared/api/contentful/constants';
import {
	ILoyaltyTier,
	ILoyaltyTierEntry,
} from '../../../shared/api/contentful/types/ILoyaltyTier';

export async function getLoyaltyTiers(): Promise<ILoyaltyTier[]> {
	const loyaltyTiers = await contentful()
		.entry<ILoyaltyTierEntry>(LOYALTY_TIER_TYPE_ID)
		.getAll({});

	return loyaltyTiers.map((loyalty) => loyalty.fields);
}

import React from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../../../ui';
import doneIcon from '../../../../../assets/done.svg?reference';

interface IGrayMarketingDoneListProps {
	title: string;
	items: string[];
}
export const GrayMarketingDoneList = ({
	title,
	items,
}: IGrayMarketingDoneListProps) => (
	<StyledGrayMarketingContainer>
		<StyledGrayMarketingTitle>{title}</StyledGrayMarketingTitle>
		<StyledGrayMarketingList role="list">
			{items.map((item) => (
				<StyledGrayMarketingListItem key={item} role="listitem">
					<img src={doneIcon} alt="" role="presentation" />
					<span>{item}</span>
				</StyledGrayMarketingListItem>
			))}
		</StyledGrayMarketingList>
	</StyledGrayMarketingContainer>
);
const StyledGrayMarketingTitle = styled.h3`
	font-size: 21px;
`;
const StyledGrayMarketingContainer = styled.div<{}, ITheme>`
	border-radius: 8px;
	padding: 24px;
	background: ${({ theme }) => theme.color.background.hover};
`;

const StyledGrayMarketingList = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
`;

const StyledGrayMarketingListItem = styled.li`
	display: flex;
	gap: 16px;
`;

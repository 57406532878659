import { action, computed, makeObservable, observable } from 'mobx';
import { AuthState } from './state/AuthState';
import { cognitoClient } from '../../../shared/api/cognito';
import { getAuthState } from './state/getAuthState';

export class AuthStore {
	authState = AuthState.Unknown;

	isLoading = false;

	finishedInitialLoad = false;

	reload = this.handleAuthChange.bind(this);

	constructor() {
		makeObservable<
			AuthStore,
			'handleAuthChange' | 'updateAuthState' | 'setAuthState'
		>(this, {
			authState: observable,
			isLoading: observable,
			finishedInitialLoad: observable,
			isAuthenticated: computed,
			handleAuthChange: action.bound,
			updateAuthState: action,
			setAuthState: action,
		});

		cognitoClient.addChangeListener(this.handleAuthChange);
		this.updateAuthState();
	}

	public dispose() {
		cognitoClient.removeChangeListener(this.handleAuthChange);
	}

	get isAuthenticated() {
		return this.authState === AuthState.Authenticated;
	}

	protected handleAuthChange() {
		this.updateAuthState();
	}

	protected async updateAuthState() {
		if (this.isLoading) {
			return;
		}

		this.authState = AuthState.Unknown;

		this.isLoading = true;

		const authState = await getAuthState();

		this.setAuthState(authState);
	}

	protected setAuthState(authState: AuthState) {
		this.authState = authState;
		this.isLoading = false;
		this.finishedInitialLoad = true;
	}
}

import React, { FC } from 'react';
import { ILinkProps, Link } from '../../../../ui';
import { useUrlForPageId } from '../../../app/pages/ui/utils/useUrlForPageId';

export interface IPageLinkProps extends Omit<ILinkProps, 'href'> {
	pageId: string;
}

export const PageLink: FC<IPageLinkProps> = ({ pageId, ...rest }) => {
	const href = useUrlForPageId(pageId);

	return <Link href={href} {...rest} />;
};

import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

interface IStyledAccordionContentProps {
	isVisible: boolean;
}

export const AccordionContent = styled.div<
	IStyledAccordionContentProps,
	ITheme
>`
	display: ${({ isVisible }) =>
		isVisible ? 'block' : 'none'}; // Use display to control visibility

	position: ${({ isVisible }) => (isVisible ? '' : 'absolute')};
	left: ${({ isVisible }) => (isVisible ? '' : '-9999px')};
	height: ${({ isVisible }) => (isVisible ? 'unset' : 0)};
	max-width: ${themed(({ layout }) => layout.main.textMaxWidth)};

	padding: ${themed(({ accordion }) => accordion.content.padding)};
	margin: ${themed(({ accordion }) => accordion.content.margin)};

	a {
		text-decoration: underline;
	}
`;

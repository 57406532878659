import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { ITextFieldProps, TextField } from '../../../../ui';

export type LicensePlateNumberProps = Partial<ITextFieldProps> & {
	validateConfirmation?: boolean;
};

export const LicensePlateNumber = ({
	validateConfirmation,
	...props
}: LicensePlateNumberProps) => {
	const { t } = useTranslation();
	const { register, errors, trigger } = useFormContext();

	const onChange = validateConfirmation
		? () => {
				if (errors.confirmLicensePlateNumber) {
					trigger('confirmLicensePlateNumber');
				}
		  }
		: undefined;

	return (
		<TextField
			name="licensePlateNumber"
			type="text"
			label={t('user_form_license_plate_number_label')}
			placeholder={t('user_form_license_plate_number_placeholder')}
			ref={register()}
			onChange={onChange}
			invalid={!!errors.licensePlateNumber}
			error={errors.licensePlateNumber?.message}
			autoComplete="off"
			{...props}
		/>
	);
};

import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import Responsive from '../../utils/Responsive';

export interface IBoxProps {
	width?: Responsive.IValueOrResponsiveValue;
}

const getWidth = (width: string) => `width: ${width};`;

export const Box = styled('div', {
	shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'width',
})`
	${Responsive.getStyles<string, any>('width', getWidth)}
`;

import { getLocalizedPath } from './getLocalizedPath';
import { ILocale } from '../../../../shared/i18n/locale/models/ILocale';
import { ISiteMap } from '../../domain/models/ISiteMap';

export function getUrlForPageId(
	pageId: string,
	{ pathToPageIdMap, locale }: ISiteMap,
) {
	const matchedPage = Object.entries(pathToPageIdMap).find(
		([, id]) => id === pageId,
	);

	if (!matchedPage) return getFallbackUrl(pageId, locale);

	const [path] = matchedPage;

	return getLocalizedPath(path, locale);
}

function getFallbackUrl(pageId: string, locale: ILocale) {
	return getLocalizedPath(`/${pageId}`, locale);
}

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Base, IBaseButtonProps } from './Base';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import { getCommonButtonStyles } from './common';
import { ButtonText } from './ButtonText';

export const StyledButton = styled(Base)<IBaseButtonProps, ITheme>`
	max-width: 100%;

	${({ inverted, secondary }) =>
		themed((theme) =>
			getCommonButtonStyles(
				secondary ? 'secondary' : 'primary',
				theme,
				inverted,
			),
		)}
`;

export interface IButtonProps extends IBaseButtonProps {
	children: ReactNode;
}

export const Button = ({ children, ...props }: IButtonProps) => (
	<StyledButton {...props}>
		<ButtonText>{children}</ButtonText>
	</StyledButton>
);

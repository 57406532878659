/* eslint-disable no-restricted-imports */
import { useRouter } from 'next/router';
import { IUrl } from './models/IUrl';

export type INavigate = (url: IUrl) => Promise<boolean>;

export function useNavigate(): INavigate {
	const router = useRouter();

	return (url: IUrl) => router.push(url);
}

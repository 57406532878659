import React, {
	DetailedHTMLProps,
	forwardRef,
	InputHTMLAttributes,
	RefObject,
} from 'react';
import styled from '@emotion/styled';

import { IUIComponentProps } from '../../types';
import { CheckIcon } from '../Icon';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

import { VisuallyHiddenInput } from '../Input';

interface ISwitchProps
	extends IUIComponentProps,
		DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	labelId: string;
	invalid?: boolean;
	ariaLabel?: string;
	ref?: RefObject<HTMLInputElement> | null;
}

export const Switch = forwardRef<HTMLInputElement, ISwitchProps>(
	(
		{ className, ariaLabel, labelId, disabled, invalid, ...rest }: ISwitchProps,
		ref,
	) => (
		<StyledLabelSwitch>
			<StyledVisuallyHiddenInput
				role="switch"
				ref={ref}
				type="checkbox"
				aria-labelledby={labelId}
				aria-invalid={invalid}
				{...rest}
			/>
			<StyledSwitch className="switch">
				<CheckIcon />
			</StyledSwitch>
		</StyledLabelSwitch>
	),
);

const StyledLabelSwitch = styled.div<{}, ITheme>`
	position: relative;
	display: inline-block;
	width: 52px;
	height: 32px;
`;

const StyledSwitch = styled.div`
	position: absolute;
	cursor: pointer;
	background-color: ${themed(({ switcher }) => switcher.backgroundColor)};
	border: 2px solid ${themed(({ switcher }) => switcher.borderColor)};
	border-radius: 25px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: all 0.2s ease;

	svg {
		position: absolute;
		right: 6px;
		top: 6px;
		width: 16px;
		height: 16px;
		opacity: 0;
		transition: opacity 0.2s ease;
		path {
			fill: ${themed(
				({ switcher, color }) => switcher.active.color || color.brand,
			)};
		}
	}

	::before {
		position: absolute;
		content: '';
		left: 6px;
		top: 6px;
		width: 16px;
		height: 16px;
		background-color: #aaa;
		border-radius: 50%;
		transition: transform 0.3s ease;
	}
`;

const StyledVisuallyHiddenInput = styled(VisuallyHiddenInput)<{}, ITheme>`
	:checked {
		+ ${StyledSwitch}::before {
			transform: translateX(25px);
			background-color: ${themed(({ color }) => color.background.default)};
			width: 24px;
			height: 24px;
			left: -4px;
			top: 2px;
		}

		+ ${StyledSwitch} {
			background-color: ${themed(
				({ switcher, color }) => switcher.active.color || color.brand,
			)};
			border-color: ${themed(
				({ switcher, color }) => switcher.active.color || color.brand,
			)};

			svg {
				opacity: 1;
			}
		}
	}

	:focus-visible + ${StyledSwitch} {
		outline: 2px solid Highlight; // use browser's native focus colour on Firefox
		outline: 2px solid -webkit-focus-ring-color; // use browser's native focus colour
		outline-offset: 2px;
	}
`;

export enum ParkingPageId {
	// New Booking Flow
	NewBookingAvailability = 'parking-new-booking',
	NewBookingExtras = 'parking-new-booking-extras',
	NewBookingDetails = 'parking-new-booking-details',
	NewBookingPayment = 'parking-new-booking-payment',
	NewBookingConfirmation = 'parking-new-booking-confirmation',

	// Partial Amend Flow
	PartialAmendBookingDetails = 'parking-partial-amend-booking-details',

	// Full Amend Flow
	FullAmendBookingAvailability = 'parking-full-amend-availability',
	FullAmendBookingExtras = 'parking-full-amend-extras',
	FullAmendBookingPayment = 'parking-full-amend-payment',
	FullAmendBookingConfirmation = 'parking-full-amend-booking-confirmation',

	// Cancel Flow
	CancelBookingConfirmation = 'parking-cancel-booking-confirmation',

	// All bookings
	ParkingBookings = 'parking-bookings',
}

import {
	action,
	computed,
	IReactionDisposer,
	makeObservable,
	observable,
	reaction,
} from 'mobx';
import { RemoteDataStore } from '@move-frontend/utils';
import { IContentfulTierDetailsResponse } from '../../../shared/api/gateway/types/ICustomerDetailsResponse';
import { getLoyaltyTiers } from '../use-cases/getLoyaltyTiers';
import { ILoyaltyTier } from '../../../shared/api/contentful/types/ILoyaltyTier';
import { LoyaltyAccountStore } from './LoyaltyAccountStore';

type LoyaltyTierParams = Record<never, never>;
type ContentfulTier = IContentfulTierDetailsResponse | undefined;

export class LoyaltyTierStore extends RemoteDataStore<
	LoyaltyTierParams,
	IContentfulTierDetailsResponse[]
> {
	loadingContentfulTierDetails = false;

	private _contentfulLastTierDetails: ContentfulTier = undefined;

	private _contentfulTierDetails: ContentfulTier = undefined;

	private _contentfulNextTierDetails: ContentfulTier = undefined;

	private disposeLoyaltyReaction: IReactionDisposer;

	constructor(private readonly loyaltyAccountStore: LoyaltyAccountStore) {
		super();

		makeObservable<
			LoyaltyTierStore,
			| '_contentfulLastTierDetails'
			| '_contentfulTierDetails'
			| '_contentfulNextTierDetails'
			| 'handleLoyaltyChange'
			| 'handleResponse'
		>(this, {
			_contentfulLastTierDetails: observable,
			_contentfulTierDetails: observable,
			_contentfulNextTierDetails: observable,
			loadingContentfulTierDetails: observable,
			isLoading: computed,
			isLoadingContentfulTierDetails: computed,
			contentfulTierDetails: computed,
			handleLoyaltyChange: action.bound,
			handleResponse: action,
			clear: action,
		});

		this.disposeLoyaltyReaction = reaction(
			() => this.loyaltyAccountStore.loyaltyDetails,
			this.handleLoyaltyChange,
		);

		this.handleLoyaltyChange();
	}

	public dispose() {
		this.disposeLoyaltyReaction();
	}

	get isLoading() {
		return (
			!this.loyaltyAccountStore.loyaltyDetails ||
			this.isLoadingContentfulTierDetails
		);
	}

	get isLoadingContentfulTierDetails() {
		return super.isLoading;
	}

	get contentfulLastTierDetails() {
		return this._contentfulLastTierDetails;
	}

	get contentfulTierDetails() {
		return this._contentfulTierDetails;
	}

	get contentfulNextTierDetails() {
		return this._contentfulNextTierDetails;
	}

	private async handleLoyaltyChange() {
		if (!this.loyaltyAccountStore.loyaltyDetails) {
			this.clear();
			this.cancelCurrentRequest();
			return;
		}

		await this.load();
	}

	async load() {
		if (!this.loyaltyAccountStore.loyaltyDetails) {
			return;
		}

		await super.load({});
	}

	protected performRequest() {
		return getLoyaltyTiers();
	}

	protected handleResponse(response: IContentfulTierDetailsResponse[]) {
		const { loyaltyDetails } = this.loyaltyAccountStore;
		if (!loyaltyDetails) {
			this.clear();
			return;
		}
		const { tierFromLastPeriod, rewardTier, tier, nextTier } = loyaltyDetails;

		this._contentfulLastTierDetails = this.getContentfulTierDetails(
			response,
			tierFromLastPeriod,
		);

		// If backend doesn't return a RewardTier, we should use Tier details
		const currentUserTier = rewardTier || tier;
		this._contentfulTierDetails = this.getContentfulTierDetails(
			response,
			currentUserTier,
		);

		this._contentfulNextTierDetails = this.getContentfulTierDetails(
			response,
			nextTier,
		);
	}

	private getContentfulTierDetails(
		response: IContentfulTierDetailsResponse[],
		tier: string | undefined,
	) {
		if (!tier) {
			return undefined;
		}
		return response.find((t: ILoyaltyTier) => t.id === tier?.toLowerCase());
	}

	async reset() {
		await super.reset();
	}

	clear() {
		this._contentfulLastTierDetails = undefined;
		this._contentfulTierDetails = undefined;
		this._contentfulNextTierDetails = undefined;
	}
}

import { IPathToPageIdMap } from '@move-frontend/contentful-routes';
import { parkingPagePathToPageIdMap } from '../../../../parking/domain/pages/parkingPagePathToPageIdMap';
import {
	BusinessPageId,
	CareersPageId,
	CommunityPageId,
	NewsPageId,
	PassengersPageId,
} from './PageId';
import { accountPagePathToPageIdMap } from '../../../../account/domain/pages/accountPagePathToPageIdMap';

export const customPagePathToPageIdMap: IPathToPageIdMap = {
	'/': PassengersPageId.Home,
	'/flights/': PassengersPageId.Flights,
	'/flight/': PassengersPageId.FlightDetail,
	'/parking/': PassengersPageId.Parking,
	'/airport-info/security/': PassengersPageId.SecurityWaitingTimes,
	'/employee-lots/': PassengersPageId.EmployeeLots,
	'/airport-info/bus-wait-times/': PassengersPageId.BusWaitTimes,
	'/airport-info/live-parking-availability/':
		PassengersPageId.ParkingAvailability,
	'/shop-dine-and-relax/': PassengersPageId.ShopDineAndRelax,
	'/shop-dine-and-relax/view/': PassengersPageId.ShopDineAndRelaxDetail,
	'/reset-password/': PassengersPageId.ResetPassword,
	'/business/': BusinessPageId.Home,
	'/community/': CommunityPageId.Home,
	'/careers/': CareersPageId.Home,
	'/royalty-information': PassengersPageId.RoyaltyProgram,
	'/account/': PassengersPageId.AccountOverview,
	'/news/': NewsPageId.Home,
	'/news/stories/': NewsPageId.NewsStories,
	'/news/press-releases/': NewsPageId.PressReleases,
	'/news/blogs/': NewsPageId.Blogs,
	'/news/press-release-detail': NewsPageId.PressReleaseDetail,
	'/news/blog-detail-page': NewsPageId.BlogDetailPage,
	'/news/press-release-detail-page': NewsPageId.PressReleaseDetailPage,
	'/news/news-story-detail-page': NewsPageId.NewsStoryDetailPage,
	'/news/newsletter-sign-up': NewsPageId.NewsletterSignup,

	...accountPagePathToPageIdMap,
	...parkingPagePathToPageIdMap,
};

export const theme = {
	boxShadow: '',
	transition: 'box-shadow 0.15s ease-out',
	contentMargin: '16px 16px 16px 0',
	hover: {
		boxShadow: '',
		color: '',
	},
	fullyClickable: {
		contentMargin: '16px',
		boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.1)',
		hover: {
			boxShadow: '0 20px 30px 0 rgba(0, 0, 0, 0.1)',
		},
	},
	icon: {
		color: '',
	},
};

import { useEffect } from 'react';
import { useLoaderStore } from './store/LoaderStoreContext';

/**
 * Instead of showing multiple spinners within the same page we add these empty placeholders
 * which will be update the global loading state of the app through the Loader Store.
 */
export const AsyncDataPlaceholder = () => {
	const loaderStore = useLoaderStore();

	useEffect(() => {
		loaderStore.increaseLoadingCount();

		return () => {
			loaderStore.decreaseLoadingCount();
		};
	}, []);

	return null;
};

import styled from '@emotion/styled';

export const DialogIllustration = styled.img`
	display: block;
	margin: 0 auto 40px;
	width: 250px;
	max-width: 70%;
`;

DialogIllustration.defaultProps = {
	alt: '',
	role: 'presentation',
};

import { ICheckpointQueue } from './ICheckpointQueue';
import { CheckpointQueueAccessType } from './CheckpointQueueAccessType';
import { WaitTimeCategory } from './WaitTimeCategory';
import { ITranslationKey } from '../../../shared/i18n/translate/models/ITranslationKey';
import { Moment } from '../../../shared/i18n/date-time/moment';

interface ICheckpointQueueData
	extends Omit<ICheckpointQueue, 'formattedWaitTime' | 'waitTimeCategory'> {}

export class CheckpointQueue implements ICheckpointQueue {
	public readonly id: string;

	public readonly name: string;

	public readonly translationKey?: ITranslationKey;

	public readonly poiId?: string;

	public readonly accessTypes: CheckpointQueueAccessType[];

	public readonly isOpen: boolean;

	public readonly waitTimeMinutes: number;

	public readonly waitTimeUpdatedAt: Moment;

	public readonly isDisplayable: boolean;

	constructor({
		id,
		name,
		translationKey,
		poiId,
		accessTypes,
		isOpen,
		waitTimeMinutes,
		waitTimeUpdatedAt,
		isDisplayable,
	}: ICheckpointQueueData) {
		this.id = id;
		this.name = name;
		this.translationKey = translationKey;
		this.poiId = poiId;
		this.accessTypes = accessTypes;
		this.isOpen = isOpen;
		this.waitTimeMinutes = waitTimeMinutes;
		this.waitTimeUpdatedAt = waitTimeUpdatedAt;
		this.isDisplayable = isDisplayable;
	}

	get formattedWaitTime() {
		if (this.waitTimeMinutes < 10) {
			return '<10';
		}

		if (this.waitTimeMinutes <= 20) {
			return '10-20';
		}

		if (this.waitTimeMinutes <= 44) {
			return '21-44';
		}

		return '45+';
	}

	get waitTimeCategory(): WaitTimeCategory {
		if (this.waitTimeMinutes < 10) {
			return 'low';
		}

		if (this.waitTimeMinutes <= 20) {
			return 'medium';
		}

		return 'high';
	}
}

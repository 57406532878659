import React from 'react';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { HashDialog } from '../../../shared/components/dialog/HashDialog';
import { SignUpDialogContent } from './SignUpDialogContent';
import { useHideChatBot } from '../../../chatbot/helpers/useHideChatBot';

export const SIGN_UP_DIALOG_HASH = '#register';

export const SignUpDialog = () => {
	const { t } = useTranslation();
	useHideChatBot('mobile');

	return (
		<HashDialog
			ariaLabel={t('user_create_account_title')}
			hash={SIGN_UP_DIALOG_HASH}
		>
			<SignUpDialogContent />
		</HashDialog>
	);
};

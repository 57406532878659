/**
 * Hash History Management
 *
 * When using `window.history.pushState` or `window.history.replaceState` to update the current hash/location, no
 * `popstate` nor `hashchange` event will be triggered. Only when directly changing the location using eg.
 * `window.location.hash = 'foo';` or by clicking a link will do so. While this would work in most cases, sometimes
 * we want to replace the history entry, for example after a successful login we don't want to keep a sign in screen
 * to be accessible by using the back button. Therefor we have a custom hash history management module that provides
 * a single interface for adding and removing event listeners supporting both ways of navigating.
 *
 * TODO: investigate how/if this can be implemented with the next/router, see:
 * - https://nextjs.org/docs/api-reference/next/router#routerreplace
 * - https://github.com/vercel/next.js/issues/18601
 * - https://codesandbox.io/s/nextjs-router-push-with-hash-589dd
 */

import { isServerSide } from '../../utils/isServerSide';

type Listener = (hash: string) => void;

interface ISetHashOptions {
	replace?: boolean;
}

let listeners: Listener[] = [];
let currentLocation = getLocation();

export function removeHash(options: ISetHashOptions = {}) {
	setHash('', options);
}

export function setHash(hash: string, { replace }: ISetHashOptions = {}) {
	const { hash: currentHash, page } = getLocation();

	let newHash = hash;

	// Allow both a hash with or without # prefix, to be consistent with setting the `window.location.hash` directly
	if (hash !== '' && hash[0] !== '#') {
		newHash = `#${newHash}`;
	}

	if (newHash === currentHash) {
		return;
	}

	const url = page + newHash;

	if (replace) {
		window.history.replaceState('', document.title, url);
	} else {
		window.history.pushState('', document.title, url);
	}

	currentLocation = getLocation();

	triggerHashChangeEvent();
}

export function addHashListener(listener: Listener) {
	listeners.push(listener);
}

export function removeHashListener(listener: Listener) {
	listeners = listeners.filter((fn) => fn !== listener);
}

/**
 * Return the window.location object enriched with a page property that
 * contains the current page without hash fragment
 */
export function getLocation() {
	if (isServerSide()) {
		return {
			hash: undefined,
			page: undefined,
		};
	}

	const { location } = window;

	return {
		...location,
		page: location.pathname + location.search,
	};
}

function update() {
	const location = getLocation();

	if (
		location.hash === currentLocation.hash &&
		location.page === currentLocation.page
	) {
		return undefined;
	}

	currentLocation = location;

	triggerHashChangeEvent();

	return location;
}

function triggerHashChangeEvent() {
	listeners.forEach((fn) => fn(window.location.hash));
}

if (!isServerSide()) {
	window.addEventListener('popstate', update);
}

import React, { forwardRef } from 'react';
import { ArrowButton, IArrowButtonProps } from '../../../../ui';

interface ISubmitProps extends IArrowButtonProps {}

export const SubmitButton = forwardRef<HTMLButtonElement, ISubmitProps>(
	(props: ISubmitProps, ref) => (
		<ArrowButton type="submit" ref={ref} {...props} />
	),
);

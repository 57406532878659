import { RouterLink } from '../features/shared/components/link/RouterLink';
import { useNavigate } from '../features/shared/router/useNavigate';
import { theme } from '../features/shared/styles/theme';
import { ICustomConfig } from '../ui';
import { timeZone } from './common';

export const ui: ICustomConfig = {
	theme,
	dateTimeFormat: {
		timeZone,
	},
	RouterLinkComponent: RouterLink,
	useNavigate,
};

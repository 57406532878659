import { IFilterFunction } from '@move-frontend/utils';
import { SuggestionStore } from './SuggestionStore';
import { IFlightSearchSuggestion } from '../models/IFlightSearchSuggestion';

export function createFlightSuggestionsFilter(
	suggestionStore: SuggestionStore<IFlightSearchSuggestion>,
) {
	const includesQuery: IFilterFunction<IFlightSearchSuggestion> = (
		suggestion: IFlightSearchSuggestion,
	) => {
		const query = suggestionStore.query.toLocaleLowerCase();
		const option = suggestion.value.toLocaleLowerCase();

		return option.includes(query);
	};

	return { includesQuery };
}

import React from 'react';
import styled from '@emotion/styled';
import { ChevronDownIcon, ChevronUpIcon } from '../../Icon';
import { ITheme } from '../../../themer';
import themed from '../../../themer/themed';

interface ISelectToggleIconProps {
	isOpen: boolean;
}

export const StyledSelectToggleIcon = styled.div<{}, ITheme>`
	margin-left: 16px;
	display: flex;
	align-items: center;

	svg {
		display: block;
		width: ${themed(({ select }) => select.main.lineHeight)};
		height: ${themed(({ select }) => select.main.lineHeight)};
	}
`;

export const SelectToggleIcon = ({ isOpen }: ISelectToggleIconProps) => (
	<StyledSelectToggleIcon>
		{isOpen ? <ChevronUpIcon aria-hidden /> : <ChevronDownIcon aria-hidden />}
	</StyledSelectToggleIcon>
);

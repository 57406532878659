import React from 'react';
import { css, Global } from '@emotion/core';

export const Table = () => {
	const styles = css`
		table,
		caption,
		tbody,
		tfoot,
		thead,
		tr,
		th,
		td {
			margin: 0;
			padding: 0;
			border: 0;
			font-size: 100%;
			font: inherit;
			vertical-align: baseline;
		}

		table {
			border-collapse: collapse;
			border-spacing: 0;
		}
	`;

	return <Global styles={styles} />;
};

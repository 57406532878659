import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ArrowButton, Image, ITheme } from '../../../../ui';
import isPropValid from '@emotion/is-prop-valid';
import { DialogCloseButton } from './DialogCloseButton';
import { StyledDialogHeader } from './DialogHeader';
import {
	StyledDialogHeaderGradient,
	StyledDialogHeaderGradientTitle,
} from './DialogHeaderGradient';
import { IHeaderTheme } from '../../api/contentful/types';
import {
	getDialogMaxWidth,
	getDialogPadding,
	getDialogPaddingMobile,
	IDialogSizeProps,
} from './DialogMain';
import { BadgeTag } from '../../../account/ui/shared/BadgeTag';
import { useTranslation } from '../../i18n/translate/useTranslation';
import Responsive from '../../../../ui/utils/Responsive';

export const StyledDialogHeaderImageTitle = styled.h3<{}, ITheme>``;

const StyledImageDialogCloseButton = styled(DialogCloseButton)`
	background: #fff;
`;

const StyledHeaderImageTitleContainer = styled.div`
	margin-top: 24px;
	display: flex;
	align-items: center;

	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		max-width: calc(${getDialogMaxWidth} + (${getDialogPadding} * 2));
		margin-left: auto;
		margin-right: auto;
	}
`;

const StyledSelectButton = styled(ArrowButton)`
	margin-left: auto;
	white-space: nowrap;
	justify-content: flex-end;
	min-width: 196px;
	font-family: inherit;

	svg {
		margin-left: 30px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		margin: 0;
		width: 100%;
		justify-content: center;

		svg {
			display: none;
		}
	}
`;

const StyledDialogHeaderImage = styled(StyledDialogHeader)<
	IDialogSizeProps,
	ITheme
>`
	padding: 0;
	position: relative;

	${StyledDialogHeaderGradient} {
		display: none;
	}

	${StyledDialogHeaderImageTitle} {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 0;
	}

	${StyledHeaderImageTitleContainer} {
		padding-left: ${getDialogPadding};
		padding-right: ${getDialogPadding};
	}

	${StyledImageDialogCloseButton} {
		position: absolute;
		top: 24px;
		right: 24px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		${StyledDialogHeaderGradient} {
			display: block;
		}

		${StyledImageDialogCloseButton},
		${StyledDialogHeaderImageTitle} {
			display: none;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: 0;

		${StyledHeaderImageTitleContainer} {
			padding: 0;
			margin-left: ${getDialogPaddingMobile()};
			margin-right: ${getDialogPaddingMobile()};
		}
	}
`;

interface IStyledImageProps extends IDialogSizeProps {
	cropImage?: boolean;
}

export const StyledImage = styled(Image, {
	shouldForwardProp: (prop) => isPropValid(prop),
})<IStyledImageProps, ITheme>`
	position: relative;

	img {
		display: block;
		width: 100%;

		${({ cropImage }) =>
			cropImage &&
			`
				position: absolute;
				object-fit: cover;
				max-height: 100%;
			`}
	}

	/* spacer for image while loading / when cropping */
	&:before {
		content: '';
		float: left;
		height: 0;
		padding-top: 55.555%;
	}

	&:after {
		/* to clear float */
		content: '';
		display: table;
		clear: both;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		max-width: calc(${getDialogMaxWidth} + (${getDialogPadding} * 2));
		padding-left: ${getDialogPadding};
		padding-right: ${getDialogPadding};
		margin: -74px auto 0;

		${({ cropImage, wideContent }) =>
			cropImage &&
			`
				img {
					width: calc(100% - (${getDialogPadding({ wideContent })} * 2));
				}
			`}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		max-width: calc(${getDialogMaxWidth} + (${getDialogPaddingMobile} * 2));
		padding-left: ${getDialogPaddingMobile};
		padding-right: ${getDialogPaddingMobile};
		margin-left: 0;
		margin-right: 0;

		${({ cropImage }) =>
			cropImage &&
			`
			img {
				width: calc(100% - (${getDialogPaddingMobile()} * 2));
			}
			`}
	}
`;

interface IDialogHeaderProps extends IStyledImageProps {
	imageSrc: string;
	imageAlt: string;
	title: string;
	loyaltyPoints?: number;
	className?: string;
	backgroundTheme?: IHeaderTheme;
	children?: ReactNode;
	button?: {
		label: string;
		onClick: () => void;
	};
}

export const DialogHeaderImage = ({
	imageSrc,
	imageAlt,
	title,
	loyaltyPoints,
	className,
	wideContent,
	backgroundTheme,
	cropImage,
	children,
	button,
}: IDialogHeaderProps) => {
	const { t } = useTranslation();
	return (
		<StyledDialogHeaderImage className={className} wideContent={wideContent}>
			<StyledDialogHeaderGradient
				backgroundTheme={backgroundTheme ?? 'blue-blue'}
				wideContent={wideContent}
			>
				{/* @ts-ignore */}
				<DialogCloseButton variation="inverted" />
				<StyledDialogHeaderGradientTitle>
					{title}
				</StyledDialogHeaderGradientTitle>
			</StyledDialogHeaderGradient>
			<StyledImage
				wideContent={wideContent}
				alt={imageAlt}
				src={imageSrc}
				cropImage={cropImage}
			/>
			<StyledImageDialogCloseButton />
			<StyledHeaderImageTitleContainer wideContent={wideContent}>
				<div>
					{title && (
						<StyledDialogHeaderImageTitle>{title}</StyledDialogHeaderImageTitle>
					)}
					{loyaltyPoints && (
						<BadgeTag
							width="96px"
							text={`+${t('loyalty_offer_points_template', {
								points: loyaltyPoints,
							})}`}
						/>
					)}
				</div>

				{button && (
					<StyledSelectButton onClick={button.onClick}>
						{button.label}
					</StyledSelectButton>
				)}
			</StyledHeaderImageTitleContainer>
			{children}
		</StyledDialogHeaderImage>
	);
};

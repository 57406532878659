import { logError } from '../../../error/logError';
import { getAnalyticsConfig } from '../config/getAnalyticsConfig';

export function trackWithBing(...args: unknown[]) {
	const { bingTrackingId } = getAnalyticsConfig();

	if (!bingTrackingId) {
		return;
	}

	if (!window.uetq) {
		logError(new Error('Bing tracking script not loaded'));
		return;
	}

	window.uetq.push(...args);
}

import log from 'loglevel';

export const trackWithGoogleAnalytics: Gtag.Gtag = (...args: unknown[]) => {
	if (!('gtag' in window)) {
		log.error("Gtag.js hasn't been initialized");
		return;
	}

	window.gtag(...(args as Parameters<Gtag.Gtag>));
};

import React from 'react';
import { Link, LinkText } from '../Link';
import { QuickLinkItem } from './QuickLinkItem';

export interface IQuickLinkInternalProps {
	title: string;
	url: string;
}

export const QuickLinkInternal = ({ title, url }: IQuickLinkInternalProps) => (
	<QuickLinkItem>
		<Link href={url}>
			<LinkText>{title}</LinkText>
		</Link>
	</QuickLinkItem>
);

import React, {
	ButtonHTMLAttributes,
	DetailedHTMLProps,
	forwardRef,
} from 'react';
import styled from '@emotion/styled';
import { CancelIcon, ITheme, VisuallyHidden } from '../../../../ui';
import { useTranslation } from '../../i18n/translate/useTranslation';
import { IIconVariation } from '../../../../ui/components/Icon/withIconStyles';
import themed from '../../../../ui/themer/themed';

export interface ICloseButtonProps
	extends DetailedHTMLProps<
			ButtonHTMLAttributes<HTMLButtonElement>,
			HTMLButtonElement
		>,
		IStyledCloseButtonProps {}

interface IStyledCloseButtonProps {
	variation?: IIconVariation;
}

function getCloseButtonColor(
	{ color }: ITheme,
	{ variation = 'highlight' }: IStyledCloseButtonProps,
) {
	if (variation === 'inverted') {
		return color.background.default;
	}

	if (variation === 'text') {
		return color.text.default;
	}

	if (variation === 'error') {
		return color.state.error;
	}

	return color[variation];
}

export const StyledCloseButton = styled.button<IStyledCloseButtonProps, ITheme>`
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	border-radius: 100%;
	outline: none;

	&:focus {
		box-shadow: 0 0 5px 0 ${themed(getCloseButtonColor)};
	}
`;

export const CloseButton = forwardRef<HTMLButtonElement, ICloseButtonProps>(
	({ variation, ...rest }: ICloseButtonProps, ref) => {
		const { t } = useTranslation();
		return (
			<StyledCloseButton
				type="button"
				variation={variation}
				ref={ref}
				{...rest}
			>
				<VisuallyHidden as="span">
					{t('generic_close_button_label')}
				</VisuallyHidden>
				<CancelIcon aria-hidden variation={variation ?? 'highlight'} />
			</StyledCloseButton>
		);
	},
);

import styled from '@emotion/styled';
import { StyledButton } from './Button';
import { StyledIconButton } from './IconButton';
import {
	StyledArrowLinkButton,
	StyledIconLink,
	StyledLinkButton,
} from '../Link';
import { Css } from '../../utils';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

interface IButtonGutter {
	horizontal: string;
	vertical: string;
}

export function getButtonMarginStyles(gutter: IButtonGutter) {
	const variations = [
		StyledButton,
		StyledIconButton,
		StyledIconLink,
		StyledArrowLinkButton,
		StyledLinkButton,
	];

	return `
		margin-top: -${gutter.vertical};
		margin-left: -${gutter.horizontal};

		${Css.getSelectorForComponents(variations)} {
			margin-top: ${gutter.vertical};
			margin-left: ${gutter.horizontal};
			vertical-align: middle;
		}
	`;
}

export const ButtonWrap = styled.div<{}, ITheme>`
	max-width: 100%;
	line-height: 0;

	> * {
		line-height: 1em;
	}

	${themed(({ button }) => getButtonMarginStyles(button.gutter))}
`;

'use client';

import React from 'react';
import styled from '@emotion/styled';
import { Color } from '../../../features/shared/styles/Color';
import { StyledArrowLink } from '../Link/StyledArrowLink';
import { Css } from '../../utils';
import Responsive from '../../utils/Responsive';

interface CarouselNavigationProps {
	currentIndex: number;
	totalItems: number;
	onPrev: () => void;
	onNext: () => void;
	onDotClick: (index: number) => void;
	getKey: (index: number) => string;
	previousLabel?: string;
	nextLabel?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function CarouselNavigation({
	currentIndex,
	totalItems,
	onPrev,
	onNext,
	onDotClick,
	getKey,
	previousLabel,
	nextLabel,
}: CarouselNavigationProps) {
	return (
		<NavigationSection>
			<NavigationContainer>
				<StyledArrowLink
					disabled={currentIndex === 0}
					aria-disabled={currentIndex === 0}
					aria-label={previousLabel}
					onClick={onPrev}
					isPrevious
					tabIndex={0}
				>
					{previousLabel}
				</StyledArrowLink>

				<DotsContainer>
					{Array.from({ length: totalItems }, (_, index) => (
						<Dot
							key={getKey(index)}
							active={currentIndex === index}
							onClick={() => onDotClick(index)}
						/>
					))}
				</DotsContainer>

				<StyledArrowLink
					disabled={currentIndex === totalItems - 1}
					aria-disabled={currentIndex === totalItems - 1}
					aria-label={nextLabel}
					onClick={onNext}
					tabIndex={0}
				>
					{nextLabel}
				</StyledArrowLink>
			</NavigationContainer>
		</NavigationSection>
	);
}

const NavigationSection = styled.div`
	position: relative;
	padding: 0;
`;

const NavigationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: ${Css.pxToRem(24)};

	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		justify-content: center;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		justify-content: space-between;
	}
`;

const DotsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Dot = styled.div<{ active: boolean }>`
	width: ${Css.pxToRem(8)};
	height: ${Css.pxToRem(8)};
	border-radius: 50%;
	background-color: ${({ active }) =>
		active ? Color.PrimaryDark : Color.Neutral90};
	border: 1px solid
		${({ active }) => (active ? Color.PrimaryDark : Color.Neutral90)};
	margin: 0 3px;
	cursor: pointer;

	&:hover {
		background-color: ${Color.PrimaryDark};
	}
`;

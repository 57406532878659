import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

export interface IIframeWrapProps {
	maxWidth: number;
	maxHeight: number;
}

export const IframeWrap = styled.div<IIframeWrapProps, ITheme>`
	position: relative;
	width: 100%;

	iframe {
		background: ${themed(({ background }) => background.light)};
	}

	${({ maxWidth, maxHeight }) => {
		const heightPercentage = (maxHeight / maxWidth) * 100;

		return `
			max-width: ${maxWidth}px;
			padding-bottom: ${heightPercentage.toFixed(3)}%;
		`;
	}}
`;

import styled from '@emotion/styled';
import Responsive from '../../utils/Responsive';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

export interface IColumnLayoutProps {
	columns?: Responsive.IValueOrResponsiveValue<number>;
}

export const ColumnLayout = styled.div<IColumnLayoutProps, ITheme>`
	${Responsive.getStyles('columns', (value) => `column-count: ${value};`)};
	column-gap: ${themed(({ grid }) => grid.gutter.horizontal.default)};
`;

ColumnLayout.defaultProps = {
	columns: [2, { mobileXL: 1 }],
};

import { useEffect } from 'react';
import { trackHashChange } from '../../analytics/navigation/trackHashChange';
import { useHashRouteContext } from './HashRouteContext';

export function useTrackHashRoute() {
	const { hash } = useHashRouteContext();

	useEffect(() => {
		trackHashChange(hash);

		return () => {
			trackHashChange('');
		};
	}, []);
}

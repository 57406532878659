import React from 'react';
import log from 'loglevel';
import styled from '@emotion/styled';
import { IQuickLinkItem } from './IQuickLinkItem';
import { QuickLinkAsset } from './QuickLinkAsset';
import { QuickLinkEmail } from './QuickLinkEmail';
import { QuickLinkExternal } from './QuickLinkExternal';
import { QuickLinkInternal } from './QuickLinkInternal';
import { FixedGrid, IFixedGridProps } from '../Grid';
import { QuickLinkItem } from './QuickLinkItem';
import { ITheme } from '../../themer';

export const StyledQuickLinks = styled(FixedGrid)<IFixedGridProps, ITheme>`
	margin-top: 0;
`;

StyledQuickLinks.defaultProps = {
	itemSelector: QuickLinkItem,
};

export function renderQuickLinksItem({ key, ...item }: IQuickLinkItem) {
	switch (item.type) {
		case 'asset-link':
			return <QuickLinkAsset key={key} {...item} />;

		case 'email-link':
			return <QuickLinkEmail key={key} {...item} />;

		case 'external-link':
			return <QuickLinkExternal key={key} {...item} />;

		case 'internal-link':
			return <QuickLinkInternal key={key} {...item} />;

		default:
			const invalidItem: never = item;
			log.warn(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				`Invalid quick link item type ${(invalidItem as any).type}`,
				invalidItem,
			);
			return null;
	}
}

export interface IQuickLinksProps extends IFixedGridProps {
	items: IQuickLinkItem[];
	renderItem?: (item: IQuickLinkItem) => JSX.Element;
}

export const QuickLinks = ({
	items,
	columns,
	renderItem: customRenderItem,
}: IQuickLinksProps) => {
	const renderItem = customRenderItem || renderQuickLinksItem;

	return (
		<StyledQuickLinks columns={columns}>
			{items.map(renderItem)}
		</StyledQuickLinks>
	);
};

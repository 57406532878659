import {
	ITokenizedCardCreateRequestData,
	ITokenizedCardCreateResponse,
	ITokenizedCardGetAllResponse,
} from '../types';

import { AbstractService } from './AbstractService';

export class TokenizedCardService extends AbstractService {
	create(data: ITokenizedCardCreateRequestData) {
		return this.client.post<
			ITokenizedCardCreateRequestData,
			ITokenizedCardCreateResponse
		>('/payment/tokenized-cards', data);
	}

	getAll() {
		return this.client.get<ITokenizedCardGetAllResponse>(
			'/payment/tokenized-cards',
		);
	}

	delete(tokenizedCardId: string) {
		return this.client.delete<void>(
			`/payment/tokenized-cards/${tokenizedCardId}`,
		);
	}
}

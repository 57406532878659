import { background } from './background';
import { typography } from './typography';
import { color } from './color';

export const blockquote = {
	background: background.default,
	boxShadow: `inset 3px 0 0 0 ${color.brand}`,
	border: '',
	padding: '6px 24px',
	margin: typography.paragraph.margin,
	paragraph: {
		color: typography.body.color,
		size: typography.body.size,
		style: 'normal',
	},
	mobile: {
		padding: '0 12px',
		paragraph: {
			size: typography.mobile.body.size,
		},
	},
};

import { MouseEvent, useCallback } from 'react';
import { useNavigate } from '../Config/useNavigate';
import { isRelativeUrl } from '../Link';

export function useOnClickCardHandler(url?: string) {
	const navigate = useNavigate();

	return useCallback(
		(e: MouseEvent) => {
			if (url === undefined) return;

			// Disable the native link click to prevent triggering navigation twice
			if ((e.target as HTMLElement).tagName.toLocaleLowerCase() === 'a') {
				e.preventDefault();
			}

			const isRelative = isRelativeUrl(url);

			if (isRelative) {
				navigate(url);
			} else {
				window.open(url);
			}
		},
		[url],
	);
}

import { ITokenizedCardResponse } from '../../../../shared/api/gateway/types/ITokenizedCardResponse';
import { ITokenizedCard } from '../../models';

export function adaptResponseToTokenizedCard(
	card: ITokenizedCardResponse,
): ITokenizedCard {
	const {
		id,
		token,
		maskedCreditCardNumber,
		creditCardType,
		expiryDateMonth,
		expiryDateYear,
	} = card;

	return {
		id,
		token,
		maskedCreditCardNumber,
		creditCardType,
		expiryDate: {
			month: expiryDateMonth,
			year: expiryDateYear.substr(-2),
		},
	};
}

export interface IRadioButtonTheme {
	size: string;
	innerSize: string;
	color?: string;
	active: {
		color?: string;
	};
	disabled: {
		color?: string;
		opacity: string;
		active: {
			color?: string;
		};
	};
}

export const theme: IRadioButtonTheme = {
	size: '22px',
	innerSize: '10px',
	/** Defaults to color.border.default */
	color: '#808080',
	active: {
		/** Defaults to color.highlight */
		color: undefined,
	},
	disabled: {
		/** Defaults to color.border.default */
		color: undefined,
		opacity: '0.3',
		active: {
			/** Defaults to color.highlight */
			color: undefined,
		},
	},
};

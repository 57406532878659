import React, { KeyboardEvent, ReactNode, Ref } from 'react';
import { GetInputPropsOptions, PropGetters } from 'downshift';
import { IDropdownItem } from '../../DropdownList';
import { Input } from '../../Input';
import { SearchInputClearWrap } from '../../Search/SearchInputClearWrap';

interface IAutoSuggestMainProps {
	getInputProps: PropGetters<IDropdownItem>['getInputProps'];
	isOpen: boolean;
	clearSelection: () => void;
	onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
	placeholder?: string;
	ariaDescribedBy?: string;
	ariaLabel?: string;
	disabled?: boolean;
	required?: boolean;
	invalid?: boolean;
	placeholderAsLabel?: boolean;
	onClearSearch?: () => void;
	onFocus?: () => void;
	icon?: ReactNode;
	autoSuggestId?: string;
}

interface IGetInputPropsOptionsRef extends GetInputPropsOptions {
	ref?: Ref<HTMLInputElement>;
}

interface IGetInputPropsOptionsRef extends GetInputPropsOptions {
	ref?: Ref<HTMLInputElement>;
}

export const AutoSuggestMain = ({
	getInputProps,
	placeholder,
	ariaLabel,
	ariaDescribedBy,
	clearSelection,
	onKeyUp,
	disabled,
	invalid,
	required,
	placeholderAsLabel,
	onClearSearch,
	onFocus,
	icon,
	autoSuggestId,
}: IAutoSuggestMainProps) => {
	const { value } = getInputProps();

	const showClearIcon =
		Array.isArray(value) || typeof value === 'string'
			? value?.length > 0
			: false;
	const clickHandler = () => {
		if (onClearSearch) {
			onClearSearch();
		}

		clearSelection();
	};

	const onFocusHandler = () => {
		if (onFocus) {
			onFocus();
		}
	};

	return (
		<SearchInputClearWrap
			onClickClear={clickHandler}
			showClearIcon={!disabled && showClearIcon}
		>
			<Input
				disabled={disabled}
				invalid={invalid}
				aria-invalid={invalid}
				aria-describedby={ariaDescribedBy}
				placeholder={placeholder}
				{...(getInputProps({
					onKeyUp,
					id: autoSuggestId,
				}) as IGetInputPropsOptionsRef)}
				aria-labelledby={undefined}
				aria-label={ariaLabel}
				required={required}
				placeholderAsLabel={placeholderAsLabel}
				onFocus={onFocusHandler}
				icon={icon}
			/>
		</SearchInputClearWrap>
	);
};

import { IBusWaitingTimesResponse } from '../../../../shared/api/dap/types/IBusWaitingTimesResponse';
import { IBusWaitingTimes } from '../../models/IBusWaitingTimes';

export type MapStopResponse = ReturnType<typeof createMapStopResponse>;

export function createMapStopResponse() {
	return function mapStopResponse(
		response: IBusWaitingTimesResponse,
	): IBusWaitingTimes[] {
		return response.data.stops.map((stop) => ({
			id: stop.id,
			name: stop.name,
			averageMinutes:
				stop.average_seconds && Math.ceil(stop.average_seconds / 60),
			lastDeparture: stop.last_departure,
			restricted: stop.restricted,
		}));
	};
}

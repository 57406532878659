import { parsePrice } from '../../../../../shared/i18n/price/utils/parsePrice';
import { IBookingResponse } from '../../../../../shared/api/gateway/types/IBookingResponse';
import { IBooking } from '../../models/IBooking';

export function adaptResponseToBooking(
	response: IBookingResponse,
	byReference = false,
): IBooking {
	return {
		...response,
		byReference,
		parkingProduct: {
			...response.parkingProduct,
			price: parsePrice(response.parkingProduct.price),
		},
		paymentDetails: {
			...response.paymentDetails,
			total: parsePrice(response.paymentDetails.total),
		},
	};
}

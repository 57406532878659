import { useEffect } from 'react';
import { trackEvent } from '../event/trackEvent';
import { getLinkElementForClickEvent } from '../../components/link/utils/getLinkElementForClickEvent';

export const useTrackExternalLinkClick = () => {
	useEffect(() => {
		const onClick = (e: MouseEvent) => {
			const link = getLinkElementForClickEvent(e);

			if (!link) {
				return;
			}

			if (!isExternalLinkClick(link)) {
				return;
			}

			trackEvent({
				category: 'External links',
				action: link.href,
			});
		};

		document.body.addEventListener('click', onClick);

		return () => {
			document.body.removeEventListener('click', onClick);
		};
	}, []);
};

function isExternalLinkClick(link: HTMLAnchorElement) {
	return link.hostname !== window.location.hostname;
}

import { IBreakpoint } from '../../types';

export const breakpoints: IBreakpoint[] = [
	{
		name: 'desktopXL',
		condition: 'min-width: 1680px',
	},
	{
		name: 'tabletXL',
		condition: 'max-width: 1280px',
	},
	{
		name: 'tablet',
		condition: 'max-width: 1024px',
	},
	{
		name: 'mobileXL',
		condition: 'max-width: 720px',
	},
	{
		name: 'mobile',
		condition: 'max-width: 480px',
	},
];

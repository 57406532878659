import React, { ReactNode } from 'react';
import { useTheme } from '../../themer';
import { ArrowIcon } from '../Icon';
import { IIconLinkProps, StyledIconLink } from './IconLink';
import { LinkText } from './LinkText';
import { VisuallyHidden } from '../VisuallyHidden';

export interface IArrowLinkProps extends IIconLinkProps {
	className?: string;
	inverted?: boolean;
	children: ReactNode;
	screenReaderTitle?: string;
	customColor?: string;
}

export const ArrowLink = ({
	children,
	className,
	inverted,
	screenReaderTitle,
	customColor,
	...rest
}: IArrowLinkProps) => {
	const theme: any = useTheme();
	const IconComponent = theme.link.ArrowLinkIcon ?? ArrowIcon;

	return (
		<StyledIconLink
			inverted={inverted}
			customColor={customColor}
			className={className}
			{...rest}
		>
			<LinkText>
				{children}
				{screenReaderTitle && (
					<VisuallyHidden as="span">{screenReaderTitle}</VisuallyHidden>
				)}
			</LinkText>
			<IconComponent aria-hidden />
		</StyledIconLink>
	);
};

import React from 'react';
import { IRouterLinkProps, RouterLinkComponentType } from '../../../../ui';
import Link from 'next/link';
import styled from '@emotion/styled';
import { Color } from '../../styles/Color';

export const RouterLink: RouterLinkComponentType = ({
	children,
	href,
	removeUnderlineStyling,
	...rest
}: IRouterLinkProps) => (
	<Link href={href} passHref>
		<StyledLink href={href} {...rest}>
			{children}
		</StyledLink>
	</Link>
);

const StyledLink = styled.a`
	color: inherit;

	&:focus-visible {
		outline: ${Color.AccentDefault} auto 3px;
	}
`;

import React from 'react';
import {
	CardsContainer as BaseCardsContainer,
	ICardsContainerProps,
} from '../../../../ui';

export const CardsContainer = (props: ICardsContainerProps) => (
	<BaseCardsContainer {...props} />
);

CardsContainer.defaultProps = {
	titleFontSize: [32, { mobileXL: 24, mobile: 16 }],
};

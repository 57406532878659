import styled from '@emotion/styled';
import {
	ContactCard,
	IContactCardProps,
	StyledCardContent,
	StyledCardPicture,
} from '../../../../ui';
import Responsive from '../../../../ui/utils/Responsive';

export const ContactItem = styled(ContactCard)<IContactCardProps>`
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		${StyledCardPicture} {
			max-width: 160px;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		${StyledCardContent} {
			margin-left: 8px;
			margin-right: 0;
		}

		${StyledCardPicture} {
			max-width: 80px;
		}
	}
`;

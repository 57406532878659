import { IFlight } from '@move-frontend/dap';
import { IFlightOriginalStatus } from '../models/IFlightOriginalStatus';

export function getPublicStatus(flight: IFlight<IFlightOriginalStatus>) {
	const status = flight.originalStatus;

	if (status === 'OutGate') {
		return flight.arrival ? 'On Time' : 'Departed';
	}

	if (status === 'InGate') {
		return flight.arrival ? 'Arrived' : 'On Time';
	}

	return status;
}

import React, { MouseEvent, ReactNode } from 'react';
import { StyledIconButton } from '../Button';
import { SearchIcon } from '../Icon';
import {
	IStyledSearchInputButtonWrapProps,
	StyledSearchInputButtonWrap,
} from './StyledSearchInputButtonWrap';

interface ISearchInputButtonWrapProps
	extends IStyledSearchInputButtonWrapProps {
	children: ReactNode;
	onClickSearch: (e: MouseEvent) => void;
	disabled?: boolean;
	ariaLabel?: string;
	buttonType?: 'button' | 'submit';
}

export const SearchInputButtonWrap = ({
	children,
	onClickSearch,
	disabled,
	ariaLabel,
	buttonType = 'button',
	...rest
}: ISearchInputButtonWrapProps) => (
	<StyledSearchInputButtonWrap disabled={disabled} {...rest}>
		{children}
		<StyledIconButton
			disabled={disabled}
			onClick={onClickSearch}
			aria-label={ariaLabel ?? 'Search'}
			type={buttonType}
		>
			<SearchIcon variation="inverted" />
		</StyledIconButton>
	</StyledSearchInputButtonWrap>
);

import { IRequestOptions, RemoteDataStore } from '@move-frontend/utils';
import { action, makeObservable, observable } from 'mobx';
import { ICurrentCheckpointQueueApiResponse as IApiResponse } from '../../../shared/api/dap/types/ICurrentCheckpointQueueApiResponse';
import { Dap } from '../../../shared/api/dap';
import { ICheckpointQueue } from '../models/ICheckpointQueue';
import { transformCheckpointQueues } from './helpers/transformCheckpointQueues';

type RequestParams = Record<never, never>;

export class CheckpointQueueStore extends RemoteDataStore<
	RequestParams,
	IApiResponse
> {
	readonly checkpointQueues = observable.array<ICheckpointQueue>([]);

	constructor(private readonly dap: Dap) {
		super();

		makeObservable<CheckpointQueueStore, 'handleResponse'>(this, {
			handleResponse: action,
			clear: action,
		});
	}

	public load(params: RequestParams = {}) {
		return super.load(params);
	}

	protected async performRequest(
		_params: RequestParams,
		options: IRequestOptions,
	) {
		return this.dap.checkpointQueues().getCurrent(options);
	}

	protected handleResponse(response: IApiResponse) {
		const checkpointQueues = transformCheckpointQueues(
			response.data.wait_times.filter((checkpoint) => checkpoint.isDisplayable),
		);

		this.checkpointQueues.replace(checkpointQueues);
	}

	public clear() {
		this.checkpointQueues.clear();
	}
}

import React, { FC, PropsWithChildren } from 'react';
import { ILocale } from '../locale/models/ILocale';
import { I18nContext } from './I18nContext';
import { initI18nWithTranslations } from './initI18n';
import { ITranslations } from './models/ITranslations';

export const I18nProvider: FC<
	PropsWithChildren<{
		locale: ILocale;
		translations: ITranslations;
	}>
> = ({ children, locale, translations }) => {
	const i18n = initI18nWithTranslations(locale.code, translations);

	return <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>;
};

import { defaultTheme } from '../../themer';

export const defaultConfig = {
	dateTimeFormat: {
		timeZone: undefined,
		locale: 'en',
	},
	theme: defaultTheme,
	translations: {
		externalLinkLabel: 'Opens in a new window',
		clearSearchLabel: 'Clear search input',
	},
};

import React from 'react';
import log from 'loglevel';
import styled from '@emotion/styled';
import { ICallToActionLink } from './ICallToActionLink';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import { CallToActionLinkGeneric } from './CallToActionLinkGeneric';
import { CallToActionLinkPhone } from './CallToActionLinkPhone';
import { StyledArrowLinkButton } from '../Link';
import { ButtonWrap } from '../Button';
import Responsive from '../../utils/Responsive';

interface IStyledCallToActionProps {
	inverted?: boolean;
	hasDescription?: boolean;
}

export const StyledCallToAction = styled.div<IStyledCallToActionProps, ITheme>`
	padding: ${themed(({ callToAction }) => callToAction.padding)};
	background: ${themed(({ callToAction }, { inverted }) =>
		inverted
			? callToAction.background.inverted
			: callToAction.background.default,
	)};

	${ButtonWrap} {
		margin-top: 0;
	}

	${({ hasDescription }) =>
		hasDescription &&
		`display: flex;
		justify-content: space-between;
		align-items: center;

		> div:first-child {
			margin-right:16px;
		}

		${ButtonWrap} > * {
			margin-top: 0;
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			flex-direction: column;
			align-items: flex-start;

			${ButtonWrap} > * {
				margin-top: 16px;
			}
		}
	`}

	h3 {
		margin-bottom: 30px;
		color: ${themed(({ callToAction }, { inverted }) =>
			inverted
				? callToAction.font.color.inverted
				: callToAction.font.color.default,
		)};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: ${themed(({ callToAction }) => callToAction.mobile.padding)};

		h3 {
			margin-bottom: 15px;
		}

		${StyledArrowLinkButton} {
			font-size: 16px;
			padding: 0 12px;
		}
	}
`;

const StyledDescription = styled.p`
	margin: 0;
	font-size: 24px;
`;

export interface ICallToActionProps {
	title?: string;
	body?: string;
	links: ICallToActionLink[];
	inverted?: boolean;
	className?: string;
	description?: string;
}

export const CallToAction = ({
	title,
	body,
	links,
	inverted,
	className,
	description,
}: ICallToActionProps) => (
	<StyledCallToAction
		className={className}
		inverted={inverted}
		hasDescription={!!description}
	>
		<div>
			{title && <h3>{title}</h3>}
			{body && <p>{body}</p>}
			{description && <StyledDescription>{description}</StyledDescription>}
		</div>
		<ButtonWrap>{links.map((link) => renderLink(link, inverted))}</ButtonWrap>
	</StyledCallToAction>
);

function renderLink({ key, ...link }: ICallToActionLink, inverted?: boolean) {
	switch (link.type) {
		case 'internal-link':
		case 'external-link':
		case 'asset-link':
			return (
				<CallToActionLinkGeneric key={key} {...link} inverted={inverted} />
			);

		case 'email-link':
			return (
				<CallToActionLinkGeneric
					key={key}
					title={link.title}
					url={`mailto:${link.address}`}
					inverted={inverted}
				/>
			);

		case 'phone-link':
			return <CallToActionLinkPhone key={key} {...link} inverted={inverted} />;

		default:
			const invalidLink: never = link;
			log.warn(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				`Invalid call-to-action link type ${(invalidLink as any).type}`,
				invalidLink,
			);
			return null;
	}
}

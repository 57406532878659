export function isRelativeUrl(url: string) {
	// Matches "https://www.example.com", "mailto:foo@example" or "tel:0123456789"
	const hasProtocol = /^([a-z]+:)/.test(url);

	if (hasProtocol) {
		return false;
	}

	// Matches "//www.example.com"
	const isAbsoluteWithoutProtocol = /^\/\//.test(url);

	return !isAbsoluteWithoutProtocol;
}

import { RemoteDataStore } from '@move-frontend/utils';
import { action, makeObservable, observable } from 'mobx';
import { TokenizedCardService } from '../../../shared/api/gateway/services/TokenizedCardService';
import {
	ITokenizedCardCreateResponse,
	ITokenizedCardGetAllResponse,
} from '../../../shared/api/gateway/types';
import { INewTokenizedCard, ITokenizedCard } from '../models';
import { adaptCardToSaveTokenizedRequestData } from './adapters/adaptCardToSaveTokenizedRequestData';
import { adaptGetAllResponseToTokenizedCards } from './adapters/adaptGetAllResponseToTokenizedCards';
import { adaptResponseToTokenizedCard } from './adapters/adaptResponseToTokenizedCard';

interface IRequestParams {
	ignoreCache?: boolean;
}

const MAX_AGE_IN_MILLISECONDS = 5 * 60 * 1000;

export class TokenizedCardsStore extends RemoteDataStore<
	IRequestParams,
	ITokenizedCardGetAllResponse
> {
	readonly cards = observable<ITokenizedCard>([]);

	private lastUpdate: number | undefined = undefined;

	constructor(private readonly service: TokenizedCardService) {
		super();

		makeObservable<
			TokenizedCardsStore,
			'handleResponse' | 'afterSave' | 'afterDelete'
		>(this, {
			load: action,
			handleResponse: action,
			save: action,
			afterSave: action,
			afterDelete: action,
			clear: action,
		});
	}

	public async load(params: IRequestParams = {}) {
		if (params.ignoreCache !== true && !this.shouldLoad) {
			return;
		}

		if (this.currentRequest) {
			await this.currentRequest;
			return;
		}

		await super.load(params);

		this.lastUpdate = Date.now();
	}

	protected get shouldLoad() {
		if (this.lastUpdate === undefined) {
			return true;
		}

		const isStale = Date.now() - this.lastUpdate > MAX_AGE_IN_MILLISECONDS;

		return isStale;
	}

	protected performRequest(_params: IRequestParams) {
		return this.service.getAll();
	}

	protected handleResponse(response: ITokenizedCardGetAllResponse) {
		const cards = adaptGetAllResponseToTokenizedCards(response);

		this.cards.replace(cards);
	}

	async save(tokenizedCard: INewTokenizedCard) {
		const requestData = adaptCardToSaveTokenizedRequestData(tokenizedCard);

		const response = await this.service.create(requestData);

		this.afterSave(response);
	}

	private afterSave(response: ITokenizedCardCreateResponse) {
		const newTokenizedCard = adaptResponseToTokenizedCard(response);

		this.cards.push(newTokenizedCard);
	}

	async delete(tokenizedCardId: string) {
		await this.service.delete(tokenizedCardId);

		this.afterDelete(tokenizedCardId);
	}

	private afterDelete(tokenizedCardId: string) {
		this.cards.replace(
			this.cards.filter((card) => card.id !== tokenizedCardId),
		);
	}

	public clear() {
		this.lastUpdate = undefined;
		this.cards.clear();
	}

	findById(id: string) {
		return this.cards.find((item) => item.id === id);
	}
}

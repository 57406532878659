import {
	action,
	computed,
	makeObservable,
	observable,
	reaction,
	toJS,
} from 'mobx';
import log from 'loglevel';
import { NewBooking } from '../models/NewBooking';
import { adaptBookingCreateData } from './adapters/adaptBookingCreateData';
import { getAncillariesForSelections } from './utils/getAncillariesForSelections';
import { getSelectionsForAncillaries } from './utils/getSelectionsForAncillaries';
import { adaptOriginalBookingToInitialNewBooking } from './adapters/adaptOriginalBookingToNewBooking';
import { adaptBookingAncillariesToAvailableAncillaries } from './adapters/adaptBookingAncillariesToAvailableAncillaries';
import { adaptBookingParkingProductToAvailabeParkingProduct } from './adapters/adaptBookingParkingProductToAvailabeParkingProduct';
import { IBooking } from '../models/IBooking';
import { BookingService } from '../../../../shared/api/gateway/services/BookingService';
import { ParkingProductsQuoteService } from '../../../../shared/api/gateway/services/ParkingProductsQuoteService';
import { IAncillaryGroupSelection } from '../../ancillary/domain/models/IAncillaryGroupSelection';
import { IParkingProduct } from '../../parking-product/models/IParkingProduct';
import { IParkingProductUpgrade } from '../../parking-product/models/IParkingProductUpgrade';
import {
	IParkingProductsQuoteParams,
	ParkingProductsQuoteStore,
} from '../../parking-product/stores/ParkingProductsQuoteStore';
import { IPaymentDetails } from '../models/IPaymentDetails';

export class NewBookingStore {
	originalBooking: IBooking | undefined = undefined;

	booking = new NewBooking();

	readonly parkingProductsQuoteStore: ParkingProductsQuoteStore;

	isSubmitting = false;

	_loyaltyPromoCode = '';

	private _preUpgradeParkingProduct: IParkingProduct | undefined = undefined;

	constructor(
		parkingProductsQuoteService: ParkingProductsQuoteService,
		private readonly bookingService: BookingService,
	) {
		makeObservable<
			NewBookingStore,
			| 'updateAvailability'
			| 'updateSelectedParkingProductId'
			| 'onSubmitSuccess'
			| 'handleOriginalBookingChange'
			| 'resetParkingProductSelection'
			| 'resetAncillariesSelection'
			| 'handleParkingProductAvailabilityChange'
			| 'handleAncillaryAvailabilityChange'
		>(this, {
			originalBooking: observable,
			booking: observable,
			isSubmitting: observable,
			_loyaltyPromoCode: observable,
			updateAvailability: action.bound,
			hasUpgraded: computed,
			updateSelectedParkingProductId: action.bound,
			submit: action,
			onSubmitSuccess: action,
			reset: action,
			selectParkingProduct: action,
			applyOfferPromoCode: action,
			selectUpgrade: action,
			removeUpgrade: action,
			ancillaryGroupSelections: computed,
			selectAncillaries: action,
			handleOriginalBookingChange: action.bound,
			resetParkingProductSelection: action,
			resetAncillariesSelection: action,
			handleParkingProductAvailabilityChange: action.bound,
			handleAncillaryAvailabilityChange: action.bound,
		});

		this.parkingProductsQuoteStore = new ParkingProductsQuoteStore(
			parkingProductsQuoteService,
		);

		reaction(
			() => ({
				entryDate: this.booking.entryDate,
				exitDate: this.booking.exitDate,
				promoCode: this.booking.promoCode,
				locale: this.booking.locale,
			}),
			this.updateAvailability,
		);

		reaction(
			() => this.booking.parkingProduct?.id,
			this.updateSelectedParkingProductId,
		);

		reaction(() => this.originalBooking, this.handleOriginalBookingChange);

		reaction(
			() => this.parkingProductsQuoteStore.parkingProducts,
			this.handleParkingProductAvailabilityChange,
		);

		reaction(
			() => this.parkingProductsQuoteStore.daysInTotal,
			(daysInTotal) => {
				this.booking.daysInTotal = daysInTotal;
			},
		);

		reaction(
			() => toJS(this.parkingProductsQuoteStore.ancillaryGroups),
			this.handleAncillaryAvailabilityChange,
		);
	}

	protected updateAvailability({
		entryDate,
		exitDate,
		promoCode,
	}: Partial<IParkingProductsQuoteParams>) {
		if (!entryDate || !exitDate) {
			return;
		}

		this.parkingProductsQuoteStore.load({
			entryDate,
			exitDate,
			promoCode,
			locale: this.booking.locale,
		});
	}

	get hasUpgraded() {
		return !!this._preUpgradeParkingProduct;
	}

	get loyaltyPromoCodeTitle() {
		return this._loyaltyPromoCode;
	}

	set loyaltyPromoCodeTitle(loyaltyPromoCode: string) {
		this._loyaltyPromoCode = loyaltyPromoCode;
	}

	get parkingProductsQuoteLoyaltyPromotions() {
		return this.parkingProductsQuoteStore?.loyaltyPromoCodeDetails;
	}

	protected updateSelectedParkingProductId(id?: number) {
		if (!this.hasUpgraded) {
			this.parkingProductsQuoteStore.updateUpgrades(this.booking.locale, id);
		}

		this.parkingProductsQuoteStore.updateAncillaryGroups(
			this.booking.locale,
			id,
		);
	}

	public async submit(paymentDetails: IPaymentDetails) {
		this.isSubmitting = true;
		this.booking.paymentDetails = paymentDetails;

		const bookingCreateData = adaptBookingCreateData(
			this.booking,
			this.parkingProductsQuoteStore.key,
			this.originalBooking,
		);

		if (!bookingCreateData) {
			throw new Error('Invalid Booking data');
		}

		const { reference, token } = await this.bookingService.create(
			bookingCreateData,
			{
				locale: this.booking.locale,
			},
		);

		this.onSubmitSuccess(reference, token);
	}

	private onSubmitSuccess(reference: string, token: string) {
		this.booking.reference = reference;
		this.booking.token = token;
		this.isSubmitting = false;
	}

	public reset() {
		this._preUpgradeParkingProduct = undefined;
		this.booking = new NewBooking();
		this.parkingProductsQuoteStore.reset();
		this.originalBooking = undefined;
	}

	selectParkingProduct(parkingProduct: IParkingProduct) {
		this._preUpgradeParkingProduct = undefined;
		this.booking.parkingProduct = parkingProduct;
	}

	applyOfferPromoCode(promoCode: string, promoCodeTitle: string) {
		this.booking.promoCode = promoCode;
		this._loyaltyPromoCode = promoCodeTitle;
	}

	removeOfferPromoCode() {
		this.booking.promoCode = '';
		this._loyaltyPromoCode = '';
	}

	selectUpgrade(upgrade: IParkingProductUpgrade) {
		this.removeUpgrade();

		const upgradedParkingProduct = this.createUpgradedParkingProduct(upgrade);

		this._preUpgradeParkingProduct = this.booking.parkingProduct;
		this.booking.parkingProduct = upgradedParkingProduct;
	}

	private createUpgradedParkingProduct(
		upgrade: IParkingProductUpgrade,
	): IParkingProduct | undefined {
		const parkingProduct = this.parkingProductsQuoteStore.findParkingProduct(
			upgrade.id,
		);

		if (!parkingProduct) {
			return undefined;
		}

		return {
			...parkingProduct,
			price: {
				value: upgrade.price.value,
				originalValue: parkingProduct.price.originalValue,
				onlineSavings: parkingProduct.price.onlineSavings,
			},
		};
	}

	removeUpgrade() {
		if (this._preUpgradeParkingProduct) {
			this.booking.parkingProduct = this._preUpgradeParkingProduct;
		}

		this._preUpgradeParkingProduct = undefined;
	}

	getAncillariesForSelections(
		ancillaryGroupSelections: IAncillaryGroupSelection[],
	) {
		return getAncillariesForSelections(
			this.parkingProductsQuoteStore.ancillaryGroups,
			ancillaryGroupSelections,
		);
	}

	get ancillaryGroupSelections(): IAncillaryGroupSelection[] {
		return getSelectionsForAncillaries(
			this.parkingProductsQuoteStore.ancillaryGroups,
			this.booking.ancillaries,
		);
	}

	selectAncillaries(selectedAncillaryGroups: IAncillaryGroupSelection[]) {
		const ancillaries = this.getAncillariesForSelections(
			selectedAncillaryGroups,
		);

		this.booking.ancillaries.replace(ancillaries);
	}

	private handleOriginalBookingChange() {
		if (!this.originalBooking) {
			return;
		}

		// When an original booking is provided it will be used to for setting the initial data of the new booking.
		const initialNewBooking = adaptOriginalBookingToInitialNewBooking(
			this.originalBooking,
		);

		Object.assign(this.booking, initialNewBooking);
	}

	private resetParkingProductSelection() {
		log.debug('Reset Parking Product Selection');
		this._preUpgradeParkingProduct = undefined;

		if (!this.originalBooking) {
			this.booking.parkingProduct = undefined;
			return;
		}

		const parkingProduct = adaptBookingParkingProductToAvailabeParkingProduct(
			this.originalBooking.parkingProduct,
			this.parkingProductsQuoteStore.parkingProducts,
		);

		log.debug('Select Original Parking Product (if available)', parkingProduct);

		this.booking.parkingProduct = parkingProduct;
	}

	private resetAncillariesSelection() {
		log.debug('Reset Ancillaries selection');

		if (
			!this.parkingProductsQuoteStore.hasAncillaries ||
			!this.originalBooking?.ancillaries
		) {
			this.booking.ancillaries.clear();
			return;
		}

		// When an original booking is provided, and ancillaries are fetched,
		// we should match the ancillaries from the original booking with the available ancillaries, and select those
		const ancillaries = adaptBookingAncillariesToAvailableAncillaries(
			this.originalBooking.ancillaries,
			this.parkingProductsQuoteStore.ancillaryGroups,
		);

		log.debug('Select Original Ancillaries (if available)', ancillaries);

		this.booking.ancillaries.replace(ancillaries);
	}

	private handleParkingProductAvailabilityChange() {
		this.resetParkingProductSelection();
	}

	private handleAncillaryAvailabilityChange() {
		this.resetAncillariesSelection();
	}
}

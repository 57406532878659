import { ICustomTheme } from '../../../ui';
import { Color } from './Color';
import { FONT_FAMILY_AVENIR_NEXT_LT_PRO } from './Fonts';
import { Z_INDEX_BASE, Z_INDEX_DROPDOWN } from './zIndex';

export const theme: ICustomTheme = {
	layout: {
		page: {
			padding: '0',
		},
	},
	typography: {
		body: {
			fontFamily: `${FONT_FAMILY_AVENIR_NEXT_LT_PRO}, Avenir, sans-serif`,
		},
		header: {
			h1: {
				fontWeight: '700',
			},
			h2: {
				fontWeight: '700',
			},
			h3: {
				fontWeight: '700',
			},
			h4: {
				fontWeight: '700',
			},
			h5: {
				fontWeight: '700',
				color: '#4E4E4E',
			},
		},
		link: {
			textDecoration: 'none',
			hover: {
				textDecoration: 'underline',
			},
		},
	},
	input: {
		boxShadow: '',
		borderRadius: '4px',
		background: '#f7f7f7',
		border: '1px solid #e6e6e6',
		padding: '15px',
		error: {
			borderRadius: '4px 4px 0 0',
		},
		focus: {
			boxShadow: `0 0 0 3px ${Color.AccentDefault}`,
			borderRadius: '4px',
		},
		placeholder: {
			color: '#4e4e4e',
		},
		configuration: {
			error: {
				overrideDefaultShadow: true,
			},
		},
	},
	textField: {
		errorMessage: {
			paddingLeft: '16px',
			fontSize: '14px',
			paddingTop: '4px',
		},
	},
	dropdownList: {
		option: {
			highlighted: {
				color: '#005B99',
			},
		},
	},
	select: {
		main: {
			boxShadow: '',
			background: '#f7f7f7',
			padding: '15px',
			borderRadius: '4px',
			border: '1px solid #e6e6e6',
		},
		placeholder: {
			color: '#4e4e4e',
		},
		focus: {
			boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.15)',
		},
	},
	search: {
		button: {
			borderRadius: '4px',
		},
		wrap: {
			boxShadow: '',
			borderRadius: '4px',
		},
	},
	button: {
		primary: {
			boxShadow: '0 3px 0 0 #0060a1',
			hover: {
				background: '#1a84cd',
				border: '2px solid #1a84cd',
			},
			active: {
				boxShadow: '0 2px 0 0 #0060a1',
				transform: 'translateY(1px)',
			},
			focus: {
				outline: `3px solid ${Color.AccentDefault}`,
			},
			inverted: {
				background: '#fff',
				border: '2px solid #fff',
				boxShadow: '0 3px 0 0 #efeff4',
				font: {
					color: '#000',
				},
				active: {
					boxShadow: '0 2px 0 0 #efeff4',
					transform: 'translateY(1px)',
				},
			},
		},
		secondary: {
			hover: {
				border: '2px solid #1a84cd',
				font: {
					color: '#1a84cd',
				},
			},
			inverted: {
				border: '2px solid #fff',
				background: 'none',
				font: {
					color: '#fff',
				},
				hover: {
					background: 'none',
					font: {
						color: '#fff',
					},
				},
			},
		},
	},
	zIndex: {
		base: `${Z_INDEX_BASE}`,
		dropdown: `${Z_INDEX_DROPDOWN}`,
	},
	card: {
		hover: {
			color: '#0077c8',
		},
	},
	chip: {
		background: {
			error: '#CC1100', // Same as canceled flight status
		},
	},
	alertBanner: {
		background: '#FCF6E3',
		emergencyBackground: '#FFCFCC',
	},
	grid: {
		gutter: {
			horizontal: {
				default: '24px',
				mobile: '16px',
			},
			vertical: {
				default: '24px',
				mobile: '16px',
			},
		},
	},
};

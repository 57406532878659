import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { ArrowButton } from '../../../../../ui';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import illustration from '../../../../../../assets/balloons.svg?reference';
import {
	DialogHeader,
	DialogHeaderGradient,
	DialogIllustration,
	DialogMain,
} from '../../../../shared/components/dialog';
import { useDialogContext } from '../../../../shared/components/dialog/DialogContext';
import { useUserEmail } from '../../../user/domain/stores/useUserEmail';
import { useAuthStore } from '../../../shared/store/useAuthStore';
import Responsive from '../../../../../ui/utils/Responsive';

export const SignUpConfirmationDialogContent = observer(() => {
	const { t } = useTranslation();
	const { close } = useDialogContext();
	const { isAuthenticated } = useAuthStore();
	const email = useUserEmail();

	useEffect(() => {
		if (!isAuthenticated) {
			close();
		}
	}, [isAuthenticated, close]);

	return (
		<>
			<DialogHeader hide={[false, { tabletXL: true }]} />
			<DialogHeaderGradient
				hide={[true, { tabletXL: false }]}
				title={t('user_sign_up_title')}
			/>
			<DialogMain>
				<DialogIllustration src={illustration} />
				<h3>{t('user_sign_up_confirmation_title')}</h3>
				{email &&
					t('web_user_sign_up_confirmation_body', {
						user_email_address: email,
					})}
				<Actions />
			</DialogMain>
		</>
	);
});

const Actions = () => {
	const { t } = useTranslation();
	const { close } = useDialogContext();

	return (
		<StyledActions>
			<ArrowButton onClick={close}>
				{t('user_sign_up_confirmation_continue_button')}
			</ArrowButton>
		</StyledActions>
	);
};

const StyledActions = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 40px 0;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin: 24px 0;
	}
`;

import React from 'react';
import Head from 'next/head';
import { isPublicRelease } from '../../../shared/utils/isPublicRelease';
import { useCommonData } from './CommonDataContext';

export const AppMetaData = () => {
	const { locale } = useCommonData();

	return (
		<Head>
			<meta name="viewport" content="width=device-width, initial-scale=1" />

			{/* OpenGraph tags used by Facebook and LinkedIn */}
			<meta property="og:locale" content={locale.code.replace('-', '_')} />

			{/* Twitter tags */}
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:site" content="@CLTAirport" />

			<link rel="icon" sizes="96x96" href="/cltairport.png" />
			<link rel="manifest" href="/manifest.json" />
			<meta name="theme-color" content="#00778b" />
			<meta name="apple-itunes-app" content="app-id=1459226423" />

			{/* We use the meta tag to block indexing of non-public releases */}
			{/* See: https://support.google.com/webmasters/answer/6062608?hl=en */}
			{!isPublicRelease() && <meta name="robots" content="noindex, nofollow" />}
		</Head>
	);
};

import React, {
	DetailedHTMLProps,
	forwardRef,
	InputHTMLAttributes,
	ReactNode,
} from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import { IUIComponentProps } from '../../types';
import { VisuallyHiddenInput } from '../Input';
import { BaseCheckboxIcon } from '../Checkbox';

interface IStyledRadioButtonProps {
	disabled?: boolean;
}

export const StyledRadioButtonIcon = styled(BaseCheckboxIcon)<
	IStyledRadioButtonProps,
	ITheme
>`
	width: ${themed(({ radioButton }) => radioButton.size)};
	height: ${themed(({ radioButton }) => radioButton.size)};
	border: 2px solid
		${themed(
			({ radioButton, color }, { disabled }) =>
				(disabled ? radioButton.disabled.color : radioButton.color) ??
				color.border.default,
		)};
	border-radius: 100%;

	&:after {
		display: none;
		width: ${themed(({ radioButton }) => radioButton.innerSize)};
		height: ${themed(({ radioButton }) => radioButton.innerSize)};
		border-radius: 100%;
		background: ${themed(
			({ radioButton, color }) => radioButton.active.color ?? color.highlight,
		)};
	}
`;

export const StyledRadioButton = styled.label<IStyledRadioButtonProps, ITheme>`
	display: flex;
	flex-direction: row;
	align-items: center;
	opacity: ${themed(({ radioButton }, { disabled }) =>
		disabled ? radioButton.disabled.opacity : '',
	)};

	input:checked + ${StyledRadioButtonIcon} {
		${themed(({ radioButton, color }, { disabled }) => {
			const activeColor =
				(disabled
					? radioButton.disabled.active.color
					: radioButton.active.color) ?? color.highlight;

			return `
				border-color: ${activeColor};
			`;
		})};

		&:after {
			display: block;
		}
	}

	${VisuallyHiddenInput}:focus-visible + ${StyledRadioButtonIcon} {
		box-shadow: 0 0 0 2px ${themed(({ color }) => color.highlight)};
		border-color: ${themed(({ color }) => color.highlight)};
	}
`;

export interface IRadioButtonProps
	extends IUIComponentProps,
		DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	label: ReactNode;
	ariaLabel?: string;
}

export const RadioButton = forwardRef<HTMLInputElement, IRadioButtonProps>(
	(
		{ className, ariaLabel, label, disabled, ...rest }: IRadioButtonProps,
		ref,
	) => (
		<StyledRadioButton disabled={disabled} className={className}>
			<VisuallyHiddenInput
				ref={ref}
				type="radio"
				disabled={disabled}
				aria-label={typeof label === 'string' ? ariaLabel ?? label : ariaLabel}
				{...rest}
			/>
			<StyledRadioButtonIcon disabled={disabled} />
			{label}
		</StyledRadioButton>
	),
);

import styled from '@emotion/styled';
import { IResponsivePictureProps, ResponsivePicture } from '../Image';

export const HighlightImage = styled(
	ResponsivePicture,
)<IResponsivePictureProps>`
	min-width: 50%;
	flex: 1 0 50%;

	// IE11 fix
	display: block;
	max-width: 50%;
`;

import styled from '@emotion/styled';
import { StyledCard, StyledCardContent, StyledCardLink } from '../Card';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import Responsive from '../../utils/Responsive';
import { StyledContactCardPhoneLink } from './StyledContactCardPhoneLink';

export const StyledContactCard = styled(StyledCard)<
	{ fullyClickable?: boolean },
	ITheme
>`
	${StyledCardContent} {
		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			display: flex;
			flex-direction: row;
			align-items: start;
		}
	}

	${StyledCardLink} {
		display: inline-flex;
		margin-top: 1em;
	}

	${StyledContactCardPhoneLink} {
		g {
			fill: ${themed(({ contact }) => contact.card.iconColor)};
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		font-size: 16px;
	}
`;

import styled from '@emotion/styled';
import { Gutter } from '../../utils';

export const Grid = styled.div`
	display: flex;
	flex-wrap: wrap;

	${Gutter.getWrapperStyles('horizontal')}
	${Gutter.getWrapperStyles('vertical')}
`;

import { getAnalyticsConfig } from '../shared/config/getAnalyticsConfig';
import { trackWithGoogleAnalytics } from '../shared/trackers/trackWithGoogleAnalytics';
import { trackWithBing } from '../shared/trackers/trackWithBing';

export function trackPageView(url: string) {
	const { legacyTrackingId, googleAdsTrackingId } = getAnalyticsConfig();

	// No need for manual page tracking for GA4, as it's supported out of the box.
	// We just need it for our legacy analytics
	if (legacyTrackingId) {
		trackPageViewForId(legacyTrackingId, url);
	}

	if (googleAdsTrackingId) {
		trackPageViewForId(googleAdsTrackingId, url);
	}

	trackPageViewToBing(url);
}

export function trackPageViewForId(trackingId: string, url: string) {
	trackWithGoogleAnalytics('config', trackingId, {
		page_path: url,
	});
}

function trackPageViewToBing(url: string) {
	// See: https://bingadsuet.azurewebsites.net/SPA_Index.html
	trackWithBing('event', 'page_view', { page_path: url });
}

import React, { ReactNode } from 'react';
import { ThemeProvider } from 'emotion-theming';
import { Utils } from '../../utils';
import { DeepPartial } from '../../types';
import { IConfig } from './IConfig';
import { defaultConfig } from './defaultConfig';
import { ConfigContext } from './ConfigContext';

interface IProps {
	config: DeepPartial<IConfig>;
	children?: ReactNode;
}

export const ConfigProvider = ({ config, children }: IProps) => {
	const configWithDefaults = Utils.deepMerge(defaultConfig, config) as IConfig;

	return (
		<ConfigContext.Provider value={configWithDefaults}>
			<ThemeProvider theme={configWithDefaults.theme}>{children}</ThemeProvider>
		</ConfigContext.Provider>
	);
};

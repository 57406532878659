import { parsePrice } from '../../../../../shared/i18n/price/utils/parsePrice';
import { IParkingProductUpgradeResponse } from '../../../../../shared/api/gateway/types/IGetParkingProductUpgradesResponse';
import { IParkingProductUpgrade } from '../../models/IParkingProductUpgrade';

export function adaptResponseToUpgrade(
	response: IParkingProductUpgradeResponse,
): IParkingProductUpgrade {
	return {
		...response,
		price: {
			value: parsePrice(response.price.value),
			upgradeCost: parsePrice(response.price.upgradeCost),
		},
	};
}

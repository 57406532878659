import {
	FirebaseAppStore,
	FirebaseRemoteConfigStore,
} from '@move-frontend/firebase';
import { remoteConfigSchema } from '../models/RemoteConfigSchema';
import { IRemoteConfig } from '../models/IRemoteConfig';

export function createRemoteConfigStore() {
	const appConfig = {
		apiKey: process.env.FIREBASE_API_KEY as string,
		authDomain: process.env.FIREBASE_AUTH_DOMAIN as string,
		databaseURL: process.env.FIREBASE_DATABASE_URL as string,
		projectId: process.env.FIREBASE_PROJECT_ID as string,
		storageBucket: process.env.FIREBASE_STORAGE_BUCKET as string,
		messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID as string,
		appId: process.env.FIREBASE_APP_ID as string,
	};

	const appStore = new FirebaseAppStore(appConfig);
	const remoteConfigStore = new FirebaseRemoteConfigStore<
		typeof remoteConfigSchema,
		IRemoteConfig
	>(appStore, remoteConfigSchema, {
		security_wait_times_enabled: true,
		bus_wait_times_enabled: true,
	});

	return {
		remoteConfigStore,
	};
}

import {
	EVENT_ACTION_ACCOUNT_REGISTRATION_VALIDATION_ERROR,
	EVENT_CATEGORY_ACCOUNT_REGISTRATION,
} from '../../../../shared/analytics';
import {
	IFormErrors,
	trackFormValidationErrors,
} from '../../../../shared/analytics/form/trackFormValidationErrors';
import { ISignUpFormData } from '../../domain/models/ISignUpFormData';

export function trackSignUpValidationErrors(
	errors: IFormErrors<ISignUpFormData>,
) {
	trackFormValidationErrors({
		category: EVENT_CATEGORY_ACCOUNT_REGISTRATION,
		action: EVENT_ACTION_ACCOUNT_REGISTRATION_VALIDATION_ERROR,
		errors,
	});
}

import React, { useState } from 'react';
import { MaybeCognitoError } from '@move-frontend/auth';
import {
	DialogHeaderGradient,
	DialogMain,
} from '../../../shared/components/dialog';
import { DialogFooter } from '../../../shared/components/dialog/DialogFooter';
import { setHash } from '../../../shared/router/hash/hashHistory';
import { ForgotPasswordErrorMessage } from './ForgotPasswordErrorMessage';
import { ForgotPasswordForm } from './form/ForgotPasswordForm';

interface IProps {
	confirmationDialogHash: string;
	title: string;
	description: string;
	hideBackLink?: boolean;
	customButtonText?: string;
}

export const ForgotPasswordDialogContent = ({
	confirmationDialogHash,
	title,
	description,
	hideBackLink,
	customButtonText,
}: IProps) => {
	const [error, setError] = useState<MaybeCognitoError | undefined>(undefined);

	const onSuccess = () => {
		setError(undefined);
		setHash(confirmationDialogHash, { replace: true });
	};
	const onFailure = setError;

	return (
		<>
			<DialogHeaderGradient title={title} backgroundTheme="purple-blue" />
			<DialogMain>
				<p>{description}</p>
				<ForgotPasswordForm
					onSuccess={onSuccess}
					onFailure={onFailure}
					hideBackLink={hideBackLink}
					customButtonText={customButtonText}
				/>
			</DialogMain>
			{error && (
				<DialogFooter variation="error">
					<ForgotPasswordErrorMessage error={error} />
				</DialogFooter>
			)}
		</>
	);
};

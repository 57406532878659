import { IPrice } from '../IPrice';
import { Price } from '../Price';

export function parsePrice(input: number | string | Price): IPrice {
	if (input instanceof Price) return input;

	const number = parseMaybeString(input);

	if (Number.isNaN(number)) {
		throw new Error(`Invalid price value: ${input}`);
	}

	const centAmount = getCentAmount(number);

	return new Price({ centAmount });
}

function parseMaybeString(input: number | string): number {
	return typeof input === 'string' ? parseFloat(input) : input;
}

function getCentAmount(value: number) {
	return Math.round(value * 100);
}

import { IBooking } from '../../models/IBooking';
import { INewBooking } from '../../models/INewBooking';

export function adaptOriginalBookingToInitialNewBooking(
	originalBooking: IBooking,
	/**
	 * A Booking can't be mapped to a New Booking. At least not with the purpose of showing it in the Booking summary.
	 * The problem is that Aeroparker API doesn't allow us to retrieve the Ancillaries for an existing booking.
	 * This means that if we would show this data in the Partial Amend flow using an empty array for ancillaries,
	 * that any Booking that actually was made WITH ancillaries, will be shown WITHOUT and therefor show incorrect data including an incorrect total price.
	 */
): Partial<INewBooking> {
	const {
		parkingProductsQuote,
		personalDetails,
		vehicleDetails,
		travelDetails,
		daysInTotal,
	} = originalBooking;
	const entryDate = new Date(parkingProductsQuote.arrivalDateTime);
	const exitDate = new Date(parkingProductsQuote.returnDateTime);

	return {
		entryDate,
		exitDate,
		promoCode: parkingProductsQuote.promoCode,
		personalDetails,
		vehicleDetails,
		travelDetails,
		daysInTotal,
	};
}

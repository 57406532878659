import React, { Component, ReactNode } from 'react';
import styled from '@emotion/styled';
import { AccordionContext } from './AccordionContext';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

interface IAccordionProps {
	children: ReactNode;
	className?: string;
}

interface IAccordionState {
	selectedItem?: Component;
}

const StyledAccordion = styled('div')<{}, ITheme>`
	border-top: ${themed(({ accordion }) => accordion.border)};
`;

export class Accordion extends Component<IAccordionProps, IAccordionState> {
	public state = {
		selectedItem: undefined,
	};

	private setSelectedItem = (item: Component) => {
		this.setState((state) => {
			if (item === state.selectedItem) {
				return { selectedItem: undefined };
			}

			return { selectedItem: item };
		});
	};

	public render() {
		const { children, className } = this.props;
		const { selectedItem } = this.state;

		return (
			<StyledAccordion className={className} role="list">
				<AccordionContext.Provider
					/* eslint-disable-next-line react/jsx-no-constructed-context-values */
					value={{
						onToggle: this.setSelectedItem,
						selectedItem,
					}}
				>
					{children}
				</AccordionContext.Provider>
			</StyledAccordion>
		);
	}
}

import { IAncillary } from '../../../ancillary/domain/models/IAncillary';
import { IAncillaryGroup } from '../../../ancillary/domain/models/IAncillaryGroup';
import { IExtra } from '../../../ancillary/domain/models/IExtra';
import { IExtraVariant } from '../../../ancillary/domain/models/IExtraVariant';
import {
	IBookingAncillary,
	IBookingExtra,
	IBookingExtraVariant,
} from '../../models/IBooking';

export function adaptBookingAncillariesToAvailableAncillaries(
	bookingAncillaries: IBookingAncillary[],
	availableAncillaryGroups: IAncillaryGroup[],
): IAncillary[] {
	const nestedAvailableAncillaries = availableAncillaryGroups.map(
		(ancillaryGroup) =>
			adaptAncillaries(bookingAncillaries, ancillaryGroup.ancillaries),
	);

	return ([] as IAncillary[]).concat(...nestedAvailableAncillaries);
}

function adaptAncillaries(
	bookingAncillaries: IBookingAncillary[],
	availableAncillaries: IAncillary[],
): IAncillary[] {
	const bookingAncillaryIds = bookingAncillaries.map(({ id }) => id);

	return availableAncillaries
		.filter(({ id }) => bookingAncillaryIds.includes(id))
		.map((ancillary) => {
			const bookingExtras =
				bookingAncillaries.find(({ id }) => id === ancillary.id)?.extras ?? [];

			return {
				...ancillary,
				extras:
					ancillary.extras && adaptExtras(bookingExtras, ancillary.extras),
			};
		});
}

function adaptExtras(
	bookingExtras: IBookingExtra[],
	availableExtras: IExtra[],
): IExtra[] {
	const bookingExtraIds = bookingExtras.map(({ id }) => id);

	return availableExtras
		.filter(({ id }) => bookingExtraIds.includes(id))
		.map((extra) => {
			const bookingExtraVariants =
				bookingExtras.find(({ id }) => id === extra.id)?.variants ?? [];

			return {
				...extra,
				variants: adaptExtraVariants(bookingExtraVariants, extra.variants),
			};
		});
}

function adaptExtraVariants(
	bookingExtraVariants: IBookingExtraVariant[],
	availableExtraVariants: IExtraVariant[],
): IExtraVariant[] {
	const bookingExtraVariantIds = bookingExtraVariants.map(({ id }) => id);

	return availableExtraVariants.filter(({ id }) =>
		bookingExtraVariantIds.includes(id),
	);
}

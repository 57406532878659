import styled from '@emotion/styled';
import Responsive from '../../utils/Responsive';

export const StyledCardContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	h3 {
		margin-bottom: 0;
	}

	p {
		margin-bottom: 16px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		line-height: 1.33;

		h3 {
			margin-bottom: 0;
		}

		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}
`;

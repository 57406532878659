import React, { useEffect, useRef } from 'react';
import { PropGetters } from 'downshift';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

import { DropdownItem, IDropdownItem } from './DropdownItem';
import { DropdownOption } from './DropdownOption';
import { IGetItemDisplayValue, IGetItemKey } from './helpers';
import { IGetSectionHeaderDisplayValue } from './helpers/IGetSectionHeaderDisplayValue';
import { VisuallyHidden } from '../VisuallyHidden';

interface IStyledDropdownListWrapProps {
	isOpen?: boolean;
}

export const StyledDropdownListWrap = styled.div<
	IStyledDropdownListWrapProps,
	ITheme
>`
	position: relative;
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export const StyledDropdownList = styled.div<{}, ITheme>`
	position: absolute;
	width: 100%;
	overflow-y: auto;
	max-height: ${themed(({ dropdownList }) => dropdownList.options.maxHeight)};
	z-index: ${themed(({ dropdownList }) => dropdownList.options.zIndex)};
	margin-top: ${themed(({ dropdownList }) => dropdownList.options.marginTop)};
	box-shadow: ${themed(({ dropdownList }) => dropdownList.options.boxShadow)};
	background: ${themed(({ dropdownList }) => dropdownList.options.background)};
`;

interface IDropdownListProps {
	getMenuProps: PropGetters<IDropdownItem>['getMenuProps'];
	getItemProps: PropGetters<IDropdownItem>['getItemProps'];
	getItemDisplayValue: IGetItemDisplayValue;
	getSectionHeaderDisplayValue?: IGetSectionHeaderDisplayValue;
	selectedItem: IDropdownItem | null;
	highlightedIndex: number | null;
	getItemKey: IGetItemKey;
	items: IDropdownItem[];
	isOpen: boolean;
	defaultScrollToNthItem?: number;
}

export const DropdownList = ({
	getItemDisplayValue,
	getSectionHeaderDisplayValue,
	highlightedIndex,
	selectedItem,
	getMenuProps,
	getItemProps,
	getItemKey,
	isOpen,
	items,
	defaultScrollToNthItem,
}: IDropdownListProps) => {
	const listRef = useRef<HTMLDivElement>(null);
	const optionRef = useRef<HTMLDivElement>(null);
	const dropdownId = `dropdown-list-${getMenuProps().id}`;

	// defaultScrollToNthItem will automatically scroll to the item by default
	useEffect(() => {
		if (
			defaultScrollToNthItem &&
			listRef.current &&
			optionRef.current &&
			isOpen
		) {
			// eslint-disable-next-line no-param-reassign
			listRef.current.scrollTop = optionRef.current.offsetTop;
		}
	}, [isOpen, defaultScrollToNthItem]);

	return (
		<StyledDropdownListWrap isOpen={isOpen}>
			<VisuallyHiddenLabel id={dropdownId}>
				Select an option
			</VisuallyHiddenLabel>
			<StyledDropdownList
				{...getMenuProps({ ref: listRef })}
				data-testid="list"
				aria-labelledby={dropdownId}
				tabIndex={0}
			>
				{isOpen &&
					items.map((item: IDropdownItem, index: number) => {
						if (typeof item !== 'string' && item.type === 'header') {
							if (getSectionHeaderDisplayValue) {
								return getSectionHeaderDisplayValue(item);
							}

							return <div>{item.title}</div>;
						}

						return (
							<DropdownOption
								key={getItemKey(item)}
								ref={index === defaultScrollToNthItem ? optionRef : undefined}
								hasSecondaryStyle={
									typeof item !== 'string' && item.hasSecondaryStyle
								}
								isHighlighted={highlightedIndex === index}
								{...getItemProps({
									index,
									item,
									isSelected: selectedItem === item,
								})}
							>
								<DropdownItem
									displayValue={getItemDisplayValue(item)}
									hint={typeof item !== 'string' ? item.hint : undefined}
								/>
							</DropdownOption>
						);
					})}
			</StyledDropdownList>
		</StyledDropdownListWrap>
	);
};

const VisuallyHiddenLabel = VisuallyHidden.withComponent('label');

import React, { ComponentType } from 'react';
import { TOptions } from 'i18next';
import { I18nContext } from './I18nContext';
import { ITranslationKey } from './models/ITranslationKey';

export interface ITranslationFunction {
	(key: ITranslationKey, options?: TOptions): string;
}

export interface II18nProps {
	t: ITranslationFunction;
}

export const withTranslate =
	<P extends {} = {}>(Component: ComponentType<P & II18nProps>) =>
	(props: P) =>
		(
			<I18nContext.Consumer>
				{(i18n) => {
					if (!i18n) {
						throw new Error('No React I18n instance initialized');
					}

					return (
						<Component
							t={i18n.t.bind(i18n) as ITranslationFunction}
							{...props}
						/>
					);
				}}
			</I18nContext.Consumer>
		);

import { FieldError } from 'react-hook-form';
import { trackEvent } from '../event/trackEvent';

export type IFormErrors<TFormData extends object> = Partial<
	Record<keyof TFormData, FieldError>
>;

interface IFormValidationErrorsEvent<TFormData extends object> {
	category: string;
	action: string;
	errors: IFormErrors<TFormData>;
}

export function trackFormValidationErrors<TFormData extends object>({
	category,
	action,
	errors,
}: IFormValidationErrorsEvent<TFormData>) {
	Object.entries(errors).forEach(([field, error]) => {
		trackEvent({
			category,
			action,
			label: getErrorLabel(field, error as FieldError),
		});
	});
}

function getErrorLabel(field: string, error?: FieldError) {
	if (!error?.message) {
		return field;
	}

	return `${field} - ${error.message}`;
}

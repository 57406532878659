import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { ITextFieldProps, TextField } from '../../../../ui';

export type PasswordProps = Partial<ITextFieldProps> & {
	validateConfirmation?: boolean;
};

export const Password = ({ validateConfirmation, ...props }: PasswordProps) => {
	const { t } = useTranslation();
	const { register, errors, trigger } = useFormContext();

	const onChange = validateConfirmation
		? () => {
				if (errors.confirmPassword) {
					trigger('confirmPassword');
				}
		  }
		: undefined;

	return (
		<TextField
			name="password"
			type="password"
			label={t('user_form_password_label')}
			autoComplete="password"
			ref={register()}
			invalid={!!errors.password}
			error={errors.password?.message}
			onChange={onChange}
			{...props}
		/>
	);
};

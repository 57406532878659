import React from 'react';
import { isUrlToPdf, LinkText, StyledIconLink } from '../Link';
import { DownloadIcon, PdfIcon } from '../Icon';
import { QuickLinkItem } from './QuickLinkItem';

export interface IQuickLinkAssetProps {
	title: string;
	url: string;
}

export const QuickLinkAsset = ({ title, url }: IQuickLinkAssetProps) => (
	<QuickLinkItem>
		<StyledIconLink href={url} target="_blank">
			<LinkText>{title}</LinkText>
			{isUrlToPdf(url) ? (
				<PdfIcon variation="highlight" />
			) : (
				<DownloadIcon variation="highlight" />
			)}
		</StyledIconLink>
	</QuickLinkItem>
);

import React from 'react';
import styled from '@emotion/styled';
import {
	getHeaderStyleCss,
	Header as BaseHeader,
	IHeaderProps as IBaseHeaderProps,
	ITheme,
	StyledHeaderParentTitle,
} from '../../../../ui';
import { IHeaderTheme } from '../../api/contentful/types/IHeaderTheme';
import { getGradientBackground, Z_INDEX_HEADER } from '../../styles';
import curve from './curve.svg?reference';
import Responsive from '../../../../ui/utils/Responsive';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';

export interface IHeaderProps extends IBaseHeaderProps {
	theme?: IHeaderTheme;
}

interface IStyledHeaderProps {
	backgroundTheme: IHeaderTheme;
}

export const HEADER_MARGIN_BOTTOM_DESKTOP = '40px';
export const HEADER_MARGIN_BOTTOM_MOBILE = '20px';
export const HEADER_PADDING_BOTTOM = '5%';
export const HEADER_PADDING_BOTTOM_MOBILE = '8%';

function getHeaderStyle(backgroundTheme: IHeaderTheme) {
	if (!backgroundTheme) {
		return '';
	}

	return `
		${getGradientBackground(backgroundTheme)}

		h1,
		${StyledHeaderParentTitle} {
			color: #fff;
		}
	`;
}

export const StyledHeader = styled(BaseHeader)<IStyledHeaderProps, ITheme>`
	position: relative;
	padding-top: 80px;
	padding-bottom: ${HEADER_PADDING_BOTTOM};
	margin-bottom: ${HEADER_MARGIN_BOTTOM_DESKTOP};
	${StyledHeaderParentTitle} {
		${getHeaderStyleCss('h5')}
		margin-bottom: 0;

		svg {
			margin-left: -6px;
		}
	}
	${({ backgroundTheme }) => getHeaderStyle(backgroundTheme)};

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding-top: 20px;
		padding-bottom: ${HEADER_PADDING_BOTTOM_MOBILE};
		margin-bottom: ${HEADER_MARGIN_BOTTOM_MOBILE};

		${StyledHeaderParentTitle} {
			font-size: 16px;
		}
	}

	${ContentWrap} {
		position: relative;
		z-index: ${Z_INDEX_HEADER};
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		// Make sure sub-pixel rounding can not cause any white space, including some rare cases when -1px is not enough...
		bottom: -2px;
		top: 0;
		background: url(${curve});
		background-repeat: no-repeat;
		// Make sure we never see any white space, including some rare cases when +2px is not enough...
		background-size: calc(100% + 6px) auto;
		background-position: center bottom;
	}
`;

export const Header = ({ theme, ...rest }: IHeaderProps) => (
	<StyledHeader
		backgroundTheme={theme}
		inverted={theme !== undefined}
		{...rest}
	/>
);

import React, { Component, KeyboardEvent, MouseEvent } from 'react';
import { ISearchInputProps, SearchInput } from './SearchInput';

export interface ISearchProps extends Partial<ISearchInputProps> {
	onUpdateQuery: (query: string) => void;
	placeholderAsLabel?: boolean;
	isForm?: boolean;
	disabled?: boolean | undefined;
}

interface ISearchState {
	query: string;
}

export class Search extends Component<ISearchProps, ISearchState> {
	public state = {
		query: '',
	};

	private inputRef = React.createRef<HTMLInputElement>();

	public componentDidMount() {
		this.updateQuery();
	}

	public componentDidUpdate(_prevProps: ISearchProps, prevState: ISearchState) {
		const { query } = this.state;
		const { onUpdateQuery } = this.props;

		if (prevState.query !== query) {
			onUpdateQuery(query);
		}
	}

	private onClickClear = (e: MouseEvent) => {
		if (!this.inputRef.current) {
			return;
		}

		this.inputRef.current.value = '';
		this.updateQuery();

		const { onClickClear } = this.props;

		if (onClickClear) {
			onClickClear(e);
		}
	};

	private onClickSearch = (e: MouseEvent) => {
		this.updateQuery();

		const { onClickSearch } = this.props;

		if (onClickSearch) {
			onClickSearch(e);
		}
	};

	private onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		this.updateQuery();

		const { onKeyUp } = this.props;

		if (onKeyUp) {
			onKeyUp(e);
		}

		if (e.key === 'Enter') {
			e.currentTarget.blur();
		}
	};

	private updateQuery() {
		if (!this.inputRef.current) {
			return;
		}

		const query = this.inputRef.current.value;

		this.setState({
			query,
		});
	}

	public render() {
		const {
			onUpdateQuery,
			onClickSearch,
			onClickClear,
			onKeyUp,
			ref,
			isForm = false,
			...rest
		} = this.props;
		const { query } = this.state;

		const showClearIcon = query !== '';

		return (
			<SearchInput
				isForm={isForm}
				onClickSearch={this.onClickSearch}
				onClickClear={this.onClickClear}
				showClearIcon={showClearIcon}
				onKeyUp={this.onKeyUp}
				ref={this.inputRef}
				{...rest}
			/>
		);
	}
}

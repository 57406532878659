import {
	action,
	computed,
	flow,
	IReactionDisposer,
	isFlowCancellationError,
	makeObservable,
	observable,
	reaction,
} from 'mobx';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { logError } from '../../../../shared/error/logError';
import { AuthStore } from '../../../shared/store/AuthStore';
import { IAuthenticatedUser } from '../models/IAuthenticatedUser';
import { getAuthenticatedUser } from '../use-cases/getAuthenticatedUser';

export class UserStore {
	user: IAuthenticatedUser | undefined = undefined;

	private cancellablePromise: CancellablePromise<void> | undefined = undefined;

	private disposeAuthReaction: IReactionDisposer;

	constructor(private readonly authStore: AuthStore) {
		makeObservable<
			UserStore,
			'cancellablePromise' | 'update' | 'setUser' | 'handleError'
		>(this, {
			user: observable,
			cancellablePromise: observable,
			isLoading: computed,
			update: action.bound,
			setUser: action.bound,
			handleError: action.bound,
		});

		this.disposeAuthReaction = reaction(
			() => this.authStore.isAuthenticated,
			this.update,
			{ fireImmediately: true },
		);
	}

	public dispose() {
		this.disposeAuthReaction();
	}

	get isLoading() {
		return this.authStore.isLoading || !!this.cancellablePromise;
	}

	protected update(isAuthenticated: boolean) {
		this.cancel();

		if (!isAuthenticated) {
			this.user = undefined;
			return;
		}

		this.cancellablePromise = this.load();
		this.cancellablePromise.catch(this.handleError);
	}

	protected load = flow(function* loadUser(this: UserStore) {
		this.user = undefined;

		const user = yield getAuthenticatedUser();

		this.setUser(user);
	});

	protected setUser(user: IAuthenticatedUser) {
		this.user = user;
		this.cancellablePromise = undefined;
	}

	protected handleError(error: Error) {
		this.user = undefined;
		this.cancellablePromise = undefined;

		if (isFlowCancellationError(error)) {
			return;
		}

		logError(error);
	}

	private cancel() {
		if (!this.cancellablePromise) {
			return;
		}

		this.cancellablePromise.cancel();
	}
}

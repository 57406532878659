import { useEffect } from 'react';
import { useDialogContext } from '../../../../../shared/components/dialog/DialogContext';

import { setHash } from '../../../../../shared/router/hash/hashHistory';
import { FORGOT_PASSWORD_DIALOG_HASH } from '../../ForgotPasswordDialog';
import { useForgotPasswordStore } from '../../../domain/stores/useForgotPasswordStore';

export function useForgotPasswordResend() {
	const { close } = useDialogContext();
	const { email } = useForgotPasswordStore();

	useEffect(() => {
		if (!email) {
			close();
		}
	}, [email, close]);

	const resend = () => {
		setHash(FORGOT_PASSWORD_DIALOG_HASH, { replace: true });
	};

	return { email, resend };
}

import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

export const Shimmer = styled.div<{}, ITheme>`
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-image: linear-gradient(
			to right,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.8) 10%,
			rgba(255, 255, 255, 0) 20%,
			rgba(255, 255, 255, 0) 100%
		);
		background-repeat: no-repeat;
		background-size: 200%;

		animation-duration: ${themed(({ shimmer }) => shimmer.animation.duration)};
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: shimmer;
		animation-timing-function: linear;
	}

	@keyframes shimmer {
		0% {
			background-position: 30% 0;
		}

		100% {
			background-position: -170% 0;
		}
	}
`;

var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"clt-airport@a923"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { isProductionBuild } from './src/features/shared/utils/isProductionBuild';

if (isProductionBuild()) {
	Sentry.init({
		dsn: 'https://50b0a5395ead46438a693138048cc42e@o232156.ingest.us.sentry.io/1393225',
		tracesSampleRate: 0.1,
		debug: false,
		environment: process.env.ENVIRONMENT,
	});
}

import styled from '@emotion/styled';
import { IResponsivePictureProps, ResponsivePicture } from '../Image';

export const StyledCardPicture = styled(
	ResponsivePicture,
)<IResponsivePictureProps>`
	flex-shrink: 0;

	img {
		display: block;
		width: 100%;
	}
`;

import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import checkmarkIcon from '../../assets/checkmark.svg?reference';
import { VisuallyHiddenInput } from '../Input';

export const BaseCheckboxIcon = styled.div<{}>`
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-right: 12px;

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

export interface IStyledCheckboxProps {
	disabled?: boolean;
	invalid?: boolean;
}

const getCheckboxBorderColor = themed<IStyledCheckboxProps>(
	({ checkbox, color }, { disabled, invalid }) => {
		if (invalid) {
			return color.state.error;
		}

		return (
			(disabled ? checkbox.disabled.color : checkbox.color) ??
			color.border.default
		);
	},
);

export const StyledCheckboxIcon = styled(BaseCheckboxIcon)<
	IStyledCheckboxProps,
	ITheme
>`
	flex-shrink: 0;
	flex-grow: 0;
	margin-top: 0.1em;
	width: ${themed(({ checkbox }) => checkbox.size)};
	height: ${themed(({ checkbox }) => checkbox.size)};
	border: 2px solid ${getCheckboxBorderColor};
	border-radius: ${themed(({ checkbox }) => checkbox.borderRadius)};

	&:after {
		display: none;
		width: ${themed(({ checkbox }) => checkbox.innerSize)};
		height: ${themed(({ checkbox }) => checkbox.innerSize)};
		background: center / contain no-repeat url(${checkmarkIcon});
	}
`;

export const StyledCheckbox = styled.label<IStyledCheckboxProps, ITheme>`
	display: flex;
	opacity: ${themed(({ checkbox }, { disabled }) =>
		disabled ? checkbox.disabled.opacity : '',
	)};

	input:checked + ${StyledCheckboxIcon} {
		${themed(({ checkbox, color }, { disabled }) => {
			const activeColor =
				(disabled ? checkbox.disabled.active.color : checkbox.active.color) ??
				color.highlight;

			return `
			background: ${activeColor};
			border-color: ${activeColor};
		`;
		})};

		&:after {
			display: block;
		}
	}

	${VisuallyHiddenInput}:focus-visible + ${StyledCheckboxIcon} {
		box-shadow: 0 0 0 2px ${themed(({ color }) => color.state.hover)};
		border-color: ${themed(({ color }) => color.state.hover)};
	}
`;

import React from 'react';
import { Link, LinkText } from '../Link';
import { QuickLinkItem } from './QuickLinkItem';

export interface IQuickLinkExternalProps {
	title: string;
	url: string;
}

export const QuickLinkExternal = ({ title, url }: IQuickLinkExternalProps) => (
	<QuickLinkItem>
		<Link href={url} target="_blank">
			<LinkText>{title}</LinkText>
		</Link>
	</QuickLinkItem>
);

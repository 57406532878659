import { parsePrice } from '../../../../../../shared/i18n/price/utils/parsePrice';
import { IAncillaryGroupResponse } from '../../../../../../shared/api/gateway/types/IAncillaryGroupResponse';
import { IAncillaryGroup } from '../../models/IAncillaryGroup';

export function adaptResponseToAncillaryGroup(
	response: IAncillaryGroupResponse,
): IAncillaryGroup {
	return {
		...response,
		ancillaries: response.ancillaries.map((ancillary) => ({
			...ancillary,
			price: ancillary.price ? parsePrice(ancillary.price) : undefined,
			extras: ancillary.extras?.map((extra) => ({
				...extra,
				variants: extra.variants.map((variant) => ({
					...variant,
					price: parsePrice(variant.price),
				})),
			})),
		})),
	};
}

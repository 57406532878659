import React from 'react';
import { CardOrientation } from './CardsContainer';
import Responsive from '../../utils/Responsive';

export type IMobileCardViewType = 'compact' | 'normal';

export interface ICardContainerContextValues {
	fullyClickable?: boolean;
	cardOrientation?: Responsive.IValueOrResponsiveValue<CardOrientation>;
	titleFontSize?: Responsive.IValueOrResponsiveValue<number>;
	linkHidden?: Responsive.IValueOrResponsiveValue<boolean>;
	childrenHidden?: Responsive.IValueOrResponsiveValue<boolean>;
}

const defaultValue: ICardContainerContextValues = {
	fullyClickable: false,
	cardOrientation: 'vertical',
	titleFontSize: 32,
	linkHidden: false,
	childrenHidden: false,
};

export const CardContainerContext = React.createContext(defaultValue);

import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ICardContainerContextValues } from './CardContainerContext';
import { ITheme, IThemedProps } from '../../themer';
import { CardOrientation } from './CardsContainer';
import { StyledCardPicture } from './StyledCardPicture';
import { StyledCardContent } from './StyledCardContent';
import { StyledCardLink } from './StyledCardLink';
import { StyledCardChildren } from './StyledCardChildren';
import { FixedGridItem } from '../Grid';
import Responsive from '../../utils/Responsive';
import { StyledCardTitle } from './StyledCardTitle';
import { getIconLinkHoverStyle } from '../Link';
import themed from '../../themer/themed';

export interface IStyledCardProps
	extends ICardContainerContextValues,
		IThemedProps {
	children?: ReactNode;
	titleAsLinkCard?: boolean;
	fullyClickable?: boolean;
}

const getOrientationStyle = (orientation: CardOrientation) => {
	if (orientation === 'horizontal') {
		return `
			flex-direction: row;
			align-items: start;

			${StyledCardPicture} {
				max-width: 33.333%;
				img {
					width: auto;
					max-width: 100%;
				}
			}

			${StyledCardContent} {
				margin-top: 0;
				margin-bottom: 0;
				margin-left: 16px;
			}

			@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
				align-items: center;
			}
		`;
	}

	return `
		flex-direction: column;
	`;
};

const getTitleFontSizeStyle = (size: number) => `
	${StyledCardTitle} {
		font-size: ${size}px;
	}`;

const getLinkHiddenStyle = (isHidden: boolean) => `
		${StyledCardLink} {
			display: ${isHidden ? 'none' : ''}
		}
	`;

const getChildrenHiddenStyle = (isHidden: boolean) => `
		${StyledCardChildren} {
			display: ${isHidden ? 'none' : ''}
		}
	`;

export const StyledCard = styled(FixedGridItem)<IStyledCardProps, ITheme>`
	display: flex;
	transition: ${themed(({ card }) => card.transition)};
	box-shadow: ${themed(({ card }, { fullyClickable }) =>
		fullyClickable ? card.fullyClickable.boxShadow : card.boxShadow,
	)};
	cursor: ${({ fullyClickable }) => (fullyClickable ? 'pointer' : '')};
	text-align: left;

	&:hover {
		box-shadow: ${themed(({ card }, { fullyClickable }) =>
			fullyClickable
				? card.fullyClickable.hover.boxShadow
				: card.hover.boxShadow,
		)};

		${StyledCardLink}, .non-clickable-card-link {
			${({ fullyClickable, ...props }) =>
				fullyClickable && getIconLinkHoverStyle(props)};
		}

		${StyledCardTitle} {
			${({ titleAsLinkCard, theme }) =>
				titleAsLinkCard && `color: ${theme.card.hover.color}`}
		}
	}

	${StyledCardContent} {
		flex-grow: 1;
		width: 100%;
		padding: ${themed(({ card }, { fullyClickable }) =>
			fullyClickable ? card.fullyClickable.contentMargin : card.contentMargin,
		)};
	}

	${Responsive.getStyles<CardOrientation, IStyledCardProps>(
		'cardOrientation',
		getOrientationStyle,
	)};
	${Responsive.getStyles<number, IStyledCardProps>(
		'titleFontSize',
		getTitleFontSizeStyle,
	)};
	${Responsive.getStyles<boolean, IStyledCardProps>(
		'linkHidden',
		getLinkHiddenStyle,
	)};
	${Responsive.getStyles<boolean, IStyledCardProps>(
		'childrenHidden',
		getChildrenHiddenStyle,
	)};
`;

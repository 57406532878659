import { ApiClient } from './ApiClient';
import { BookingService } from './services/BookingService';
import { EmailSuggestionService } from './services/EmailSuggestionService';
import { ParkingProductsQuoteService } from './services/ParkingProductsQuoteService';
import { PublicKeyService } from './services/PublicKeyService';
import { TokenizedCardService } from './services/TokenizedCardService';
import { CaptureContextSessionService } from './services/CaptureContextSessionService';
import { AccountService } from './services/AccountService';

export class Gateway {
	private client: ApiClient;

	constructor() {
		this.client = new ApiClient();
	}

	booking() {
		return new BookingService(this.client);
	}

	parkingProductsQuote() {
		return new ParkingProductsQuoteService(this.client);
	}

	publicKey() {
		return new PublicKeyService(this.client);
	}

	captureContextSession() {
		return new CaptureContextSessionService(this.client);
	}

	tokenizedCard() {
		return new TokenizedCardService(this.client);
	}

	emailSuggestion() {
		return new EmailSuggestionService(this.client);
	}

	account() {
		return new AccountService(this.client);
	}
}

import { IGetParams, resolveQueryString } from '@move-frontend/utils';

type Fit = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';

type FocusAreas =
	| 'center'
	| 'top'
	| 'right'
	| 'left'
	| 'bottom'
	| 'top_right'
	| 'top_left'
	| 'bottom_right'
	| 'bottom_left'
	| 'face'
	| 'faces';

export interface IImageOptions extends IGetParams {
	/** Width */
	w?: number;
	/** Height */
	h?: number;
	/** Image format */
	fm?: 'jpg' | 'png' | 'webp';
	/** File compression */
	fl?: 'progressive' | 'png8';
	/** Resizing behavior */
	fit?: Fit;
	/** Focus area */
	f?: FocusAreas;
	/** Rounded corners radius */
	r?: number;
	/** Quality, value between 1 and 100. */
	q?: number;
	/** Background color */
	bg?: string;
}

export const progressiveJpegOptions = {
	fm: 'jpg' as const,
	fl: 'progressive' as const,
	q: 98, // no perceivable jpeg artifacts, yet 30% smaller images
};

/**
 * @see https://www.contentful.com/developers/docs/references/images-api
 */
export function getResponsiveImageUrl(url: string, options: IImageOptions) {
	return `${url}${resolveQueryString(options)}`;
}

import React from 'react';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { HashDialog } from '../../../../shared/components/dialog/HashDialog';

import { ForgotPasswordConfirmationDialogContent } from './ForgotPasswordConfirmationDialogContent';

export const FORGOT_PASSWORD_CONFIRMATION_DIALOG_HASH =
	'#forgot-password-confirmation';

export const ForgotPasswordConfirmationDialog = () => {
	const { t } = useTranslation();

	const title = t('user_forgot_password_title');

	return (
		<HashDialog
			ariaLabel={title}
			hash={FORGOT_PASSWORD_CONFIRMATION_DIALOG_HASH}
		>
			<ForgotPasswordConfirmationDialogContent title={title} />
		</HashDialog>
	);
};

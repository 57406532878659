import React from 'react';
import styled from '@emotion/styled';
import { IStyledHeaderProps, ITheme } from '../../../../ui';
import { StyledHeader } from '../../layout/header';
import { IHeaderTheme } from '../../api/contentful/types';
import { useDialogContext } from './DialogContext';
import {
	DialogCloseButton,
	StyledDialogCloseButton,
} from './DialogCloseButton';
import {
	getDialogMaxWidth,
	getDialogPadding,
	getDialogPaddingMobile,
	IDialogSizeProps,
} from './DialogMain';
import { IResponsiveHiddenProps } from './DialogHeader';
import themed from '../../../../ui/themer/themed';
import Responsive from '../../../../ui/utils/Responsive';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';

export interface IStyledDialogHeaderGradientProps
	extends IStyledHeaderProps,
		IDialogSizeProps,
		IResponsiveHiddenProps {}

export const StyledDialogHeaderGradientTitle = styled.h1<{}, ITheme>`
	color: ${themed(({ color }) => color.text.inverted)};
`;

export const StyledDialogHeaderGradient = styled(StyledHeader)<
	IStyledDialogHeaderGradientProps,
	ITheme
>`
	${Responsive.getHideStyles()}
	padding: 24px 0 40px;
	margin-bottom: 0;

	&:after {
		top: auto;
		height: 30px;
		background-size: 100% 100%;
	}

	${StyledDialogCloseButton} {
		margin-right: 24px;
	}

	${StyledDialogHeaderGradientTitle} {
		max-width: calc(${getDialogMaxWidth} + (${getDialogPadding} * 2));
		padding-left: ${getDialogPadding};
		padding-right: ${getDialogPadding};
		margin: 24px auto;

		&:last-child {
			margin-bottom: 0;
		}
	}

	${ContentWrap} {
		max-width: none;
		margin: 0;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		min-height: 170px;
		padding: 16px 0 40px;

		${StyledDialogCloseButton} {
			margin-right: 16px;
		}

		${StyledDialogHeaderGradientTitle} {
			max-width: calc(${getDialogMaxWidth} + (${getDialogPaddingMobile} * 2));
			padding-left: ${getDialogPaddingMobile};
			padding-right: ${getDialogPaddingMobile};
			margin-top: 8px;
		}
	}
`;

interface IDialogHeaderProps extends IStyledDialogHeaderGradientProps {
	backgroundTheme?: IHeaderTheme;
	className?: string;
	title: string;
}

export const DialogHeaderGradient = ({
	backgroundTheme,
	title,
	...rest
}: IDialogHeaderProps) => {
	const { close } = useDialogContext();

	return (
		<StyledDialogHeaderGradient
			backgroundTheme={backgroundTheme ?? 'green-blue'}
			{...rest}
		>
			{/* @ts-ignore */}
			<DialogCloseButton variation="inverted" onClick={close} />
			<StyledDialogHeaderGradientTitle>{title}</StyledDialogHeaderGradientTitle>
		</StyledDialogHeaderGradient>
	);
};

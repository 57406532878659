import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

export const ImageWrap = styled.span<{}, ITheme>`
	display: block;

	img {
		background: ${themed(({ background }) => background.light)};
	}
`;

import React from 'react';
import { HashDialog } from '../../../../shared/components/dialog/HashDialog';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { ForgotPasswordConfirmationDialogContent } from '../../../forgot-password/ui/confirmation/ForgotPasswordConfirmationDialogContent';

export const RESET_PASSWORD_CONFIRMATION_DIALOG_HASH =
	'#reset-password-confirmation';

// This is a variation of the Forgot Password Confirmation dialog, the only difference is the copy
export const ResetPasswordConfirmationDialog = () => {
	const { t } = useTranslation();

	const title = t('user_reset_password_title');

	return (
		<HashDialog
			ariaLabel={title}
			hash={RESET_PASSWORD_CONFIRMATION_DIALOG_HASH}
		>
			<ForgotPasswordConfirmationDialogContent title={title} />
		</HashDialog>
	);
};

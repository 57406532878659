import React, { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import { ArrowIcon } from '../Icon';
import { StyledIconButton } from './IconButton';
import { ButtonText } from './ButtonText';

export interface IArrowButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	inverted?: boolean;
	secondary?: boolean;
	children: ReactNode;
}

export const ArrowButton = forwardRef<HTMLButtonElement, IArrowButtonProps>(
	({ inverted, secondary, children, ...rest }: IArrowButtonProps, ref) => (
		<StyledIconButton
			inverted={inverted}
			secondary={secondary}
			{...rest}
			ref={ref}
		>
			<ButtonText>{children}</ButtonText>
			<ArrowIcon aria-hidden />
		</StyledIconButton>
	),
);

const log = require('loglevel');

// The first item in this list will be used as default,
// which has been explicitly added in the post-build step in package.json
const locales = [
	{
		code: /** @type {const} */ ('en-US'),
		prefix: 'en',
		displayValue: 'EN',
	},
	{
		code: /** @type {const} */ ('es-US'),
		prefix: 'es',
		displayValue: 'ES',
	},
];

// TODO: remove when full Phrase translations are available
if (!process.env.ENABLE_MULTI_LANGUAGE) {
	log.warn('(!) Multi-language support is disabled');
	locales.splice(1);
}

module.exports = locales;

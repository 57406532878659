import { ITranslationKey } from '../../../shared/i18n/translate/models/ITranslationKey';
import { Keyword } from './Keyword';

export const KeywordTranslationKeys: Record<Keyword, ITranslationKey> = {
	[Keyword.Shop]: 'shop_dine_tag_shop',
	[Keyword.Dine]: 'shop_dine_tag_dine',
	[Keyword.Relax]: 'shop_dine_tag_relax',
	[Keyword.FullService]: 'shop_dine_tag_full_service',
	[Keyword.Coffee]: 'shop_dine_tag_coffee',
	[Keyword.Bar]: 'shop_dine_tag_bar',
	[Keyword.Breakfast]: 'shop_dine_tag_breakfast',
	[Keyword.TravelEssentials]: 'shop_dine_tag_travel_essentials',
};

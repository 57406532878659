import React from 'react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';
import {
	ArrowBackIcon,
	contentWrapBreakpoint,
	StyledIconLink,
} from '../../../../ui';
import illustration from '../../../../../assets/something-went-wrong.svg?reference';
import { isProductionBuild } from '../../utils/isProductionBuild';
import Responsive from '../../../../ui/utils/Responsive';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';

export const StyledIllustratedErrorMessage = styled(ContentWrap)`
	margin-top: 88px;
	padding-top: 40px;
	text-align: center;

	h2 {
		margin: 0 auto;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		max-width: 500px;
		margin: 0 auto;
		padding: 40px 0;
	}

	a {
		display: inline-block;
		cursor: pointer;

		svg {
			margin-left: 0;
		}
	}

	@media (orientation: portrait) {
		img {
			max-height: 60vh;
		}
	}

	@media (${contentWrapBreakpoint}) {
		margin: 88px 0;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		img {
			padding: 60px 20px;
		}
	}
`;

export const IllustratedErrorMessage = () => (
	<StyledIllustratedErrorMessage>
		<StyledIconLink href="/">
			<ArrowBackIcon aria-hidden variation="highlight" />
			{` Go Back home`}
		</StyledIconLink>
		<img src={illustration} alt="Something went wrong..." />
		{isProductionBuild() && (
			<>
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
				<a onClick={Sentry.showReportDialog}>Report feedback</a>
			</>
		)}
	</StyledIllustratedErrorMessage>
);

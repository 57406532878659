import React from 'react';
import { css, Global } from '@emotion/core';
import { ITheme } from '../../themer';

interface IProps {
	theme: ITheme;
}

export const Separator = ({ theme }: IProps) => {
	const styles = css`
		hr {
			background: ${theme.separator.color};
			height: ${theme.separator.height};
			border: none;
		}
	`;

	return <Global styles={styles} />;
};

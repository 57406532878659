import React, { ReactNodeArray, useState } from 'react';
import styled from '@emotion/styled';
import { useSwipeable } from 'react-swipeable';
import { NavigationDots } from '../button/NavigationDots';
import Responsive from '../../../../ui/utils/Responsive';

interface IDesktopNewsProps {
	children: ReactNodeArray;
}

export const MobileNewsAlert = ({ children }: IDesktopNewsProps) => {
	const [active, setActive] = useState(0);

	const swipeable = useSwipeable({
		onSwipedLeft: () => {
			setActive(active + 1 >= children.length ? 0 : active + 1);
		},
		onSwipedRight: () => {
			setActive(active - 1 < 0 ? children.length - 1 : active - 1);
		},
	});

	return (
		<Wrapper {...swipeable}>
			{children[active]}
			<NavigationDots
				active={active}
				count={children.length}
				onActiveChange={setActive}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: none;
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: block;
	}
`;

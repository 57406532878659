import React from 'react';
import NextApp, { AppContext, AppInitialProps } from 'next/app';
import log, { LogLevelDesc } from 'loglevel';
import { IAppProps } from '../features/app/root/domain/models/IAppProps';
import { App as BaseApp } from '../features/app/root/ui/App';
import { getCommonData } from '../features/app/root/domain/use-cases/getCommonData';
import { ICommonData } from '../features/app/root/domain/models/ICommonData';
import { getLocaleFromPath } from '../features/shared/i18n/locale/getLocaleFromPath';
import { defaultLocale } from '../features/shared/i18n/locale/constants/default';

log.setLevel(process.env.LOG_LEVEL as LogLevelDesc);

const App = (props: IAppProps) => <BaseApp {...props} />;

export interface IAppInitialProps extends AppInitialProps {
	commonData: ICommonData;
}

App.getInitialProps = async (
	appContext: AppContext,
): Promise<IAppInitialProps> => {
	const nextAppProps = await NextApp.getInitialProps(appContext);
	const locale = getLocaleFromPath(appContext.router.asPath);

	log.debug('Detected locale', {
		path: appContext.router.asPath,
		locale,
	});

	const commonData = await getCommonData(locale ?? defaultLocale);

	return { ...nextAppProps, commonData };
};

export default App;

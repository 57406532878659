import { color } from '../../themer/theme/color';

export interface IContactTheme {
	card: {
		iconColor: string;
	};
}

export const theme: IContactTheme = {
	card: {
		iconColor: color.text.default,
	},
};

import React, { useEffect, useRef } from 'react';
import { VisuallyHidden } from './VisuallyHidden';

export const HiddenFocus = ({ children }: { children: string }) => {
	const ref = useRef<HTMLSpanElement>(null);
	useEffect(() => ref.current?.focus(), [ref.current]);
	return (
		<VisuallyHidden as="div">
			<span ref={ref} tabIndex={-1}>
				{children}
			</span>
		</VisuallyHidden>
	);
};

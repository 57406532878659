import React from 'react';
import { withTheme } from 'emotion-theming';
import { Typography } from './Typography';
import { Base } from './Base';
import { List } from './List';
import { Separator } from './Separator';
import { Blockquote } from './Blockquote';
import { Gutter } from './Gutter';

export const GlobalStyles = withTheme(({ theme, children }) => (
	<>
		<Base theme={theme} />
		<Blockquote theme={theme} />
		<Gutter theme={theme} />
		<List />
		<Separator theme={theme} />
		<Typography theme={theme} />
		{children}
	</>
));

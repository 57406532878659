import { action, computed, makeObservable, observable } from 'mobx';
import { RemoteDataStore } from '@move-frontend/utils';
import { IParkingLocation } from '../types/IParkingLocation';
import { ParkingAvailabilityCategory } from '../types/ParkingAvailabilityCategory';
import { Dap } from '../../../shared/api/dap';
import { IParkingAvailabilityResponse } from '../../../shared/api/dap/types/IParkingAvailabilityResponse';
import { IParkingInfoEntry } from '../../../shared/api/contentful/types';

export class LocationStore extends RemoteDataStore<
	{ parkingInfo?: IParkingInfoEntry[] },
	IParkingAvailabilityResponse
> {
	private _locations?: IParkingLocation[] = [];

	private _lastUpdated?: Date;

	private _parkingInfo?: IParkingInfoEntry[];

	private _filters = new Set<ParkingAvailabilityCategory>([
		ParkingAvailabilityCategory.ALL,
	]);

	constructor(private readonly dap: Dap) {
		super();

		makeObservable<LocationStore, '_locations' | '_filters' | 'handleResponse'>(
			this,
			{
				_locations: observable,
				_filters: observable,
				locations: computed,
				toggleFilter: action.bound,
				clear: action,
				handleResponse: action,
			},
		);
	}

	public get locations() {
		if (!this._locations) return [];
		if (!this.filters.size) return this._locations;
		return this._locations.filter(
			({ category }) =>
				this.filters.has(category) ||
				this.filters.has(ParkingAvailabilityCategory.ALL),
		);
	}

	public get filters() {
		return this._filters;
	}

	public toggleFilter(filter: ParkingAvailabilityCategory) {
		if (this._filters.has(filter)) {
			this._filters.delete(filter);
			if (this._filters.size === 0) {
				this._filters.add(ParkingAvailabilityCategory.ALL);
			}
		} else {
			this._filters.add(filter);
			Object.keys(ParkingAvailabilityCategory).forEach((c) => {
				const category =
					ParkingAvailabilityCategory[
						c as keyof typeof ParkingAvailabilityCategory
					];
				if (category !== filter) {
					this._filters.delete(category);
				}
			});
		}
	}

	public getLastUpdated() {
		if (!this._locations || !this._lastUpdated) return undefined;

		return this.locations.length ? this._lastUpdated : undefined;
	}

	public clear(): void {
		this._locations = undefined;
	}

	protected handleResponse(response: IParkingAvailabilityResponse): void {
		response.data.parkingAvailability.forEach((p, index) => {
			const parkingAvailabilityItem = response.data.parkingAvailability[index];
			const parkingInfo = this._parkingInfo?.find(
				(pI) => pI.fields.id === parkingAvailabilityItem.id,
			);

			if (parkingInfo) {
				const {
					fields: {
						coordinates: { lat, lon },
					},
				} = parkingInfo;
				parkingAvailabilityItem.order = parkingInfo.fields.order;
				parkingAvailabilityItem.directionsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;
			}

			if (
				p.category !== ParkingAvailabilityCategory.DECK &&
				p.category !== ParkingAvailabilityCategory.VALET
			) {
				parkingAvailabilityItem.category = ParkingAvailabilityCategory.NONE;
			}
		});
		this._locations = response.data.parkingAvailability.sort(
			(a, b) => a.order - b.order,
		);
		this._lastUpdated = new Date();
	}

	protected performRequest({
		parkingInfo,
	}: {
		parkingInfo?: IParkingInfoEntry[];
	}): Promise<IParkingAvailabilityResponse> {
		this._parkingInfo = parkingInfo;
		return this.dap.parkingAvailability().get();
	}
}

import {
	FirebaseRemoteConfigBoolean,
	FirebaseRemoteConfigNumber,
} from '@move-frontend/firebase';

export const remoteConfigSchema = Object.freeze({
	security_filter_priority_enabled: FirebaseRemoteConfigBoolean,
	security_wait_times_enabled: FirebaseRemoteConfigBoolean,
	bus_wait_times_enabled: FirebaseRemoteConfigBoolean,
	parking_booking_entry_time_minimum_offset: FirebaseRemoteConfigNumber,
	chatbot_enabled: FirebaseRemoteConfigBoolean,
	mobile_ordering_enabled: FirebaseRemoteConfigBoolean,
	chatbot_disabled_spanish: FirebaseRemoteConfigBoolean,
	parking_availability_enabled: FirebaseRemoteConfigBoolean,
});

export type IRemoteConfigSchema = typeof remoteConfigSchema;

import { typography } from '../../themer/theme/typography';
import { color } from '../../themer/theme/color';

export const theme = {
	fontFamily: typography.body.fontFamily,
	background: color.background.default,
	color: '',
	boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1)',
	padding: '16px',
	margin: '0',
	border: 'none',
	borderRadius: '0',
	fontSize: typography.input.size,
	lineHeight: '24px',
	mobile: {
		padding: '12px',
		lineHeight: '24px',
		// Font-size should not be smaller than 16px to prevent zooming in on iOS
		// See: https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
		fontSize: '16px',
	},
	disabled: {
		background: '',
		color: color.background.light,
		boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.05)',
	},
	focus: {
		background: '',
		color: '',
		outline: 'none',
		boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.15)',
		borderRadius: '0',
	},
	error: {
		boxShadow: 'inset 0 -3px 0 0 #CC1100',
		borderRadius: '0',
	},
	warning: {
		boxShadow: 'inset 0 -3px 0 0 #ff791f',
		borderRadius: '0',
	},
	placeholder: {
		color: 'rgba(0, 0, 0, 0.44)',
		disabled: {
			color: 'rgba(0, 0, 0, 0.08)',
		},
		focussed: {
			color: color.brand,
		},
	},
	configuration: {
		error: {
			overrideDefaultShadow: false,
		},
	},
	icon: {
		background: color.text.default,
	},
};

export type IInputTheme = typeof theme;

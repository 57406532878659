import React from 'react';
import { ArrowLinkButton } from '../Link';

export interface ICallToActionLinkGenericProps {
	title: string;
	url: string;
	inverted?: boolean;
}

export const CallToActionLinkGeneric = ({
	title,
	url,
	inverted,
}: ICallToActionLinkGenericProps) => (
	<ArrowLinkButton href={url} inverted={inverted}>
		{title}
	</ArrowLinkButton>
);

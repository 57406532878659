/* eslint-disable no-restricted-imports */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { trackPageView } from './trackPageView';
import { trackRetargeting } from '../e-commerce/trackRetargeting';

export function useTrackPageViews() {
	const router = useRouter();

	useEffect(() => {
		const handleRouteChange = (path: string) => {
			trackPageView(path);
			trackRetargeting();
		};

		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);
}

import { Path, PathLike } from '@move-frontend/contentful-routes';
import { locales } from './constants/locales';

export function getLocaleFromPath(input: PathLike) {
	const path = Path.from(input);

	return locales.find(
		(locale) => !!locale.prefix && path.startsWith(locale.prefix),
	);
}

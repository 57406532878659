import React from 'react';
import styled from '@emotion/styled';
import { AsyncDataPlaceholder, Link } from '../../../../ui';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { SubmitButton } from '../../../shared/components';
import { FORGOT_PASSWORD_DIALOG_HASH } from '../../forgot-password/ui/ForgotPasswordDialog';
import { AuthFormActions } from '../../shared/form-layout/AuthFormActions';
import { useSignInFormContext } from './helpers/useSignInFormContext';
import { useSignInHasRequiredFields } from './helpers/useSignInHasRequiredFields';
import Responsive from '../../../../ui/utils/Responsive';

export const SignInFormActions = () => {
	const { t } = useTranslation();
	const { formState } = useSignInFormContext();

	const hasRequiredFields = useSignInHasRequiredFields();
	const disabled = !hasRequiredFields || formState.isSubmitting;

	return (
		<Actions>
			{formState.isSubmitting && <AsyncDataPlaceholder />}
			<SubmitButton disabled={disabled}>
				{t('user_sign_in_submit_button')}
			</SubmitButton>
			<Link href={FORGOT_PASSWORD_DIALOG_HASH}>
				{t('user_sign_in_forgot_button')}
			</Link>
		</Actions>
	);
};

const Actions = styled(AuthFormActions)`
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		flex-direction: column-reverse;
		align-items: flex-end;

		${Link} {
			margin-bottom: 24px;
		}
	}
`;

export enum Color {
	// Primary Colors
	PrimaryDefault = '#0077C8',
	PrimaryDark = '#005B99',
	PrimaryLight = '#298ED3',

	// Secondary & Tertiary Colors
	SecondaryDefault = '#00778B',
	TertiaryDefault = '#01426A',

	// Accent Colors
	AccentDefault = '#981D97',

	// Neutral Colors
	Neutral30 = '#4D4D4D',
	Neutral50 = '#808080',
	Neutral70 = '#B3B3B3',
	Neutral90 = '#E6E6E6',

	// Text Colors
	TextBlack = '#1A1A1A',
	TextWhite = '#FFFFFF',
	TextGrey = '#4E4E4E',

	// Background Colors
	Background = '#000000',
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	BackgroundWhite = '#FFFFFF',
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	Background1 = '#1A1A1A',
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	Background2 = '#4E4E4E',
	DialogBackground = '#00000054',

	// Surface Colors
	Surface = '#F7F7F7',

	// State Colors
	ErrorDark = '#CC1100',
	AlertLight = '#FBF0D0',
}

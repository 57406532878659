import React, { KeyboardEvent, ReactNode } from 'react';
import Downshift, {
	DownshiftProps,
	DownshiftState,
	StateChangeOptions,
} from 'downshift';
import { AutoSuggestRoot } from './AutoSuggestRoot';
import {
	getItemDisplayValueDefault,
	getItemKeyDefault,
	getItemToStringDefault,
	IDropdownItem,
} from '../DropdownList';

export interface IAutoSuggestInputProps<
	TItemType extends IDropdownItem = IDropdownItem,
> extends DownshiftProps<TItemType> {
	items: TItemType[];
	placeholder?: string;
	ariaLabel?: string;
	ariaDescribedBy?: string;
	name?: string;
	itemToKey?: (item: TItemType, index?: number) => string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	itemToString?: (item: any | null) => string;
	itemToDisplayValue?: (item: TItemType | null) => string | JSX.Element;
	onSelect?: (item: TItemType | null) => void;
	onInputValueChange?: (inputValue: string) => void;
	stateReducer?: (
		state: DownshiftState<TItemType>,
		changes: StateChangeOptions<TItemType>,
	) => StateChangeOptions<TItemType>;
	onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
	initialInputValue?: string;
	disabled?: boolean;
	invalid?: boolean;
	required?: boolean;
	placeholderAsLabel?: boolean;
	onClearSearch?: () => void;
	icon?: ReactNode;
	withFocusMenu?: boolean;
	autoSuggestId?: string;
}

export const AutoSuggestInput = <
	TItemType extends IDropdownItem = IDropdownItem,
>({
	name,
	placeholder,
	ariaLabel,
	ariaDescribedBy,
	items,
	itemToKey,
	itemToString,
	itemToDisplayValue,
	onKeyUp,
	disabled,
	invalid,
	required,
	placeholderAsLabel,
	onClearSearch,
	icon,
	withFocusMenu,
	autoSuggestId,
	...rest
}: IAutoSuggestInputProps<TItemType>) => {
	const getItemKey = itemToKey || getItemKeyDefault;
	const getItemDisplayValue = itemToDisplayValue || getItemDisplayValueDefault;
	const getItemToString = itemToString || getItemToStringDefault;

	return (
		<Downshift itemToString={getItemToString} {...rest}>
			{({ getRootProps, ...props }) => (
				<AutoSuggestRoot
					{...getRootProps({ refKey: 'innerRef' })}
					name={name}
					getItemKey={getItemKey}
					getItemDisplayValue={getItemDisplayValue}
					placeholder={placeholder}
					ariaLabel={ariaLabel ?? placeholder}
					ariaDescribedBy={ariaDescribedBy}
					items={items}
					onKeyUp={onKeyUp}
					onFocus={withFocusMenu ? props.openMenu : null}
					disabled={disabled}
					invalid={invalid}
					required={required}
					placeholderAsLabel={placeholderAsLabel}
					onClearSearch={onClearSearch}
					icon={icon}
					autoSuggestId={autoSuggestId}
					{...props}
				/>
			)}
		</Downshift>
	);
};

import styled from '@emotion/styled';
import Responsive from '../../utils/Responsive';
import { InputLabel, StyledInput } from '../Input';
import { StyledIconButton } from '../Button';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

export interface IStyledSearchInputButtonWrapProps {
	disabled?: boolean;
}

export const StyledSearchInputButtonWrap = styled.div<
	IStyledSearchInputButtonWrapProps,
	ITheme
>`
	display: flex;
	box-shadow: ${themed(({ search }, { disabled }) =>
		disabled ? search.wrap.disabled.boxShadow : search.wrap.boxShadow,
	)};
	border-radius: ${themed(({ search }) => search.wrap.borderRadius)};

	> * {
		flex-grow: 1;
	}

	${StyledInput} {
		box-shadow: none;
		min-height: 100%;

		&:focus-visible {
			box-shadow: none;
		}
	}

	${StyledIconButton} {
		flex-grow: 0;
		line-height: 0;
		width: ${themed(({ search }) => search.button.width)};
		height: ${themed(({ search }) => search.button.height)};
		box-shadow: none;
		background: ${themed(({ search }, { disabled }) =>
			disabled ? search.button.disabled.background : search.button.background,
		)};
		border: ${themed(({ search }, { disabled }) =>
			disabled ? search.button.disabled.border : search.button.border,
		)};
		border-radius: ${themed(({ search }) => search.button.borderRadius)};
		text-align: center;
		cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

		svg {
			width: ${themed(({ search }) => search.button.iconWidth)};
			height: ${themed(({ search }) => search.button.iconHeight)};
			margin-left: 0;
		}

		&:hover {
			background: ${themed(({ search }, { disabled }) =>
				disabled
					? search.button.disabled.background
					: search.button.hover.background,
			)};
			border: ${themed(({ search }, { disabled }) =>
				disabled ? search.button.disabled.border : search.button.hover.border,
			)};
		}

		&:active {
			transform: ${themed(({ search }) => search.button.active.transform)};
			box-shadow: none;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		${StyledInput}, ${InputLabel} {
			padding: 12px;
		}
		.moveUp {
			${InputLabel} {
				padding-top: 6px;
			}
			${StyledInput} {
				padding-top: 20px;
				padding-bottom: 3px;
			}
		}

		${StyledIconButton} {
			width: ${themed(({ search }) => search.button.mobile.width)};
			height: ${themed(({ search }) => search.button.mobile.height)};

			svg {
				width: ${themed(({ search }) => search.button.mobile.iconWidth)};
				height: ${themed(({ search }) => search.button.mobile.iconHeight)};
			}
		}
	}
`;

import { CognitoErrorCode, withErrorLogger } from '@move-frontend/auth';
import { cognitoClient } from '../../../../shared/api/cognito';
import { logError } from '../../../../shared/error/logError';
import { ISignInData } from '../models/ISignInData';
import { ISignInResult } from '../models/ISignInResult';

async function performSignIn(data: ISignInData): Promise<ISignInResult> {
	const result = await cognitoClient.signIn(data);

	if (!result) {
		throw new Error('Invalid sign in response');
	}

	return result;
}

export const signIn = withErrorLogger(performSignIn, {
	ignoreErrorCodes: [CognitoErrorCode.NotAuthorizedException],
	customLogError: logError,
});

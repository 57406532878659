import React from 'react';
import { css, Global } from '@emotion/core';
import { ITheme } from '../../themer';
import Responsive from '../../utils/Responsive';

interface IProps {
	theme: ITheme;
}

export const Blockquote = ({ theme }: IProps) => {
	const styles = css`
		blockquote {
			background: ${theme.blockquote.background};
			box-shadow: ${theme.blockquote.boxShadow};
			border: ${theme.blockquote.border};
			padding: ${theme.blockquote.padding};
			margin: ${theme.blockquote.margin};

			p {
				color: ${theme.blockquote.paragraph.color};
				font-size: ${theme.blockquote.paragraph.size};
				font-style: ${theme.blockquote.paragraph.style};
			}

			p:last-child {
				margin-bottom: 0;
			}

			@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
				padding: ${theme.blockquote.mobile.padding};

				p {
					font-size: ${theme.blockquote.mobile.paragraph.size};
				}
			}
		}
	`;

	return <Global styles={styles} />;
};

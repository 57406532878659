import {
	AirlineStore,
	AirportStore,
	FlightEnrichStore,
} from '@move-frontend/dap';
import { IEnrichedFlightProps } from '../models/IEnrichedFlight';
import { IFlight } from '../models/IFlight';

import { createFlightEnrichers } from './createFlightEnrichers';

export function createFlightEnrichStore(
	airlineStore: AirlineStore,
	airportStore: AirportStore,
) {
	const enrichers = createFlightEnrichers(airlineStore, airportStore);

	return new FlightEnrichStore<IFlight, IEnrichedFlightProps>(
		enrichers.airlineAndAirportEnricher,
		enrichers.publicStatusEnricher,
	);
}

import { Dap } from '../../../shared/api/dap';
import { LocationStore } from './LocationStore';

export function createParkingAvailabilityStores(dap: Dap) {
	const locationStore = new LocationStore(dap);

	return {
		locationStore,
	};
}

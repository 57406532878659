export enum PassengersPageId {
	Home = 'passengers-home',
	Accessibility = 'accessibility',
	Flights = 'passengers-flights',
	FlightDetail = 'passengers-flight-detail',
	Parking = 'passengers-parking',
	ParkingOptions = 'parking-options',
	ParkingAvailability = 'live-parking-availability-page',
	ShopDineAndRelax = 'passengers-shop-dine-and-relax',
	ShopDineAndRelaxDetail = 'passengers-shop-dine-and-relax-detail',
	AirportInfo = 'passengers-airport-info',
	ConnectingFlights = 'prepare-for-travel',
	SecurityWaitingTimes = 'security',
	TermsAndConditions = 'terms-and-conditions',
	PrivacyPolicy = 'privacy-notice',
	ResetPassword = 'reset-password',
	BusWaitTimes = 'bus-wait-times',
	EmployeeLots = 'employee-lots',
	OrderingFood = 'ordering-food',
	RoyaltyProgram = 'royalty-information',
	RewardOffers = 'reward-offers',
	AccountOverview = 'account',
	AccountDetails = 'account-details',
	ChangePassword = 'change-password',
}

export enum NewsPageId {
	Home = 'news-home',
	NewsletterSignup = 'newsletter-sign-up',
	ConstructionProjects = 'construction-projects',
	NewsStories = 'news-stories',
	PressReleases = 'press-releases',
	Blogs = 'blogs',
	PressReleaseDetail = 'press-release-detail',
	NewsStoryDetailPage = 'news-story-detail-page',
	PressReleaseDetailPage = 'press-release-detail-page',
	BlogDetailPage = 'blog-detail-page',
	StayConnected = 'stay-connected',
}

export enum BusinessPageId {
	Home = 'business-home',
}

export enum CommunityPageId {
	Home = 'community-home',
}

export enum CareersPageId {
	Home = 'careers',
}

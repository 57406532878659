import log from 'loglevel';
import { CognitoErrorCode, withErrorLogger } from '@move-frontend/auth';
import { ISignUpData } from '../models/ISignUpData';
import { cognitoClient } from '../../../../shared/api/cognito';
import { logError } from '../../../../shared/error/logError';
import { setUserLoginStatusProperty } from '../../../../shared/analytics/user/useSetUserProperties';

async function performSignUp(data: ISignUpData) {
	const user = await cognitoClient.signUp(data);

	if (!user) {
		throw new Error('Invalid sign up response');
	}

	log.debug('User signed up:', user);

	await cognitoClient.signIn({ email: data.email, password: data.password });
	setUserLoginStatusProperty(true);

	return user;
}

export const signUp = withErrorLogger(performSignUp, {
	ignoreErrorCodes: [CognitoErrorCode.UsernameExistsException],
	customLogError: logError,
});

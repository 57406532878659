import { Path } from '@move-frontend/contentful-routes';
import {
	defaultLocale,
	isDefaultLocale,
} from '../../../../shared/i18n/locale/constants/default';
import { ILocale } from '../../../../shared/i18n/locale/models/ILocale';

export function getLocalizedPath(
	path: string,
	locale: ILocale = defaultLocale,
) {
	// We will ignore the prefix for the default locale (ie. instead of `/en/some-path/` we use `/some-path/`)
	const prefix = isDefaultLocale(locale) ? '' : locale.prefix;

	return Path.from([prefix, path]).toString();
}

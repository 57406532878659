import React, { ComponentType } from 'react';
import { HashRoute, IHashRouteProps } from './HashRoute';

export function withHashRoute<TProps extends {} = {}>(
	Component: ComponentType<TProps>,
) {
	return ({ hash, ...rest }: IHashRouteProps & TProps) => (
		<HashRoute hash={hash}>
			<Component {...(rest as TProps)} />
		</HashRoute>
	);
}

import React from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { IArrowButtonProps } from '../Button/ArrowButton';
import { IIconButtonProps, StyledIconButton } from '../Button/IconButton';
import { ITheme } from '../../themer';

import { Link } from './Link';
import { ArrowIcon } from '../Icon';
import { ButtonText } from '../Button/ButtonText';
import themed from '../../themer/themed';

export interface IArrowLinkButtonProps extends IArrowButtonProps {
	href: string;
	rel?: string;
	target?: string;
	secondary?: boolean;
	hideExternalIndicator?: boolean;
}

interface IStyledIconLinkButton
	extends StyledComponent<IArrowLinkButtonProps, IIconButtonProps, ITheme> {}

export const StyledIconLinkButton = StyledIconButton.withComponent(
	Link,
) as IStyledIconLinkButton;

export const StyledArrowLinkButton = styled(StyledIconLinkButton)<{}, ITheme>`
	:hover {
		text-decoration: none;
	}
	&:focus-visible {
		border-radius: 2px;
		box-shadow: 0 0 0 6px ${themed(({ typography }) => typography.body.color)};
		text-decoration: underline;
	}
`;

export const ArrowLinkButton = ({
	children,
	...props
}: IArrowLinkButtonProps) => (
	<StyledArrowLinkButton {...props}>
		<ButtonText>{children}</ButtonText>
		<ArrowIcon aria-hidden />
	</StyledArrowLinkButton>
);

import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import { getTextColor } from './helpers';
import { StyledHeaderParentTitle } from './HeaderParentTitle';

interface IStyledHeaderTitleProps {
	inverted?: boolean;
}

export const StyledHeaderTitle = styled.h1<IStyledHeaderTitleProps, ITheme>`
	margin-top: 48px;
	color: ${getTextColor};

	${StyledHeaderParentTitle} + & {
		margin-top: 0;
	}
`;

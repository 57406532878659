import React from 'react';
import { FormProvider } from 'react-hook-form';
import { IOnFailure } from '@move-frontend/auth';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { useFormValidationErrorTracker } from '../../../../shared/analytics/form/useFormValidationErrorTracker';
import { trackSignUpValidationErrors } from '../helpers/trackSignUpValidationError';
import { ConfirmLicensePlateNumber } from '../../../shared/form-fields/ConfirmLicensePlateNumber';
import { ConfirmPassword } from '../../../shared/form-fields/ConfirmPassword';
import { Email } from '../../../shared/form-fields/Email';
import { FirstName } from '../../../shared/form-fields/FirstName';
import { LastName } from '../../../shared/form-fields/LastName';
import { LicensePlateNumber } from '../../../shared/form-fields/LicensePlateNumber';
import { Password } from '../../../shared/form-fields/Password';
import { TermsAndConditions } from '../../../shared/form-fields/TermsAndConditions';
import { useSignUpForm } from './helpers/useSignUpForm';
import { ISignUpOnSuccess } from '../../domain/models/ISignUpOnSuccess';
import { SignUpFormActions } from './SignUpFormActions';
import { AuthFormSection as Section } from '../../../shared/form-layout/AuthFormSection';
import { AuthFormRow as Row } from '../../../shared/form-layout/AuthFormRow';
import { AuthFormSectionHeader as Header } from '../../../shared/form-layout/AuthFormSectionHeader';
import { AuthFormCheckboxesSection as CheckboxesSection } from '../../../shared/form-layout/AuthFormCheckboxesSection';
import { useSignUpSubmit } from '../helpers/useSignUpSubmit';

export interface ISignUpFormProps {
	onSuccess: ISignUpOnSuccess;
	onFailure: IOnFailure;
}

export const SignUpForm = ({ onSuccess, onFailure }: ISignUpFormProps) => {
	const { t } = useTranslation();
	const form = useSignUpForm();
	const onSubmit = useSignUpSubmit(onSuccess, onFailure);

	useFormValidationErrorTracker(form, trackSignUpValidationErrors);

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)}>
				<Section>
					<Header
						title={t('user_sign_up_customer_details_title')}
						hint={t('user_sign_up_custom_details_hint')}
					/>

					<Row>
						<FirstName />
					</Row>
					<Row>
						<LastName />
					</Row>
					<Row>
						<Email />
					</Row>
					<Row>
						<Password validateConfirmation autoComplete="new-password" />
					</Row>
					<Row>
						<ConfirmPassword />
					</Row>
				</Section>

				<Section>
					<Header
						title={t('user_sign_up_vehicle_details_title')}
						hint={t('user_sign_up_vehicle_details_hint')}
					/>

					<Row>
						<LicensePlateNumber validateConfirmation />
					</Row>
					<Row>
						<ConfirmLicensePlateNumber />
					</Row>
				</Section>

				<CheckboxesSection>
					<Row>
						<TermsAndConditions />
					</Row>
				</CheckboxesSection>

				<SignUpFormActions />
			</form>
		</FormProvider>
	);
};

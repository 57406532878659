import { AbstractService } from '@move-frontend/dap';
import { AxiosRequestConfig } from 'axios';
import { ICurrentCheckpointQueueApiResponse } from './types/ICurrentCheckpointQueueApiResponse';

export class CheckpointQueuesService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	public static readonly URI_PATH = 'wait-times/checkpoint/CLT';

	/**
	 * API version necessary for the service.
	 */
	public static readonly VERSION = 130;

	getCurrent(requestConfig: AxiosRequestConfig = {}) {
		const uri = CheckpointQueuesService.URI_PATH;

		const defaultConfig: AxiosRequestConfig = {
			method: 'GET',
			headers: {
				'Api-Version': CheckpointQueuesService.VERSION,
				'Api-Key': process.env.DAP_API_KEY,
			},
		};

		return this.client.request<ICurrentCheckpointQueueApiResponse>(uri, {
			...defaultConfig,
			...requestConfig,
		});
	}
}

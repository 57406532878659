import styled from '@emotion/styled';
import themed from '../../themer/themed';
import { Css } from '../../utils';
import { ImageWrap } from '../Image';
import { StyledAlert } from '../Alert';
import Responsive from '../../utils/Responsive';

export const contentWrapBreakpoint = themed(
	({ layout }) =>
		`max-width: ${Css.calc(
			layout.main.maxWidth,
			(v) => v + parseInt(layout.main.margin.tablet, 10) * 2,
		)}`,
);

const ContentWrap = styled.div`
	max-width: ${themed(({ layout }) => layout.main.maxWidth)};
	margin: 0 auto;

	h1,
	h2,
	h3,
	h4,
	p,
	blockquote {
		max-width: ${themed(({ layout }) => layout.main.textMaxWidth)};
	}

	p,
	blockquote {
		&:last-child {
			margin-bottom: 0;
		}

		+ h1,
		+ h2,
		+ h3,
		+ h4 {
			padding-top: 16px;
		}
	}

	${ImageWrap} {
		text-align: ${themed(({ layout }) => layout.image.align)};
		padding: ${themed(({ layout }) => layout.image.margin)};

		> &:last-child {
			padding-bottom: 0;
		}

		img {
			display: block;
			width: ${themed(({ layout }) => layout.image.width)};
			max-width: ${themed(({ layout }) => layout.image.maxWidth)};
			max-height: ${themed(({ layout }) => layout.image.maxHeight)};
		}
	}

	${StyledAlert} {
		max-width: ${themed(({ layout }) => layout.main.textMaxWidth)};
	}

	@media (${contentWrapBreakpoint}) {
		max-width: none;
		margin: 0 ${themed(({ layout }) => layout.main.margin.tablet)};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin: 0 ${themed(({ layout }) => layout.main.margin.mobile)};

		${ImageWrap} {
			padding: ${themed(({ layout }) => layout.image.mobile.margin)};

			img {
				width: ${themed(({ layout }) => layout.image.mobile.width)};
				max-width: ${themed(({ layout }) => layout.image.mobile.maxWidth)};
			}
		}
	}
`;

export default ContentWrap;

import styled from '@emotion/styled';
import { TagButton } from './TagButton';
import Responsive from '../../utils/Responsive';

export const TagButtonWrap = styled.div`
	margin-bottom: 16px;
	font-size: 16px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		display: flex;
	}

	${TagButton} {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
`;

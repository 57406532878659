import React from 'react';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { HashDialog } from '../../../shared/components/dialog/HashDialog';
import { SignInDialogContent } from './SignInDialogContent';
import { useHideChatBot } from '../../../chatbot/helpers/useHideChatBot';

export const SIGN_IN_DIALOG_HASH = '#sign-in';

export const SignInDialog = () => {
	const { t } = useTranslation();
	useHideChatBot('mobile');

	return (
		<HashDialog ariaLabel={t('user_sign_in_title')} hash={SIGN_IN_DIALOG_HASH}>
			<SignInDialogContent />
		</HashDialog>
	);
};

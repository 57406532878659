import { useEffect } from 'react';
import { useAuthStore } from '../../../auth/shared/store/useAuthStore';
import {
	USER_PROPERTY_LOGIN_STATUS_NO,
	USER_PROPERTY_LOGIN_STATUS_YES,
} from './constants';
import { trackWithGoogleAnalytics } from '../shared/trackers/trackWithGoogleAnalytics';

export const setUserLoginStatusProperty = (isAuthenticated: boolean) => {
	trackWithGoogleAnalytics('set', 'user_properties', {
		login_status: isAuthenticated
			? USER_PROPERTY_LOGIN_STATUS_YES
			: USER_PROPERTY_LOGIN_STATUS_NO,
	});
};
export const useSetUserProperties = () => {
	const { isAuthenticated } = useAuthStore();

	useEffect(() => {
		setUserLoginStatusProperty(isAuthenticated);
	}, [isAuthenticated]);
};

import { IPrice } from '../IPrice';
import { Price } from '../Price';
import { assertSameCurrency } from './assertSameCurrency';

export function getSum(...prices: [IPrice, ...IPrice[]]): IPrice {
	return prices.reduce((previous, current) => {
		assertSameCurrency(previous, current);

		return new Price({
			currencyCode: current.currencyCode,
			centAmount: previous.centAmount + current.centAmount,
		});
	});
}

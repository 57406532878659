import styled from '@emotion/styled';
import { ITheme } from '../../../../ui';
import Responsive from '../../../../ui/utils/Responsive';

export const AuthFormSection = styled.div<{}, ITheme>`
	margin-bottom: 40px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 32px;
	}
`;

import React from 'react';
import { css, Global } from '@emotion/core';
import { ITheme } from '../../themer';

interface IProps {
	theme: ITheme;
}

export const Base = ({ theme }: IProps) => {
	const styles = css`
		html {
			margin: 0;
			padding: 0;
			box-sizing: border-box;
		}

		*,
		*:before,
		*:after {
			box-sizing: inherit;
		}

		body {
			margin: 0;
			padding: ${theme.layout.page.padding};

			/* enable smooth scrolling on iOS inside dialogs or other overflown elements */
			-webkit-overflow-scrolling: touch;
		}
	`;

	return <Global styles={styles} />;
};

import React from 'react';
import { FormProvider } from 'react-hook-form';
import { IOnFailure } from '@move-frontend/auth';
import { ForgotPasswordFormActions } from './ForgotPasswordFormActions';
import { AuthFormSection as Section } from '../../../shared/form-layout/AuthFormSection';
import { AuthFormRow as Row } from '../../../shared/form-layout/AuthFormRow';
import { Email } from '../../../shared/form-fields/Email';
import { useForgotPasswordForm } from './helpers/useForgotPasswordForm';
import { ISendRecoveryLinkOnSuccess } from '../../domain/models/ISendRecoveryLinkOnSuccess';
import { useForgotPasswordSubmit } from '../helpers/useForgotPasswordSubmit';

export interface IForgotPasswordFormProps {
	onSuccess: ISendRecoveryLinkOnSuccess;
	onFailure: IOnFailure;
	hideBackLink?: boolean;
	customButtonText?: string;
}

export const ForgotPasswordForm = ({
	onSuccess,
	onFailure,
	hideBackLink,
	customButtonText,
}: IForgotPasswordFormProps) => {
	const form = useForgotPasswordForm();
	const onSubmit = useForgotPasswordSubmit(onSuccess, onFailure);

	return (
		<FormProvider {...form}>
			<form noValidate onSubmit={form.handleSubmit(onSubmit)}>
				<Section>
					<Row>
						{/* @ts-ignore */}
						<Email autoFocus />
					</Row>
				</Section>

				<ForgotPasswordFormActions
					hideBackLink={hideBackLink}
					customButtonText={customButtonText}
				/>
			</form>
		</FormProvider>
	);
};

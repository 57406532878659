import React, { ImgHTMLAttributes } from 'react';
import { Image } from '../../../ui';
import {
	getResponsiveImageUrl,
	IImageOptions,
	progressiveJpegOptions,
} from './getResponsiveImageUrl';

const CONTENTFUL_IMAGE_CDN_REGEX = /\/\/images\.ctfassets/;

export const ResponsiveImage = ({
	src,
	...rest
}: ImgHTMLAttributes<HTMLImageElement>) => {
	if (!src || !canBeResized(src)) {
		return <Image src={src} {...rest} />;
	}

	const common: IImageOptions = {
		fit: 'scale',
		...progressiveJpegOptions,
	};

	const defaultImageSource = getResponsiveImageUrl(src, { ...common, w: 1200 });
	const sourceSet = `
		${getResponsiveImageUrl(src, { ...common, w: 400 })} 400w,
		${getResponsiveImageUrl(src, { ...common, w: 600 })} 600w,
		${getResponsiveImageUrl(src, { ...common, w: 800 })} 800w,
		${getResponsiveImageUrl(src, { ...common, w: 1200 })} 1200w,
		${getResponsiveImageUrl(src, { ...common, w: 2400 })} 2400w
	`;

	return <Image src={defaultImageSource} srcSet={sourceSet} {...rest} />;
};

function canBeResized(url: string) {
	// Images should be hosted on the Contentful Image CDN to be able to use their resize API
	if (!CONTENTFUL_IMAGE_CDN_REGEX.test(url)) {
		return false;
	}

	// The image url should not include a query string
	if (url.includes('?')) {
		return false;
	}

	return true;
}

import { RemoteDataStore } from '@move-frontend/utils';
import { action, makeObservable, observable } from 'mobx';
import { ParkingProductsQuoteService } from '../../../../shared/api/gateway/services/ParkingProductsQuoteService';
import { IGetParkingProductUpgradesQueryParams } from '../../../../shared/api/gateway/types/IGetParkingProductUpgradesQueryParams';
import { IGetParkingProductUpgradesResponse } from '../../../../shared/api/gateway/types/IGetParkingProductUpgradesResponse';
import { IParkingProductUpgrade } from '../models/IParkingProductUpgrade';
import { adaptResponseToUpgrade } from './adapters/adaptResponseToUpgrade';

export class UpgradesStore extends RemoteDataStore<
	IGetParkingProductUpgradesQueryParams,
	IGetParkingProductUpgradesResponse
> {
	upgrades = observable<IParkingProductUpgrade>([]);

	private _currentParkingProductId: number | undefined = undefined;

	constructor(private readonly service: ParkingProductsQuoteService) {
		super();

		makeObservable<UpgradesStore, 'handleResponse'>(this, {
			handleResponse: action,
			clear: action,
		});
	}

	load(params: IGetParkingProductUpgradesQueryParams) {
		const { parkingProductId } = params;

		if (parkingProductId === this._currentParkingProductId) {
			return this.currentRequest ?? Promise.resolve();
		}

		const promise = super.load(params);

		// calling `super.load` will clear the previous state, so we need to set this after that
		this._currentParkingProductId = parkingProductId;

		return promise;
	}

	performRequest(params: IGetParkingProductUpgradesQueryParams) {
		return this.service.getUpgrades(params);
	}

	protected handleResponse(response: IGetParkingProductUpgradesResponse) {
		const upgrades = response.map(adaptResponseToUpgrade);

		this.upgrades.replace(upgrades);
	}

	clear() {
		this.upgrades.clear();
		this._currentParkingProductId = undefined;
	}
}

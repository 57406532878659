// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import { isPreviewMode } from '../utils/isPreviewMode';

/**
 * When the current build is set to preview mode, but it hasn't been enabled yet through the API
 * @see https://nextjs.org/docs/advanced-features/preview-mode
 */
export function useIsPreviewModeDisabled(): boolean {
	const router = useRouter();

	if (!isPreviewMode()) return false;

	return !router.isPreview;
}

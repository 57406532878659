import React from 'react';
import styled from '@emotion/styled';
import { Flex, ITheme } from '../../../../ui';
import { getGradientBackground } from '../../../shared/styles';
import CrownIcon from '../../../../../assets/loyalty/crown.svg?reference';
import themed from '../../../../ui/themer/themed';

export enum BadgeTagVariant {
	Default = 'default',
	Inverted = 'inverted',
	Brand = 'brand',
}
interface IBadgeTagProps {
	badge?: string;
	text: string;
	width?: string;
	variant?: BadgeTagVariant;
	children?: React.ReactNode;
}
export const BadgeTag = ({
	badge = CrownIcon,
	text,
	width,
	variant = BadgeTagVariant.Brand,
	children,
}: IBadgeTagProps) => (
	<StyledBadgeTag variant={variant} width={width}>
		<Flex alignItems="center" justifyContent="flex-start">
			<StyledTierBadge src={badge} alt="" role="presentation" />
			<StyledText>{text}</StyledText>
		</Flex>
		{children}
	</StyledBadgeTag>
);

export const StyledTierBadge = styled.img`
	width: 16px;
	height: 16px;
`;
export const StyledBadgeTag = styled.div<
	{ width?: string; variant: BadgeTagVariant },
	ITheme
>`
	display: flex;
	gap: 6px;
	align-items: center;
	justify-content: space-between;

	width: fit-content;
	height: 28px;
	padding: 6px;

	border-radius: 8px;

	overflow: hidden;
	display: flex;
	text-wrap: nowrap;

	color: ${themed(({ color }) => color.text.inverted)};
	font-size: 13px;
	font-weight: 700;

	${({ variant }) =>
		(variant === BadgeTagVariant.Brand &&
			getGradientBackground('purple-blue')) ||
		`background: rgba(0, 0, 0, 0.2);`};

	${Flex} {
		width: 100%;
		height: 100%;
		gap: 6px;
	}
`;

const StyledText = styled.span`
	display: inline-block;
`;

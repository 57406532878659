import { getAnalyticsConfig } from '../shared/config/getAnalyticsConfig';
import { trackWithGoogleAnalytics } from '../shared/trackers/trackWithGoogleAnalytics';

export function trackRetargeting() {
	const { adaraTrackingId, adaraRTGLabel } = getAnalyticsConfig();

	if (!adaraTrackingId || !adaraRTGLabel) {
		return;
	}

	const sendTo = `${adaraTrackingId}/${adaraRTGLabel}`;
	trackWithGoogleAnalytics('event', 'conversion', {
		send_to: sendTo,
		allow_custom_scripts: true,
	});
}

export const PASSENGERS_HOME_PAGE_ID = 'passengers-home';
export const PASSENGERS_FLIGHTS_PAGE_ID = 'passengers-flights';
export const PASSENGERS_FLIGHT_DETAIL_PAGE_ID = 'passengers-flight-detail';
export const PASSENGERS_PARKING_LANDING_PAGE_ID = 'passengers-parking';
export const PASSENGERS_PARKING_OPTIONS_PAGE_ID = 'parking-options';
export const PASSENGERS_PARKING_AVAILABILITY_PAGE_ID =
	'live-parking-availability-page';
export const PASSENGERS_SHOP_DINE_AND_RELAX_PAGE_ID =
	'passengers-shop-dine-and-relax';
export const PASSENGERS_SHOP_DINE_AND_RELAX_DETAIL_PAGE_ID =
	'passengers-shop-dine-and-relax-detail';
export const PASSENGERS_AIRPORT_INFO_PAGE_ID = 'passengers-airport-info';
export const PASSENGERS_CONNECTING_FLIGHTS_PAGE_ID = 'prepare-for-travel';
export const PASSENGERS_SECURITY_WAITING_TIMES_PAGE_ID = 'security';

export const TERMS_AND_CONDITIONS_PAGE_ID = 'terms-and-conditions';
export const PRIVACY_POLICY_PAGE_ID = 'privacy-notice';

export const BUSINESS_HOME_PAGE_ID = 'business-home';
export const COMMUNITY_HOME_PAGE_ID = 'community-home';
export const CAREERS_HOME_PAGE_ID = 'careers';

import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { ChevronDownIcon, ChevronUpIcon, ITheme } from '../../../../ui';
import Responsive from '../../../../ui/utils/Responsive';
import { useTranslation } from '../../i18n/translate/useTranslation';
import themed from '../../../../ui/themer/themed';

export const StyledShowMoreButton = styled.button<
	{ absolutePositioning?: boolean },
	ITheme
>`
	${({ absolutePositioning }) =>
		absolutePositioning &&
		`
 		position: absolute;
 		top: 265px;
 		width: 95% !important;
 
 		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
 				top: 320px;
 			}
 
 			@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
 				top: 310px;
 			}
 	`}
	color: ${themed(({ color }) => color.highlight)};
	text-align: center;
	padding: 10px;
	margin-top: 10px;
	cursor: pointer;
	border-radius: 3px;
	transition: all 0.1s;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	width: 100%;
	border: none;
	font-size: inherit;
	background: transparent;
	font-family: inherit;
	line-height: inherit;

	&:hover {
		background: ${themed(({ color }) => color.background.hover)};
		color: ${themed(({ color }) => color.text.hover)};

		svg {
			g {
				stroke: ${themed(({ color }) => color.text.hover)};
			}
		}
	}

	&:active {
		opacity: 0.8;
	}
`;

export interface IShowMoreButtonProps
	extends HTMLAttributes<HTMLButtonElement> {
	direction?: 'up' | 'down';
	title?: string;
	absolutePositioning?: boolean;
}

export const ShowMoreButton = ({
	direction = 'down',
	title,
	absolutePositioning,
	...rest
}: IShowMoreButtonProps) => {
	const { t } = useTranslation();

	return (
		<StyledShowMoreButton {...rest} absolutePositioning={absolutePositioning}>
			{direction === 'up' ? (
				<ChevronUpIcon aria-hidden variation="highlight" />
			) : (
				<ChevronDownIcon aria-hidden variation="highlight" />
			)}
			{title ?? t('generic_show_more_button')}
		</StyledShowMoreButton>
	);
};

import styled from '@emotion/styled';
import { getHeaderStyleCss, HeaderLevel } from '.';
import { ITheme } from '../../themer';

export interface IStyledHeadingProps {
	styling?: HeaderLevel;
}

const getHeaderStyleCssWithProp =
	(defaultStyling: HeaderLevel) =>
	({ styling }: IStyledHeadingProps) =>
		getHeaderStyleCss(styling || defaultStyling);

export const H1 = styled.h1<IStyledHeadingProps, ITheme>`
	${getHeaderStyleCssWithProp('h1')}
`;

export const H2 = styled.h2<IStyledHeadingProps, ITheme>`
	${getHeaderStyleCssWithProp('h2')}
`;

export const H3 = styled.h3<IStyledHeadingProps, ITheme>`
	${getHeaderStyleCssWithProp('h3')}
`;

export const H4 = styled.h4<IStyledHeadingProps, ITheme>`
	${getHeaderStyleCssWithProp('h4')}
`;

export const H5 = styled.h5<IStyledHeadingProps, ITheme>`
	${getHeaderStyleCssWithProp('h5')}
`;

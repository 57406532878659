import React from 'react';
import { ResponsiveIframe } from './ResponsiveIframe';
import { isYouTubeUrl, YouTubeIframe } from './YouTubeIframe';
import { IIframeProps } from './IIframeProps';

export const Iframe = (props: IIframeProps) => {
	const { url } = props;

	if (isYouTubeUrl(url)) {
		return <YouTubeIframe {...props} />;
	}

	return <ResponsiveIframe {...props} />;
};

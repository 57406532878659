import { IRequestOptions } from '@move-frontend/utils';
import { AbstractService } from './AbstractService';
import { IEmailSuggestionQuery } from '../types/IEmailSuggestionQuery';
import { IEmailSuggestionResponse } from '../types/IEmailSuggestionResponse';

export class EmailSuggestionService extends AbstractService {
	get({ email }: IEmailSuggestionQuery, options?: IRequestOptions) {
		return this.client.get<IEmailSuggestionResponse>('/email/suggestions', {
			queryStringParameters: {
				email,
			},
			...options,
		});
	}
}

import { useForm } from '../../../../shared/form/useForm';
import { ISignInFormData } from '../../domain/models/ISignInFormData';
import { useSignInSchema } from './useSignInSchema';

export function useSignInForm() {
	const validationSchema = useSignInSchema();
	const form = useForm<ISignInFormData>({
		mode: 'onBlur',
		validationSchema,
	});

	return form;
}

import React, { MouseEvent, ReactNode } from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import { CancelIcon } from '../Icon';
import { StyledInput } from '../Input';
import { Css } from '../../utils/Css';
import { useConfig } from '..';
import { theme } from '../../../features/shared/styles';

export const StyledSearchInputClearWrap = styled.div<
	{ showClearIcon?: boolean },
	ITheme
>`
	position: relative;

	&:focus-within {
		outline: ${theme.button?.primary?.focus?.outline};
		border-radius: 4px;
		z-index: 1;
	}

	${StyledInput} {
		font-family: ${themed(({ typography }) => typography.body.fontFamily)};
		padding-right: ${themed(({ input }, { showClearIcon }) =>
			showClearIcon
				? Css.calc(input.padding, (value: number) => value + 24)
				: '',
		)};
	}
`;

export const StyledClearIcon = styled.button<{}, ITheme>`
	position: absolute;
	cursor: pointer;
	height: 100%;
	right: 0px;
	top: 0px;
	padding: ${themed(({ search }) => search.clear.padding)};
	border: ${themed(({ search }) => search.clear.border)};
	background: ${themed(({ search }) => search.clear.background)};

	&:focus-visible {
		outline: none;

		${CancelIcon} g {
			stroke: ${themed(({ color }) => color.highlight)};
		}
	}

	${CancelIcon} {
		height: 100%;
	}
`;

interface ISearchInputClearWrapProps {
	onClickClear: (e: MouseEvent) => void;
	showClearIcon: boolean;
	children: ReactNode;
}

export const SearchInputClearWrap = ({
	showClearIcon,
	onClickClear,
	children,
}: ISearchInputClearWrapProps) => {
	const { translations } = useConfig();

	return (
		<StyledSearchInputClearWrap showClearIcon={showClearIcon}>
			{children}
			{showClearIcon && (
				<StyledClearIcon
					type="button"
					aria-label={translations.clearSearchLabel}
					onClick={onClickClear}
				>
					<CancelIcon />
				</StyledClearIcon>
			)}
		</StyledSearchInputClearWrap>
	);
};

import styled from '@emotion/styled';
import { Base } from '../../../../ui/components/Button/Base';

export const NavigationDotButton = styled(Base)<{ active: boolean }>`
	padding: 5px;
	cursor: pointer;
	border: none;
	background: none;
	&:before {
		display: inline-block;
		content: '';
		width: 8px;
		height: 8px;
		border-radius: 4px;
		background-color: ${({ active }) => (active ? 'black' : '#a99555')};
	}
`;

import { ForgotPasswordStore } from '../../../../auth/forgot-password/domain/stores/ForgotPasswordStore';
import { AuthStore } from '../../../../auth/shared/store/AuthStore';
import { createUserStores } from '../../../../auth/user/domain/stores';
import {
	createBusWaitingTimesStores,
	createEmployeeLotsStores,
} from '../../../../bus-wait-times/domain/stores/createBusWaitingTimesStores';
import { createFlightStores } from '../../../../flights/createFlightStores';
import { createLoyaltyAccountStores } from '../../../../account/domain/stores/createLoyaltyAccountStores';
import { createParkingAvailabilityStores } from '../../../../parking-availability/ui/stores/createParkingAvailabilityStores';
import { createParkingStores } from '../../../../parking/domain/createParkingStores';
import { createPaymentStores } from '../../../../payment/domain/stores';
import { createCheckpointQueueStores } from '../../../../security-wait-times/domain/stores';
import { Dap } from '../../../../shared/api/dap/Dap';
import { Gateway } from '../../../../shared/api/gateway/Gateway';
import { createRemoteConfigStore } from '../../../../shared/remote-config/domain/store/createRemoteConfigStore';
import { createPoiStores } from '../../../../shop-dine-and-relax/domain/stores';

export type IStores = ReturnType<typeof createStores>;

// TODO: split these in separate stores each with their own React context
// as soon as we get rid of the legacy Mobx store Provider
export function createStores() {
	const dap = new Dap(
		process.env.DAP_API_KEY as string,
		process.env.DAP_BASE_URI as string,
	);

	const flightStores = createFlightStores(dap);
	const poiStores = createPoiStores();
	const checkpointQueueStores = createCheckpointQueueStores(dap);
	const remoteConfigStores = createRemoteConfigStore();
	const authStore = new AuthStore();
	const forgotPasswordStore = new ForgotPasswordStore();

	const gateway = new Gateway();
	const parkingStores = createParkingStores(authStore, gateway);
	const paymentStores = createPaymentStores(gateway);

	const userStores = createUserStores(authStore);

	const busWaitingTimesStore = createBusWaitingTimesStores(dap);
	const employeeLotsStore = createEmployeeLotsStores(dap);
	const locationStore = createParkingAvailabilityStores(dap);

	const loyaltyStore = createLoyaltyAccountStores(
		authStore,
		gateway,
		parkingStores.newBookingStore,
	);

	return {
		authStore,
		forgotPasswordStore,
		...flightStores,
		...poiStores,
		...checkpointQueueStores,
		...remoteConfigStores,
		...parkingStores,
		...paymentStores,
		...userStores,
		...busWaitingTimesStore,
		...employeeLotsStore,
		...locationStore,
		...loyaltyStore,
	};
}

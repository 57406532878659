import { Component } from 'react';
import { logError } from '../../error/logError';

/**
 * This error boundary will simply hide the wrapped component when an error is thrown.
 * This should only be used for non-essential components, to allow the page content to be rendered regardless.
 */
export class SilentErrorBoundary extends Component<{
	children: React.ReactNode;
}> {
	public state = { hasError: false };

	public static getDerivedStateFromError() {
		return { hasError: true };
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public componentDidCatch(error: Error, info: any) {
		logError(error, info);
	}

	public render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (!hasError) {
			return children;
		}

		return null;
	}
}

import { IBookingCreateRequestData } from '../types/IBookingCreateRequestData';
import { IBookingCreateResponse } from '../types/IBookingCreateResponse';
import { IGetUserBookingsResponse } from '../types/IGetUserBookingsResponse';
import { ICancelBookingByReferenceQueryParams } from '../types/ICancelBookingByReferenceQueryParams';
import { IPatchBookingByReferenceQueryParams } from '../types/IPatchBookingByReferenceQueryParams';
import { IPatchBookingByReferenceRequestData } from '../types/IPatchBookingByReferenceRequestData';
import { IPatchBookingByReferenceResponse } from '../types/IPatchBookingByReferenceResponse';
import { IPatchBookingRequestData } from '../types/IPatchBookingRequestData';
import { IPatchBookingResponse } from '../types/IPatchBookingResponse';
import { AbstractService } from './AbstractService';
import { IGetBookingByReferenceQueryParams } from '../types/IGetBookingByReferenceQueryParams';
import { IGetBookingByReferenceResponse } from '../types/IGetBookingByReferenceResponse';
import { IBookingCreateParams } from '../types/IBookingCreateParams';

export class BookingService extends AbstractService {
	create(data: IBookingCreateRequestData, params: IBookingCreateParams) {
		return this.client.post<IBookingCreateRequestData, IBookingCreateResponse>(
			'/parking/bookings',
			data,
			{
				queryStringParameters: {
					...params,
				},
			},
		);
	}

	getUserBookings() {
		return this.client.get<IGetUserBookingsResponse>('/parking/bookings');
	}

	getGuestBooking({ reference, email }: IGetBookingByReferenceQueryParams) {
		return this.client.get<IGetBookingByReferenceResponse>(
			`/parking/bookings/by-reference/${reference}`,
			{
				queryStringParameters: {
					email,
				},
			},
		);
	}

	patchBookingByReference(
		{ reference, email }: IPatchBookingByReferenceQueryParams,
		data: IPatchBookingByReferenceRequestData = {},
	) {
		return this.client.patch<
			IPatchBookingByReferenceRequestData,
			IPatchBookingByReferenceResponse
		>(`/parking/bookings/by-reference/${reference}`, data, {
			queryStringParameters: {
				email,
			},
		});
	}

	patchBooking(id: number, data: IPatchBookingRequestData = {}) {
		return this.client.patch<IPatchBookingRequestData, IPatchBookingResponse>(
			`/parking/bookings/${id}`,
			data,
		);
	}

	cancelBookingByReference({
		reference,
		email,
	}: ICancelBookingByReferenceQueryParams) {
		return this.client.delete(`/parking/bookings/by-reference/${reference}`, {
			queryStringParameters: {
				email,
			},
		});
	}

	cancelBooking(id: number) {
		return this.client.delete(`/parking/bookings/${id}`);
	}
}

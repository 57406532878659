import { Gateway } from '../../shared/api/gateway/Gateway';
import { AuthStore } from '../../auth/shared/store/AuthStore';
import { MyBookingsStore } from './booking/stores/MyBookingsStore';
import { NewBookingStore } from './booking/stores/NewBookingStore';

export function createParkingStores(authStore: AuthStore, gateway: Gateway) {
	const bookingService = gateway.booking();

	const myBookingsStore = new MyBookingsStore(authStore, bookingService);

	const newBookingStore = new NewBookingStore(
		gateway.parkingProductsQuote(),
		bookingService,
	);

	return {
		myBookingsStore,
		newBookingStore,
	};
}

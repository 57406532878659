import { hasRequiredFields } from '@move-frontend/utils';
import { IForgotPasswordFormData } from '../../../domain/models/IForgotPasswordFormData';
import { useForgotPasswordFormContext } from './useForgotPasswordFormContext';

export function useForgotPasswordHasRequiredFields() {
	const { watch } = useForgotPasswordFormContext();
	const values: Partial<IForgotPasswordFormData> = watch();

	return hasRequiredFields(values, ['email']);
}

import { color } from '../../themer/theme/color';
import { typography } from '../../themer/theme/typography';

export const theme = {
	border: color.border.default,
	borderRadius: '3px',
	padding: '20px',
	subtitle: {
		fontSize: typography.body.size,
		color: color.text.light,
	},
	mobile: {
		padding: '13px',
	},
};

import { CheckpointQueueStore } from './CheckpointQueueStore';
import { CheckpointQueueListStore } from './CheckpointQueueListStore';
import { Dap } from '../../../shared/api/dap';

export function createCheckpointQueueStores(dap: Dap) {
	const checkpointQueueStore = new CheckpointQueueStore(dap);
	const checkpointQueueListStore = new CheckpointQueueListStore(
		checkpointQueueStore,
	);

	return {
		checkpointQueueListStore,
	};
}

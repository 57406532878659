import React, {
	DetailedHTMLProps,
	forwardRef,
	InputHTMLAttributes,
	ReactNode,
} from 'react';
import { IUIComponentProps } from '../../types';
import { VisuallyHiddenInput } from '../Input';
import { StyledCheckbox, StyledCheckboxIcon } from './StyledCheckbox';

export interface ICheckboxProps
	extends IUIComponentProps,
		DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	invalid?: boolean;
	label: ReactNode;
	ariaLabel?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
	(
		{ className, ariaLabel, label, disabled, invalid, ...rest }: ICheckboxProps,
		ref,
	) => (
		<StyledCheckbox disabled={disabled} className={className}>
			<VisuallyHiddenInput
				ref={ref}
				type="checkbox"
				disabled={disabled}
				aria-invalid={invalid}
				aria-label={typeof label === 'string' ? ariaLabel ?? label : ariaLabel}
				{...rest}
			/>
			<StyledCheckboxIcon invalid={invalid} />
			{typeof label === 'string' ? <div>{label}</div> : label}
		</StyledCheckbox>
	),
);

import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import Responsive from '../../utils/Responsive';

export interface ITagButtonProps {
	active?: boolean;
	disabled?: boolean;
}

export const TagButton = styled.button<ITagButtonProps, ITheme>`
	display: inline-block;
	height: 38px;
	line-height: 36px;
	padding: 0 16px;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	white-space: nowrap;
	font-family: inherit;
	font-size: 16px;
	appearance: none;

	border: 1px solid
		${themed(({ color }, { disabled }) =>
			disabled ? color.background.light : color.highlight,
		)};
	background: ${themed(({ color }, { active, disabled }) =>
		active
			? disabled
				? color.background.light
				: color.highlight
			: color.background.default,
	)};
	border-radius: ${themed(({ button }) => button.radius)};
	color: ${themed(({ color }, { active, disabled }) =>
		active
			? color.text.inverted
			: disabled
			? color.background.light
			: color.highlight,
	)};
	transition: all 0.15s;

	&:hover {
		${themed(({ color }, { disabled }) =>
			disabled ? '' : `box-shadow: inset 0 0 0 1px ${color.highlight};`,
		)}
	}

	&:focus {
		outline: none;
	}

	&:focus-visible {
		${themed(
			({ color }, { active }) =>
				`box-shadow: inset 0 0 0 2px ${
					active ? color.background.light : color.highlight
				};`,
		)}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 14px;
		height: 32px;
		line-height: 30px;
		padding: 0 14px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		flex-grow: 1;
		text-align: center;
		padding: 0 6px;
	}
`;

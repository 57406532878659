import React from 'react';
import { ConfigContext } from './ConfigContext';
import { IConfig } from './IConfig';

interface IConfigProps {
	config: IConfig;
}

export const withConfig =
	<P extends {}>(Component: React.ComponentType<P & IConfigProps>) =>
	(props: P) =>
		(
			<ConfigContext.Consumer>
				{(config) => <Component config={config} {...props} />}
			</ConfigContext.Consumer>
		);

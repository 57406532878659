import React from 'react';
import { SignInDialog } from './sign-in/ui/SignInDialog';
import { ForgotPasswordDialog } from './forgot-password/ui/ForgotPasswordDialog';
import { ForgotPasswordConfirmationDialog } from './forgot-password/ui/confirmation/ForgotPasswordConfirmationDialog';
import { SignUpDialog } from './sign-up/ui/SignUpDialog';
import { SignUpConfirmationDialog } from './sign-up/ui/confirmation/SignUpConfirmationDialog';
import { ResetPasswordDialog } from './reset-password/ui/ResetPasswordDialog';
import { ResetPasswordConfirmationDialog } from './reset-password/ui/confirmation/ResetPasswordConfirmationDialog';

export const AuthDialogs = () => (
	<>
		<ForgotPasswordDialog />
		<ForgotPasswordConfirmationDialog />
		<ResetPasswordDialog />
		<ResetPasswordConfirmationDialog />
		<SignUpDialog />
		<SignUpConfirmationDialog />
		<SignInDialog />
	</>
);

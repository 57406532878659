import { IParkingProductsQuoteResponse } from '../../../../../shared/api/gateway/types/IParkingProductsQuoteResponse';
import { parsePrice } from '../../../../../shared/i18n/price/utils/parsePrice';
import { IParkingProductsQuote } from '../../models/IParkingProductsQuote';

export function adaptResponseToParkingProductQuote(
	response: IParkingProductsQuoteResponse,
): IParkingProductsQuote {
	return {
		...response,
		parkingProducts: response.parkingProducts.map((parkingProduct) => ({
			...parkingProduct,
			price: {
				value: parsePrice(parkingProduct.price.value),
				originalValue: parsePrice(parkingProduct.price.originalValue),
				onlineSavings: parsePrice(parkingProduct.price.onlineSavings),
				discount: parkingProduct.price?.discount
					? parsePrice(parkingProduct.price?.discount)
					: undefined,
			},
		})),
	};
}

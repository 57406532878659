import { IGatewayError } from './IGatewayError';

export function isGatewayError(
	error: Error | IGatewayError,
): error is IGatewayError {
	if (!('response' in error) || !error.response) {
		return false;
	}

	if (!('data' in error.response)) {
		return false;
	}

	return true;
}

import { MouseEvent as ReactMouseEvent } from 'react';

export function getLinkElementForClickEvent(
	event: MouseEvent | ReactMouseEvent<Element>,
): HTMLAnchorElement | undefined {
	let link;

	try {
		link = event.target && (event.target as HTMLElement).closest('a');
	} catch {
		return undefined;
	}

	return link ?? undefined;
}

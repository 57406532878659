import { IDropdownItem } from '../DropdownItem';
import { IGetItemDisplayValue } from './IGetItemDisplayValue';

export const getItemDisplayValueDefault: IGetItemDisplayValue = (
	item: IDropdownItem | null,
) => {
	if (!item || (typeof item !== 'string' && item.type === 'header')) return '';

	if (typeof item === 'string') return item;

	return item.stringValue ?? item.value;
};

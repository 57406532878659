import React from 'react';
import styled from '@emotion/styled';
import { H1, IStyledHeaderProps, ITheme, Link } from '../../../../ui';
import { SIGN_IN_DIALOG_HASH } from '../../sign-in/ui/SignInDialog';
import { SIGN_UP_DIALOG_HASH } from '../../sign-up/ui/SignUpDialog';
import {
	DialogCloseButton,
	IDialogSizeProps,
	IResponsiveHiddenProps,
	StyledDialogHeaderGradient,
} from '../../../shared/components/dialog';
import { useDialogContext } from '../../../shared/components/dialog/DialogContext';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import themed from '../../../../ui/themer/themed';
import Responsive from '../../../../ui/utils/Responsive';

export interface IStyledDialogHeaderGradientProps
	extends IStyledHeaderProps,
		IDialogSizeProps,
		IResponsiveHiddenProps {}

interface IStyledDialogHeaderGradientTitleProps {
	selected: boolean;
}

export const StyledDialogHeaderGradientTitle = styled(H1.withComponent(Link))<
	IStyledDialogHeaderGradientTitleProps,
	ITheme
>`
	color: ${themed(({ color }) => color.text.inverted)};
	margin: 0;
	border-bottom: ${({ selected }) => (selected ? '5px solid white' : 'none')};
	opacity: ${({ selected }) => (selected ? '1' : '0.5')};
	text-decoration: none;
	font-size: 3.2rem;
	&:hover {
		color: ${themed(({ color }) => color.text.inverted)} !important;
		text-decoration: none;
	}
	&:hover:not(:disabled) {
		color: ${themed(({ color }) => color.text.inverted)} !important;
		text-decoration: none;
	}
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 1.5rem;
		border-bottom: ${({ selected }) => (selected ? '3px solid white' : 'none')};
	}
`;

const StyledDialogHeaderContainer = styled.div`
	width: 100%;
	display: flex;
	gap: 40px;
	justify-content: center;
	align-items: start;
	margin-top: 35px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-top: 30px;
	}
`;
interface IDialogHeaderProps extends IStyledDialogHeaderGradientProps {
	className?: string;
	type: string;
}

export const SignInRegisterDialogHeaderGradient = ({
	type,
	...rest
}: IDialogHeaderProps) => {
	const { t } = useTranslation();
	const { close } = useDialogContext();

	return (
		<StyledDialogHeader backgroundTheme="purple-blue">
			<StyledDialogHeaderGradient backgroundTheme="purple-blue" {...rest}>
				<StyledDialogHeaderContainer>
					<StyledDialogHeaderGradientTitle
						href={SIGN_IN_DIALOG_HASH}
						selected={type === SIGN_IN_DIALOG_HASH}
					>
						{t('user_sign_in_title')}
					</StyledDialogHeaderGradientTitle>
					<StyledDialogHeaderGradientTitle
						href={SIGN_UP_DIALOG_HASH}
						selected={type === SIGN_UP_DIALOG_HASH}
					>
						{t('user_create_account_title')}
					</StyledDialogHeaderGradientTitle>
				</StyledDialogHeaderContainer>
			</StyledDialogHeaderGradient>
			<StyledDialogCloseButton variation="inverted" onClick={close} />
		</StyledDialogHeader>
	);
};

const StyledDialogHeader = styled.div`
	height: 100%;
	position: relative;
	${StyledDialogHeaderGradient} {
		padding-top: 50px;
	}
`;
const StyledDialogCloseButton = styled(DialogCloseButton)`
	position: absolute;
	top: 32px;
	right: 32px;
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		top: 18px;
		right: 18px;
	}
`;

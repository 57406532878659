export interface ITimeTheme {
	format: Intl.DateTimeFormatOptions;
}

export const theme: ITimeTheme = {
	format: {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	},
};

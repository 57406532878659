import styled from '@emotion/styled';

export const AuthFormActions = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row-reverse;
	align-content: center;
	margin-bottom: 24px;
`;

import { configure } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import { isServerSide } from '../../../../shared/utils/isServerSide';
import { createStores } from './createStores';

enableStaticRendering(isServerSide());

configure({
	enforceActions: 'never',
	computedRequiresReaction: false,
	observableRequiresReaction: false,
	reactionRequiresObservable: false,
	disableErrorBoundaries: false,
});

export const stores = createStores();

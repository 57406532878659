import { border } from '../../themer/theme/border';

export const theme = {
	border: border.default,
	title: {
		padding: '24px 0',
	},
	content: {
		padding: '16px 0 0 0',
		margin: '0',
	},
};

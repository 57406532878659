import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import {
	ArrowLink,
	ArrowLinkButton,
	isRelativeUrl,
	StyledIconLink,
} from '../Link';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import Responsive from '../../utils/Responsive';

export const StyledHighlightContent = styled.div<{}, ITheme>`
	min-width: 50%;
	flex: 1 0 50%;
	padding: ${themed(({ highlight }) => highlight.padding.default)};

	// IE11 Fix
	max-width: 50%;

	p:last-of-type {
		margin-bottom: 0;
	}

	${StyledIconLink} {
		margin-top: 1em;
		display: inline-flex;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: ${themed(({ highlight }) => highlight.padding.mobileXL)};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		padding: ${themed(({ highlight }) => highlight.padding.mobile)};
	}
`;

interface IHighlightContentProps {
	title: string;
	children?: ReactNode;
	linkHref?: string;
	linkTitle?: string;
	showLinkAsButton?: boolean;
}

export const HighlightContent = ({
	title,
	children,
	linkHref,
	linkTitle,
	showLinkAsButton,
}: IHighlightContentProps) => (
	<StyledHighlightContent>
		<h2>{title}</h2>
		{children}
		{linkTitle && linkHref && (
			<>
				{showLinkAsButton ? (
					<ArrowLinkButton
						href={linkHref}
						target={isRelativeUrl(linkHref) ? undefined : '_blank'}
						hideExternalIndicator
					>
						{linkTitle}
					</ArrowLinkButton>
				) : (
					<ArrowLink
						href={linkHref}
						target={isRelativeUrl(linkHref) ? undefined : '_blank'}
					>
						{linkTitle}
					</ArrowLink>
				)}
			</>
		)}
	</StyledHighlightContent>
);

//

import React from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../../../ui';
import themed from '../../../../ui/themer/themed';
import Responsive from '../../../../ui/utils/Responsive';

const StyledAuthFormSectionHeader = styled.h3<{}, ITheme>`
	margin-bottom: 24px;

	span {
		display: inline-block;
		margin-left: 16px;
		font-size: ${themed(({ typography }) => typography.body.size)};
		font-weight: normal;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding-bottom: 8px;
		margin-bottom: 16px;
		font-size: 22px;
		border-bottom: ${themed(({ border }) => border.default)};

		span {
			display: none;
		}
	}
`;

interface IAuthFormSectionHeaderProps {
	title: string;
	hint?: string;
}

export const AuthFormSectionHeader = ({
	title,
	hint,
}: IAuthFormSectionHeaderProps) => (
	<StyledAuthFormSectionHeader>
		{title}
		{hint && <span>{hint}</span>}
	</StyledAuthFormSectionHeader>
);

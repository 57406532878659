import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';

import Responsive from '../../utils/Responsive';
import { IUIComponentProps } from '../../types';
import themed from '../../themer/themed';

export interface IPaperProps extends IUIComponentProps {
	clickable?: boolean;
	selected?: boolean;
	disabled?: boolean;
	children?: ReactNode;
}

export function getPaperBoxShadow({
	selected,
	clickable,
	theme,
}: IPaperProps & { theme: ITheme }) {
	if (selected) {
		return theme.paper.selected.boxShadow;
	}

	if (clickable) {
		return theme.paper.clickable.boxShadow;
	}

	return theme.paper.boxShadow;
}

export const Paper = styled.div<IPaperProps, ITheme>`
	padding: ${themed(({ paper }) => paper.padding)};
	background: ${themed(({ paper }) => paper.background)};
	transition: ${themed(({ paper }) => paper.transition)};
	box-shadow: ${getPaperBoxShadow};
	opacity: ${themed(({ paper }, { disabled }) =>
		disabled ? paper.disabled.opacity : '',
	)};

	${themed(({ paper }, { clickable, disabled, selected }) =>
		clickable && !disabled
			? `
				cursor: pointer;

				&:focus,
				&:hover {
					outline: none;
					box-shadow: ${
						selected
							? paper.selected.boxShadow
							: paper.clickable.hover.boxShadow
					};
				}
			`
			: '',
	)}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: ${themed(({ paper }) => paper.mobile.padding)};
	}
`;

import { IBusWaitingTimes } from '../../models/IBusWaitingTimes';

export type FilterStopResponce = ReturnType<typeof createFilterStopResponse>;

export function createFilterStopResponse() {
	return function filterResponse(
		stops: IBusWaitingTimes[],
		isRestricted: boolean,
	): IBusWaitingTimes[] {
		return stops.filter((stop) =>
			isRestricted ? stop.restricted : !stop.restricted,
		);
	};
}

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ISelectProps, Select } from '../Select';
import { withConfig } from '../Config';
import { theme } from '../../../features/shared/styles';

export interface IDateSelectItem {
	value: Date;
	hint?: string;
}

export type IDateSelectItemOrDate = IDateSelectItem | Date;

interface IDateSelectProps extends ISelectProps<IDateSelectItemOrDate> {}

function getDateFromItem(item: IDateSelectItemOrDate): Date {
	return item instanceof Date ? item : item.value;
}

const DateSelectComponent = Select as FC<IDateSelectProps>;

export const DateSelect = withConfig<IDateSelectProps>(
	({ itemToString, itemToKey, items, config, ...rest }) => {
		const getFormattedDate =
			itemToString ||
			((item: IDateSelectItemOrDate | null) =>
				item
					? getDateFromItem(item).toLocaleDateString(
							config.dateTimeFormat.locale,
							{
								timeZone: config.dateTimeFormat.timeZone,
								...config.theme.dateSelect.format,
							},
					  )
					: '');

		const getKeyFromDate = (item: IDateSelectItemOrDate) =>
			getDateFromItem(item).toDateString();

		return (
			<StyledDateSelectComponent
				items={items}
				itemToString={getFormattedDate}
				itemToKey={getKeyFromDate}
				{...rest}
			/>
		);
	},
);

const StyledDateSelectComponent = styled(DateSelectComponent)`
	white-space: nowrap;

	&:focus-within {
		outline: ${theme.button?.primary?.focus?.outline};
		border-radius: 4px;
	}
`;

import React from 'react';
import { withTheme } from 'emotion-theming';
import { ITheme } from '../../themer';
import Responsive from '../../utils/Responsive';

export type IResponsiveImageSource = Responsive.IValueOrResponsiveValue<string>;

export interface IResponsivePictureProps extends NonNullable<unknown> {
	source: Responsive.IValueOrResponsiveValue<string>;
	className?: string;
	alt?: string;
}

type IResponsivePicturePropsWithTheme = IResponsivePictureProps & {
	theme: ITheme;
};

export const ResponsivePicture = withTheme(
	({
		source,
		theme,
		className,
		alt,
		...rest
	}: IResponsivePicturePropsWithTheme) => {
		let defaultImageSource: string;
		let responsiveImageSources: Responsive.IBreakpointValues<string> = {};

		if (Array.isArray(source)) {
			[defaultImageSource, responsiveImageSources] = source;
		} else {
			defaultImageSource = source;
		}

		const breakpoints = theme.breakpoints.map(({ name }) => name);

		// Image sources should be ordered from smallest to largest
		breakpoints.reverse();

		return (
			<picture className={className} {...rest}>
				{breakpoints.map(
					(breakpoint) =>
						breakpoint in responsiveImageSources && (
							<source
								key={breakpoint}
								media={`(${Responsive.getMediaQueryForBreakpoint(breakpoint)})`}
								srcSet={responsiveImageSources[breakpoint]}
							/>
						),
				)}
				<img src={defaultImageSource} alt={alt} />
			</picture>
		);
	},
);

import React from 'react';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { HashDialog } from '../../../../shared/components/dialog/HashDialog';
import { SignUpConfirmationDialogContent } from './SignUpConfirmationDialogContent';

export const SIGN_UP_CONFIRMATION_DIALOG_HASH = '#register-confirmation';

export const SignUpConfirmationDialog = () => {
	const { t } = useTranslation();

	return (
		<HashDialog
			ariaLabel={t('user_sign_up_confirmation_title')}
			hash={SIGN_UP_CONFIRMATION_DIALOG_HASH}
			replaceStateOnClose
		>
			<SignUpConfirmationDialogContent />
		</HashDialog>
	);
};

import React, { PropsWithoutRef, ReactNode, RefObject } from 'react';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars';
import styled from '@emotion/styled';

interface IScrollBarProps {
	children: ReactNode;
	className?: string;
	scrollRef?: RefObject<Scrollbars>;
	noMargin?: boolean;
}

type IScrollBarCombinedProps = IScrollBarProps &
	PropsWithoutRef<ScrollbarProps>;

export const ScrollbarLeft = ({
	children,
	className,
	scrollRef,
	noMargin,
	...props
}: IScrollBarCombinedProps) => (
	<div className={className}>
		<StyledScrollbars
			noMargin={noMargin}
			universal
			renderTrackHorizontal={() => <div />}
			renderThumbHorizontal={() => <div />}
			ref={scrollRef}
			renderThumbVertical={scrollbarThumb}
			renderTrackVertical={scrollbarTrack}
			{...props}
		>
			{children}
		</StyledScrollbars>
	</div>
);

interface IStyledScrollbars {
	noMargin?: boolean;
}

const StyledScrollbars = styled(Scrollbars, {
	shouldForwardProp: (prop) => prop !== 'noMargin',
})<IStyledScrollbars>`
	> div {
		margin: ${({ noMargin }) => (noMargin ? '0' : '0 0 0 30px')};
		overflow-x: hidden;
	}
	overflow-x: hidden;
`;

const scrollbarThumb = (props: any) => <ScrollbarThumb {...props} />;
const ScrollbarThumb = styled.div`
	background-color: black;
	border-radius: 3px;
`;

const scrollbarTrack = (props: any) => <ScrollbarTrack {...props} />;
const ScrollbarTrack = styled.div`
	left: -30px;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 3px;
`;

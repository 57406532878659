import { adaptEventToCustomEvent } from './adapters/adaptEventToCustomEvent';
import { getAnalyticsConfig } from '../shared/config/getAnalyticsConfig';
import { trackWithGoogleAnalytics } from '../shared/trackers/trackWithGoogleAnalytics';
import { ICustomEvent } from './models/ICustomEvent';
import { IEvent } from './models/IEvent';

/**
 * As long as the Universal Analytics is supported we will track events both as Universal Analytics events and the new GA4 Custom Events.
 * For the difference between the two see: https://developers.google.com/analytics/devguides/collection/ga4/translate-events
 */
export function trackEvent(event: IEvent) {
	trackUniversalAnalyticsEvent(event);

	trackCustomEvent(adaptEventToCustomEvent(event));
}

/**
 * Besides built-in events GA4 supports Custom Events, which will need to be configured before showing up in the reports.
 * See: https://support.google.com/analytics/answer/10085872?hl=en
 */
export function trackCustomEvent({ name, sendTo, ...rest }: ICustomEvent) {
	const { trackingId } = getAnalyticsConfig();

	if (!trackingId && !sendTo) {
		return;
	}

	trackWithGoogleAnalytics('event', name, {
		send_to: sendTo ?? trackingId,
		...rest,
	});
}

/**
 * See: https://support.google.com/analytics/answer/10106689?hl=en
 */
export function trackUniversalAnalyticsEvent({
	sendTo,
	category,
	action,
	label,
	value,
}: IEvent) {
	const { legacyTrackingId } = getAnalyticsConfig();

	if (!legacyTrackingId && !sendTo) {
		return;
	}

	trackWithGoogleAnalytics('event', action, {
		send_to: sendTo ?? legacyTrackingId,
		event_category: category,
		event_label: label,
		value,
	});
}

import React, { forwardRef, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import { IUIComponentProps, PropsOf } from '../../types';
import { Input } from '../Input';
import { ITheme } from '../../themer';

import Responsive from '../../utils/Responsive';
import themed from '../../themer/themed';

export interface IBaseTextFieldProps
	extends IUIComponentProps,
		Omit<PropsOf<typeof Input>, 'ref'> {
	name: string;
	error?: string | ReactElement;
	warning?: string | ReactElement;
	invalid?: boolean;
	disabled?: boolean;
	label?: string;
	type?: string;
	placeholder?: string;
	autoComplete?: string;
	ariaLabel?: string;
	ariaDescribedBy?: string;
	suffixContent?: ReactNode;
	hideErrorMessage?: boolean;
	icon?: ReactNode;
	hasCustomErrorMessage?: boolean;
	onChange?: (() => void) | undefined;
}

export type ITextFieldProps = IBaseTextFieldProps &
	({ label: string } | { ariaLabel?: string });

export const StyledTextFieldLabel = styled.label<{}, ITheme>`
	font-size: ${themed(({ typography }) => typography.header.h5.size)};

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: ${themed(({ typography }) => typography.mobile.small.size)};
		font-weight: bold;
	}
`;

export const StyledTextFieldLabelText = styled.span`
	display: block;
	margin-bottom: 8px;
`;

export const StyledTextField = styled.div`
	input {
		font-family: inherit;
	}
`;

export const StyledTextFieldErrorMessage = styled.div<{}, ITheme>`
	color: ${themed(({ color }) => color.state.error)};
	padding-left: ${({ theme: { textField } }) =>
		textField.errorMessage.paddingLeft};
	font-size: ${({ theme: { textField } }) => textField.errorMessage.fontSize};
	padding-top: ${({ theme: { textField } }) =>
		textField.errorMessage.paddingTop};
`;

export const StyledTextFieldWarningMessage = styled(
	StyledTextFieldErrorMessage,
)`
	color: #ff791f;
`;

export const StyledTextFieldInputWrapper = styled.div<{}, ITheme>`
	position: relative;

	input {
		font-family: ${themed(({ typography }) => typography.body.fontFamily)};
	}

	svg {
		path {
			fill: ${themed(({ input }) => input.icon.background)};
		}
	}
`;

export const TextField = forwardRef<HTMLInputElement, ITextFieldProps>(
	(
		{
			label,
			error,
			warning,
			invalid,
			className,
			ariaLabel,
			ariaDescribedBy,
			suffixContent,
			hideErrorMessage,
			icon,
			id,
			onChange,
			...rest
		}: ITextFieldProps,
		ref,
	) => {
		const hasError = !!error && !hideErrorMessage;
		const hasWarning = !!warning;
		const shouldShowWarning = hasWarning && !hasError;

		const { name } = rest;
		const errorId = error ? `${name}-error` : undefined;

		const inputId = id ?? `${name}-input`;

		return (
			<StyledTextField className={className}>
				{label && (
					<StyledTextFieldLabel htmlFor={inputId}>
						<StyledTextFieldLabelText>{label}</StyledTextFieldLabelText>
					</StyledTextFieldLabel>
				)}

				<StyledTextFieldInputWrapper>
					<Input
						id={inputId}
						ref={ref}
						invalid={invalid ?? hasError}
						aria-invalid={invalid ?? hasError}
						aria-label={ariaLabel}
						aria-describedby={ariaDescribedBy}
						hasWarning={shouldShowWarning}
						icon={icon}
						onChange={onChange}
						{...rest}
					/>
					{suffixContent}
				</StyledTextFieldInputWrapper>

				{hasError && (
					<StyledTextFieldErrorMessage role="alert" id={errorId}>
						{error}
					</StyledTextFieldErrorMessage>
				)}
				{shouldShowWarning && (
					<StyledTextFieldWarningMessage role="alert">
						{warning}
					</StyledTextFieldWarningMessage>
				)}
			</StyledTextField>
		);
	},
);

import { IEntry } from './IEntry';
import { NEWS_ALERT_TYPE_ID } from '../constants';

export enum NewsAlertType {
	EmergencyInformation = 'Emergency Information',
	Warning = 'Warning',
}

export interface INewsAlert {
	title: string;
	body: string;
	link?: string;
	linkLabel?: string;
	type: NewsAlertType;
}

export interface INewsAlertEntry
	extends IEntry<INewsAlert, typeof NEWS_ALERT_TYPE_ID> {}

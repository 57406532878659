import { IBusWaitingTimes } from '../models/IBusWaitingTimes';

export type OrderBusStops = ReturnType<typeof createOrderBusStops>;

export function createOrderBusStops() {
	function sortByName(
		currentStop: IBusWaitingTimes,
		compareStop: IBusWaitingTimes,
	) {
		if (currentStop.name > compareStop.name) {
			return 1;
		}
		if (currentStop.name < compareStop.name) {
			return -1;
		}
		return 0;
	}

	function isExpressDeck(stop: IBusWaitingTimes) {
		return /express deck/gi.test(stop.name);
	}

	return function orderBusStops(stops: IBusWaitingTimes[]) {
		const expressDecks = stops.filter(isExpressDeck).sort(sortByName);

		const anyBusStop = stops
			.filter((stop) => !isExpressDeck(stop))
			.sort(sortByName);
		return [...expressDecks, ...anyBusStop];
	};
}

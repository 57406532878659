import moment from 'moment-timezone/moment-timezone';
import { ICheckpointQueueResponse } from '../../../../shared/api/dap/types/ICheckpointQueueResponse';
import { CheckpointQueue } from '../../models/CheckpointQueue';
import { ICheckpointQueue } from '../../models/ICheckpointQueue';
import { getWaitTimeSeconds } from './getWaitTimeSeconds';
import { mapCheckpointQueueAccessTypes } from './mapCheckpointQueueAccessTypes';

export function transformCheckpointQueues(
	checkpointQueues: ICheckpointQueueResponse[],
): ICheckpointQueue[] {
	return checkpointQueues.map((checkpointQueue) => {
		const {
			id,
			name,
			attributes,
			maxWaitSeconds,
			waitSeconds,
			isOpen,
			lastUpdatedTimestamp,
			isDisplayable,
		} = checkpointQueue;

		const waitTimeSeconds = getWaitTimeSeconds(maxWaitSeconds, waitSeconds);
		const waitTimeMinutes = Math.floor(waitTimeSeconds / 60);
		const poiId = attributes.mapId;
		const accessTypes = mapCheckpointQueueAccessTypes(attributes);
		const waitTimeUpdatedAt = moment(lastUpdatedTimestamp * 1000);

		return new CheckpointQueue({
			id,
			name,
			accessTypes,
			poiId,
			isOpen,
			waitTimeMinutes,
			waitTimeUpdatedAt,
			isDisplayable,
		});
	});
}

import React from 'react';
import { Dialog, IDialogProps } from './Dialog';
import { removeHash } from '../../router/hash/hashHistory';
import { withHashRoute } from '../hash-route';
import { useTrackHashRoute } from '../hash-route/useTrackHashRoute';

interface IHashDialogProps extends IDialogProps {
	replaceStateOnClose?: boolean;
	allowPinchZoom?: boolean;
}

export const HashDialog = withHashRoute(
	({
		onClose,
		replaceStateOnClose,
		allowPinchZoom,
		...rest
	}: IHashDialogProps) => {
		useTrackHashRoute();

		const close = () => {
			let shouldClose: boolean | void = true;

			if (onClose) {
				shouldClose = onClose();
			}

			if (shouldClose === false) {
				return;
			}

			removeHash({ replace: replaceStateOnClose });
		};

		return <Dialog {...rest} onClose={close} />;
	},
);

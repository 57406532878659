import { IPrice } from '../../../../../shared/i18n/price/IPrice';
import { getSum } from '../../../../../shared/i18n/price/utils/getSum';
import { parsePrice } from '../../../../../shared/i18n/price/utils/parsePrice';
import { IExtra } from '../../../ancillary/domain/models/IExtra';

export function getTotalExtrasPrice(extras: IExtra[] = []): IPrice {
	return extras.reduce((previousExtraSum, currentExtra) => {
		const variantsSum = currentExtra.variants.reduce(
			(previousVariantSum, currentVariant) =>
				getSum(previousVariantSum, currentVariant.price),
			parsePrice(0),
		);

		return getSum(previousExtraSum, variantsSum);
	}, parsePrice(0));
}

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import { AccordionContext } from './AccordionContext';
import { AccordionTitle } from './AccordionTitle';
import { AccordionContent } from './AccordionContent';
import themed from '../../themer/themed';

interface IAccordionItemProps {
	title: string | ReactNode;
	children: ReactNode;
}

export const StyledAccordionItem = styled.div<{}, ITheme>`
	border-bottom: ${themed(({ accordion }) => accordion.border)};
	padding: ${themed(({ accordion }) => accordion.title.padding)};
`;

// eslint-disable-next-line react/prefer-stateless-function
export class AccordionItem extends React.Component<IAccordionItemProps> {
	public render() {
		const { title, children } = this.props;

		return (
			<AccordionContext.Consumer>
				{({ onToggle, selectedItem }) => {
					const isExpanded = selectedItem === this;

					const onClick = () => {
						onToggle(this);
					};

					return (
						<StyledAccordionItem role="listitem">
							<AccordionTitle
								onClick={onClick}
								isActive={isExpanded}
								title={title}
							/>
							<AccordionContent isVisible={isExpanded}>
								{children}
							</AccordionContent>
						</StyledAccordionItem>
					);
				}}
			</AccordionContext.Consumer>
		);
	}
}

import { IPathToPageIdMap } from '@move-frontend/contentful-routes';
import { ParkingPageId } from './ParkingPageId';

export const parkingPagePathToPageIdMap: IPathToPageIdMap = {
	// New Booking Flow
	'/parking/booking/new': ParkingPageId.NewBookingAvailability,

	'/parking/booking/new/extras': ParkingPageId.NewBookingExtras,

	'/parking/booking/new/details': ParkingPageId.NewBookingDetails,

	'/parking/booking/new/payment': ParkingPageId.NewBookingPayment,

	'/parking/booking/new/confirmation': ParkingPageId.NewBookingConfirmation,

	// Partial Amend Flow
	'/parking/booking/modify/details': ParkingPageId.PartialAmendBookingDetails,

	// Full Amend Flow
	'/parking/booking/modify': ParkingPageId.FullAmendBookingAvailability,

	'/parking/booking/modify/extras': ParkingPageId.FullAmendBookingExtras,

	'/parking/booking/modify/payment': ParkingPageId.FullAmendBookingPayment,

	'/parking/booking/modify/confirmation':
		ParkingPageId.FullAmendBookingConfirmation,

	// Cancel Flow
	'/parking/booking/cancel/confirmation':
		ParkingPageId.CancelBookingConfirmation,
	'/parking/bookings': ParkingPageId.ParkingBookings,
};

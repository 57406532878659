import React from 'react';
import { AsyncDataPlaceholder } from '../../../../../ui';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { SubmitButton } from '../../../../shared/components';
import { AuthFormActions as Actions } from '../../../shared/form-layout/AuthFormActions';
import { useSignUpFormContext } from './helpers/useSignUpFormContext';

export const SignUpFormActions = () => {
	const { t } = useTranslation();
	const { formState } = useSignUpFormContext();

	return (
		<>
			{formState.isSubmitting && <AsyncDataPlaceholder />}
			<Actions>
				<SubmitButton disabled={formState.isSubmitting}>
					{t('user_sign_up_submit_button')}
				</SubmitButton>
			</Actions>
		</>
	);
};

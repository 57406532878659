export enum GatewayErrorResponseCode {
	ParkingProductsQuoteExpired = 'PARKING_PRODUCTS_QUOTE_EXPIRED',
	InvalidPaymentDetails = 'INVALID_PAYMENT_DETAILS',
	PaymentError = 'PAYMENT_ERROR',
	BookingNotFound = 'BOOKING_NOT_FOUND',
	BookingAlreadyAmended = 'BOOKING_ALREADY_AMENDED',
	ProductNotAvailable = 'PRODUCT_NOT_AVAILABLE',
	BookingAlreadyCancelled = 'BOOKING_ALREADY_CANCELLED',
	DuplicateBooking = 'DUPLICATE_BOOKING',
}

'use client';

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../..';
import { Section } from '../../../features/content-sections/ui/components/Section';
import { useDragScroll } from './useDragScroll';
import CarouselNavigation from './CarouselNavigation';
import { StyledCarouselItem } from './CarouselItem';
import { useTranslation } from '../../../features/shared/i18n/translate/useTranslation';
import Responsive from '../../utils/Responsive';
import themed from '../../themer/themed';

export interface ICarouselProps {
	children?: React.ReactNode;
	desktopCardWidth?: number;
	mobileCardWidth?: number;
	gap?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function Carousel({
	children,
	desktopCardWidth = 486,
	mobileCardWidth = 253,
	gap = 16,
}: ICarouselProps) {
	const [containerRef, { handleMouseDown, handleMouseMove, handleMouseUp }] =
		useDragScroll();
	const [currentIndex, setCurrentIndex] = useState(0);
	const { t } = useTranslation();

	const updateCurrentIndex = () => {
		if (!containerRef.current) return;

		const container = containerRef.current;

		const firstCard = container.querySelector('[data-carousel-card]');

		if (!firstCard) return;

		// Get the width of a single carousel card to calculate scrolling positions.
		const cardWidth = firstCard.getBoundingClientRect().width;

		const beforeWidth =
			container.firstElementChild?.getBoundingClientRect().width || 0;

		const adjustedScrollLeft = container.scrollLeft + beforeWidth;
		const index = Math.round(adjustedScrollLeft / (cardWidth + gap));

		setCurrentIndex(index - 1);
	};

	useEffect(() => {
		const container = containerRef.current;
		container?.addEventListener('scroll', updateCurrentIndex);

		return () => {
			container?.removeEventListener('scroll', updateCurrentIndex);
		};
	}, []);

	const scrollToIndex = (index: number) => {
		if (containerRef.current) {
			const cardWidth =
				containerRef.current.children[0].getBoundingClientRect().width;
			const totalScrollableWidth =
				containerRef.current.scrollWidth - containerRef.current.offsetWidth;

			const targetScrollLeft = Math.min(
				index * (cardWidth + gap),
				totalScrollableWidth,
			);

			containerRef.current.scrollTo({
				left: targetScrollLeft,
				behavior: 'smooth',
			});
		}
	};

	const handlePreviousClick = () =>
		scrollToIndex(Math.max(currentIndex - 1, 0));
	const handleNextClick = () => scrollToIndex(Math.max(currentIndex + 1, 0));
	const handleDotClick = (index: number) => scrollToIndex(index);
	const childrenCount = React.Children.count(children);

	return (
		<>
			<CarouselWrapper
				ref={containerRef}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				onMouseLeave={handleMouseUp}
				onMouseUp={handleMouseUp}
				gap={gap}
				desktopCardWidth={desktopCardWidth}
				mobileCardWidth={mobileCardWidth}
				aria-label={t('carousel_list_aria_label')}
				aria-roledescription="carousel"
			>
				{children}
			</CarouselWrapper>
			{childrenCount > 1 && (
				<NavigationSection>
					<CarouselNavigation
						currentIndex={currentIndex}
						totalItems={React.Children.count(children)}
						onPrev={handlePreviousClick}
						onNext={handleNextClick}
						onDotClick={handleDotClick}
						getKey={(index) => `dot-${index}`}
						previousLabel={t('previous_button')}
						nextLabel={t('next_button')}
					/>
				</NavigationSection>
			)}
		</>
	);
}

interface ICarouselWrapperProps {
	gap: number;
	desktopCardWidth: number;
	mobileCardWidth: number;
}

const CarouselWrapper = styled.ul<ICarouselWrapperProps, ITheme>`
	--gap: ${({ gap }) => gap}px;
	--content-width: ${themed(({ layout }) => layout.main.maxWidth)};
	--card-width: ${({ desktopCardWidth }) => desktopCardWidth}px;

	list-style: none;
	padding: 0;
	margin: 16px 0 0 0;

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		--card-width: ${({ mobileCardWidth }) => mobileCardWidth}px;
		margin-top: 0;
		padding-top: 0;
	}

	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	gap: var(--gap);

	/* Add extra space before the first card to align with centered content */
	&::before {
		content: '';
		min-width: 16px;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			min-width: 4px;
		}

		flex: 0 0 calc((100% - var(--content-width) - 24px) / 2);
	}

	/* Add extra space after the last card to allow scrolling to last card all the way to align left with the content */
	&::after {
		content: '';
		flex: 0 0
			calc(
				(100% - var(--card-width)) -
					(100% - var(--content-width) + var(--gap) * 2) / 2
			);

		@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
			flex: 0 0 calc((100% - var(--card-width)) - 20px * 2);
		}
	}

	&::-webkit-scrollbar {
		display: none;
	}

	/* Firefox */
	scrollbar-width: none;

	${StyledCarouselItem} {
		flex: 0 0 var(--card-width);
	}
`;

const NavigationSection = styled(Section)`
	position: relative;
	padding: 16px 0 0 0;
`;

import React, { FC } from 'react';
import { ArrowLink, IArrowLinkProps } from '../../../../ui';
import { useUrlForPageId } from '../../../app/pages/ui/utils/useUrlForPageId';

export interface IPageArrowLinkProps extends Omit<IArrowLinkProps, 'href'> {
	pageId: string;
}

export const PageArrowLink: FC<IPageArrowLinkProps> = ({ pageId, ...rest }) => {
	const href = useUrlForPageId(pageId);

	return <ArrowLink href={href} {...rest} />;
};

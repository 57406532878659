import styled from '@emotion/styled';

/**
 * Visually hidden element, but still accessible for screen readers.
 */
export const VisuallyHidden = styled.div<{
	as?: 'div' | 'span';
}>`
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
`;

import {
	action,
	computed,
	IReactionDisposer,
	makeObservable,
	observable,
	reaction,
	runInAction,
} from 'mobx';
import {
	FlightEnrichStore,
	FlightFilterStore,
	FlightSortStore,
	FlightStore,
} from '@move-frontend/dap';
import { IAsyncStore } from '@move-frontend/utils';
import { moment } from '../../../../shared/i18n/date-time/moment';
import { FlightListScope } from '../constants/FlightListScope';
import { createFlightSearchFilter } from './createFlightSearchFilter';
import { logError } from '../../../../shared/error/logError';
import {
	IEnrichedFlight,
	IEnrichedFlightProps,
} from '../../../shared/flight/domain/models/IEnrichedFlight';
import { IFlight } from '../../../shared/flight/domain/models/IFlight';
import { Moment } from 'moment';

export class FlightListStore implements IAsyncStore {
	protected _selectedDay = moment();

	protected showFromTime = moment();

	protected showUntilTime = moment();

	protected _minimumListLength = 10;

	private _scope: FlightListScope = FlightListScope.All;

	private _query = '';

	private _loadedFromIndex = -1;

	private _loadedAmount = 0;

	private disposeErrorReaction: IReactionDisposer;

	constructor(
		protected readonly flightStore: FlightStore<IFlight>,
		protected readonly flightEnrichStore: FlightEnrichStore<
			IFlight,
			IEnrichedFlightProps
		>,
		protected readonly flightFilterStore: FlightFilterStore<IEnrichedFlight>,
		protected readonly flightSortStore: FlightSortStore<IEnrichedFlight>,
	) {
		makeObservable<
			FlightListStore,
			| '_selectedDay'
			| 'showFromTime'
			| 'showUntilTime'
			| '_scope'
			| '_query'
			| '_loadedFromIndex'
			| '_loadedAmount'
			| 'showFromIndex'
			| 'showUntilIndex'
			| 'updateFromFlightWindow'
			| 'updateUntilFlightWindow'
		>(this, {
			_selectedDay: observable,
			showFromTime: observable,
			showUntilTime: observable,
			_scope: observable,
			_query: observable,
			_loadedFromIndex: observable,
			_loadedAmount: observable,
			finishedInitialLoad: computed,
			isLoading: computed,
			error: computed,
			enrichedFlights: computed,
			filteredFlights: computed,
			sortedFlights: computed,
			flights: computed,
			totalResults: computed,
			canNavigateBackward: computed,
			canNavigateForward: computed,
			showFromIndex: computed,
			showUntilIndex: computed,
			setScope: action,
			setQuery: action,
			loadDay: action,
			updateFromFlightWindow: action,
			updateUntilFlightWindow: action,
			showEarlier: action,
			showLater: action,
			reset: action,
		});

		this.disposeErrorReaction = reaction(
			() => this.flightStore.error,
			this.onError,
		);
	}

	public dispose() {
		this.disposeErrorReaction();
	}

	public get selectedDay() {
		return this._selectedDay;
	}

	public get query() {
		return this._query;
	}

	public get loadedFromIndex() {
		return this._loadedFromIndex;
	}

	public get loadedAmount() {
		return this._loadedAmount;
	}

	public get minimumListLength() {
		return this._minimumListLength;
	}

	public get finishedInitialLoad() {
		return this.flightStore.finishedInitialLoad;
	}

	public get isLoading() {
		return this.flightStore.isLoading;
	}

	public get error() {
		return this.flightStore.error;
	}

	public get enrichedFlights() {
		return this.flightEnrichStore.enrich(
			this.flightStore.flights,
		) as IEnrichedFlight[];
	}

	public get filteredFlights() {
		return this.flightFilterStore.filter(this.enrichedFlights);
	}

	public get sortedFlights() {
		return this.flightSortStore.sort(this.filteredFlights);
	}

	public get flights() {
		return this.sortedFlights.slice(this.showFromIndex, this.showUntilIndex);
	}

	public get totalResults() {
		return this.sortedFlights.length;
	}

	public get canNavigateBackward() {
		return this.showFromIndex > 0;
	}

	public get canNavigateForward() {
		return this.showUntilIndex < this.totalResults;
	}

	protected get showFromIndex() {
		const timestamp = this.showFromTime.unix();
		const index = this.findIndexOfFirstFlightScheduledAfter(timestamp);
		return index === -1 ? this.totalResults : index;
	}

	protected get showUntilIndex() {
		const timestamp = this.showUntilTime.unix();
		const index = this.findIndexOfFirstFlightScheduledAfter(timestamp);
		return index === -1 ? this.totalResults : index;
	}

	public get scope() {
		return this._scope;
	}

	public setScope(scope: FlightListScope) {
		this._loadedFromIndex = -1;
		this._scope = scope;

		if (scope === FlightListScope.All) {
			this.flightFilterStore.removeFieldFilter('arrival');
		} else if (scope === FlightListScope.Arrivals) {
			this.flightFilterStore.setFieldFilter('arrival', true);
		} else if (scope === FlightListScope.Departures) {
			this.flightFilterStore.setFieldFilter('arrival', false);
		}
	}

	public setQuery(query: string) {
		this._loadedFromIndex = -1;
		const trimmedQuery = query.trim();
		this._query = trimmedQuery;

		this.showUntilTime = this.showFromTime.clone();

		if (trimmedQuery === '') {
			this.flightFilterStore.removeFilter('query-filter');
		} else {
			const queryFilter = createFlightSearchFilter(trimmedQuery);
			this.flightFilterStore.setFilter('query-filter', queryFilter);
		}

		this.updateUntilFlightWindow();
	}

	public async loadDay(day: Moment = moment()) {
		this._loadedFromIndex = -1;
		const now = moment();
		const startFrom = day.isSame(now, 'day') ? now : day.clone().startOf('day');

		const from = day.clone().startOf('day');
		const to = day.clone().endOf('day');

		this._selectedDay = day;

		await this.flightStore.loadBetween(from.unix(), to.unix());

		runInAction(() => {
			this.showFromTime = startFrom;
			this.showUntilTime = startFrom;
			this.updateUntilFlightWindow();
		});
	}

	protected updateFromFlightWindow() {
		const from = this.showFromTime;
		const fromIndex = this.showFromIndex;
		const startOfSelectedDay = this.selectedDay.clone().startOf('day');
		let hours = 1;

		while (
			this.minimumListLength >
				fromIndex -
					this.findIndexOfFirstFlightScheduledAfter(
						from.clone().subtract(hours, 'hours').unix(),
					) &&
			from.clone().subtract(hours, 'hours') > startOfSelectedDay
		) {
			hours += 1;
		}

		this.showFromTime =
			from.clone().subtract(hours, 'hours') < startOfSelectedDay
				? startOfSelectedDay
				: from.clone().subtract(hours, 'hours');
	}

	protected updateUntilFlightWindow() {
		const until = this.showUntilTime;
		const untilIndex = this.showUntilIndex;
		const endOfSelectedDay = this.selectedDay.clone().endOf('day');
		let hours = 1;

		while (
			this.minimumListLength >
				this.findIndexOfFirstFlightScheduledAfter(
					until.clone().add(hours, 'hours').unix(),
				) -
					untilIndex &&
			endOfSelectedDay > until.clone().add(hours, 'hours')
		) {
			hours += 1;
		}

		this.showUntilTime =
			until.clone().add(hours, 'hours') > endOfSelectedDay
				? endOfSelectedDay
				: until.clone().add(hours, 'hours');
	}

	protected findIndexOfFirstFlightScheduledAfter(timestamp: number) {
		return this.sortedFlights.findIndex(
			(flight) => flight.scheduledTimestamp >= timestamp,
		);
	}

	public showEarlier() {
		const lengthBefore = this.flights.length;
		this.updateFromFlightWindow();
		this._loadedFromIndex = this.flights.length - lengthBefore;
		this._loadedAmount = this._loadedFromIndex;
	}

	public showLater() {
		this._loadedFromIndex = this.flights.length;
		this.updateUntilFlightWindow();
		this._loadedAmount = this.flights.length - this.loadedFromIndex;
	}

	public reset() {
		this._selectedDay = moment();
		this.showFromTime = moment();
		this.showUntilTime = moment();

		this._scope = FlightListScope.All;
		this._query = '';
		this._loadedFromIndex = -1;

		this.flightFilterStore.reset();

		return this.flightStore.reset();
	}

	private onError = () => {
		if (this.error) {
			logError(this.error, {});
		}
	};
}

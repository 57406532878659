import { RemoteDataStore } from '@move-frontend/utils';
import { action, makeObservable, observable } from 'mobx';
import { ICaptureContextResponse } from '../../../shared/api/gateway/types/ICaptureContextResponse';
import { CaptureContextSessionService } from '../../../shared/api/gateway/services/CaptureContextSessionService';

type RequestParams = Record<never, never>;

export class CaptureContextStore extends RemoteDataStore<
	RequestParams,
	ICaptureContextResponse
> {
	private _value: string | undefined = undefined;

	constructor(private readonly service: CaptureContextSessionService) {
		super();

		makeObservable<CaptureContextStore, 'handleResponse' | '_value'>(this, {
			handleResponse: action,
			clear: action,
			_value: observable,
		});
	}

	public load(params: RequestParams = {}) {
		return super.load(params);
	}

	protected performRequest(): Promise<ICaptureContextResponse> {
		return this.service.create('microform');
	}

	protected handleResponse(response: ICaptureContextResponse): void {
		this._value = response.jwt;
	}

	get value() {
		return this._value;
	}

	clear() {
		this._value = undefined;
	}
}

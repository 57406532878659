/* eslint-disable react/no-danger */
import React, { memo } from 'react';
import Head from 'next/head';
import { getAnalyticsConfig } from './shared/config/getAnalyticsConfig';

export const GoogleAnalyticsScript = memo(() => {
	const { legacyTrackingId, trackingId, googleAdsTrackingId, adaraTrackingId } =
		getAnalyticsConfig();

	if (!legacyTrackingId && !trackingId) return null;

	return (
		<>
			<Head>
				<script
					async
					src={`https://www.googletagmanager.com/gtag/js?id=${
						legacyTrackingId ?? trackingId
					}`}
				/>
				<script
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						// eslint-disable-next-line @typescript-eslint/naming-convention
						__html: `
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							${legacyTrackingId && `gtag('config', '${legacyTrackingId}');`}
							${trackingId && `gtag('config', '${trackingId}');`}
							${googleAdsTrackingId && `gtag('config', '${googleAdsTrackingId}');`}
							${adaraTrackingId && `gtag('config', '${adaraTrackingId}');`}
					`,
					}}
				/>
			</Head>
		</>
	);
});

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import {
	HeaderParentTitle,
	IHeaderParentTitleProps,
} from './HeaderParentTitle';
import { StyledHeaderTitle } from './StyledHeaderTitle';
import { IStyledHeaderProps } from './IStyledHeaderProps';
import { getBackgroundColor, getTextColor } from './helpers';
import ContentWrap from '../Layout/ContentWrap';

export interface IHeaderProps extends IHeaderParentTitleProps {
	title?: string;
	className?: string;
	children?: ReactNode;
}

const StyledHeader = styled.div<IStyledHeaderProps, ITheme>`
	background: ${getBackgroundColor};
	color: ${getTextColor};
`;

export const Header = ({
	title,
	parentTitle,
	parentUrl,
	inverted,
	className,
	children,
}: IHeaderProps) => (
	<StyledHeader className={className}>
		<ContentWrap>
			<HeaderParentTitle
				parentTitle={parentTitle}
				parentUrl={parentUrl}
				inverted={inverted}
			/>
			{title && (
				<StyledHeaderTitle inverted={inverted}>{title}</StyledHeaderTitle>
			)}
			{children}
		</ContentWrap>
	</StyledHeader>
);

import React from 'react';
import { CallLinkButton } from '../Link';

export interface ICallToActionLinkPhoneProps {
	title: string;
	number: string;
	inverted?: boolean;
}

export const CallToActionLinkPhone = ({
	title,
	number,
	inverted,
}: ICallToActionLinkPhoneProps) => (
	<CallLinkButton number={number} inverted={inverted}>
		{title}
	</CallLinkButton>
);

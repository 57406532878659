import { Z_INDEX_HEADER_OVERLAP } from '../../styles';
import {
	HEADER_MARGIN_BOTTOM_DESKTOP,
	HEADER_MARGIN_BOTTOM_MOBILE,
	HEADER_PADDING_BOTTOM,
	HEADER_PADDING_BOTTOM_MOBILE,
} from './Header';
import Responsive from '../../../../ui/utils/Responsive';

export function getOverlapWithHeaderStyles() {
	return `
		position: relative;
		z-index: ${Z_INDEX_HEADER_OVERLAP};
		margin-top: calc(-${HEADER_PADDING_BOTTOM} - ${HEADER_MARGIN_BOTTOM_DESKTOP});

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			margin-top: calc(-${HEADER_PADDING_BOTTOM_MOBILE} - ${HEADER_MARGIN_BOTTOM_MOBILE});
		}
	`;
}

import { useEffect, useState } from 'react';
import { IBreakpointName } from '../../../ui';
import Responsive from '../../../ui/utils/Responsive';

export function useHideChatBot(breakPoint: IBreakpointName) {
	const isBreakPoint = Responsive.useMatchesBreakpoint(breakPoint);
	const [chatBotEl, setChatBotEl] = useState<HTMLDivElement>();

	useEffect(() => {
		const el = document.getElementById('airportAI-root') as HTMLDivElement;
		let observer: MutationObserver;

		if (el) {
			setChatBotEl(el);
		} else {
			// wait until element is created
			observer = new MutationObserver((mutationsList) => {
				mutationsList.forEach((mutation) => {
					mutation.addedNodes.forEach((addedNode) => {
						if ((addedNode as HTMLDivElement).id === 'airportAI-root') {
							setChatBotEl(addedNode as HTMLDivElement);
							observer.disconnect();
						}
					});
				});
			});

			observer.observe(document.body, {
				subtree: false,
				childList: true,
			});
		}

		return () => {
			if (observer) {
				observer.disconnect();
			}
		};
	}, []);

	const displayChatBotEl = () => {
		if (chatBotEl) {
			chatBotEl.style.display = '';
		}
	};

	useEffect(() => {
		if (isBreakPoint && chatBotEl) {
			chatBotEl.style.display = 'none';
		} else {
			displayChatBotEl();
		}
	}, [isBreakPoint, chatBotEl]);
}

import React, { ReactElement, ReactNode } from 'react';
import { isElement } from 'react-is';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MarkdownParagraph = (props: any) => {
	const { children } = props;

	if (isImageParagraph(children)) {
		// Render images without paragraph wrapper (full-width)
		return children;
	}

	return <p>{children}</p>;
};

function isImageParagraph(input: ReactNode) {
	if (isSingleImageElement(input)) return true;

	return isSingleLinkWithSingleImageElement(input);
}

function isSingleImageElement(input: ReactNode) {
	const singleElement = validateSingleReactElement(input);

	if (!singleElement) return false;

	return isImageElement(singleElement);
}

function validateSingleReactElement(
	input: ReactNode,
): ReactElement | undefined {
	if (!Array.isArray(input)) return validateReactElement(input);

	if (input.length !== 1) return undefined;

	return validateReactElement(input[0]);
}

function validateReactElement(input: ReactNode): ReactElement | undefined {
	return isElement(input) ? input : undefined;
}

function isImageElement(input: ReactElement) {
	return 'src' in input.props;
}

function isSingleLinkWithSingleImageElement(input: ReactNode) {
	const singleElement = validateSingleReactElement(input);

	if (!singleElement || !isLinkElement(singleElement)) return false;

	const { children } = singleElement.props;

	const singleChildElement = validateSingleReactElement(children);

	return !!singleChildElement && isImageElement(singleChildElement);
}

function isLinkElement(input: ReactElement) {
	return 'href' in input.props;
}

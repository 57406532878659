import { AbstractService } from './AbstractService';
import {
	ICustomerDetailsResponse,
	IPromoCodeResponse,
} from '../types/ICustomerDetailsResponse';

export class AccountService extends AbstractService {
	public getCustomerDetails() {
		return this.client.get<ICustomerDetailsResponse>('/account/customer');
	}

	public deleteCustomer() {
		return this.client.delete<void>('/account/customer');
	}

	public generatePromoCode(offerId: string) {
		return this.client.get<IPromoCodeResponse>(
			`/account/generate-promo-code?offerId=${offerId}`,
		);
	}
}

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ITextFieldProps, TextField } from '../../../../ui';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';

export const ConfirmLicensePlateNumber = (props: Partial<ITextFieldProps>) => {
	const { t } = useTranslation();
	const { register, errors } = useFormContext();

	return (
		<TextField
			name="confirmLicensePlateNumber"
			type="text"
			label={t('user_form_confirm_license_plate_number_label')}
			placeholder={t('user_form_confirm_license_plate_number_placeholder')}
			ref={register()}
			invalid={!!errors.confirmLicensePlateNumber}
			error={errors.confirmLicensePlateNumber?.message}
			autoComplete="off"
			{...props}
		/>
	);
};

import { hasRequiredFields } from '@move-frontend/utils';
import { ISignInFormData } from '../../domain/models/ISignInFormData';
import { useSignInFormContext } from './useSignInFormContext';

export function useSignInHasRequiredFields() {
	const { watch } = useSignInFormContext();
	const values: Partial<ISignInFormData> = watch();

	return hasRequiredFields(values, ['email', 'password']);
}

import log from 'loglevel';
import { cognitoClient } from '../../../../shared/api/cognito';
import { ICognitoUser } from '../../../../shared/api/cognito/types/ICognitoUser';
import { AuthState } from './AuthState';

export async function getAuthState() {
	let user: ICognitoUser;

	try {
		user = await cognitoClient.getCurrentUser();
	} catch (error) {
		log.debug('getAuthenticatedUser error:', error);
		return AuthState.Unauthenticated;
	}

	log.debug('Authenticated user:', user);

	return user ? AuthState.Authenticated : AuthState.Unauthenticated;
}

import { Contentful } from '@move-frontend/contentful';
import log from 'loglevel';
import { getConfig } from './config';
import { IPageSectionEntry } from './types';
import { isPreviewMode } from '../../../app/preview/utils/isPreviewMode';

let client: Contentful<IPageSectionEntry>;

export const contentful = () => {
	if (client) {
		return client;
	}

	if (isPreviewMode()) {
		log.info('🔮 Preview mode enabled');
	}

	const config = getConfig();

	client = new Contentful<IPageSectionEntry>(config);
	return client;
};

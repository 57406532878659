'use client';

import React, { useEffect, useState } from 'react';
import { ArrowIcon, LinkText, useTheme } from '../../../../ui';
import styled from '@emotion/styled';
import { INewsAlertEntry } from '../../api/contentful/types';
import { Dialog, DialogHeader, DialogMain } from '../dialog';
import { useTranslation } from '../../i18n/translate/useTranslation';
import { StyledDialogContent } from '../dialog/DialogContent';
import {
	getSessionStorageItem,
	setSessionStorageItem,
} from '../../utils/sessionStorage';
import Responsive from '../../../../ui/utils/Responsive';
import themed from '../../../../ui/themer/themed';

interface IAlertDialogProps {
	onClose: () => void;
	newsAlert: INewsAlertEntry;
}

export const AlertDialog = ({ onClose, newsAlert }: IAlertDialogProps) => {
	const [isDialogClosed, setIsDialogClosed] = useState(true);

	useEffect(() => {
		const dialogClosedInSection = !!getSessionStorageItem('news-dialog-closed');
		setIsDialogClosed(dialogClosedInSection);
	}, []);

	const theme: any = useTheme();
	const IconComponent = theme.link.ArrowLinkIcon ?? ArrowIcon;
	const { t } = useTranslation();

	const url = newsAlert.fields.link || undefined;
	const defaultLinkLabel = url
		? t('newsroom_emergency_information_read_more')
		: t('newsroom_emergency_information_dismiss');

	const onCloseDialog = () => {
		setSessionStorageItem('news-dialog-closed', true.toString());
		onClose();
	};

	if (isDialogClosed) return null;

	return (
		<StyledDialog
			onClose={onCloseDialog}
			ariaLabel={t('newsroom_emergency_information_title')}
			fullWidthMobile={false}
		>
			<StyledDialogHeader title={newsAlert.fields.title} />
			<StyledDialogMain>
				<StyledModalBody>{newsAlert.fields.body}</StyledModalBody>
				<StyledReadMoreLink href={url} onClick={url ? undefined : onClose}>
					<LinkText>{newsAlert.fields.linkLabel || defaultLinkLabel}</LinkText>
					{url && <IconComponent aria-hidden />}
				</StyledReadMoreLink>
			</StyledDialogMain>
		</StyledDialog>
	);
};

const StyledDialog = styled(Dialog)`
	${StyledDialogContent} {
		border-radius: 12px;
	}
`;

const StyledDialogMain = styled(DialogMain)`
	padding: 10px 0 40px;
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding-top: 8px;
		display: flex;
		flex-direction: column;
	}
`;

const StyledDialogHeader = styled(DialogHeader)`
	font-size: 34px;
	font-weight: bold;
	line-height: 38px;

	h3 {
		max-width: 636px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: space-between;
		font-size: 22px;
		h3 {
			margin: 0;
		}
		button {
			height: 24px;
			line-height: 0;
			width: 24px;
		}
	}
`;

const StyledModalBody = styled.div`
	font-size: 18px;
	color: ${themed(({ color }) => color.text.default)};
	margin-bottom: 24px;
	line-height: 21px;
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 16px;
	}
`;

const StyledReadMoreLink = styled.a`
	display: inline-flex;
	align-items: center;

	span {
		font-size: 18px;
		font-weight: 400;
		line-height: 21.6px;
	}
	&:hover {
		text-decoration: underline;
	}

	svg {
		margin-left: 12px;
		width: 16px;

		g {
			stroke: ${themed(({ color }) => color.text.hover)};
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		align-self: flex-end;
	}
`;

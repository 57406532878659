import { IAsyncStore } from '@move-frontend/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { CheckpointQueueStore } from './CheckpointQueueStore';
import { CheckpointQueueAccessType } from '../models/CheckpointQueueAccessType';
import { IQueueList } from '../models/IQueueList';
import { ITranslationKey } from '../../../shared/i18n/translate/models/ITranslationKey';

const DEFAULT_ACCESS_TYPE = CheckpointQueueAccessType.General;

const accessTypeTitleTranslationKeys: {
	[key in CheckpointQueueAccessType]: ITranslationKey;
} = {
	[CheckpointQueueAccessType.General]:
		'security_checkpoint_standard_checkpoints',
	[CheckpointQueueAccessType.PreCheck]:
		'security_checkpoint_pre_check_checkpoints',
	[CheckpointQueueAccessType.Priority]:
		'security_checkpoint_priority_checkpoints',
};

export class CheckpointQueueListStore implements IAsyncStore {
	private _accessType: CheckpointQueueAccessType = DEFAULT_ACCESS_TYPE;

	constructor(protected readonly checkpointQueueStore: CheckpointQueueStore) {
		makeObservable<CheckpointQueueListStore, '_accessType'>(this, {
			_accessType: observable,
			isLoading: computed,
			finishedInitialLoad: computed,
			error: computed,
			checkpointQueueLists: computed,
			accessType: computed,
			setAccessType: action,
			reset: action,
		});

		this.setAccessType(DEFAULT_ACCESS_TYPE);
	}

	public get isLoading() {
		return this.checkpointQueueStore.isLoading;
	}

	public get finishedInitialLoad() {
		return this.checkpointQueueStore.finishedInitialLoad;
	}

	public get error() {
		return this.checkpointQueueStore.error;
	}

	public get checkpointQueueLists(): IQueueList[] {
		return Object.values(CheckpointQueueAccessType)
			.filter(
				(filter) =>
					this.accessType === filter || this.accessType === DEFAULT_ACCESS_TYPE,
			)
			.map((filter) => ({
				titleTranslationKey: accessTypeTitleTranslationKeys[filter],
				accessType: filter,
				checkpointQueues: this.checkPointsByAccessType(filter),
			}));
	}

	private checkPointsByAccessType(type: CheckpointQueueAccessType) {
		return this.checkpointQueueStore.checkpointQueues.filter((checkpoint) =>
			checkpoint.accessTypes.includes(type),
		);
	}

	public get accessType() {
		return this._accessType;
	}

	public async load() {
		return this.checkpointQueueStore.load();
	}

	public setAccessType(accessType: CheckpointQueueAccessType) {
		this._accessType = accessType;
	}

	public async reset() {
		this.setAccessType(DEFAULT_ACCESS_TYPE);

		await this.checkpointQueueStore.reset();
	}
}

import React from 'react';
import styled from '@emotion/styled';
import { Link } from './Link';
import { StyledButton } from '../Button/Button';
import { IArrowButtonProps } from '../Button/ArrowButton';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import { LinkText } from './LinkText';

interface IArrowLinkButtonProps extends IArrowButtonProps {
	href: string;
	rel?: string;
	target?: string;
	secondary?: boolean;
	icon?: React.ReactNode;
}

export const StyledLinkButton = styled(StyledButton.withComponent('div'))<
	{},
	ITheme
>`
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		text-decoration: none;
		height: inherit;
		display: flex;
		align-items: center;
		padding: ${themed(({ button }) => button.padding)};

		:focus-visible {
			text-decoration: none;
			box-shadow: none;
		}
	}

	svg {
		g {
			stroke: ${themed(({ button }) => button.primary.font.color)};
		}
	}
`;

export const LinkButton = ({
	secondary,
	children,
	href,
	target,
	rel,
	className,
	icon,
}: IArrowLinkButtonProps) => (
		<StyledLinkButton secondary={secondary} className={className}>
			<Link href={href} target={target} rel={rel} inverted={!secondary}>
				<LinkText>{children}</LinkText>
				{icon}
			</Link>
		</StyledLinkButton>
	);

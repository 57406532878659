import { RemoteDataStore } from '@move-frontend/utils';
import { action, makeObservable, observable } from 'mobx';
import { Dap } from '../../../shared/api/dap';

import { IBusWaitingTimesResponse } from '../../../shared/api/dap/types/IBusWaitingTimesResponse';
import { IBusWaitingTimes } from '../models/IBusWaitingTimes';
import { OrderBusStops } from '../use-cases/orderBusStops';
import { MapStopResponse } from './adapters/mapBusStopResponse';
import { FilterStopResponce } from './filters/createFilterStopResponse';

interface IRequestParams {
	ignoreCache?: boolean;
}
export class BusWaitingTimesStore extends RemoteDataStore<
	IRequestParams,
	IBusWaitingTimesResponse
> {
	lastUpdate: number = getCurrentTimestampInSeconds();

	readonly stops = observable<IBusWaitingTimes>([]);

	constructor(
		private readonly dap: Dap,
		private readonly isRestricted: boolean,
		private readonly filterResponse: FilterStopResponce,
		private readonly mapStopResponse: MapStopResponse,
		private orderBusStops: OrderBusStops,
	) {
		super();

		makeObservable<BusWaitingTimesStore, 'handleResponse'>(this, {
			load: action,
			handleResponse: action,
			clear: action,
			stops: observable,
			lastUpdate: observable,
		});
	}

	public async load(params: IRequestParams): Promise<void | undefined> {
		if (this.currentRequest) {
			await this.currentRequest;
			return;
		}

		await super.load(params);
	}

	protected performRequest(
		_params: IRequestParams,
	): Promise<IBusWaitingTimesResponse> {
		return this.dap.busWaitTimes().getBusWaitTimes();
	}

	protected handleResponse(response: IBusWaitingTimesResponse): void {
		const stops = this.mapStopResponse(response);
		const filterStops = this.filterResponse(stops, this.isRestricted);
		const orderedStops = this.orderBusStops(filterStops);

		this.lastUpdate = getCurrentTimestampInSeconds();

		this.stops.replace(orderedStops);
	}

	clear(): void {
		this.stops.clear();
	}
}

function getCurrentTimestampInSeconds() {
	return Math.round(Date.now() / 1000);
}

import React, { ComponentType, MouseEvent, ReactNode } from 'react';
import styled from '@emotion/styled';
import {
	CardContainerContext,
	ICardContainerContextValues,
} from './CardContainerContext';
import { StyledCardPicture } from './StyledCardPicture';
import { StyledCardContent } from './StyledCardContent';
import { StyledCardChildren } from './StyledCardChildren';
import { StyledCardLink } from './StyledCardLink';
import { IResponsiveImageSource } from '../Image';
import { isRelativeUrl, StyledLinkText } from '../Link';
import { IStyledCardProps, StyledCard } from './StyledCard';
import { useOnClickCardHandler } from './useOnClickCardHandler';
import { StyledCardTitle } from './StyledCardTitle';
import { HeaderLevel } from '../Headings';
import { useTheme } from '../../themer';
import { ArrowIcon } from '../Icon';
import Responsive from '../../utils/Responsive';
import { UnstyledButton, UnstyledLi } from '../List';
import { PolyComponent } from '../../types';

interface ICardProps {
	children?: ReactNode;
	title: string;
	screenReaderLinkTitle?: string;
	linkTitle?: string;
	linkHref: string;
	imageSource: IResponsiveImageSource;
	imageAlt?: string;
	className?: string;
	onClick?: (e: MouseEvent<HTMLLIElement>) => void;
	titleHeaderLevel?: HeaderLevel;
	titleAsLinkCard?: boolean;
	fullyClickable?: boolean;
}

export const withCardContainerContext =
	<T extends {} = {}>(
		Component: ComponentType<T & ICardContainerContextValues>,
	) =>
	(props: T) =>
		(
			<CardContainerContext.Consumer>
				{(contextValues: ICardContainerContextValues) => (
					<Component {...contextValues} {...props} />
				)}
			</CardContainerContext.Consumer>
		);

export const Card = withCardContainerContext<ICardProps>(
	({
		children,
		title,
		linkTitle,
		screenReaderLinkTitle,
		linkHref,
		imageSource,
		imageAlt,
		fullyClickable,
		onClick,
		titleHeaderLevel,
		titleAsLinkCard,
		...cardProps
	}) => {
		const onClickUrl = fullyClickable ? linkHref : undefined;
		const defaultOnClick = useOnClickCardHandler(onClickUrl);
		const finalLinkTitle = linkTitle === undefined ? 'Read more' : linkTitle;

		const theme: any = useTheme();
		const IconComponent = theme.link.ArrowLinkIcon ?? ArrowIcon;

		return (
			<StyledPolyCard
				as={fullyClickable ? UnstyledButton : UnstyledLi}
				fullyClickable={fullyClickable}
				titleAsLinkCard={titleAsLinkCard}
				{...(fullyClickable && { onClick: onClick ?? defaultOnClick })}
				{...(fullyClickable && { tabIndex: 0 })}
				{...cardProps}
			>
				<StyledCardPicture source={imageSource} alt={imageAlt ?? title} />

				<StyledCardContent>
					<StyledCardTitleWrap>
						<StyledCardTitle styling={titleHeaderLevel || 'h3'}>
							{title}
						</StyledCardTitle>
						{titleAsLinkCard && <IconComponent aria-hidden />}
					</StyledCardTitleWrap>
					<StyledCardChildren>{children}</StyledCardChildren>
					{!titleAsLinkCard &&
						(fullyClickable ? (
							<StyledNonClickableCardLink
								as="div"
								className="non-clickable-card-link"
							>
								{finalLinkTitle}
							</StyledNonClickableCardLink>
						) : (
							<StyledCardLink
								href={linkHref}
								screenReaderTitle={screenReaderLinkTitle}
								target={!isRelativeUrl(linkHref) ? '_blank' : undefined}
							>
								{finalLinkTitle}
							</StyledCardLink>
						))}
				</StyledCardContent>
			</StyledPolyCard>
		);
	},
);

const StyledPolyCard: PolyComponent<'div', IStyledCardProps> = StyledCard;

const StyledCardTitleWrap = styled.div`
	display: flex;
	align-items: center;

	${StyledCardTitle} {
		flex: 1;
	}

	svg {
		width: 32px;
		height: 32px;

		g {
			stroke: #0077c8;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		svg {
			width: 24px;
			height: 24px;
		}
	}
`;

const StyledNonClickableCardLink = styled(StyledCardLink)`
	width: fit-content;

	${StyledLinkText} {
		width: unset;
	}
`;

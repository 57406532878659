import { TokenizedCardsStore } from './TokenizedCardsStore';
import { Gateway } from '../../../shared/api/gateway/Gateway';
import { CaptureContextStore } from './CaptureContextStore';

export function createPaymentStores(gateway: Gateway) {
	const tokenizedCardsStore = new TokenizedCardsStore(gateway.tokenizedCard());
	const captureContextStore = new CaptureContextStore(
		gateway.captureContextSession(),
	);

	return {
		tokenizedCardsStore,
		captureContextStore,
	};
}

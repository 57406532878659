import React from 'react';
import ReactMarkdown from 'react-markdown';
import githubFlavoredMarkdown from 'remark-gfm';
import { MarkdownRenderContext } from './RenderContext';
import { IMarkdownRenderers } from './IMarkdownRenderers';

interface IProps {
	source: string;
}

export const Markdown = ({ source }: IProps) => (
	<MarkdownRenderContext.Consumer>
		{(renderers: IMarkdownRenderers) => (
			<ReactMarkdown plugins={[githubFlavoredMarkdown]} components={renderers}>
				{source}
			</ReactMarkdown>
		)}
	</MarkdownRenderContext.Consumer>
);

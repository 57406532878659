import styled from '@emotion/styled';
import { Link, StyledIconLink, StyledLinkText } from '../Link';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import { FixedGridItem } from '../Grid';
import Responsive from '../../utils/Responsive';

export const QuickLinkItem = styled(FixedGridItem)<{}, ITheme>`
	margin-top: 0;

	${themed(
		({ quickLink }) => `
		border-bottom: ${quickLink.item.border.width} solid ${quickLink.item.border.color};
		box-shadow: 0px calc(-1 * ${quickLink.item.border.width}) 0px 0px ${quickLink.item.border.color};
	`,
	)};

	${Link},
	${StyledIconLink} {
		display: flex;
		justify-content: space-between;
		padding: ${themed(({ quickLink }) => quickLink.item.padding.desktop)};

		&:hover {
			text-decoration: none;

			&:after {
				display: none;
			}
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			padding: ${themed(({ quickLink }) => quickLink.item.padding.mobile)};
		}

		svg {
			min-width: 2em;
			height: 2em;
		}
	}

	${StyledLinkText} {
		line-height: 2em;

		// IE11 fix
		display: inline-block;
		flex-grow: 1;
	}
`;

import React from 'react';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { SubmitButton } from '../../../../shared/components';
import { SIGN_IN_DIALOG_HASH } from '../../../sign-in/ui/SignInDialog';
import { useForgotPasswordFormContext } from './helpers/useForgotPasswordFormContext';
import { useForgotPasswordHasRequiredFields } from './helpers/useForgotPasswordHasRequiredFields';
import { AuthFormActions as Actions } from '../../../shared/form-layout/AuthFormActions';
import { Link } from '../../../../../ui';

interface IProps {
	hideBackLink?: boolean;
	customButtonText?: string;
}

export const ForgotPasswordFormActions = ({
	hideBackLink,
	customButtonText,
}: IProps) => {
	const { t } = useTranslation();
	const { formState } = useForgotPasswordFormContext();

	const hasRequiredFields = useForgotPasswordHasRequiredFields();
	const disabled = !hasRequiredFields || formState.isSubmitting;

	return (
		<Actions>
			<SubmitButton disabled={disabled}>
				{customButtonText ?? t('user_forgot_password_submit_button')}
			</SubmitButton>
			{!hideBackLink && (
				<Link href={SIGN_IN_DIALOG_HASH}>
					{t('user_forgot_password_back_button')}
				</Link>
			)}
		</Actions>
	);
};

import { IStyledComponentSelector } from '../../../../../ui';
import { StyledImagePageHeader } from './ImagePageHeader';
import {
	HEADER_MARGIN_BOTTOM_DESKTOP,
	HEADER_MARGIN_BOTTOM_MOBILE,
} from '../Header';
import { getOverlapWithHeaderStyles } from '../getOverlapWithHeaderStyles';
import Responsive from '../../../../../ui/utils/Responsive';

export function getOverlapWithImagePageHeaderStyles(
	componentSelector: IStyledComponentSelector,
	overlapDesktop: string,
	overlapMobile: string,
) {
	return `
		${StyledImagePageHeader} {
			padding-bottom: ${overlapDesktop};
		}

		${componentSelector} {
			${getOverlapWithHeaderStyles()}
			margin-top: calc(-${overlapDesktop} - ${HEADER_MARGIN_BOTTOM_DESKTOP});
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			${StyledImagePageHeader} {
				padding-bottom: ${overlapMobile};
			}

			${componentSelector} {
				margin-top: calc(-${overlapMobile} - ${HEADER_MARGIN_BOTTOM_MOBILE});
			}
		}
	`;
}

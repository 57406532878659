import React from 'react';
import {
	CognitoErrorCode,
	hasCognitoErrorCode,
	MaybeCognitoError,
} from '@move-frontend/auth';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';

interface IForgotPasswordErrorMessage {
	error: MaybeCognitoError;
}

export const ForgotPasswordErrorMessage = ({
	error,
}: IForgotPasswordErrorMessage) => {
	const { t } = useTranslation();

	return (
		<>
			{hasCognitoErrorCode(error, CognitoErrorCode.UserNotFoundException)
				? t('user_forgot_password_user_not_found_error')
				: t('user_forgot_password_generic_error')}
		</>
	);
};

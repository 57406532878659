import React, { Component } from 'react';

interface IAccordionContextValues {
	onToggle: (component: Component) => void;
	selectedItem?: Component;
}

const defaultValue: IAccordionContextValues = {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onToggle: (_component) => {},
};

export const AccordionContext = React.createContext(defaultValue);

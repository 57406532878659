import {
	AncillaryId,
	IAncillary,
} from '../../../ancillary/domain/models/IAncillary';
import { IAncillaryGroup } from '../../../ancillary/domain/models/IAncillaryGroup';
import {
	IAncillaryGroupSelection,
	IAncillarySelection,
	IExtraSelection,
} from '../../../ancillary/domain/models/IAncillaryGroupSelection';
import { IExtra } from '../../../ancillary/domain/models/IExtra';

export function getAncillariesForSelections(
	ancillaryGroups: IAncillaryGroup[],
	ancillaryGroupSelections: IAncillaryGroupSelection[],
): IAncillary[] {
	const selectedGroups = getSelectedAncillaryGroups(
		ancillaryGroups,
		ancillaryGroupSelections,
	);

	const selectedAncillariesPerGroup = selectedGroups.map((selectedGroup) => {
		const ancillarySelections = getAncillarySelections(
			selectedGroup,
			ancillaryGroupSelections,
		);

		const selectedAncillaryIds =
			ancillaryGroupSelections.find(({ id }) => id === selectedGroup.id)
				?.selected ?? [];

		return getSelectedAncillaries(
			selectedGroup.ancillaries,
			selectedAncillaryIds,
			ancillarySelections,
		);
	});

	return ([] as IAncillary[]).concat(...selectedAncillariesPerGroup);
}

function getSelectedAncillaryGroups(
	ancillaryGroups: IAncillaryGroup[],
	ancillaryGroupSelections: IAncillaryGroupSelection[],
) {
	const selectedAncillaryGroupIds = ancillaryGroupSelections.map(
		({ id }) => id,
	);

	return ancillaryGroups.filter(({ id }) =>
		selectedAncillaryGroupIds.includes(id),
	);
}

function getSelectedAncillaries(
	ancillaries: IAncillary[],
	selectedAncillaryIds: AncillaryId[],
	ancillarySelections: IAncillarySelection[],
): IAncillary[] {
	const selectedAncillaries = ancillaries.filter(({ id }) =>
		selectedAncillaryIds.includes(id),
	);

	return selectedAncillaries.map((ancillary) => {
		const extraSelections = getExtraSelections(ancillarySelections, ancillary);

		return {
			...ancillary,
			extras: getSelectedExtras(ancillary.extras ?? [], extraSelections),
		};
	});
}

function getAncillarySelections(
	ancillaryGroup: IAncillaryGroup,
	ancillaryGroupSelections: IAncillaryGroupSelection[],
): IAncillarySelection[] {
	const selection = ancillaryGroupSelections.find(
		({ id }) => id === ancillaryGroup.id,
	);

	return selection?.ancillaries ?? [];
}

function getExtraSelections(
	ancillarySelections: IAncillarySelection[],
	ancillary: IAncillary,
) {
	return (
		ancillarySelections.find(({ id }) => id === ancillary.id)?.extras ?? []
	);
}

function getSelectedExtras(
	extras: IExtra[],
	extraSelections: IExtraSelection[],
): IExtra[] {
	const selectedExtraIds = extraSelections.map(({ id }) => id);

	const selectedExtras = extras.filter(({ id }) =>
		selectedExtraIds.includes(id),
	);

	return selectedExtras.map((extra) => {
		const selectedVariantIds =
			extraSelections.find(({ id }) => id === extra.id)?.selected ?? [];

		return {
			...extra,
			variants: extra.variants.filter(({ id }) =>
				selectedVariantIds.includes(id),
			),
		};
	});
}

import { ITheme } from './theme';
import { validateTheme } from './validateTheme';

export interface IThemedProps {
	theme?: ITheme;
}

function themed<T>(callback: (theme: ITheme, props: T) => string) {
	return (props: T & IThemedProps) => {
		const theme = validateTheme(props.theme);

		return callback(theme, props as T);
	};
}

export default themed;

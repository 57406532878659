import { ITokenizedCardCreateRequestData } from '../../../../shared/api/gateway/types';
import { INewTokenizedCard } from '../../models';

export function adaptCardToSaveTokenizedRequestData(
	tokenizedCard: INewTokenizedCard,
): ITokenizedCardCreateRequestData {
	const { token, maskedCreditCardNumber, creditCardType, expiryDate } =
		tokenizedCard;

	return {
		token,
		maskedCreditCardNumber,
		creditCardType,
		expiryDateMonth: expiryDate.month,
		expiryDateYear: expiryDate.year,
	};
}

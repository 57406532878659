import { getUrlForPageId } from './getUrlForPageId';
import { useSiteMap } from './withSiteMap';

export function useUrlForPageId(pageId: string, params?: string) {
	const siteMap = useSiteMap();

	return params
		? `${getUrlForPageId(pageId, siteMap)}?${params}`
		: getUrlForPageId(pageId, siteMap);
}

/* eslint-disable no-inner-declarations, @typescript-eslint/no-namespace */
import { ITheme } from '../themer';
import themed from '../themer/themed';
import { Css } from './Css';
import Responsive from './Responsive';

export type IGutterOrientation = 'horizontal' | 'vertical';

export namespace Gutter {
	export const cssVariableName = {
		horizontal: Css.createVariableName('gutter-horizontal'),
		vertical: Css.createVariableName('gutter-vertical'),
	};

	export function getWrapperStyles(
		orientation: IGutterOrientation,
		variableName?: string,
		defaultValue?: string,
	) {
		const property = `margin-${orientation === 'horizontal' ? 'left' : 'top'}`;

		return themed(
			({ grid }) => `
			// Fall-back when var() is not supported.
			${property}: calc(-1 * ${defaultValue || grid.gutter[orientation].default});
			${property}: calc(-1 * var(${variableName || cssVariableName[orientation]}));
		`,
		);
	}

	export function getItemStyles(
		orientation: IGutterOrientation,
		variableName?: string,
		defaultValue?: string,
	) {
		const property = `margin-${orientation === 'horizontal' ? 'left' : 'top'}`;

		return themed(
			({ grid }) => `
			// Fall-back when var() is not supported.
			${property}: ${defaultValue || grid.gutter[orientation].default};
			${property}: var(${variableName || cssVariableName[orientation]});
		`,
		);
	}

	export function getValues(orientation: IGutterOrientation) {
		return (value: string) => `
		${cssVariableName[orientation]}: ${value};
	`;
	}

	export function getResponsiveValues(
		theme: ITheme,
		values: Responsive.IValueOrResponsiveValue<string>,
		orientation: IGutterOrientation,
	) {
		return Responsive.getStylesFromValues(
			values,
			{ theme },
			getValues(orientation),
		);
	}

	export function getResponsiveValuesFromTheme(
		theme: ITheme,
		orientation: IGutterOrientation,
	) {
		const { default: defaultValue, ...responsiveValues } =
			theme.grid.gutter[orientation];

		return getResponsiveValues(
			theme,
			[defaultValue, responsiveValues],
			orientation,
		);
	}
}

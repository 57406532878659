import {
	action,
	computed,
	IReactionDisposer,
	makeObservable,
	observable,
	reaction,
} from 'mobx';
import { RemoteDataStore } from '@move-frontend/utils';
import { sortBookingsByDate } from './utils/sortBookings';
import { adaptDetailsFormDataToPatchRequest } from './adapters/adaptDetailsFormDataToPatchRequest';
import { GuestBookingStore, IGuestBookingParams } from './GuestBookingStore';
import { adaptResponseToBooking } from './adapters/adaptResponseToBooking';
import { AuthStore } from '../../../../auth/shared/store/AuthStore';
import { BookingService } from '../../../../shared/api/gateway/services/BookingService';
import { IGetUserBookingsResponse } from '../../../../shared/api/gateway/types';
import { IDetailsFormData } from '../../../ui/booking-steps/details/types/IDetailsFormData';
import { IBooking } from '../models/IBooking';

type MyBookingsParams = Record<never, never>;

export class MyBookingsStore extends RemoteDataStore<
	MyBookingsParams,
	IGetUserBookingsResponse
> {
	loadingBookings = false;

	lastUpdate: Date | undefined = undefined;

	private _guestBookingStore: GuestBookingStore;

	private _bookings = observable<IBooking>([]);

	private disposeAuthReaction: IReactionDisposer;

	private shouldLoad = false;

	constructor(
		private readonly authStore: AuthStore,
		private readonly bookingService: BookingService,
	) {
		super();

		makeObservable<
			MyBookingsStore,
			| '_bookings'
			| 'handleAuthChange'
			| 'handleResponse'
			| 'handleAddGuestBookingSuccess'
			| 'handleUpdateBookingDetailsSuccess'
		>(this, {
			_bookings: observable,
			loadingBookings: observable,
			lastUpdate: observable,
			isLoading: computed,
			isLoadingBookings: computed,
			bookings: computed,
			handleAuthChange: action.bound,
			handleResponse: action,
			clear: action,
			handleAddGuestBookingSuccess: action,
			handleUpdateBookingDetailsSuccess: action,
			cancelBooking: action,
		});

		this._guestBookingStore = new GuestBookingStore(bookingService);

		this.disposeAuthReaction = reaction(
			() => this.authStore.isAuthenticated,
			this.handleAuthChange,
		);

		this.handleAuthChange();
	}

	public dispose() {
		this.disposeAuthReaction();
	}

	get isLoading() {
		return !this.authStore.finishedInitialLoad || this.isLoadingBookings;
	}

	get isLoadingBookings() {
		return super.isLoading;
	}

	get bookings() {
		return this._bookings.slice().sort(sortBookingsByDate);
	}

	private async handleAuthChange() {
		if (!this.authStore.isAuthenticated) {
			this._bookings.clear();
			this.cancelCurrentRequest();
			return;
		}

		if (this.shouldLoad) {
			await this.load();
		}
	}

	async load() {
		if (!this.authStore.isAuthenticated) {
			this.shouldLoad = true;
			return;
		}

		await super.load({});
	}

	protected performRequest() {
		return this.bookingService.getUserBookings();
	}

	protected handleResponse(response: IGetUserBookingsResponse) {
		const bookings = response.map((booking) => adaptResponseToBooking(booking));

		this._bookings.replace(bookings);
	}

	async reset() {
		this.shouldLoad = false;
		this.resetGuestBooking();
		await super.reset();
	}

	clear() {
		this._guestBookingStore.clear();
		this._bookings.clear();
	}

	async addGuestBooking(guestBookingParams: IGuestBookingParams) {
		const response = await this.bookingService.patchBookingByReference(
			guestBookingParams,
		);

		const booking = adaptResponseToBooking(response);

		this.handleAddGuestBookingSuccess(booking);
	}

	private handleAddGuestBookingSuccess(booking: IBooking) {
		this._bookings.push(booking);
	}

	async updateBookingDetails(booking: IBooking, data: IDetailsFormData) {
		if (this.authStore.isAuthenticated && !booking.byReference) {
			await this.updateUserBookingDetails(booking, data);
		} else {
			await this._guestBookingStore.updateBookingDetails(booking, data);
		}
	}

	private async updateUserBookingDetails(
		booking: IBooking,
		data: IDetailsFormData,
	) {
		const requestData = adaptDetailsFormDataToPatchRequest(data);

		const response = await this.bookingService.patchBooking(
			booking.id,
			requestData,
		);

		const updatedBooking = adaptResponseToBooking(response);

		this.handleUpdateBookingDetailsSuccess(updatedBooking);
	}

	private handleUpdateBookingDetailsSuccess(updatedBooking: IBooking) {
		this._bookings.replace(
			this._bookings.map((booking) => {
				if (booking.id === updatedBooking.id) {
					return updatedBooking;
				}

				return booking;
			}),
		);
	}

	async cancelBooking(booking: IBooking) {
		if (this.authStore.isAuthenticated) {
			await this.bookingService.cancelBooking(booking.id);
		} else {
			await this._guestBookingStore.cancelBooking({
				reference: booking.reference,
				email: booking.personalDetails.email,
			});
		}
	}

	loadGuestBooking(params: IGuestBookingParams) {
		return this._guestBookingStore.load(params);
	}

	get guestBooking() {
		return this._guestBookingStore.guestBooking;
	}

	get guestBookingError() {
		return this._guestBookingStore.error;
	}

	get isGuestBookingLoading() {
		return this._guestBookingStore.isLoading;
	}

	resetGuestBooking() {
		this._guestBookingStore.reset();
	}

	findBookingByReference(reference: string) {
		if (this.isGuestBooking(reference)) {
			return this.guestBooking;
		}

		return this.bookings.find((booking) => booking.reference === reference);
	}

	private isGuestBooking(reference: string) {
		return this.guestBooking?.reference === reference;
	}
}

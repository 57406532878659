import React from 'react';
import styled from '@emotion/styled';
import Responsive from '../../../../../ui/utils/Responsive';
import { IPageHeaderProps, PageHeader } from '../PageHeader';
import desktopHeaderFallback from '../../../../../../assets/default-header-image-desktop.jpg';
import mobileHeaderFallback from '../../../../../../assets/default-header-image-mobile.jpg';
import { IHeaderImages } from './IHeaderImages';

interface IStyledImagePageHeaderProps {
	images: IHeaderImages;
}

export const StyledImagePageHeader = styled(PageHeader)<
	IStyledImagePageHeaderProps,
	{}
>`
	min-height: 30vw;
	background-size: cover;
	background-position: center;

	${({ images }) => `
		background-image: url('${images.desktop || desktopHeaderFallback}');
	`}

	${({ images }) =>
		images.desktopXL &&
		`
		@media (${Responsive.getMediaQueryForBreakpoint('desktopXL')}),
		(min-device-pixel-ratio: 1.5) and (min-width: 1440px),
		(min-resolution: 1.5dppx) and (min-width: 1440px) {
			background-image: url('${images.desktopXL}');
		}
	`}

	${({ images }) => `
		@media (orientation: portrait) {
			min-height: 68vw;
			background-image: url('${images.mobile || mobileHeaderFallback}');
		}
	`}

	${({ images }) =>
		images.mobileXL &&
		`
		@media (orientation: portrait) and (min-device-pixel-ratio: 1.5),
		(orientation: portrait) and (min-resolution: 1.5dppx),
		(orientation: portrait) and (min-width: 481px) {
			background-image: url('${images.mobileXL}');
		}
	`}

	${({ images }) =>
		images.tablet &&
		`
		@media (orientation: portrait) and (min-device-pixel-ratio: 3),
		(orientation: portrait) and (min-resolution: 3dppx),
		(orientation: portrait) and (min-width: 960px) {
			background-image: url('${images.tablet}');
		}
	`}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		h1 {
			width: 80%;
			font-size: 40px;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		h1 {
			width: auto;
			font-size: 32px;
		}
	}
`;

export interface IImagePageHeaderProps
	extends IPageHeaderProps,
		IStyledImagePageHeaderProps {}

export const ImagePageHeader = (props: IImagePageHeaderProps) => (
	<StyledImagePageHeader {...props} />
);

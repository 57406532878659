import { LocusLabs } from '@move-frontend/locuslabs';
import { FilterStore, SortStore } from '@move-frontend/utils';
import { PoiStore } from './PoiStore';
import { PoiListStore } from './PoiListStore';
import { PoiAreaStore } from './PoiAreaStore';
import { IPoi } from '../models/IPoi';

export function createPoiStores() {
	const locusLabs = new LocusLabs(
		process.env.LOCUS_LABS_VENUE_ID as string,
		process.env.LOCUS_LABS_ACCOUNT_ID as string,
	);

	const poiStore = new PoiStore(
		locusLabs,
		process.env.LOCUS_LABS_VENUE_ID as string,
	);

	const poiFilterStore = new FilterStore<IPoi>();

	const poiSortStore = new SortStore<IPoi>();
	poiSortStore.setSortBy((a, b) => a.name.localeCompare(b.name));

	const poiListStore = new PoiListStore(poiStore, poiFilterStore, poiSortStore);
	const poiAreaStore = new PoiAreaStore(poiStore);

	return {
		poiStore,
		poiListStore,
		poiAreaStore,
	};
}

import {
	AirlineStore,
	AirportStore,
	FlightDetailStore,
	FlightStore,
	WeatherStore,
} from '@move-frontend/dap';
import { createFlightListStore } from './overview/domain/stores';
import { createFlightSuggestionStore } from './search/domain/stores';
import { Dap } from '../shared/api/dap';
import { IFlight } from './shared/flight/domain/models/IFlight';
import { createFlightEnrichStore } from './shared/flight/domain/stores/createFlightEnrichStore';

export function createFlightStores(dap: Dap) {
	const airlineStore = new AirlineStore();
	const airportStore = new AirportStore();
	const flightStore = new FlightStore<IFlight>(dap);

	const flightEnrichStore = createFlightEnrichStore(airlineStore, airportStore);

	const flightListStore = createFlightListStore(flightStore, flightEnrichStore);

	const flightSuggestionStore = createFlightSuggestionStore(
		flightStore,
		airlineStore,
		airportStore,
	);

	const flightDetailStore = new FlightDetailStore(flightEnrichStore, dap);

	const weatherStore = new WeatherStore(dap);

	return {
		airlineStore,
		airportStore,
		flightListStore,
		flightSuggestionStore,
		flightDetailStore,
		weatherStore,
	};
}

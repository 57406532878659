import { withIconStyles } from './withIconStyles';

import Arrow from '../../assets/arrow.svg';
import ArrowBack from '../../assets/arrow-back.svg';
import Bus from '../../assets/bus.svg';
import Calendar from '../../assets/calendar.svg';
import Cancel from '../../assets/cancel.svg';
import Close from '../../assets/close.svg';
import Checkmark from '../../assets/checkmark.svg';
import ChevronDown from '../../assets/chevron-down.svg';
import ChevronLeft from '../../assets/chevron-left.svg';
import ChevronRight from '../../assets/chevron-right.svg';
import ChevronUp from '../../assets/chevron-up.svg';
import Delete from '../../assets/delete.svg';
import Facebook from '../../assets/facebook.svg';
import Instagram from '../../assets/instagram.svg';
import LinkedIn from '../../assets/linked-in.svg';
import Info from '../../assets/info.svg';
import InfoOutline from '../../assets/info-outline.svg';
import Location from '../../assets/location.svg';
import GeoLocation from '../../assets/geo-location.svg';
import Pdf from '../../assets/pdf.svg';
import PlaneDeparture from '../../assets/plane-departure.svg';
import Download from '../../assets/download.svg';
import ExternalLink from '../../assets/external-link.svg';
import Mail from '../../assets/mail.svg';
import Map from '../../assets/map.svg';
import Menu from '../../assets/menu.svg';
import Phone from '../../assets/phone.svg';
import Search from '../../assets/search.svg';
import Twitter from '../../assets/twitter.svg';
import Walk from '../../assets/walk.svg';
import YouTube from '../../assets/youtube.svg';
import Eye from '../../assets/eye.svg';
import EyeStriked from '../../assets/eye-striked.svg';
import Check from '../../assets/check.svg';
import Coupon from '../../assets/coupon.svg';
import Schedule from '../../assets/schedule.svg';
import PlaneTop from '../../assets/plane-top.svg';
import CircleCheck from '../../assets/circle-check.svg';
import Account from '../../assets/account.svg';
import Stars from '../../assets/stars.svg';
import Pencil from '../../assets/pencil.svg';
import User from '../../assets/user.svg';
import Car from '../../assets/car.svg';
import CreditCard from '../../assets/credit-card.svg';
import Parking from '../../assets/parking.svg';
import SecurityPreferences from '../../assets/security-preferences.svg';
import LogOut from '../../assets/logout.svg';
import Van from '../../assets/van.svg';
import Skylink from '../../assets/skylink.svg';
import CarOutline from '../../assets/car-outline.svg';
import Star from '../../assets/star.svg';
import TrashCan from '../../assets/trash-can.svg';
import Print from '../../assets/print.svg';
import ChatBubble from '../../assets/chat-bubble.svg';
import Lock from '../../assets/lock.svg';
import Lock2fa from '../../assets/lock-2fa.svg';
import EmptyCreditCard from '../../assets/empty-credit-card.svg';
import QuestionMarkCircle from '../../assets/question-mark.svg';
import UnfoldMore from '../../assets/unfold-more.svg';
import UnfoldLess from '../../assets/unfold-less.svg';
import BusStop from '../../assets/bus-stop.svg';
import Shuttle from '../../assets/shuttle.svg';
import DropOff from '../../assets/drop-off.svg';
import Replay from '../../assets/replay.svg';
import XLogo from '../../assets/x-logo.svg';
import ShuttleDropOffLocation from '../../assets/shuttle-drop-off-location-icon.svg';
import ShuttlePickUpLocation from '../../assets/shuttle-pickup-location-icon.svg';
import MyLocation from '../../assets/my-location.svg';
import CurrentWaitTime from '../../assets/current-wait-time.svg';
import Time from '../../assets/time.svg';
import DriveUp from '../../assets/drive-up.svg';

export const ArrowIcon = withIconStyles(Arrow);
export const ArrowBackIcon = withIconStyles(ArrowBack);
export const BusIcon = withIconStyles(Bus, ['fill']);
export const CalendarIcon = withIconStyles(Calendar, ['fill']);
export const CancelIcon = withIconStyles(Cancel);
export const CloseIcon = withIconStyles(Close);
export const CheckmarkIcon = withIconStyles(Checkmark);
export const ChevronDownIcon = withIconStyles(ChevronDown);
export const ChevronLeftIcon = withIconStyles(ChevronLeft);
export const ChevronRightIcon = withIconStyles(ChevronRight);
export const ChevronUpIcon = withIconStyles(ChevronUp);
export const DeleteIcon = withIconStyles(Delete, ['fill']);
export const FacebookIcon = withIconStyles(Facebook, ['fill']);
export const InstagramIcon = withIconStyles(Instagram, ['fill']);
export const LinkedInIcon = withIconStyles(LinkedIn, ['fill']);
export const InfoIcon = withIconStyles(Info, ['fill']);
export const InfoOutlineIcon = withIconStyles(InfoOutline, ['fill']);
export const LocationIcon = withIconStyles(Location, ['fill']);
export const GeoLocationIcon = withIconStyles(GeoLocation, ['fill']);
export const DownloadIcon = withIconStyles(Download);
export const PdfIcon = withIconStyles(Pdf, ['stroke', 'fill']);
export const PlaneDepartureIcon = withIconStyles(PlaneDeparture, ['fill']);
export const ExternalLinkIcon = withIconStyles(ExternalLink);
export const MailIcon = withIconStyles(Mail);
export const MapIcon = withIconStyles(Map, ['fill']);
export const MenuIcon = withIconStyles(Menu);
export const PhoneIcon = withIconStyles(Phone, ['fill']);
export const SearchIcon = withIconStyles(Search, ['stroke', 'fill']);
export const TwitterIcon = withIconStyles(Twitter, ['fill']);
export const WalkIcon = withIconStyles(Walk, ['fill']);
export const YouTubeIcon = withIconStyles(YouTube, ['fill']);
export const EyeIcon = withIconStyles(Eye, ['fill']);
export const EyeStrikedIcon = withIconStyles(EyeStriked, ['fill']);
export const CheckIcon = withIconStyles(Check);
export const CouponIcon = withIconStyles(Coupon, ['fill']);
export const ScheduleIcon = withIconStyles(Schedule, ['fill']);
export const PlaneTopIcon = withIconStyles(PlaneTop, ['fill']);
export const CircleCheckIcon = withIconStyles(CircleCheck);
export const AccountIcon = withIconStyles(Account);
export const StarsIcon = withIconStyles(Stars);
export const PencilIcon = withIconStyles(Pencil);
export const CarIcon = withIconStyles(Car);
export const UserIcon = withIconStyles(User);
export const CreditCardIcon = withIconStyles(CreditCard);
export const ParkingIcon = withIconStyles(Parking);
export const SecurityPreferencesIcon = withIconStyles(SecurityPreferences);
export const LogOutIcon = withIconStyles(LogOut);
export const VanIcon = withIconStyles(Van, ['fill']);
export const SkylinkIcon = withIconStyles(Skylink, ['fill']);
export const CarOutlineIcon = withIconStyles(CarOutline);
export const StarIcon = withIconStyles(Star, ['fill', 'stroke']);
export const TrashCanIcon = withIconStyles(TrashCan);
export const PrintIcon = withIconStyles(Print);
export const ChatBubbleIcon = withIconStyles(ChatBubble);
export const LockIcon = withIconStyles(Lock);
export const Lock2faIcon = withIconStyles(Lock2fa);
export const EmptyCreditCardIcon = withIconStyles(EmptyCreditCard);
export const QuestionMark = withIconStyles(QuestionMarkCircle);
export const UnfoldLessIcon = withIconStyles(UnfoldLess, ['fill', 'stroke']);
export const UnfoldMoreIcon = withIconStyles(UnfoldMore, ['fill', 'stroke']);
export const BusStopIcon = withIconStyles(BusStop, ['fill', 'stroke']);
export const ShuttleIcon = withIconStyles(Shuttle, ['fill', 'stroke']);
export const DropOffIcon = withIconStyles(DropOff);
export const ReplayIcon = withIconStyles(Replay, ['fill']);
export const XLogoIcon = withIconStyles(XLogo, ['fill']);
export const ShuttleDropOffLocationIcon = withIconStyles(
	ShuttleDropOffLocation,
);
export const ShuttlePickUpLocationIcon = withIconStyles(ShuttlePickUpLocation);
export const MyLocationIcon = withIconStyles(MyLocation, ['fill']);
export const CurrentWaitTimeIcon = withIconStyles(CurrentWaitTime, ['fill']);
export const TimeIcon = withIconStyles(Time);
export const DriveUpIcon = withIconStyles(DriveUp);

export { withIconStyles };

export * from './IIconProps';

import { AuthStore } from '../../../shared/store/AuthStore';
import { UserStore } from './UserStore';

export function createUserStores(authStore: AuthStore) {
	const userStore = new UserStore(authStore);

	return {
		userStore,
	};
}

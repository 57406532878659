import { typography } from './typography';

export const layout = {
	page: {
		padding: '24px',
	},
	main: {
		maxWidth: '1200px',
		textMaxWidth: '792px',
		margin: {
			tablet: '24px',
			mobile: '16px',
		},
	},
	image: {
		width: '',
		maxWidth: '100%',
		maxHeight: '900px',
		align: 'center',
		margin: '20px 0 44px',
		mobile: {
			width: '',
			maxWidth: '',
			margin: typography.mobile.paragraph.margin,
		},
	},
};

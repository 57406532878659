import styled from '@emotion/styled';
import { Box, IBoxProps } from './Box';

export interface IFlexProps extends IBoxProps {
	inline?: boolean;
	direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
	wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
	justifyContent?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'space-around'
		| 'space-evenly';
	alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
	alignContent?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'space-around'
		| 'stretch';
}

export const Flex = styled(Box)<IFlexProps>`
	display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: ${({ direction }) => direction ?? 'inherit'};
	flex-wrap: ${({ wrap }) => wrap ?? 'inherit'};
	justify-content: ${({ justifyContent }) => justifyContent ?? 'inherit'};
	align-items: ${({ alignItems }) => alignItems ?? 'inherit'};
	align-content: ${({ alignContent }) => alignContent ?? 'inherit'};
`;

import { Provider as LegacyStoresProvider } from 'mobx-react';
import React, { FC } from 'react';
import { config } from '../../../../config';
import { I18nProvider } from '../../../shared/i18n/translate';
import { useStores } from '../domain/stores/useStores';
import { ICommonData } from '../domain/models/ICommonData';
import { CommonDataContext } from './CommonDataContext';
import { MarkdownRenderContextProvider } from '../../../shared/markdown';
import { moment } from '../../../shared/i18n/date-time/moment';
import { ConfigProvider, ICustomConfig } from '../../../../ui';

export const AppContextProvider: FC<{
	commonData: ICommonData;
	children?: React.ReactNode;
}> = ({ children, commonData }) => {
	const { locale, translations } = commonData;

	const stores = useStores();
	const uiConfig: ICustomConfig = {
		...config.ui,
		dateTimeFormat: {
			...config.ui.dateTimeFormat,
			locale: locale.prefix,
		},
		translations: {
			externalLinkLabel: translations.generic_external_link_label,
			clearSearchLabel: translations.generic_clear_search_label,
		},
	};

	moment.locale(locale.prefix);

	return (
		<CommonDataContext.Provider value={commonData}>
			{/* TODO: refactor class components using the deprecated `inject` decorator which depends on this Mobx context provider` */}
			<LegacyStoresProvider {...stores}>
				<ConfigProvider config={uiConfig}>
					<I18nProvider locale={locale} translations={translations}>
						<MarkdownRenderContextProvider>
							{children}
						</MarkdownRenderContextProvider>
					</I18nProvider>
				</ConfigProvider>
			</LegacyStoresProvider>
		</CommonDataContext.Provider>
	);
};

import React from 'react';
import {
	CardsContainer,
	ICardsContainerProps,
	StyledContactCard,
} from '../../../../ui';

export const ContactItemContainer = ({
	children,
	...rest
}: ICardsContainerProps) => (
	<CardsContainer itemSelector={StyledContactCard} {...rest}>
		{children}
	</CardsContainer>
);

ContactItemContainer.defaultProps = {
	columns: [3, { tablet: 2, mobileXL: 1 }],
	titleFontSize: [32, { mobileXL: 24, mobile: 18 }],
	cardOrientation: ['vertical', { mobileXL: 'horizontal' }],
};

/* eslint-disable no-restricted-imports */
import React from 'react';
import {
	DefaultNamespace,
	Trans as BaseTrans,
	TransProps,
} from 'react-i18next';
import { ITranslationKey } from './models/ITranslationKey';
import { useTranslation } from './useTranslation';

export const Trans = <TElement extends Element = HTMLDivElement>(
	props: TransProps<ITranslationKey, DefaultNamespace, TElement>,
) => {
	const { t, i18n } = useTranslation();

	return <BaseTrans t={t as any} i18n={i18n} {...props} />;
};

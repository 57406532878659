import React from 'react';
import styled from '@emotion/styled';
import { Link } from '../Link';
import { ITheme } from '../../themer';
import { ChevronLeftIcon } from '../Icon';
import { getTextColor } from './helpers';

export interface IHeaderParentTitleProps {
	parentTitle?: string;
	parentUrl?: string;
	inverted?: boolean;
}

export const StyledHeaderParentTitle = styled.span<{}, ITheme>`
	color: ${getTextColor};
`;

export const StyledHeaderParentLink = styled(Link)`
	position: relative;

	svg {
		width: 24px;
		height: 24px;
		margin-left: -24px;
		margin-top: -2px;
	}

	@media (max-width: 1280px) {
		svg {
			margin-left: -6px;
		}
	}
`;

const ParentTitleLink = ({
	parentTitle,
	parentUrl,
	inverted,
}: IHeaderParentTitleProps) => {
	if (!parentUrl) {
		return <>{parentTitle}</>;
	}

	return (
		<StyledHeaderParentLink href={parentUrl} inverted={inverted}>
			<ChevronLeftIcon aria-hidden variation={inverted ? 'inverted' : 'text'} />
			{parentTitle}
		</StyledHeaderParentLink>
	);
};

export const HeaderParentTitle = ({
	parentTitle,
	...rest
}: IHeaderParentTitleProps) => {
	if (!parentTitle) {
		return null;
	}

	return (
		<StyledHeaderParentTitle>
			<ParentTitleLink parentTitle={parentTitle} {...rest} />
		</StyledHeaderParentTitle>
	);
};

import log from 'loglevel';
import { LocaleCode } from '../locale/models/LocaleCode';
import { ITranslations } from './models/ITranslations';

export async function getTranslationsData(
	localeCode: LocaleCode,
): Promise<ITranslations> {
	switch (localeCode) {
		case 'en-US':
			return import('./translations/en-US.json');
		case 'es-US':
			return import('./translations/es-US.json');
		default:
			log.error(`Invalid locale code: ${localeCode}, using default: en-US`);

			return import('./translations/en-US.json');
	}
}

import { makeAutoObservable } from 'mobx';

export class LoaderStore {
	private _loadingCount = 0;

	private _loadingSince: Date | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	get isLoading() {
		return this._loadingCount > 0;
	}

	get loadingSince() {
		return this._loadingSince;
	}

	public increaseLoadingCount() {
		if (this._loadingCount === 0) {
			this._loadingSince = new Date();
		}

		this._loadingCount += 1;
	}

	public decreaseLoadingCount() {
		this._loadingCount -= 1;

		if (this._loadingCount === 0) {
			this._loadingSince = undefined;
		}
	}
}

import styled from '@emotion/styled';
import { ITheme } from '../../../../ui';
import { DialogContent } from '@reach/dialog';
import themed from '../../../../ui/themer/themed';

interface IStyledDialogContentProps {
	fullWidthMobile: boolean;
}

const fullWidthMobileStyles = `
  width: 100vw;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  box-shadow: none;
`;

const mobileStyles = `
  width: calc(100vw - 32px);
  margin: 16px;
  box-shadow: none;
`;

export const StyledDialogContent = styled(DialogContent)<
	IStyledDialogContentProps,
	ITheme
>`
	&[data-reach-dialog-content] {
		width: ${themed(({ layout }) => layout.main.textMaxWidth)};
		padding: 0;
		margin: 10vh auto;
		background: #fff;
		box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
		overflow-x: hidden;
		overflow-y: auto;
		scroll-behavior: smooth;

		@media (max-width: 1280px) {
			${({ fullWidthMobile }) =>
				fullWidthMobile ? fullWidthMobileStyles : mobileStyles}
		}
	}
`;

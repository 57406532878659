import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { IDialogOverlayProps, StyledDialogOverlay } from './DialogOverlay';
import '@reach/dialog/styles.css';
import { StyledDialogContent } from './DialogContent';
import { DialogContext } from './DialogContext';

export interface IDialogProps extends IDialogOverlayProps {
	children: ReactNode;
	ariaLabel: string;
	onClose?: () => void | boolean;
	fullWidthMobile?: boolean;
}

export const Dialog = ({
	children,
	ariaLabel,
	fullWidthMobile = true,
	onClose,
	...rest
}: IDialogProps) => {
	const [isOpen, setIsOpen] = useState(true);

	const close = useCallback(() => {
		let shouldClose: boolean | void = true;

		if (onClose) {
			shouldClose = onClose();
		}

		if (shouldClose !== false) {
			setIsOpen(false);
		}
	}, [onClose]);

	const context = useMemo(() => ({ close }), [close]);

	if (!isOpen) {
		return null;
	}

	return (
		<DialogContext.Provider value={context}>
			<StyledDialogOverlay
				fullWidthMobile={fullWidthMobile}
				data-testid="dialog"
				onDismiss={close}
				{...rest}
			>
				<StyledDialogContent
					fullWidthMobile={fullWidthMobile}
					aria-label={ariaLabel}
				>
					{children}
				</StyledDialogContent>
			</StyledDialogOverlay>
		</DialogContext.Provider>
	);
};

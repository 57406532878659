import { Auth } from '@aws-amplify/auth';
import API from '@aws-amplify/api-rest';
import { logError } from '../../error/logError';
import { CLT_GATEWAY_API_NAME } from './constants';
import { isKnownGatewayError } from './errors/isKnownGatewayError';
import { cognitoConfig } from '../cognito/config';

interface IRequestOptions {
	headers?: Record<string, string>;
	timeout?: number;
	responseType?: string;
	withCredentials?: boolean;
	queryStringParameters?: Record<string, string | number | undefined>;
}

export class ApiClient {
	constructor() {
		Auth.configure(cognitoConfig);
		API.configure({
			endpoints: [
				{
					name: CLT_GATEWAY_API_NAME,
					endpoint: process.env.CLT_GATEWAY_BASE_URL,
				},
			],
		});
	}

	get<TResponseData>(path: string, options: IRequestOptions = {}) {
		return API.get(CLT_GATEWAY_API_NAME, path, options).catch(
			handleError,
		) as Promise<TResponseData>;
	}

	post<TRequestData, TResponseData>(
		path: string,
		data?: TRequestData,
		options: IRequestOptions = {},
	) {
		return API.post(
			CLT_GATEWAY_API_NAME,
			path,
			createOptionsWithBody(options, data),
		).catch(handleError) as Promise<TResponseData>;
	}

	patch<TRequestData, TResponseData>(
		path: string,
		data?: TRequestData,
		options: IRequestOptions = {},
	) {
		return API.patch(
			CLT_GATEWAY_API_NAME,
			path,
			createOptionsWithBody(options, data),
		).catch(handleError) as Promise<TResponseData>;
	}

	delete<TResponseData>(path: string, options: IRequestOptions = {}) {
		return API.del(CLT_GATEWAY_API_NAME, path, options).catch(
			handleError,
		) as Promise<TResponseData>;
	}
}

/**
 * Using the Amplify SDK POST/PATCH requests made without body will be send with an 'application/x-www-form-urlencoded',
 * which is not supported by the API. However using 'application/json' and content type without providing a body would
 * fail as well, since an empty body would be considered 'invalid json'. Instead we will need to use an empty object
 * as default request body along with the appropriate headers.
 */
function createOptionsWithBody(options: IRequestOptions, body: unknown = {}) {
	return {
		...options,
		body,
		headers: {
			...(options.headers ?? {}),
			'Content-Type': 'application/json; charset=UTF-8',
		},
	};
}

function handleError(error: Error) {
	if (!isKnownGatewayError(error)) {
		logError(error);
	}

	throw error;
}

import React, { forwardRef, MouseEvent } from 'react';
import { PropsOf } from '../../types';
import { Input } from '../Input';
import { SearchInputButtonWrap } from './SearchInputButtonWrap';
import { SearchInputClearWrap } from './SearchInputClearWrap';

export interface ISearchInputProps extends PropsOf<typeof Input> {
	onClickSearch: (e: MouseEvent) => void;
	onClickClear: (e: MouseEvent) => void;
	showClearIcon: boolean;
	placeholder?: string;
	ariaLabel?: string;
	buttonAriaLabel?: string;
	placeholderAsLabel?: boolean;
	isForm?: boolean;
}

export const SearchInput = forwardRef<HTMLInputElement, ISearchInputProps>(
	(
		{
			onClickSearch,
			showClearIcon,
			onClickClear,
			placeholder,
			disabled,
			ariaLabel,
			buttonAriaLabel,
			isForm = false,
			...rest
		}: ISearchInputProps,
		ref,
	) => (
		<SearchInputButtonWrap
			onClickSearch={onClickSearch}
			disabled={disabled}
			ariaLabel={buttonAriaLabel}
			buttonType={isForm ? 'submit' : 'button'}
		>
			<SearchInputClearWrap
				onClickClear={onClickClear}
				showClearIcon={showClearIcon}
			>
				<Input
					disabled={disabled}
					ref={ref}
					aria-label={ariaLabel}
					placeholder={placeholder}
					{...rest}
				/>
			</SearchInputClearWrap>
		</SearchInputButtonWrap>
	),
);

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Omit } from '../../../../ui';
import { PAGE_REFERENCE_TYPE_ID } from '@move-frontend/contentful';
import { IPageEntry } from '../../api/contentful/types';
import { Header, IHeaderProps } from './Header';
import { ISiteMap } from '../../../app/pages/domain/models/ISiteMap';
import { getUrlForPageId } from '../../../app/pages/ui/utils/getUrlForPageId';
import { useSiteMap } from '../../../app/pages/ui/utils/withSiteMap';

function hasParentPage(page: IPageEntry) {
	return (
		page.fields.parent &&
		page.fields.parent.contentType === PAGE_REFERENCE_TYPE_ID
	);
}

function getParentPageTitle(page: IPageEntry) {
	if (page.fields.parentTitle) {
		return page.fields.parentTitle;
	}

	if (!hasParentPage(page)) {
		return undefined;
	}

	return page.fields.parent!.fields.title;
}

function getParentPageUrl(page: IPageEntry, siteMap: ISiteMap) {
	if (!hasParentPage(page)) {
		return undefined;
	}

	return getUrlForPageId(page.fields.parent!.fields.id, siteMap);
}

function getParentPageProps(page: IPageEntry, siteMap: ISiteMap) {
	const parentTitle = getParentPageTitle(page);
	const parentUrl = getParentPageUrl(page, siteMap);

	return {
		parentTitle,
		parentUrl,
	};
}

export interface IPageHeaderProps
	extends Omit<IHeaderProps, 'title' | 'theme'> {
	hasCustomTitle?: boolean;
	page: IPageEntry;
}

export const PageHeader = ({
	page,
	hasCustomTitle,
	...rest
}: IPageHeaderProps) => {
	const siteMap = useSiteMap();

	return (
		<Header
			{...getParentPageProps(page, siteMap)}
			title={hasCustomTitle ? undefined : page.fields.title}
			theme={page.fields.headerTheme}
			{...rest}
		/>
	);
};

import { ICustomEvent } from '../models/ICustomEvent';
import { IEvent } from '../models/IEvent';

export function adaptEventToCustomEvent({
	category,
	action,
	label,
	value,
}: IEvent): ICustomEvent {
	return {
		// Use category as Custom Event name, see: https://developers.google.com/analytics/devguides/collection/ga4/translate-events#example_universal_analytics_event_with_no_google_analytics_4_equivalent
		name: category,
		event_action: action,
		event_category: category,
		event_label: label,
		value,
	};
}

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { StyledButtonText } from '../Button/ButtonText';

export const StyledLinkText = styled(StyledButtonText)<{}>``;

interface ILinkTextProps {
	children: ReactNode;
	className?: string;
}

export const LinkText = ({ children, className }: ILinkTextProps) => (
	<>
		{typeof children === 'string' ? (
			<StyledLinkText className={className}>{children}</StyledLinkText>
		) : (
			children
		)}
	</>
);

import { computed, makeObservable } from 'mobx';
import { IAsyncStore } from '@move-frontend/utils';
import { PoiStore } from './PoiStore';

export class PoiAreaStore implements IAsyncStore {
	constructor(private readonly poiStore: PoiStore) {
		makeObservable(this, {
			areas: computed,
		});
	}

	public get isLoading() {
		return this.poiStore.isLoading;
	}

	public get error() {
		return this.poiStore.error;
	}

	get areas() {
		const { filteredResults } = this.poiStore;

		const allAreas: string[] = filteredResults
			.map((result) => result.nearbyLandmark || '')
			.filter((t) => t);

		// Filter unique values
		const areas = allAreas.filter((area, i) => allAreas.indexOf(area) === i);

		areas.sort((a, b) => a.localeCompare(b));

		return areas;
	}
}

import { trackPageView } from './trackPageView';

/**
 * Track hash changes as individual page views
 */
export function trackHashChange(hash: string) {
	const url = window.location.pathname + hash;

	trackPageView(url);
}

/* eslint-disable react/no-danger */
import React, { memo } from 'react';
import Head from 'next/head';
import { getAnalyticsConfig } from './shared/config/getAnalyticsConfig';

export const BingTrackingScript = memo(() => {
	const { bingTrackingId } = getAnalyticsConfig();

	if (!bingTrackingId) return null;

	return (
		<Head>
			<script
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					// eslint-disable-next-line @typescript-eslint/naming-convention
					__html: `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${bingTrackingId}"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`,
				}}
			/>
		</Head>
	);
});

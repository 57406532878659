import React, { useState } from 'react';
import { SignInForm } from './SignInForm';
import { DialogMain } from '../../../shared/components/dialog';
import { DialogFooter } from '../../../shared/components/dialog/DialogFooter';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { removeHash } from '../../../shared/router/hash/hashHistory';
import { setUserLoginStatusProperty } from '../../../shared/analytics/user/useSetUserProperties';
import { SignInRegisterDialogHeaderGradient } from '../../shared/sign-in-register/SignInRegisterDialogHeaderGradient';
import { SIGN_IN_DIALOG_HASH } from './SignInDialog';

export const SignInDialogContent = () => {
	const { t } = useTranslation();
	const [hasSignInError, setHasSignInError] = useState(false);
	const onSignInSuccess = () => {
		setUserLoginStatusProperty(true);

		setHasSignInError(false);
		removeHash({ replace: true });
	};

	const onSignInFailure = () => {
		setHasSignInError(true);
	};

	return (
		<>
			<SignInRegisterDialogHeaderGradient type={SIGN_IN_DIALOG_HASH} />
			<DialogMain>
				<p>{t('user_sign_in_message')}</p>
				<SignInForm onSuccess={onSignInSuccess} onFailure={onSignInFailure} />
			</DialogMain>
			{hasSignInError && (
				<DialogFooter variation="error">
					{t('user_sign_in_failed_message')}
				</DialogFooter>
			)}
		</>
	);
};

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';
import Responsive from '../../../../ui/utils/Responsive';

export const StyledNoResultsMessage = styled(ContentWrap)`
	text-align: center;
	padding: 80px 0px 40px 0px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: 40px 0px;
	}

	h3 {
		margin: 0 auto;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			font-size: 24px;
		}
	}

	p {
		margin: 0 auto;
		max-width: 320px;
		padding-top: 12px;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		max-width: 200px;
		max-height: 60vh;
		margin: 0 auto;
		padding: 60px 0;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			padding: 40px 0px 20px 0px;
		}
	}
`;

interface INoResultsMessageProps {
	title: string;
	body?: string;
	imageSource: string;
	className?: string;
	children?: ReactNode;
}

export const NoResultsMessage = ({
	title,
	body,
	imageSource,
	className,
	children,
}: INoResultsMessageProps) => (
	<StyledNoResultsMessage className={className}>
		<img src={imageSource} alt="" />
		<h3>{title}</h3>
		{body && <p>{body}</p>}
		{children}
	</StyledNoResultsMessage>
);

import React, { ReactNode } from 'react';
import { PropGetters } from 'downshift';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ITheme } from '../../../themer';
import themed from '../../../themer/themed';
import { SelectCurrent } from './SelectCurrent';
import { SelectPlaceholder } from './SelectPlaceholder';
import { SelectToggleIcon } from './SelectToggleIcon';
import {
	DropdownItem,
	IDropdownItem,
	IGetItemDisplayValue,
	StyledDropdownItem,
} from '../../DropdownList';
import { appendErrorBoxShadow, CloseIcon } from '../..';
import { Css } from '../../../utils';
import { theme } from '../../../../features/shared/styles';

interface IStyledSelectMainProps {
	disabled?: boolean;
	invalid?: boolean;
	hasFloatingLabelStyle?: boolean;
	className?: string;
}

export const StyledSelectMain = styled.div<IStyledSelectMainProps, ITheme>`
	display: flex;
	position: relative;
	padding: ${themed(({ select }) => select.main.padding)};
	border: ${themed(({ select }) => select.main.border)};
	background: ${themed(({ select }, { disabled }) =>
		disabled ? select.disabled.background : select.main.background,
	)};
	border-radius: ${themed(({ select }) => select.main.borderRadius)};
	color: ${themed(({ select }, { disabled }) =>
		disabled ? select.disabled.color : select.main.color,
	)};
	box-shadow: ${themed(({ select, input }, { disabled, invalid }) => {
		const style = disabled ? select.disabled.boxShadow : select.main.boxShadow;

		return appendErrorBoxShadow(style, input, invalid);
	})};
	font-size: ${themed(({ select }) => select.main.fontSize)};
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

	${SelectCurrent} {
		flex-grow: 1;
	}

	${({ hasFloatingLabelStyle, theme: { input } }) =>
		hasFloatingLabelStyle &&
		css`
			padding-bottom: ${
				Css.calc(input.padding, (value) => value - 8) // Subtract to align with other input fields
			};
			${StyledDropdownItem} {
				padding-top: 4px;
			}
			${SelectCurrent} {
				padding-top: calc(${input.padding} / 4);
			}
			${SelectPlaceholder} {
				padding-top: calc(${input.padding} / 4);
				font-size: 13px;
			}
		`}
`;

export const SelectInput = styled.input<{}, ITheme>`
	position: absolute;
	left: -9999px;
	width: 1px;
	height: 1px;

	&:focus-visible + ${StyledSelectMain} {
		background: ${themed(({ select }) => select.focus.background)};
		color: ${themed(({ select }) => select.focus.color)};
		outline: ${theme.button?.primary?.focus?.outline};
	}
`;

export const SelectFloatingPlaceholder = styled.label<
	{ hasValue: boolean; isOpen: boolean },
	ITheme
>`
	position: absolute;
	top: 0;
	left: 0;
	padding: ${themed(({ input }) => input.padding)};
	transition: all 0.3s ease-in-out;
	opacity: 0;
	color: ${themed(({ input }) => input.placeholder.color)};

	${({ hasValue, isOpen, theme: { input } }) =>
		hasValue &&
		css`
			opacity: 1;
			padding: calc(${input.padding} / 3) ${input.padding};
			font-size: 13px;
			${isOpen &&
			css`
				color: ${input.placeholder.focussed.color};
			`}
		`}
`;

const SelectIcon = styled.div<{}, ITheme>`
	path {
		fill: ${themed(({ input }) => input.icon.background)};
	}
`;

interface ISelectMainProps {
	getInputProps: PropGetters<IDropdownItem>['getInputProps'];
	getToggleButtonProps: PropGetters<IDropdownItem>['getToggleButtonProps'];
	getItemDisplayValue: IGetItemDisplayValue;
	selectedItem: IDropdownItem | null;
	placeholder?: string;
	ariaLabel?: string;
	ariaLabelledBy?: string;
	disabled?: boolean;
	invalid?: boolean;
	allowsRemovingSelection?: boolean;
	onClickRemoveSelection?: () => void;
	isOpen: boolean;
	icon?: ReactNode;
	placeholderAsLabel?: boolean;
	id?: string;
}

export const SelectMain = ({
	getToggleButtonProps,
	selectedItem,
	placeholder,
	ariaLabel,
	ariaLabelledBy,
	getItemDisplayValue,
	isOpen,
	disabled,
	invalid,
	allowsRemovingSelection,
	onClickRemoveSelection,
	icon,
	placeholderAsLabel,
	id,
}: ISelectMainProps) => {
	const hint =
		selectedItem &&
		typeof selectedItem === 'object' &&
		selectedItem.type === 'item'
			? selectedItem.hint
			: undefined;
	const mainProps = disabled ? {} : getToggleButtonProps();

	const shouldShowOpenCloseIcon = allowsRemovingSelection
		? !disabled && selectedItem === null
		: !disabled;

	const shouldShowRemoveSelectionIcon = !shouldShowOpenCloseIcon;

	return (
		<>
			<SelectInput
				{...getToggleButtonProps()}
				id={id}
				aria-invalid={invalid}
				aria-labelledby={ariaLabelledBy}
				aria-label={ariaLabelledBy ? undefined : 'Select an option'}
			/>
			<StyledSelectMain
				{...mainProps}
				hasFloatingLabelStyle={selectedItem && placeholderAsLabel}
				aria-label={
					ariaLabelledBy
						? undefined
						: ariaLabel ?? getToggleButtonProps()['aria-label']
				}
				aria-labelledby={ariaLabelledBy}
				disabled={disabled}
				invalid={invalid}
				className={isOpen ? 'is-open' : ''}
			>
				{placeholderAsLabel && (
					<SelectFloatingPlaceholder
						isOpen={isOpen}
						hasValue={!!selectedItem}
						id={ariaLabelledBy}
					>
						{placeholder}
					</SelectFloatingPlaceholder>
				)}
				<SelectCurrent>
					{selectedItem ? (
						<DropdownItem
							displayValue={getItemDisplayValue(selectedItem)}
							hint={hint}
						/>
					) : (
						<SelectPlaceholder>{placeholder}</SelectPlaceholder>
					)}
				</SelectCurrent>
				{shouldShowOpenCloseIcon &&
					(icon ? (
						<SelectIcon>{icon}</SelectIcon>
					) : (
						<SelectToggleIcon isOpen={isOpen} />
					))}

				{shouldShowRemoveSelectionIcon && (
					<CloseIcon
						aria-label="Remove selection"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();

							if (onClickRemoveSelection) {
								onClickRemoveSelection();
							}
						}}
					/>
				)}
			</StyledSelectMain>
		</>
	);
};

import { ILocale } from '@move-frontend/contentful-routes';
import { IAnyContentfulEntry } from '@move-frontend/contentful/dist/api/types';
import { contentful } from '../../../../shared/api/contentful';
import { NEWS_ALERT_TYPE_ID } from '../../../../shared/api/contentful/constants';
import { INewsAlertEntry } from '../../../../shared/api/contentful/types';

export async function getNewsAlerts(
	locale: ILocale,
): Promise<INewsAlertEntry[]> {
	return contentful()
		.entry<INewsAlertEntry, IAnyContentfulEntry>(NEWS_ALERT_TYPE_ID)
		.get({
			locale: locale.code,
			order: '-sys.updatedAt',
			limit: 3,
		});
}

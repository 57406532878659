import styled from '@emotion/styled';

export const VideoMarkdownWrapper = styled.span`
	display: block;

	video {
		width: 100%;
		aspect-ratio: 3 / 2;
	}
`;

import React, { Children, ReactNode } from 'react';
import styled from '@emotion/styled';
import Responsive from '../../utils/Responsive';
import { useTheme } from '../../themer';
import { FixedGrid, FixedGridItem } from './FixedGrid';
import { PropsOf } from '../../types';
import { NumberOfColumns } from './theme';
import themed from '../../themer/themed';

export const StyledRow = styled(FixedGrid)`
	margin-bottom: ${themed(({ grid }) => grid.gutter.vertical.default)};

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: ${themed(({ grid }) => grid.gutter.vertical.mobileXL)};
	}
`;

export type IRowProps = Omit<PropsOf<typeof FixedGrid>, 'columns'> & {
	children?: ReactNode;
	columns?: NumberOfColumns;
};

export const Row = ({ children, className, columns }: IRowProps) => {
	const { grid } = useTheme() as any;

	return (
		<StyledRow
			className={className}
			columns={columns ?? grid.row.defaultColumns}
		>
			{Children.map(children, (child) => (
				<FixedGridItem>{child}</FixedGridItem>
			))}
		</StyledRow>
	);
};

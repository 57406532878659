import { AbstractService } from './AbstractService';
import {
	IParkingProductsQuoteCreateRequestData,
	IParkingProductsQuoteCreateResponse,
} from '../types';
import { IGetParkingProductUpgradesQueryParams } from '../types/IGetParkingProductUpgradesQueryParams';
import { IGetParkingProductUpgradesResponse } from '../types/IGetParkingProductUpgradesResponse';
import { IGetAncillaryGroupsQueryParams } from '../types/IGetAncillaryGroupsQueryParams';
import { IGetAncillaryGroupsResponse } from '../types/IGetAncillaryGroupsResponse';
import { IParkingProductsQuoteParams } from '../types/IParkingProductsQuoteParams';

export class ParkingProductsQuoteService extends AbstractService {
	create({
		locale,
		...data
	}: IParkingProductsQuoteCreateRequestData & IParkingProductsQuoteParams) {
		return this.client.post<
			IParkingProductsQuoteCreateRequestData,
			IParkingProductsQuoteCreateResponse
		>('/parking/parking-products-quotes', data, {
			queryStringParameters: {
				locale,
			},
		});
	}

	getUpgrades(params: IGetParkingProductUpgradesQueryParams) {
		return this.client.get<IGetParkingProductUpgradesResponse>(
			'/parking/parking-products-quotes/upgrades',
			{
				queryStringParameters: {
					...params,
				},
			},
		);
	}

	getAncillaryGroups(params: IGetAncillaryGroupsQueryParams) {
		return this.client.get<IGetAncillaryGroupsResponse>(
			'/parking/parking-products-quotes/ancillary-groups',
			{
				queryStringParameters: {
					...params,
				},
			},
		);
	}
}

import React from 'react';
import styled from '@emotion/styled';
import { StyledHeaderParentTitle, StyledHeaderTitle } from '../../../../ui';
import { Header, IHeaderProps } from './Header';
import { TierPointsInfoTag } from '../../../account/ui/shared/TierPointsInfoTag';
import Responsive from '../../../../ui/utils/Responsive';

export const StyledUserHeaderTitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 32px;

	${StyledHeaderParentTitle} + & {
		margin-top: 0;
	}

	${StyledHeaderTitle} {
		margin-top: 0;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		gap: 16px;
		flex-direction: column-reverse;
		align-items: flex-start;
	}
`;

export const UserHeader = ({ title, ...rest }: IHeaderProps) => (
	<Header {...rest}>
		<StyledUserHeaderTitleWrap>
			<StyledHeaderTitle>{title}</StyledHeaderTitle>
			<TierPointsInfoTag />
		</StyledUserHeaderTitleWrap>
	</Header>
);

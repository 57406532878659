import { RemoteDataStore } from '@move-frontend/utils';
import { action, makeObservable, observable } from 'mobx';
import { ParkingProductsQuoteService } from '../../../../../shared/api/gateway/services/ParkingProductsQuoteService';
import { IGetAncillaryGroupsQueryParams } from '../../../../../shared/api/gateway/types/IGetAncillaryGroupsQueryParams';
import { IGetAncillaryGroupsResponse } from '../../../../../shared/api/gateway/types/IGetAncillaryGroupsResponse';
import { IAncillaryGroup } from '../models/IAncillaryGroup';
import { adaptResponseToAncillaryGroup } from './adapters/adaptResponseToAncillaryGroup';

export class AncillaryGroupStore extends RemoteDataStore<
	IGetAncillaryGroupsQueryParams,
	IGetAncillaryGroupsResponse
> {
	ancillaryGroups = observable<IAncillaryGroup>([]);

	constructor(private readonly service: ParkingProductsQuoteService) {
		super();

		makeObservable<AncillaryGroupStore, 'handleResponse'>(this, {
			handleResponse: action,
			clear: action,
		});
	}

	protected performRequest(params: IGetAncillaryGroupsQueryParams) {
		return this.service.getAncillaryGroups(params);
	}

	protected handleResponse(response: IGetAncillaryGroupsResponse) {
		const ancillaryGroups = response.map(adaptResponseToAncillaryGroup);

		this.ancillaryGroups.replace(ancillaryGroups);
	}

	clear() {
		this.ancillaryGroups.clear();
	}
}

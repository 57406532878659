import React, { ChangeEvent, forwardRef, MouseEvent } from 'react';
import styled from '@emotion/styled';
import { getPaperBoxShadow, IPaperProps, Paper } from './Paper';
import { ITheme } from '../../themer';

import { VisuallyHiddenInput } from '../Input';
import themed from '../../themer/themed';

export interface IPaperSelectorProps extends IPaperProps {
	name?: string;
	value?: string;
	defaultChecked?: boolean;
	type?: 'radio' | 'checkbox';
	disabled?: boolean;
	onClick?: (e: MouseEvent) => void;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const PaperSelector = forwardRef<HTMLInputElement, IPaperSelectorProps>(
	(
		{
			name,
			type,
			value,
			defaultChecked,
			onClick,
			onChange,
			disabled,
			...props
		}: IPaperSelectorProps,
		ref,
	) => (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label>
			<VisuallyHiddenInput
				ref={ref}
				name={name}
				value={value}
				onChange={onChange}
				type={type ?? 'checkbox'}
				disabled={disabled}
			/>
			<StyledPaperSelector clickable disabled={disabled} {...props} />
		</label>
	),
);

const StyledPaperSelector = styled(Paper)<IPaperProps, ITheme>`
	input:focus + &,
	input:active + & {
		box-shadow: ${themed(({ paper }) => paper.clickable.hover.boxShadow)};
	}

	input:checked + & {
		box-shadow: ${(props) => getPaperBoxShadow({ selected: true, ...props })};
	}
`;

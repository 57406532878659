import styled from '@emotion/styled';
import { ITheme, IThemedProps, validateTheme } from '../../themer';
import { Gutter } from '../../utils';
import { IBreakpointName } from '../../types';
import Responsive from '../../utils/Responsive';

export interface IStyledGridItemProps extends IThemedProps {
	columns: Responsive.IValueOrResponsiveValue<number>;
	grow?: boolean;
}

const decimalFactor = 1e3;

const getStylesForColumns = (columns: number, props: IStyledGridItemProps) => {
	const theme = validateTheme(props.theme);
	const size =
		Math.floor((columns / theme.grid.columns) * 100 * decimalFactor) /
		decimalFactor;

	const cssProperty = props.grow ? 'min-width' : 'flex-basis';

	if (columns === 0) {
		return `
			${cssProperty}: 0;
			margin-top: 0;
			margin-left: 0;
		`;
	}

	return `
		${cssProperty}: calc(${size}% - ${theme.grid.gutter.horizontal.default});
		${cssProperty}: calc(${size}% - var(${Gutter.cssVariableName.horizontal}));
	`;
};

export interface IGridItemProps {
	columns: number | [number, Partial<{ [key in IBreakpointName]: number }>];
	grow?: boolean;
}

export const BaseGridItem = styled.div`
	${Gutter.getItemStyles('horizontal')}
	${Gutter.getItemStyles('vertical')}
`;

export const GridItem = styled(BaseGridItem)<IGridItemProps, ITheme>`
	${Responsive.getStyles<number, IStyledGridItemProps>(
		'columns',
		getStylesForColumns,
	)};
`;

import { IParkingProduct } from '../../../parking-product/models/IParkingProduct';
import { IBookingParkingProduct } from '../../models/IBooking';

export function adaptBookingParkingProductToAvailabeParkingProduct(
	bookingParkingProduct: IBookingParkingProduct,
	availableParkingProducts: IParkingProduct[],
): IParkingProduct | undefined {
	return availableParkingProducts.find(
		({ id }) => id === bookingParkingProduct.id,
	);
}

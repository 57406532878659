import { isPreviewMode } from '../../../app/preview/utils/isPreviewMode';
import { isProductionBuild } from '../../utils/isProductionBuild';

export interface IContentfulConfig {
	space: string;
	environment?: string;
	accessToken: string;
	host: string;
	hasCache: boolean;
	maxCacheAgeInSeconds: number;
}

export function getConfig(): IContentfulConfig {
	const space = process.env.CONTENTFUL_SPACE;

	if (!space) {
		throw new Error(
			'No Contentful Space was provided as environment variable.',
		);
	}

	const environment = process.env.CONTENTFUL_ENVIRONMENT;

	const accessToken = isPreviewMode()
		? process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN
		: process.env.CONTENTFUL_ACCESS_TOKEN;

	if (!accessToken) {
		throw new Error(
			'No valid Contentful Access Token was provided as environment variable.',
		);
	}

	const host = isPreviewMode()
		? 'preview.contentful.com'
		: 'cdn.contentful.com';

	const maxCacheAgeInSeconds =
		isProductionBuild() && !isPreviewMode() ? 15 * 60 : 60;

	return {
		host,
		space,
		environment,
		accessToken,
		hasCache: true,
		maxCacheAgeInSeconds,
	};
}

import styled from '@emotion/styled';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';
import Responsive from '../../../../ui/utils/Responsive';

export const SECTION_SPACING_DESKTOP = '30px';
export const SECTION_SPACING_MOBILE = '16px';

export const Section = styled(ContentWrap.withComponent('section'))`
	padding-top: ${SECTION_SPACING_DESKTOP};
	padding-bottom: ${SECTION_SPACING_DESKTOP};

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding-top: ${SECTION_SPACING_MOBILE};
		padding-bottom: ${SECTION_SPACING_MOBILE};
	}
`;

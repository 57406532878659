import { background } from '../../themer/theme/background';

export const theme = {
	animation: {
		duration: '2.2s',
	},
	placeholder: {
		defaultWidth: '100%',
		defaultHeight: '100%',
		background: background.light,
		borderRadius: '2px',
	},
};

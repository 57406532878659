import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { cognitoConfig } from './config';
import { IChangeCallback } from './types/IChangeCallback';
import { IUpdateUserAttributesData } from '../../../auth/user/domain/models/IUpdateUserAttributesData';
import { ISendRecoveryLinkData } from '../../../auth/forgot-password/domain/models/ISendRecoveryLinkData';
import { ISignInData } from '../../../auth/sign-in/domain/models/ISignInData';
import { ISignUpData } from '../../../auth/sign-up/domain/models/ISignUpData';
import { ICognitoUserInfo } from './types/ICognitoUserInfo';

export class CognitoClient {
	constructor() {
		Auth.configure(cognitoConfig);
	}

	signIn({ email, password }: ISignInData) {
		return Auth.signIn(email, password);
	}

	signOut() {
		return Auth.signOut();
	}

	signUp({
		email: username,
		password,
		firstName,
		lastName,
		licensePlateNumber,
	}: ISignUpData) {
		return Auth.signUp({
			username,
			password,
			attributes: {
				given_name: firstName,
				family_name: lastName,
				'custom:license_plate_number': licensePlateNumber,
			},
		});
	}

	sendRecoveryLink({ email }: ISendRecoveryLinkData) {
		return Auth.forgotPassword(email);
	}

	resetPassword(username: string, code: string, newPassword: string) {
		return Auth.forgotPasswordSubmit(username, code, newPassword);
	}

	async changePassword(oldPassword: string, newPassword: string) {
		const user = await this.getCurrentUser();

		return Auth.changePassword(user, oldPassword, newPassword);
	}

	getCurrentUser(): Promise<CognitoUser> {
		return Auth.currentAuthenticatedUser();
	}

	getCurrentUserInfo(): Promise<ICognitoUserInfo | undefined> {
		return Auth.currentUserInfo();
	}

	async updateUserAttributes(
		user: CognitoUser,
		{ address, ...rest }: IUpdateUserAttributesData,
	) {
		await Auth.updateUserAttributes(user, {
			address: JSON.stringify(address),
			...rest,
		});
	}

	addChangeListener(callback: IChangeCallback) {
		Hub.listen('auth', callback);
	}

	removeChangeListener(callback: IChangeCallback) {
		Hub.remove('auth', callback);
	}
}

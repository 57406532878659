import { useMemo } from 'react';
import { useTranslation } from '../../../../../shared/i18n/translate/useTranslation';
import { createSignUpSchema } from '../../../domain/models/SignUpSchema';

export function useSignUpSchema() {
	const { t } = useTranslation();

	const schema = useMemo(() => createSignUpSchema(t), [t]);

	return schema;
}

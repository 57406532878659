import React, { MouseEvent } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import illustration from '../../../../../../assets/email.svg?reference';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { Trans } from '../../../../shared/i18n/translate/Trans';
import {
	DialogHeader,
	DialogHeaderGradient,
	DialogIllustration,
	DialogMain,
} from '../../../../shared/components/dialog';
import { useForgotPasswordResend } from './helpers/useForgotPasswordResend';
import { ITextButtonProps, TextButton } from '../../../../../ui';

interface IProps {
	title: string;
}

export const ForgotPasswordConfirmationDialogContent = observer(
	({ title }: IProps) => {
		const { t } = useTranslation();
		const { email, resend } = useForgotPasswordResend();

		return (
			<>
				<DialogHeader hide={[false, { tabletXL: true }]} />
				<DialogHeaderGradient
					hide={[true, { tabletXL: false }]}
					title={title}
				/>
				<DialogMain>
					<DialogIllustration src={illustration} />
					<h3>{t('user_forgot_password_confirmation_title')}</h3>
					<p>
						<ConfirmationMessage email={email} onClickResend={resend} />
					</p>
				</DialogMain>
			</>
		);
	},
);

interface IConfirmationMessageProps {
	email?: string;
	onClickResend?: (e: MouseEvent) => void;
}

const ConfirmationMessage = ({
	email,
	onClickResend,
}: IConfirmationMessageProps) => {
	const link = <ResendLink onClick={onClickResend} />;

	return (
		<Trans
			i18nKey="web_user_forgot_password_confirmation_body"
			values={{ user_email_address: email }}
			components={{ link }}
		/>
	);
};

const ResendLink = (props: ITextButtonProps) => {
	const { t } = useTranslation();

	return (
		<StyledResendLink variant="highlight" {...props}>
			{t('web_user_forgot_password_confirmation_resend_link_text')}
		</StyledResendLink>
	);
};

const StyledResendLink = styled(TextButton)`
	padding: 0;
	text-decoration: underline;
`;

import { IAncillary } from '../../../ancillary/domain/models/IAncillary';
import { IAncillaryGroup } from '../../../ancillary/domain/models/IAncillaryGroup';
import { IAncillaryGroupSelection } from '../../../ancillary/domain/models/IAncillaryGroupSelection';

export function getSelectionsForAncillaries(
	ancillaryGroups: IAncillaryGroup[],
	selectedAncillaries: IAncillary[],
): IAncillaryGroupSelection[] {
	const allSelectedAncillaryIds = selectedAncillaries.map(({ id }) => id);

	const ancillaryGroupSelections = ancillaryGroups.map((ancillaryGroup) => {
		const ancillarySelections = ancillaryGroup.ancillaries.map((ancillary) => {
			const selectedExtras = selectedAncillaries.find(
				({ id }) => id === ancillary.id,
			)?.extras;

			const extraSelections = ancillary.extras?.map((extra) => {
				const selectedExtraVariantIds = selectedExtras
					?.find(({ id }) => id === extra.id)
					?.variants.map(({ id }) => id);

				return {
					id: extra.id,
					selected: selectedExtraVariantIds ?? [],
				};
			});

			return {
				id: ancillary.id,
				extras: extraSelections ?? [],
			};
		});

		const selectedAncillaryIds = ancillarySelections
			.map(({ id }) => id)
			.filter((id) => allSelectedAncillaryIds.includes(id));

		return {
			id: ancillaryGroup.id,
			selected: selectedAncillaryIds,
			ancillaries: ancillarySelections,
		};
	});

	return ancillaryGroupSelections;
}

import React from 'react';
import styled from '@emotion/styled';
import { useIsPreviewModeDisabled } from './useIsPreviewModeDisabled';
import { StyledIllustratedErrorMessage } from '../../../shared/components';
import illustration from '../../../../../assets/something-went-wrong.svg?reference';

export const PreviewModeWrapper: ({
	children,
}: {
	children: any;
}) => React.JSX.Element = ({ children }) => {
	const isPreviewModeDisabled = useIsPreviewModeDisabled();

	if (!isPreviewModeDisabled) {
		return <>{children}</>;
	}

	return (
		<StyledIllustratedErrorMessage>
			<img src={illustration} alt="" />
			<StyledMessage>
				Preview Mode is disabled. Please use the Preview button in Contentful to
				enable Preview Mode.
			</StyledMessage>
		</StyledIllustratedErrorMessage>
	);
};

const StyledMessage = styled.p`
	margin: 0 auto;
	text-align: center;
`;

import { background } from '../../themer/theme/background';
import { border } from '../../themer/theme/border';
import { typography } from '../../themer/theme/typography';
import { color } from '../../themer/theme/color';

const base = {
	background: '',
	border: border.highlight,
	boxShadow: '',
	transition: 'all .1s',
	transform: '',
	font: {
		color: '',
		size: typography.body.size,
		weight: '',
		fontFamily: '',
	},
	hover: {
		background: '',
		border: '',
		boxShadow: '',
		font: {
			color: '',
		},
		transition: '',
		transform: '',
	},
	active: {
		background: '',
		border: '',
		boxShadow: '',
		font: {
			color: '',
		},
		transition: '',
		transform: '',
	},
	focus: {
		outline: '',
	},
	disabled: {
		opacity: '0.3',
		cursor: 'default',
		background: '',
		border: '',
		boxShadow: '',
		font: {
			color: '',
		},
	},
};

export const theme = {
	radius: '4px',
	padding: '0 15px',
	height: '46px',
	lineHeight: '44px',
	gutter: {
		horizontal: '20px',
		vertical: '20px',
	},
	iconSize: '1.2em',
	iconSeparator: '0.3em',
	wrap: {
		buttonGutter: '20px',
		margin: '20px',
	},
	primary: {
		...base,
		background: background.highlight,
		font: {
			...base.font,
			color: color.text.inverted,
		},
		hover: {
			...base.hover,
			background: color.text.hover,
			font: {
				...base.hover.font,
				color: color.text.inverted,
			},
		},
		inverted: {
			...base,
			font: {
				...base.font,
				color: '#707070',
			},
			hover: {
				...base.hover,
				font: {
					...base.hover.font,
					color: '#707070',
				},
			},
		},
	},
	secondary: {
		...base,
		background: 'transparent',
		font: {
			...base.font,
			color: color.highlight,
		},
		hover: {
			...base.hover,
			font: {
				color: color.text.hover,
			},
		},
		inverted: {
			...base,
			background: 'transparent',
			font: {
				...base.font,
				color: color.highlight,
			},
			hover: {
				...base.hover,
				background: 'transparent',
				font: {
					...base.hover.font,
					color: color.text.hover,
				},
			},
		},
	},
	textButton: {
		padding: '6px',
		transition: 'color 0.1s',
		fontFamily: 'inherit',
		fontSize: 'inherit',
	},
};

import { IOnFailure } from '@move-frontend/auth';
import { useSignInWithCallbacks } from './useSignInWithCallbacks';
import { ISignInOnSuccess } from '../../domain/models/ISignInOnSuccess';

export function useSignInSubmit(
	onSuccess: ISignInOnSuccess,
	onFailure: IOnFailure,
) {
	return useSignInWithCallbacks(onSuccess, onFailure);
}

export interface IChipTheme {
	background: {
		default?: string;
		light?: string;
		highlight?: string;
		brand?: string;
		error?: string;
	};
	font: {
		color?: {
			default?: string;
			light?: string;
			highlight?: string;
			brand?: string;
			error?: string;
		};
	};
}

export const theme: IChipTheme = {
	background: {},
	font: {
		color: {},
	},
};

import React, { HTMLAttributes } from 'react';

export const TableRole = (props: HTMLAttributes<HTMLDivElement>) => (
	<div {...props} role="table" />
);

export const RowGroupRole = (props: HTMLAttributes<HTMLDivElement>) => (
	<div {...props} role="rowgroup" />
);

export const RowRole = (props: HTMLAttributes<HTMLDivElement>) => (
	<div {...props} role="row" />
);

export const ColumnHeaderRole = (props: HTMLAttributes<HTMLDivElement>) => (
	<div {...props} role="columnheader" />
);

export const CellRole = (props: HTMLAttributes<HTMLDivElement>) => (
	<div {...props} role="cell" />
);

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import Responsive from '../../utils/Responsive';
import themed from '../../themer/themed';

export const StyledAlert = styled.div`
	border: 1px solid ${themed(({ alert }) => alert.border)};
	border-radius: ${themed(({ alert }) => alert.borderRadius)};
	padding: ${themed(({ alert }) => alert.padding)};

	p:last-of-type {
		margin-bottom: 0;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: ${themed(({ alert }) => alert.mobile.padding)};
	}
`;

export const StyledAlertTitle = styled.h2`
	margin-bottom: 0;
`;

export const StyledAlertSubtitle = styled.h5`
	font-size: ${themed(({ alert }) => alert.subtitle.fontSize)};
	color: ${themed(({ alert }) => alert.subtitle.color)};
`;

interface IAlertProps {
	title: string;
	subtitle: string;
	children: ReactNode;
}

export const Alert = ({ title, subtitle, children }: IAlertProps) => (
	<StyledAlert>
		<StyledAlertTitle>{title}</StyledAlertTitle>
		<StyledAlertSubtitle>{subtitle}</StyledAlertSubtitle>
		{children}
	</StyledAlert>
);

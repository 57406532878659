import { getSum } from '../../../../../shared/i18n/price/utils/getSum';
import { parsePrice } from '../../../../../shared/i18n/price/utils/parsePrice';
import { INewBooking } from '../INewBooking';
import { getTotalExtrasPrice } from './getTotalExtrasPrice';

export function getTotalNewBookingPrice(booking: INewBooking) {
	const parkingProductPrice =
		booking.parkingProduct?.price.value ?? parsePrice(0);

	const ancillariesSum = booking.ancillaries.reduce(
		(previousSum, ancillary) => {
			const ancillaryPrice = ancillary.price || parsePrice(0);
			const extrasTotalPrice = getTotalExtrasPrice(ancillary.extras);

			return getSum(previousSum, ancillaryPrice, extrasTotalPrice);
		},
		parsePrice(0),
	);

	return getSum(parkingProductPrice, ancillariesSum);
}

import { ILocale } from '@move-frontend/contentful-routes';
import { IAnyContentfulEntry } from '@move-frontend/contentful/dist/api/types';
import { contentful } from '../../../../shared/api/contentful';
import { PARKING_INFO_TYPE_ID } from '../../../../shared/api/contentful/constants';
import { IParkingInfoEntry } from '../../../../shared/api/contentful/types/IParkingInfo';

export async function getParkingInfo(
	locale: ILocale,
): Promise<IParkingInfoEntry[]> {
	return contentful()
		.entry<IParkingInfoEntry, IAnyContentfulEntry>(PARKING_INFO_TYPE_ID)
		.getAll({
			locale: locale.code,
		});
}

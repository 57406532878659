import styled from '@emotion/styled';
import themed from '../../../../ui/themer/themed';

export const ErrorBar = styled.div`
	background: ${themed(({ color }) => color.state.error)};
	color: ${themed(({ color }) => color.text.inverted)};
	padding: 16px;
`;

ErrorBar.defaultProps = {
	role: 'alert',
};

import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

import { IUIComponentProps } from '../../types';

export type TooltipPosition =
	| 'top'
	| 'bottom'
	| 'left'
	| 'right'
	| 'bottomRight'
	| 'bottomLeft'
	| 'topLeft'
	| 'topRight';

export interface ITooltipProps extends IUIComponentProps {
	position: TooltipPosition;
}

export const Tooltip = ({
	children,
	position,
	className,
}: PropsWithChildren<ITooltipProps>) => (
	<StyledTooltip className={`${position} ${className ?? className}`}>
		{children}
	</StyledTooltip>
);

const StyledTooltip = styled.div<{}, ITheme>`
	position: absolute;
	z-index: 1;
	white-space: nowrap;

	border-radius: 4px;
	padding: 10px 16px;
	background: var(
		--tooltip-background-color,
		${themed(({ color }) => color.highlight)}
	);
	line-height: 20px;

	&::before {
		content: ' ';
		left: 50%;
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-width: var(--tooltip-arrow-size, 7px);
	}

	&.top {
		bottom: calc(100% + 14px);
		left: 50%;
		transform: translateX(-50%);

		&::before {
			top: 100%;
			left: calc(50% - 14px); // half size of tooltip width - arrow width
			border-top-color: var(
				--tooltip-background-color,
				${themed(({ color }) => color.highlight)}
			);
		}
	}

	&.topLeft {
		bottom: calc(100% + 14px);
		left: -47px;

		&::before {
			top: 100%;
			left: var(--tooltip-top-left-arrow-pos, 50px);
			border-top-color: var(
				--tooltip-background-color,
				${themed(({ color }) => color.highlight)}
			);
		}
	}

	&.topRight {
		bottom: calc(100% + 14px);
		right: -37px;

		&::before {
			top: 100%;
			left: unset;
			right: var(--tooltip-top-right-arrow-pos, 50px);
			border-top-color: var(
				--tooltip-background-color,
				${themed(({ color }) => color.highlight)}
			);
		}
	}

	&.bottom {
		top: calc(100% + 14px);
		left: 50%;
		transform: translateX(-50%);

		&::before {
			bottom: 100%;
			left: calc(50% - 14px); // half size of tooltip width - arrow width
			border-bottom-color: var(
				--tooltip-background-color,
				${themed(({ color }) => color.highlight)}
			);
		}
	}

	&.bottomRight {
		top: calc(100% + 14px);
		right: -37px;

		&::before {
			bottom: 100%;
			left: unset;
			right: var(--tooltip-bottom-right-arrow-pos, 50px);
			border-bottom-color: var(
				--tooltip-background-color,
				${themed(({ color }) => color.highlight)}
			);
		}
	}

	&.bottomLeft {
		top: calc(100% + 14px);
		left: -47px;

		&::before {
			bottom: 100%;
			left: var(--tooltip-bottom-left-arrow-pos, 50px);
			border-bottom-color: var(
				--tooltip-background-color,
				${themed(({ color }) => color.highlight)}
			);
		}
	}

	&.left {
		left: auto;
		right: calc(100% + 14px);
		top: 50%;
		transform: translateX(0) translateY(-50%);

		&::before {
			left: auto;
			right: -13px;
			top: calc(50% - 7px);
			border-left-color: var(
				--tooltip-background-color,
				${themed(({ color }) => color.highlight)}
			);
		}
	}

	&.right {
		left: calc(100% + 14px);
		top: 50%;
		transform: translateX(0) translateY(-50%);

		&::before {
			left: -13px;
			top: calc(50% - 7px);
			border-right-color: var(
				--tooltip-background-color,
				${themed(({ color }) => color.highlight)}
			);
		}
	}
`;

import { IPatchBookingRequestData } from '../../../../../shared/api/gateway/types/IPatchBookingRequestData';
import { IDetailsFormData } from '../../../../ui/booking-steps/details/types/IDetailsFormData';

export function adaptDetailsFormDataToPatchRequest(
	data: IDetailsFormData,
): IPatchBookingRequestData {
	return {
		personalDetails: {
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			phoneNumber: data.phoneNumber,
			address: data.address,
			city: data.city,
			state: data.state,
			zipCode: data.zipCode,
		},
		vehicleDetails: {
			licensePlateNumber: data.licensePlateNumber,
		},
		travelDetails: {
			reasonForTravel: data.reasonForTravel,
		},
	};
}

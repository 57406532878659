import styled from '@emotion/styled';

export const UnstyledUl = styled.ul`
	margin: 0;
	padding: 0;
	text-indent: 0;
	list-style-type: none;
`;

export const UnstyledLi = styled.li`
	padding: 0px;
	margin: 0px;
	list-style-image: none;
	display: inline;
`;

export const UnstyledButton = styled.button`
	border: none;
	background: none;
	padding: 0;
	font-size: inherit;
	font-family: inherit;
	color: inherit;
	line-height: inherit;
	cursor: pointer;
`;

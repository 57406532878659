import { action, computed, makeObservable, observable } from 'mobx';
import { RemoteDataStore } from '@move-frontend/utils';
import log from 'loglevel';
import { IParkingProductsQuote } from '../models/IParkingProductsQuote';
import { IParkingProductsQuoteCreateResponse } from '../../../../shared/api/gateway/types';
import { ParkingProductsQuoteService } from '../../../../shared/api/gateway/services/ParkingProductsQuoteService';
import { UpgradesStore } from './UpgradesStore';
import { AncillaryGroupStore } from '../../ancillary/domain/stores/AncillaryGroupStore';
import { adaptResponseToParkingProductQuote } from './adapters/adaptResponseToParkingProductQuote';
import { LocaleCode } from '../../../../shared/i18n/locale/models/LocaleCode';

export interface IParkingProductsQuoteParams {
	entryDate: Date;
	exitDate: Date;
	promoCode?: string;
	locale: LocaleCode;
}

export class ParkingProductsQuoteStore extends RemoteDataStore<
	IParkingProductsQuoteParams,
	IParkingProductsQuoteCreateResponse
> {
	private _parkingProductsQuote: IParkingProductsQuote | undefined = undefined;

	private _upgradesStore: UpgradesStore;

	private _ancillaryGroupStore: AncillaryGroupStore;

	constructor(private readonly service: ParkingProductsQuoteService) {
		super();

		makeObservable<
			ParkingProductsQuoteStore,
			'_parkingProductsQuote' | 'handleResponse'
		>(this, {
			_parkingProductsQuote: observable,
			parkingProducts: computed,
			upgrades: computed,
			error: computed,
			handleResponse: action,
			clear: action,
		});

		this._upgradesStore = new UpgradesStore(service);
		this._ancillaryGroupStore = new AncillaryGroupStore(service);
	}

	get key() {
		return this._parkingProductsQuote?.key;
	}

	get parkingProducts() {
		return this._parkingProductsQuote?.parkingProducts ?? [];
	}

	get daysInTotal() {
		return this._parkingProductsQuote?.daysInTotal;
	}

	get upgrades() {
		// Only show upgrades for parking products that are actually available
		return this._upgradesStore.upgrades.filter((upgrade) =>
			this.parkingProducts.some(
				(parkingProduct) => parkingProduct.id === upgrade.id,
			),
		);
	}

	get error() {
		return (
			this._error ??
			this._upgradesStore.error ??
			this._ancillaryGroupStore.error
		);
	}

	get hasPromoCode() {
		return !!this._parkingProductsQuote?.promotions.length;
	}

	get hasLoyaltyPromoCode() {
		return (
			!!this.hasPromoCode && this._parkingProductsQuote?.promotions[0].loyalty
		);
	}

	get promoCode() {
		return this.hasPromoCode
			? this._parkingProductsQuote?.promotions[0].promoCode
			: undefined;
	}

	get loyaltyPromoCodeDetails() {
		return this.hasLoyaltyPromoCode
			? {
					promoCode: this._parkingProductsQuote?.promotions[0].promoCode,
					pointsCost: this._parkingProductsQuote?.promotions[0].pointsCost,
			  }
			: undefined;
	}

	get isPromoCodeValid() {
		return !!this._parkingProductsQuote?.promotions.some(
			({ isValid }) => isValid,
		);
	}

	get hasUpgrades() {
		return this._upgradesStore.upgrades.length > 0;
	}

	get hasFinishedLoadingUpgrades() {
		return (
			this._upgradesStore.finishedInitialLoad && !this._upgradesStore.isLoading
		);
	}

	get ancillaryGroups() {
		return this._ancillaryGroupStore.ancillaryGroups;
	}

	get hasAncillaries() {
		return this._ancillaryGroupStore.ancillaryGroups.length > 0;
	}

	get hasFinishedLoadingAncillaries() {
		return (
			this._ancillaryGroupStore.finishedInitialLoad &&
			!this._ancillaryGroupStore.isLoading
		);
	}

	protected async performRequest(params: IParkingProductsQuoteParams) {
		log.debug('Request Parking Products Quote', params);

		const { entryDate, exitDate, ...rest } = params;

		return this.service.create({
			arrivalDateTime: entryDate.toISOString(),
			returnDateTime: exitDate.toISOString(),
			...rest,
		});
	}

	protected handleResponse(response: IParkingProductsQuoteCreateResponse) {
		const parkingProductsQuote = adaptResponseToParkingProductQuote(response);
		this._parkingProductsQuote = parkingProductsQuote;
	}

	clear() {
		this._parkingProductsQuote = undefined;
		this._upgradesStore.clear();
		this._ancillaryGroupStore.clear();
	}

	reset() {
		this._upgradesStore.reset();
		this._ancillaryGroupStore.reset();
		return super.reset();
	}

	findParkingProduct(id: number) {
		return this.parkingProducts.find((item) => item.id === id);
	}

	updateUpgrades(locale: LocaleCode, parkingProductId?: number) {
		if (!parkingProductId || !this._parkingProductsQuote) {
			this._upgradesStore.reset();
			return;
		}

		this._upgradesStore.load({
			parkingProductsQuoteKey: this._parkingProductsQuote.key,
			parkingProductId,
			locale,
		});
	}

	updateAncillaryGroups(locale: LocaleCode, parkingProductId?: number) {
		if (!parkingProductId || !this._parkingProductsQuote) {
			this._ancillaryGroupStore.reset();
			return;
		}

		this._ancillaryGroupStore.load({
			parkingProductId,
			parkingProductsQuoteKey: this._parkingProductsQuote.key,
			arrivalDateTime: this._parkingProductsQuote.arrivalDateTime,
			returnDateTime: this._parkingProductsQuote.returnDateTime,
			promoCode: this.promoCode,
			locale,
		});
	}
}

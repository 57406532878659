import React from 'react';
import { HashDialog } from '../../../shared/components/dialog/HashDialog';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { ForgotPasswordDialogContent } from '../../forgot-password/ui/ForgotPasswordDialogContent';
import { RESET_PASSWORD_CONFIRMATION_DIALOG_HASH } from './confirmation/ResetPasswordConfirmationDialog';

export const RESET_PASSWORD_DIALOG_HASH = '#reset-password';

// This is a variation of the Forgot Password Dialog, the only difference is the copy
export const ResetPasswordDialog = () => {
	const { t } = useTranslation();

	const title = t('user_reset_password_title');
	return (
		<HashDialog ariaLabel={title} hash={RESET_PASSWORD_DIALOG_HASH}>
			<ForgotPasswordDialogContent
				confirmationDialogHash={RESET_PASSWORD_CONFIRMATION_DIALOG_HASH}
				title={title}
				description={t('user_reset_password_description')}
				customButtonText={t('user_reset_password_send_link_button')}
				hideBackLink
			/>
		</HashDialog>
	);
};

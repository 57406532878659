import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { ITextFieldProps, TextField } from '../../../../ui';

export const Email = (props: Partial<ITextFieldProps>) => {
	const { t } = useTranslation();
	const { register, errors } = useFormContext();

	return (
		<TextField
			name="email"
			type="email"
			label={t('user_form_email_label')}
			placeholder={t('user_form_email_placeholder')}
			autoComplete="username"
			ref={register()}
			invalid={!!errors.email}
			error={errors.email?.message}
			{...props}
		/>
	);
};

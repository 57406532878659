import { getGradientBackground } from '../../styles';
import { ICallToActionTheme } from './ICallToActionTheme';

export function getCallToActionStyle(callToActionTheme: ICallToActionTheme) {
	if (callToActionTheme === 'grey') {
		// Use the default styling.
		return '';
	}

	return `
		${getGradientBackground(callToActionTheme)}

		h3 {
			color: #fff;
		}
	`;
}

export interface IDateSelectTheme {
	format: Intl.DateTimeFormatOptions;
}

export const theme: IDateSelectTheme = {
	format: {
		day: 'numeric',
		month: 'long',
	},
};

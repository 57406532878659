import React from 'react';
import styled from '@emotion/styled';
import { IIframeProps } from './IIframeProps';
import { IframeWrap } from './IframeWrap';

export const StyledIframe = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const ResponsiveIframe = ({
	url,
	maxWidth,
	maxHeight,
	title,
	...rest
}: IIframeProps) => (
	<IframeWrap maxWidth={maxWidth} maxHeight={maxHeight}>
		<StyledIframe src={url} title={title} {...rest} frameBorder="0" />
	</IframeWrap>
);

import {
	action,
	computed,
	IReactionDisposer,
	makeObservable,
	observable,
	reaction,
} from 'mobx';
import { RemoteDataStore } from '@move-frontend/utils';
import log from 'loglevel';
import { AccountService } from '../../../shared/api/gateway/services/AccountService';
import {
	ICustomerDetailsResponse,
	ILoyaltyDetailsResponse,
} from '../../../shared/api/gateway/types/ICustomerDetailsResponse';
import { AuthStore } from '../../../auth/shared/store/AuthStore';
import { IOffer } from '../models/IOffer';
import { AuthState } from '../../../auth/shared/store/state/AuthState';
import { NewBookingStore } from '../../../parking/domain/booking/stores/NewBookingStore';

interface IGeneratePromoCode {
	promoCode?: string;
	error?: unknown;
}
type LoyaltyAccountParams = Record<never, never>;

export class LoyaltyAccountStore extends RemoteDataStore<
	LoyaltyAccountParams,
	ICustomerDetailsResponse
> {
	loadingLoyaltyDetails = false;

	loadingPromoCode = false;

	private _customerDetails: ICustomerDetailsResponse | undefined = undefined;

	private _loyaltyDetails: ILoyaltyDetailsResponse | undefined = undefined;

	private _promoCode: string | undefined = undefined;

	private _selectedOffer: IOffer | undefined = undefined;

	private disposeAuthReaction: IReactionDisposer;

	constructor(
		private readonly authStore: AuthStore,
		private readonly accountService: AccountService,
		private readonly bookingStore: NewBookingStore,
	) {
		super();

		makeObservable<
			LoyaltyAccountStore,
			| '_customerDetails'
			| '_loyaltyDetails'
			| '_promoCode'
			| '_selectedOffer'
			| 'handleAuthChange'
			| 'handleResponse'
		>(this, {
			_customerDetails: observable,
			_loyaltyDetails: observable,
			_promoCode: observable,
			_selectedOffer: observable,
			loadingLoyaltyDetails: observable,
			loadingPromoCode: observable,
			isLoading: computed,
			isLoadingLoyaltyDetails: computed,
			isPromoCodeLoading: computed,
			loyaltyDetails: computed,
			handleAuthChange: action.bound,
			handleResponse: action,
			clear: action,
		});

		this.disposeAuthReaction = reaction(
			() => this.authStore.authState,
			this.handleAuthChange,
		);

		// refresh the store once the booking is done
		reaction(
			() => this.bookingStore.booking.reference,
			() => this.load(),
		);

		this.handleAuthChange();
	}

	public dispose() {
		this.disposeAuthReaction();
	}

	get isLoading() {
		return !this.authStore.finishedInitialLoad || this.isLoadingLoyaltyDetails;
	}

	get isLoadingLoyaltyDetails() {
		return super.isLoading;
	}

	get customerDetails() {
		return this._customerDetails;
	}

	get loyaltyDetails() {
		return this._loyaltyDetails;
	}

	get promoCode() {
		return this._promoCode;
	}

	set promoCode(promoCode) {
		this._promoCode = promoCode;
	}

	get isPromoCodeLoading() {
		return this.loadingPromoCode;
	}

	get selectedOffer() {
		return this._selectedOffer;
	}

	set selectedOffer(offer: IOffer | undefined) {
		this._selectedOffer = offer;
	}

	removeOfferPromoCode() {
		this._selectedOffer = undefined;
		this._promoCode = undefined;
	}

	private async handleAuthChange() {
		if (this.authStore.authState === AuthState.Unauthenticated) {
			this._customerDetails = undefined;
			this._loyaltyDetails = undefined;
			this.cancelCurrentRequest();

			// if user is not authenticated any more, remove the loyalty promo code from the booking store
			if (this._promoCode === this.bookingStore.booking.promoCode) {
				this.bookingStore.removeOfferPromoCode();
			}
			// remove the loyalty promo code if the user is not authenticated
			this.removeOfferPromoCode();

			return;
		}

		await this.load();
	}

	async load() {
		if (!this.authStore.isAuthenticated) {
			return;
		}

		await super.load({});
	}

	protected performRequest(): Promise<ICustomerDetailsResponse> {
		return this.accountService.getCustomerDetails();
	}

	protected handleResponse(response: ICustomerDetailsResponse) {
		this._customerDetails = response;
		this._loyaltyDetails = response.loyaltyDetails;
	}

	async deleteAccount() {
		if (this.authStore.isAuthenticated) {
			await this.accountService.deleteCustomer();
			this.handleDeleteAccountSuccess();
		}
	}

	private handleDeleteAccountSuccess() {
		this.reset();
	}

	async reset() {
		await super.reset();
	}

	clear() {
		this._customerDetails = undefined;
		this._loyaltyDetails = undefined;
	}

	public async loadCustomerPromoCode(
		offer: IOffer,
	): Promise<IGeneratePromoCode> {
		try {
			this.loadingPromoCode = true;
			this._selectedOffer = offer;
			const { promoCode } = await this.accountService.generatePromoCode(
				offer.id,
			);
			this._promoCode = promoCode;
			return { promoCode };
		} catch (e) {
			log.error('Generate promo code', e);
			return { promoCode: undefined, error: e };
		} finally {
			this.loadingPromoCode = false;
		}
	}
}

import { ButtonHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

export interface ITextButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'highlight' | 'inverted' | 'error';
}

const getTextButtonColor = themed<ITextButtonProps>(
	({ color }, { variant }) => {
		switch (variant) {
			case 'inverted':
				return color.text.inverted;

			case 'highlight':
				return color.highlight;

			case 'error':
				return color.state.error;

			default:
				return 'inherit';
		}
	},
);

const getTextButtonColorHover = themed<ITextButtonProps>(
	({ color }, { variant }) => {
		switch (variant) {
			case 'inverted':
				return color.text.inverted;

			case 'highlight':
				return color.state.hover;

			case 'error':
				return color.state.error;

			default:
				return 'inherit';
		}
	},
);

export const TextButton = styled.button<ITextButtonProps, ITheme>`
	-webkit-appearance: none;
	padding: ${themed(({ button }) => button.textButton.padding)};
	background: none;
	border: none;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	color: ${getTextButtonColor};
	font-family: ${themed(({ button }) => button.textButton.fontFamily)};
	font-size: ${themed(({ button }) => button.textButton.fontSize)};
	transition: ${themed(({ button }) => button.textButton.transition)};
	opacity: ${themed(({ button }, { disabled }) =>
		disabled ? button.primary.disabled.opacity : '',
	)};

	&:hover {
		color: ${getTextButtonColorHover};
	}
`;

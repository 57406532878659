import { ILocale } from '@move-frontend/contentful-routes';
import { IAnyContentfulEntry } from '@move-frontend/contentful/dist/api/types';
import { contentful } from '../../../../shared/api/contentful';
import { FOOTER_TYPE_ID } from '../../../../shared/api/contentful/constants';
import { IFooterEntry } from '../../../../shared/api/contentful/types';

export async function getFooter(
	locale: ILocale,
): Promise<IFooterEntry | undefined> {
	return contentful()
		.entry<IFooterEntry, IAnyContentfulEntry>(FOOTER_TYPE_ID)
		.getFirst({
			locale: locale.code,
			order: '-sys.updatedAt',
			include: 3,
		});
}

import React, { FC } from 'react';
import { AppContextProvider } from './AppContextProvider';
import { GlobalStyles } from '../../../shared/styles/GlobalStyles';
import { IAppProps } from '../domain/models/IAppProps';
import { ErrorBoundary } from '../../../shared/components';
import { useNavigationTracking } from '../../../shared/analytics/navigation/useNavigationTracking';
import { GoogleAnalyticsScript } from '../../../shared/analytics/GoogleAnalyticsScript';
import { BingTrackingScript } from '../../../shared/analytics/BingTrackingScript';
import { AppMetaData } from './AppMetaData';
import { useLoadRemoteConfig } from '../../../shared/remote-config/ui/useLoadRemoteConfig';
import { initSentry } from './utils/initSentry';
import { PreviewModeWrapper } from '../../preview/ui/PreviewModeWrapper';
import { Chatbot } from '../../../chatbot/Chatbot';
import { useSetUserProperties } from '../../../shared/analytics/user/useSetUserProperties';
import { AuthDialogs } from '../../../auth/AuthDialogs';
import { MonsidoAdaScript } from '../../../shared/layout/navigation/accessibility/MonsidoAdaScript';
import { PageLoader } from '../../../../ui';

initSentry();

export const App: FC<IAppProps> = ({ Component, pageProps, commonData }) => {
	useNavigationTracking();
	useLoadRemoteConfig();
	useSetUserProperties();

	return (
		<>
			<AppContextProvider commonData={commonData}>
				<Chatbot />
				<GlobalStyles />
				<PreviewModeWrapper>
					<AppMetaData />
					<GoogleAnalyticsScript />
					<MonsidoAdaScript />
					<BingTrackingScript />
					<ErrorBoundary>
						<PageLoader />
						<Component {...pageProps} />
						<AuthDialogs />
					</ErrorBoundary>
				</PreviewModeWrapper>
			</AppContextProvider>
		</>
	);
};

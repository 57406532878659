import { AxiosRequestConfig } from 'axios';
import { AbstractService } from '@move-frontend/dap';
import { IParkingAvailabilityResponse } from './types/IParkingAvailabilityResponse';

export class ParkingAvailabilityService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	public static readonly URI_PATH = '/parking/availability/CLT';

	public static readonly VERSION = '110';

	public get(
		requestConfig: AxiosRequestConfig = {
			method: 'GET',
			headers: {
				'Api-Version': ParkingAvailabilityService.VERSION,
			},
		},
	): Promise<IParkingAvailabilityResponse> {
		const uri = ParkingAvailabilityService.URI_PATH;

		return this.client.request(uri, requestConfig);
	}
}

import React from 'react';
import { GlobalStyles as BaseGlobalStyles } from '../../../ui';
import { Fonts } from './Fonts';
import { Table } from './Table';

export const GlobalStyles = () => (
	<>
		<BaseGlobalStyles />
		<Fonts />
		<Table />
	</>
);

import React, { forwardRef, ReactNode } from 'react';
import styled from '@emotion/styled';
import { ITheme, IThemedProps } from '../../../../ui';
import { DialogCloseButton } from './DialogCloseButton';
import {
	getDialogMaxWidth,
	getDialogPadding,
	getDialogPaddingMobile,
	IDialogSizeProps,
} from './DialogMain';
import Responsive from '../../../../ui/utils/Responsive';

export interface IResponsiveHiddenProps extends IThemedProps {
	hide?: Responsive.IValueOrResponsiveValue<boolean>;
}

export interface IStyledDialogHeaderProps
	extends IDialogSizeProps,
		IResponsiveHiddenProps {}

export const StyledDialogHeader = styled.div<IStyledDialogHeaderProps, ITheme>`
	${Responsive.getHideStyles()}
	padding: ${getDialogPadding} ${getDialogPadding} 0;

	h3 {
		max-width: ${getDialogMaxWidth};
		margin-left: auto;
		margin-right: auto;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: ${getDialogPaddingMobile} ${getDialogPaddingMobile} 0;
	}
`;

interface IDialogHeaderProps extends IStyledDialogHeaderProps {
	className?: string;
	title?: string;
	children?: ReactNode;
	closeButtonRef?: React.Ref<HTMLButtonElement>;
}

export const DialogHeader = forwardRef<HTMLDivElement, IDialogHeaderProps>(
	({ title, children, closeButtonRef, ...rest }: IDialogHeaderProps, ref) => (
		<StyledDialogHeader {...rest} ref={ref}>
			<DialogCloseButton ref={closeButtonRef} />
			{title && <h3>{title}</h3>}
			{children}
		</StyledDialogHeader>
	),
);

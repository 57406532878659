/* eslint-disable no-inner-declarations, @typescript-eslint/no-namespace */
export namespace Utils {
	export const deepMerge = (...sources: any) => {
		let returnValue = {};

		for (const source of sources) {
			if (Array.isArray(source)) {
				if (!Array.isArray(returnValue)) {
					returnValue = [];
				}

				returnValue = [...(returnValue as []), ...source];
			} else if (isObject(source)) {
				for (const key of Object.keys(source)) {
					let value = source[key];

					if (isObject(value) && Reflect.has(returnValue, key)) {
						value = deepMerge((returnValue as any)[key], value);
					}

					returnValue = { ...returnValue, [key]: value };
				}
			}
		}

		return returnValue;
	};

	const isObject = (value: any) => value !== null && typeof value === 'object';

	export const getCamelCased = (string: string) =>
		string.replace(/-([a-z])/g, (matches) => matches[1].toUpperCase());
}

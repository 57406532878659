import { DeepPartial } from '../../types';

import { background } from './background';
import { blockquote } from './blockquote';
import { border } from './border';
import { breakpoints } from './breakpoints';
import { color } from './color';
import { layout } from './layout';
import { separator } from './separator';
import { typography } from './typography';
import { zIndex } from './zIndex';

// Keep references to component styles explicitly pointing to the theme file rather than
// importing it from index.ts to prevent circular dependencies to be created
import { theme as accordion } from '../../components/Accordion/theme';
import { theme as alert } from '../../components/Alert/theme';
import { theme as alertBanner } from '../../components/AlertBanner/theme';
import { theme as button } from '../../components/Button/theme';
import { theme as callToAction } from '../../components/CallToAction/theme';
import { theme as card } from '../../components/Card/theme';
import { theme as checkbox } from '../../components/Checkbox/theme';
import { theme as chip } from '../../components/Chip/theme';
import { theme as contact } from '../../components/Contact/theme';
import { theme as dateSelect } from '../../components/DateSelect/theme';
import { theme as dropdownList } from '../../components/DropdownList/theme';
import { theme as grid } from '../../components/Grid/theme';
import { theme as highlight } from '../../components/Highlight/theme';
import { theme as input } from '../../components/Input/theme';
import { theme as link } from '../../components/Link/theme';
import { theme as pageLoader } from '../../components/PageLoader/theme';
import { theme as paper } from '../../components/Paper/theme';
import { theme as quickLink } from '../../components/QuickLinks/theme';
import { theme as radioButton } from '../../components/RadioButton/theme';
import { theme as search } from '../../components/Search/theme';
import { theme as select } from '../../components/Select/theme';
import { theme as shimmer } from '../../components/Shimmer/theme';
import { theme as time } from '../../components/Time/theme';
import { theme as textField } from '../../components/TextField/theme';
import { theme as switcher } from '../../components/Switch/theme';

export const defaultTheme = {
	accordion,
	alert,
	alertBanner,
	background,
	blockquote,
	border,
	breakpoints,
	button,
	callToAction,
	card,
	checkbox,
	chip,
	color,
	contact,
	dateSelect,
	dropdownList,
	grid,
	highlight,
	input,
	layout,
	link,
	pageLoader,
	paper,
	quickLink,
	radioButton,
	search,
	select,
	separator,
	shimmer,
	time,
	typography,
	zIndex,
	textField,
	switcher,
};

export type ITheme = typeof defaultTheme;
export interface ICustomTheme extends DeepPartial<ITheme> {}

export * from './breakpoints';

import { ILocale } from '../../../../shared/i18n/locale/models/ILocale';
import { getTranslationsData } from '../../../../shared/i18n/translate/getTranslationsData';
import { getLocalizedPathToPageIdMap } from '../../../pages/domain/use-cases/getLocalizedPathToPageIdMap';
import { ICommonData } from '../models/ICommonData';
import { getFooter } from './getFooter';
import { getNewsAlerts } from './getNewsAlerts';
import { getParkingInfo } from './getParkingInfo';
import { getSiteSections } from './getSiteSections';

export async function getCommonData(locale: ILocale): Promise<ICommonData> {
	const [
		pathToPageIdMap,
		siteSections,
		newsAlerts,
		footer,
		parkingInfo,
		translations,
	] = await Promise.all([
		getLocalizedPathToPageIdMap(locale),
		getSiteSections(locale),
		getNewsAlerts(locale),
		getFooter(locale),
		getParkingInfo(locale),
		getTranslationsData(locale.code),
	]);

	return {
		locale,
		pathToPageIdMap,
		siteSections,
		newsAlerts,
		footer,
		translations,
		parkingInfo,
	};
}

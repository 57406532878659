import styled from '@emotion/styled';
import { ShimmerPlaceholder } from './ShimmerPlaceholder';
import { ITheme } from '../../themer';

interface IStyledShimmerPlaceholderTextProps {
	width?: string;
}

export const ShimmerPlaceholderSpan = ShimmerPlaceholder.withComponent('span');

export const ShimmerPlaceholderText = styled(ShimmerPlaceholderSpan)<
	IStyledShimmerPlaceholderTextProps,
	ITheme
>`
	display: inline-block;
	height: 1em;
	width: ${({ width }) => width ?? '80%'};
`;

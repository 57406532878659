import React from 'react';
import { withConfig } from '../Config';

interface IFormattedTimeProps {
	/** Date object or epoch timestamp in seconds */
	value: number | Date;
	children?: string;
}

export const FormattedTime = withConfig<IFormattedTimeProps>(
	({ value: dateOrTimestamp, config, children }) => {
		const date =
			dateOrTimestamp instanceof Date
				? dateOrTimestamp
				: new Date(dateOrTimestamp * 1000);

		const { theme, dateTimeFormat } = config;

		const formattedTime = new Intl.DateTimeFormat(dateTimeFormat.locale, {
			timeZone: dateTimeFormat.timeZone,
			...theme.time.format,
		}).format(date);

		return <>{(children ? `${children} ` : '') + formattedTime}</>;
	},
);

import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
	ITermsAndConditionsCheckboxProps,
	TermsAndConditionsCheckbox,
} from './TermsAndConditionsCheckbox';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { StyledTextFieldErrorMessage } from '../../../../ui';

export const TermsAndConditions = (
	props: Partial<ITermsAndConditionsCheckboxProps>,
) => {
	const { register, errors, trigger, watch } = useFormContext();
	const isChecked = watch('termsAndConditions');

	const { t } = useTranslation();

	return (
		<>
			<TermsAndConditionsCheckbox
				name="termsAndConditions"
				ref={register as any}
				onChange={() => trigger('termsAndConditions')}
				invalid={!!errors.termsAndConditions}
				{...props}
			/>
			{!!errors.termsAndConditions && !isChecked && (
				<StyledTextFieldErrorMessage role="alert">
					{t('parking_booking_error_terms_and_conditions')}
				</StyledTextFieldErrorMessage>
			)}
		</>
	);
};

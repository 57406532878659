import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import Responsive from '../../utils/Responsive';
import { ITheme, IThemedProps, validateTheme } from '../../themer';
import { getIconLinkHoverStyle } from '../Link';
import { HighlightImage } from './HighlightImage';
import { StyledHighlightContent } from './HighlightContent';
import themed from '../../themer/themed';

type IHighlightOrientation = 'horizontal' | 'vertical';
type IImagePosition = 'first' | 'last';

export interface IStyledHighlightProps extends IThemedProps {
	orientation: Responsive.IValueOrResponsiveValue<IHighlightOrientation>;
	imagePosition?: Responsive.IValueOrResponsiveValue<IImagePosition>;
	elevated?: Responsive.IValueOrResponsiveValue<boolean>;
	fullyClickable?: boolean;
	withBackgroundColor?: boolean;
	usesLinkAsButton?: boolean;
}

export const StyledHighlight = styled('div', {
	shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'orientation',
})<IStyledHighlightProps, ITheme>`
	display: flex;
	transition: ${themed(({ highlight }) => highlight.transition)};

	img {
		display: block;
		width: 100%;
	}

	${themed(({ highlight }, { withBackgroundColor }) =>
		withBackgroundColor ? `background: ${highlight.background};` : '',
	)}

	${({ fullyClickable }) =>
		fullyClickable
			? `
				cursor: pointer;
			`
			: ''}

	${({ fullyClickable, usesLinkAsButton, theme }) =>
		fullyClickable && !usesLinkAsButton
			? `
				&:hover {
					a {
						${getIconLinkHoverStyle({
							theme,
							inverted: false,
						})}
					}
				}
				`
			: ''}

	${Responsive.getStyles<IHighlightOrientation, IStyledHighlightProps>(
		'orientation',
		getOrientationStyle,
	)}
	${Responsive.getStyles<IImagePosition, IStyledHighlightProps>(
		'imagePosition',
		getImagePositionStyle,
	)}
	${Responsive.getStyles<boolean, IStyledHighlightProps>(
		'elevated',
		getElevatedStyle,
	)}
`;

function getOrientationStyle(orientation: IHighlightOrientation) {
	if (orientation === 'vertical') {
		return `
			flex-direction: column;

			${StyledHighlightContent},
			${HighlightImage} {
				max-width: none;
			}
		`;
	}

	return `
		flex-direction: row;

		img {
			height: 100%;
			object-fit: cover;
		}
	`;
}

function getImagePositionStyle(position?: IImagePosition) {
	return `
		picture {
			order: ${position === 'last' ? 1 : 0};
		}
	`;
}

function getElevatedStyle(
	elevated: boolean | undefined,
	props: IStyledHighlightProps,
) {
	const theme = validateTheme(props.theme);

	if (elevated) {
		return `
			box-shadow: ${theme.highlight.elevated.boxShadow};

			${
				props.fullyClickable
					? `
						&:hover {
							box-shadow: ${theme.highlight.elevated.hover.boxShadow};
						}
					`
					: ''
			}
		`;
	}

	return props.fullyClickable
		? `
			&:hover {
				a {
					${getIconLinkHoverStyle({
						theme,
						inverted: false,
					})}
				}
			}
		`
		: '';
}

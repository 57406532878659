import { Dap as BaseDap } from '@move-frontend/dap';
import { BusWaitingTimeService } from './BusWaitTimesService';
import { CheckpointQueuesService } from './CheckpointQueuesService';
import { ParkingAvailabilityService } from './ParkingAvailabilityService';

export class Dap extends BaseDap {
	public checkpointQueues() {
		return new CheckpointQueuesService(this.client);
	}

	public busWaitTimes() {
		return new BusWaitingTimeService(this.client);
	}

	public parkingAvailability() {
		return new ParkingAvailabilityService(this.client);
	}
}

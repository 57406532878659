import { color } from '../../themer/theme/color';

export const theme = {
	background: '#fff',
	padding: '24px',
	transition: 'box-shadow 0.15s ease-out',
	boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.1)',
	clickable: {
		boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.1)',
		hover: {
			boxShadow: '0 20px 30px 0 rgba(0, 0, 0, 0.1)',
		},
	},
	selected: {
		boxShadow: `inset 0 0 0 2px ${color.highlight}`,
	},
	disabled: {
		opacity: '0.5',
	},
	mobile: {
		padding: '16px',
	},
};

import React from 'react';
import { css, Global } from '@emotion/core';
import { ITheme } from '../../themer';
import Responsive from '../../utils/Responsive';

interface IProps {
	theme: ITheme;
}

export const Typography = ({ theme }: IProps) => {
	const { body, header, paragraph, link, small, mobile } = theme.typography;

	const styles = css`
		body {
			font-family: ${body.fontFamily};
			font-size: ${body.size};
			line-height: ${body.lineHeight};
			color: ${body.color};
		}

		h1,
		h2,
		h3,
		h4,
		h5 {
			margin: ${header.margin};
			padding: 0;
			line-height: ${header.lineHeight};
		}

		h1 {
			font-size: ${header.h1.size};
			color: ${header.h1.color};
			font-weight: ${header.h1.fontWeight};
		}

		h2 {
			font-size: ${header.h2.size};
			color: ${header.h2.color};
			font-weight: ${header.h2.fontWeight};
		}

		h3 {
			font-size: ${header.h3.size};
			color: ${header.h3.color};
			font-weight: ${header.h3.fontWeight};
		}

		h4 {
			font-size: ${header.h4.size};
			color: ${header.h4.color};
			font-weight: ${header.h4.fontWeight};
		}

		h5 {
			font-size: ${header.h5.size};
			color: ${header.h5.color};
			font-weight: ${header.h5.fontWeight};
		}

		p {
			margin: ${paragraph.margin};
		}

		a {
			color: ${link.color};
			text-decoration: ${link.textDecoration};

			&:hover {
				color: ${link.hover.color};
				text-decoration: ${link.hover.textDecoration};
			}

			&:active {
				color: ${link.active.color};
			}
		}

		small {
			font-size: ${small.size};
			color: ${small.color};
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			body {
				font-size: ${mobile.body.size};
				line-height: ${mobile.body.lineHeight};
			}

			h1,
			h2,
			h3,
			h4,
			h5 {
				margin: ${mobile.header.margin};
				line-height: ${mobile.header.lineHeight};
			}

			h1 {
				font-size: ${mobile.header.h1.size};
			}

			h2 {
				font-size: ${mobile.header.h2.size};
			}

			h3 {
				font-size: ${mobile.header.h3.size};
			}

			h4 {
				font-size: ${mobile.header.h4.size};
			}

			h5 {
				font-size: ${mobile.header.h5.size};
			}

			p {
				margin: ${mobile.paragraph.margin};
			}

			small {
				font-size: ${mobile.small.size};
			}
		}
	`;

	return <Global styles={styles} />;
};

import { ISignUpResult } from 'amazon-cognito-identity-js';
import { when } from 'mobx';
import { IOnFailure } from '@move-frontend/auth';
import { useUserStore } from '../../../user/domain/stores/useUserStore';
import { UserStore } from '../../../user/domain/stores/UserStore';
import { ISignUpOnSuccess } from '../../domain/models/ISignUpOnSuccess';
import { useSignUpWithCallbacks } from './useSignUpWithCallbacks';

export function useSignUpSubmit(
	onSuccess: ISignUpOnSuccess,
	onFailure: IOnFailure,
) {
	const userStore = useUserStore();

	return useSignUpWithCallbacks(async (result: ISignUpResult) => {
		await waitForUserDataToBeLoad(userStore);
		await onSuccess(result);
	}, onFailure);
}

function waitForUserDataToBeLoad(userStore: UserStore) {
	return when(() => userStore.user !== undefined);
}

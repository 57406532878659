import * as yup from 'yup';
import { ITranslationFunction } from '../../../../shared/i18n/translate';
import { ISignUpFormData } from './ISignUpFormData';

export function createSignUpSchema(t: ITranslationFunction) {
	// The regex should match the password requirements configured in AWS Cognito
	// See: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
	const passwordRegex = new RegExp(
		[
			'^(?=.*[a-z])', // Require lowercase letters
			'(?=.*[A-Z])', // Require uppercase letters
			'(?=.*[0-9])', // Require numbers
			'(?=.*[\\^$*.[\\]{}()?"!@#%&\\\\/,><\':;|_~`])', // Require special character
		].join(''),
	);

	return yup
		.object<ISignUpFormData>({
			firstName: yup.string().required(t('first_name_form_field_error')),
			lastName: yup.string().required(t('last_name_form_field_error')),
			email: yup
				.string()
				.email(t('user_form_email_pattern_error'))
				.required(t('email_form_field_error')),
			password: yup
				.string()
				.required(t('password_form_field_error'))
				.min(8, t('user_form_password_min_length_error'))
				.matches(passwordRegex, t('user_form_password_pattern_error')),
			confirmPassword: yup
				.string()
				.oneOf<string>(
					[yup.ref('password'), ''],
					t('user_form_confirm_password_mismatch_error'),
				)
				.required(t('confirm_password_form_field_error')),
			licensePlateNumber: yup.string(),
			confirmLicensePlateNumber: yup
				.string()
				.oneOf<string>(
					[yup.ref('licensePlateNumber'), ''],
					t('user_form_confirm_license_plate_number_mismatch_error'),
				),
			termsAndConditions: yup.boolean().oneOf([true]).required(),
		})
		.required();
}

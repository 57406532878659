import { config } from '../../../../config';
import { IPrice } from './IPrice';

const {
	currency: { code: defaultCurrencyCode },
} = config;

interface IPriceData {
	centAmount: number;
	currencyCode?: string;
}
export class Price implements IPrice {
	readonly centAmount: number;

	readonly currencyCode: string;

	constructor({ centAmount, currencyCode }: IPriceData) {
		this.centAmount = centAmount;
		this.currencyCode = currencyCode ?? defaultCurrencyCode;
	}

	get decimalValue() {
		return this.centAmount / 100;
	}

	toJSON() {
		return this.decimalValue;
	}

	toString() {
		return this.decimalValue.toFixed(2);
	}
}

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { IUIComponentProps, Omit } from '../../types';
import { ArrowLinkButton, IArrowLinkButtonProps } from './ArrowLinkButton';
import { ButtonText } from '../Button';
import Responsive from '../../utils/Responsive';

export interface ICallButtonProps
	extends Omit<IArrowLinkButtonProps, 'href'>,
		IUIComponentProps {
	children: ReactNode;
	number: string;
}

const CallButtonNumber = styled.span<{}>``;

const StyledCallButton = styled(ArrowLinkButton)<IArrowLinkButtonProps>`
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		${CallButtonNumber} {
			display: none;
		}
	}
`;

export const CallLinkButton = ({
	children,
	number,
	...rest
}: ICallButtonProps) => (
	<StyledCallButton href={`tel:${number}`} {...rest}>
		<ButtonText>{children}</ButtonText>
		<CallButtonNumber>
			&nbsp;
			{`${number}`}
		</CallButtonNumber>
	</StyledCallButton>
);

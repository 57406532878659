import { object, string } from 'yup';
import { useMemo } from 'react';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { ISignInFormData } from '../../domain/models/ISignInFormData';

export function useSignInSchema() {
	const { t } = useTranslation();

	const schema = useMemo(
		() =>
			object<ISignInFormData>({
				email: string()
					.email(t('user_form_email_pattern_error'))
					.required(t('email_form_field_error')),
				password: string().required(t('password_form_field_error')),
			}).required(),
		[t],
	);

	return schema;
}

import React, { ComponentType } from 'react';
import { useCommonData } from '../../../root/ui/CommonDataContext';
import { ISiteMap } from '../../domain/models/ISiteMap';

export interface ISiteMapProps {
	siteMap: ISiteMap;
}

export const withSiteMap =
	<Props extends {} = {}>(
		Component: ComponentType<Props & ISiteMapProps>,
	): ComponentType<Props> =>
	(props: Props) => {
		const siteMap = useSiteMap();

		return <Component siteMap={siteMap} {...props} />;
	};

export const useSiteMap = () => {
	const { locale, pathToPageIdMap } = useCommonData();

	return { locale, pathToPageIdMap };
};

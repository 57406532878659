import React, { ReactNodeArray } from 'react';
import styled from '@emotion/styled';
import { ScrollbarLeft } from '../scrollbar/ScrollbarLeft';
import Responsive from '../../../../ui/utils/Responsive';

interface IDesktopNewsProps {
	children: ReactNodeArray;
	noMargin?: boolean;
}

export const DesktopNewsAlert = ({ children, noMargin }: IDesktopNewsProps) => (
	<StyledScrollbarLeft
		noMargin={noMargin}
		autoHeight
		autoHeightMin={30}
		autoHeightMax={65}
	>
		{children}
	</StyledScrollbarLeft>
);

const StyledScrollbarLeft = styled(ScrollbarLeft)`
	flex-grow: 1;
	overflow: hidden;
	a {
		line-height: 21px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

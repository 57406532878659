import { FilterStore, IAsyncStore, SortStore } from '@move-frontend/utils';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { PoiStore } from './PoiStore';
import { Keyword } from '../models';
import { Amenity } from '../models/Amenity';
import { IPoi } from '../models/IPoi';

const KEYWORD_FILTER_NAME = 'keyword-filter';
const AREA_FILTER_NAME = 'area-filter';
const QUERY_FILTER_NAME = 'query-filter';

export class PoiListStore implements IAsyncStore {
	public static readonly INITIAL_LENGTH = 10;

	public static readonly DEFAULT_SHOW_MORE_BATCH_LENGTH = 20;

	private showUntilIndex = 0;

	private _previousShowUntilIndex = -1;

	private _currentKeyword?: Keyword | null = null;

	private _currentArea?: string | null = null;

	private _currentQuery = '';

	private _currentAmenityKeywords: Amenity[] = [];

	constructor(
		protected readonly poiStore: PoiStore,
		protected readonly poiFilterStore: FilterStore<IPoi>,
		protected readonly poiSortStore: SortStore<IPoi>,
	) {
		makeObservable<
			PoiListStore,
			| 'showUntilIndex'
			| '_previousShowUntilIndex'
			| '_currentKeyword'
			| '_currentArea'
			| '_currentQuery'
			| '_currentAmenityKeywords'
		>(this, {
			showUntilIndex: observable,
			_previousShowUntilIndex: observable,
			_currentKeyword: observable,
			_currentArea: observable,
			_currentQuery: observable,
			_currentAmenityKeywords: observable,
			filteredResults: computed,
			sortedResults: computed,
			results: computed,
			hasMore: computed,
			allKeywords: computed,
			showMore: action,
			resetIndexPosition: action,
			setAmenityKeywords: action,
			setKeywordFilter: action,
			resetAllKeywords: action,
			setAreaFilter: action,
			setQuery: action,
			reset: action,
		});

		reaction(
			() => this.poiStore.filteredResults,
			(results) => {
				if (results && results.length) {
					this.resetIndexPosition();
				}
			},
		);
	}

	public get finishedInitialLoad() {
		return this.poiStore.finishedInitialLoad;
	}

	public get isLoading() {
		return this.poiStore.isLoading;
	}

	public get error() {
		return this.poiStore.error;
	}

	public get previousShowUntilIndex() {
		return this._previousShowUntilIndex;
	}

	public get currentKeyword() {
		return this._currentKeyword;
	}

	public get currentAmenityKeywords() {
		return this._currentAmenityKeywords;
	}

	public get currentArea() {
		return this._currentArea;
	}

	public get currentQuery() {
		return this._currentQuery;
	}

	public get filteredResults() {
		return this.poiFilterStore.filter(this.poiStore.filteredResults);
	}

	public get sortedResults() {
		return this.poiSortStore.sort(this.filteredResults);
	}

	public get results() {
		return this.sortedResults.slice(0, this.showUntilIndex);
	}

	get hasMore() {
		return this.filteredResults.length > this.showUntilIndex;
	}

	get allKeywords() {
		const keywords: string[] = [...this.currentAmenityKeywords];
		if (this.currentKeyword) keywords.push(this.currentKeyword);
		return keywords;
	}

	public showMore(length = PoiListStore.DEFAULT_SHOW_MORE_BATCH_LENGTH) {
		if (this.showUntilIndex !== 0)
			this._previousShowUntilIndex = this.showUntilIndex;

		const index = this.showUntilIndex + length;
		this.showUntilIndex = Math.min(index, this.filteredResults.length);
	}

	public resetIndexPosition() {
		this.showUntilIndex = 0;
		this._previousShowUntilIndex = -1;
		this.showMore(PoiListStore.INITIAL_LENGTH);
	}

	private toggleInAmenities(amenityKeyword: Amenity) {
		return this.currentAmenityKeywords.includes(amenityKeyword)
			? this.currentAmenityKeywords.filter((a) => a !== amenityKeyword)
			: this.currentAmenityKeywords.concat(amenityKeyword);
	}

	public toggleAmenityKeyword(amenityKeyword: Amenity) {
		this.setAmenityKeywords(this.toggleInAmenities(amenityKeyword));
	}

	public setAmenityKeywords(amenityKeywords: Amenity[]) {
		this._currentAmenityKeywords = amenityKeywords;
		this.applyKeywordFilter();
	}

	public setKeywordFilter(keyword?: Keyword) {
		this._currentKeyword = keyword;
		this.applyKeywordFilter();
	}

	public resetAllKeywords() {
		this._currentKeyword = undefined;
		this._currentAmenityKeywords = [];
		this.applyKeywordFilter();
	}

	private applyKeywordFilter() {
		if (!this.allKeywords.length) {
			this.poiFilterStore.removeFilter(KEYWORD_FILTER_NAME);
		} else {
			this.poiFilterStore.setFilter(KEYWORD_FILTER_NAME, (poi) =>
				this.allKeywords.every((k) => poi.tags.includes(k)),
			);
		}

		this.resetIndexPosition();
	}

	public setAreaFilter(area?: string) {
		this._currentArea = area;

		if (area === undefined) {
			this.poiFilterStore.removeFilter(AREA_FILTER_NAME);
		} else {
			this.poiFilterStore.setFilter(
				AREA_FILTER_NAME,
				(poi) =>
					typeof poi.nearbyLandmark === 'string' &&
					poi.nearbyLandmark.localeCompare(area) === 0,
			);
		}

		this.resetIndexPosition();
	}

	public setQuery(query: string) {
		const normalizedQuery = query.trim().toLowerCase();

		if (this._currentQuery === normalizedQuery) {
			return;
		}

		this._currentQuery = normalizedQuery;

		if (normalizedQuery === '') {
			this.poiFilterStore.removeFilter(QUERY_FILTER_NAME);
		} else {
			this.poiFilterStore.setFilter(QUERY_FILTER_NAME, (poi) =>
				poi.name.toLocaleLowerCase().includes(normalizedQuery),
			);
		}

		// Keyword filter should be reset when using the query filter
		this.resetAllKeywords();
	}

	public reset() {
		this.setAreaFilter(undefined);
		this.setQuery('');
	}
}

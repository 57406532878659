import { background } from '../../themer/theme/background';

export const theme = {
	wrap: {
		boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1)',
		disabled: {
			boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.05)',
		},
		focus: {
			boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.15)',
		},
		borderRadius: '',
	},
	button: {
		width: '56px',
		height: '56px',
		iconWidth: 'auto',
		iconHeight: '24px',
		background: '',
		border: 'none',
		borderRadius: '0',
		hover: {
			background: '',
			border: 'none',
		},
		active: {
			transform: 'none',
		},
		disabled: {
			background: background.light,
			border: 'none',
		},
		mobile: {
			width: '48px',
			height: '48px',
			iconWidth: 'auto',
			iconHeight: '19px',
		},
	},
	clear: {
		padding: '0px 16px',
		border: 'none',
		background: 'transparent',
	},
};

import React, { MouseEvent } from 'react';
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';
import {
	AutoSuggestInput,
	IAutoSuggestInputProps,
	IDropdownItem,
	SearchInputButtonWrap,
} from '../../../../ui';

export interface IAutoSuggestProps<
	TItemType extends IDropdownItem = IDropdownItem,
> extends IAutoSuggestInputProps<TItemType> {
	onSelectItem: (selected: TItemType | null) => void;
	onClickSearch: (e: MouseEvent) => void;
	disabled?: boolean;
	required?: boolean;
	autoSuggestId?: string;
}

export const AutoSuggest = <TItemType extends IDropdownItem>({
	onSelectItem,
	onClickSearch,
	disabled,
	required,
	autoSuggestId,
	...rest
}: IAutoSuggestProps<TItemType>) => (
	<SearchInputButtonWrap disabled={disabled} onClickSearch={onClickSearch}>
		<AutoSuggestInput
			{...rest}
			onSelect={onSelectItem}
			stateReducer={stateReducer}
			disabled={disabled}
			required={required}
			autoSuggestId={autoSuggestId}
			placeholderAsLabel
		/>
	</SearchInputButtonWrap>
);

function stateReducer<TItemType extends IDropdownItem>(
	state: DownshiftState<TItemType>,
	changes: StateChangeOptions<TItemType>,
) {
	// This prevents the input value to be reset on
	// outer click or loss of focus.
	switch (changes.type) {
		case Downshift.stateChangeTypes.mouseUp:
		case Downshift.stateChangeTypes.blurInput:
		case Downshift.stateChangeTypes.touchEnd:
			return {
				...changes,
				inputValue: state.inputValue,
			};
		default:
			return changes;
	}
}

import styled from '@emotion/styled';
import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import { css } from '@emotion/core';

import { ITheme } from '../../themer';
import Responsive from '../../utils/Responsive';
import { IInputTheme } from './theme';

interface IInputProps {
	invalid?: boolean;
	readonly?: boolean;
	placeholderAsLabel?: boolean;
	hasWarning?: boolean;
	isFocussed?: boolean;
	icon?: ReactNode;
	id?: string;
}

interface IPlaceHolderProps {
	disabled?: boolean;
	moveUp?: boolean;
	isOpen?: boolean;
}

interface IStyledInputProps {
	disabled?: boolean;
	moveUp?: boolean;
	invalid?: boolean;
	hasWarning?: boolean;
	hasPlaceholderAsLabel?: boolean;
}

export const Input = forwardRef<
	HTMLInputElement,
	React.HTMLProps<HTMLInputElement> & IInputProps
>(
	(
		{
			placeholderAsLabel,
			placeholder,
			readonly,
			isFocussed,
			defaultValue,
			icon,
			id,
			...props
		}: React.HTMLProps<HTMLInputElement> & IInputProps,
		ref,
	) => {
		const [hasValue, setHasValue] = useState<boolean | undefined>(undefined);
		useEffect(
			() => setHasValue(!!props.value || !!defaultValue),
			[props.value, defaultValue],
		);

		if (!placeholderAsLabel)
			return (
				<StyledInput
					{...props}
					ref={ref}
					id={id}
					placeholder={placeholder}
					readOnly={readonly || props.readOnly}
					defaultValue={defaultValue}
				/>
			);

		return (
			<Label className={hasValue ? ' moveUp' : ''}>
				<InputLabel
					className={props.className}
					moveUp={hasValue}
					isOpen={isFocussed}
					disabled={props.disabled}
				>
					{placeholder}
					{icon && !hasValue && <span>{icon}</span>}
				</InputLabel>
				<StyledInput
					{...props}
					onInput={(e) => {
						if (props.onInput) props.onInput(e);
						setHasValue(!!e.currentTarget.value);
					}}
					id={id}
					hasPlaceholderAsLabel={!!placeholderAsLabel}
					moveUp={hasValue}
					readOnly={readonly || props.readOnly}
					ref={ref}
					defaultValue={defaultValue}
				/>
			</Label>
		);
	},
);

export function appendWarningBoxShadow(
	current: string,
	theme: IInputTheme,
	hasWarning: boolean,
) {
	if (!hasWarning) {
		return current;
	}

	if (theme.configuration.error.overrideDefaultShadow) {
		return theme.warning.boxShadow;
	}

	return current
		? `${current}, ${theme.warning.boxShadow}`
		: theme.warning.boxShadow;
}

export function appendErrorBoxShadow(
	current: string,
	theme: IInputTheme,
	invalid?: boolean,
) {
	if (!invalid) return current;

	if (theme.configuration.error.overrideDefaultShadow) {
		return invalid ? theme.error.boxShadow : current;
	}

	return current
		? `${current}, ${theme.error.boxShadow}`
		: theme.error.boxShadow;
}

export const InputLabel = styled.span<IPlaceHolderProps, ITheme>`
	color: ${({ theme: { input }, disabled }) =>
		disabled ? input.placeholder.disabled.color : input.placeholder.color};

	padding: ${({ theme: { input } }) => input.padding};
	margin: ${({ theme: { input } }) => input.margin};
	pointer-events: none;
	transition: all 300ms;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	${({ theme: { input }, moveUp, isOpen }) =>
		moveUp &&
		css`
			padding: calc(${input.padding} / 3) ${input.padding};
			font-size: 13px;
			${isOpen &&
			css`
				color: ${input.placeholder.focussed.color};
			`}
		`}
	position: absolute;

	display: flex;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
`;

export const Label = styled.label`
	position: relative;
`;

export const StyledInput = styled.input<IStyledInputProps, ITheme>`
	width: 100%;
	font-family: ${({ theme: { typography } }) => typography.body.fontFamily};
	padding: ${({ theme: { input } }) => input.padding};
	margin: ${({ theme: { input } }) => input.margin};
	background: ${({ theme: { input }, disabled }) =>
		disabled ? input.disabled.background : input.background};
	color: ${({ theme: { input }, disabled }) =>
		disabled ? input.disabled.color : input.color};
	box-shadow: ${({ theme: { input }, disabled, invalid, hasWarning }) => {
		const style = disabled ? input.disabled.boxShadow : input.boxShadow;

		if (invalid) {
			return appendErrorBoxShadow(style, input, invalid);
		}

		return appendWarningBoxShadow(style, input, !!hasWarning);
	}};
	font-size: ${({ theme: { input } }) => input.fontSize};
	border: ${({ theme: { input } }) => input.border};
	border-radius: ${({ theme: { input }, invalid, hasWarning }) => {
		if (invalid) {
			return input.error.borderRadius;
		}

		if (hasWarning) {
			return input.warning.borderRadius;
		}

		return input.borderRadius;
	}};
	line-height: ${({ theme: { input } }) => input.lineHeight};
	text-overflow: ellipsis;

	/* Fix box-shadow for iOS Safari */
	-webkit-appearance: none;

	&:focus-visible {
		box-shadow: ${({ theme: { input }, invalid, hasWarning }) =>
			invalid
				? appendErrorBoxShadow(input.focus.boxShadow, input, invalid)
				: appendWarningBoxShadow(input.focus.boxShadow, input, !!hasWarning)};
		outline: none;
		border-radius: ${({ theme: { input } }) => input.focus.borderRadius};
		text-overflow: initial;
	}

	&::placeholder {
		color: ${({ theme: { input }, disabled }) =>
			disabled ? input.placeholder.disabled.color : input.placeholder.color};
	}

	// Prevents the input from being resized on mobile if input has placeholder as label
	${({ hasPlaceholderAsLabel, theme: { input } }) =>
		!hasPlaceholderAsLabel &&
		css`
			@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
				padding: ${input.mobile.padding};
				font-size: ${input.mobile.fontSize};
				line-height: ${input.mobile.lineHeight};
			}
		`}

	${({ moveUp, theme: { input } }) =>
		moveUp &&
		`
		padding-top: calc(${input.padding} + 7px);
		padding-bottom: calc(${input.padding} - 7px);
		`}
`;

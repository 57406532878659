import React, { ReactNode } from 'react';

import styled from '@emotion/styled';
import { StyledCard } from './StyledCard';
import Responsive from '../../utils/Responsive';
import { FixedGrid, IFixedGridProps } from '../Grid';
import {
	CardContainerContext,
	ICardContainerContextValues,
} from './CardContainerContext';
import { IAsProp, IStyledComponentSelector } from '../../types';
import { UnstyledUl } from '../List';

export type CardOrientation = 'horizontal' | 'vertical';

export interface ICardsContainerProps extends ICardContainerContextValues {
	children: ReactNode;
	columns: Responsive.IValueOrResponsiveValue<number>;
	itemSelector?: IStyledComponentSelector;
}

interface IStyledCardsContainerProps extends IFixedGridProps {}

const StyledCardsContainer = styled(FixedGrid)<
	IStyledCardsContainerProps & IAsProp<'div' | typeof UnstyledUl>
>``;

StyledCardsContainer.defaultProps = {
	itemSelector: StyledCard,
};

export const CardsContainer = ({
	children,
	columns,
	fullyClickable,
	...rest
}: ICardsContainerProps) => (
	// eslint-disable-next-line react/jsx-no-constructed-context-values
	<CardContainerContext.Provider value={{ ...rest }}>
		<StyledCardsContainer
			as={fullyClickable ? 'div' : UnstyledUl}
			columns={columns}
			{...rest}
		>
			{' '}
			{children}
		</StyledCardsContainer>
	</CardContainerContext.Provider>
);

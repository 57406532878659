import { background } from '../../themer/theme/background';

export const theme = {
	transition: 'box-shadow 0.15s ease-out',
	background: background.light,
	padding: {
		default: '60px 80px',
		tablet: '',
		mobileXL: '40px',
		mobile: '40px 20px',
	},
	elevated: {
		boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.1)',
		hover: {
			boxShadow: '0 20px 30px 0 rgba(0, 0, 0, 0.1)',
		},
	},
};

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';

export const StyledButtonText = styled.span<{}, ITheme>`
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

interface IButtonTextProps {
	children: ReactNode;
	screenreadertitle?: string;
}

export const ButtonText = ({
	children,
	screenreadertitle,
	...rest
}: IButtonTextProps) => (
	<>
		{typeof children === 'string' ? (
			<StyledButtonText {...rest}>{children}</StyledButtonText>
		) : (
			children
		)}
	</>
);

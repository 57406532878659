import { useForm } from '../../../../../shared/form/useForm';
import { ISignUpFormData } from '../../../domain/models/ISignUpFormData';
import { useSignUpSchema } from './useSignUpSchema';

export function useSignUpForm() {
	const validationSchema = useSignUpSchema();
	const form = useForm<ISignUpFormData>({
		mode: 'onBlur',
		validationSchema,
	});

	return form;
}

import { IDropdownItem } from '../DropdownItem';
import { IGetItemKey } from './IGetItemKey';

export const getItemToStringDefault: IGetItemKey = (
	item: IDropdownItem | null,
) => {
	if (!item || (typeof item !== 'string' && item.type === 'header')) return '';

	return typeof item === 'object' ? item.value : item;
};

import React, { ReactNode } from 'react';
import { ControllerStateAndHelpers } from 'downshift';
import { SelectMain } from './SelectMain';
import {
	DropdownList,
	IDropdownItem,
	IGetItemDisplayValue,
	IGetItemKey,
} from '../DropdownList';
import { IGetSectionHeaderDisplayValue } from '../DropdownList/helpers/IGetSectionHeaderDisplayValue';

interface ISelectRootProps extends ControllerStateAndHelpers<IDropdownItem> {
	getItemDisplayValue: IGetItemDisplayValue;
	getSectionHeaderDisplayValue?: IGetSectionHeaderDisplayValue;
	getItemKey: IGetItemKey;
	items: IDropdownItem[];
	innerRef: () => void;
	disabled?: boolean;
	invalid?: boolean;
	allowsRemovingSelection?: boolean;
	onClickRemoveSelection?: () => void;
	className?: string;
	placeholder?: string;
	ariaLabel?: string;
	ariaLabelledBy?: string;
	placeholderAsLabel?: boolean;
	icon?: ReactNode;
	defaultScrollToNthItem?: number;
	id?: string;
}

export const SelectRoot = ({
	getToggleButtonProps,
	getItemDisplayValue,
	getSectionHeaderDisplayValue,
	highlightedIndex,
	getInputProps,
	getMenuProps,
	getItemProps,
	selectedItem,
	ariaLabel,
	ariaLabelledBy,
	placeholder,
	getItemKey,
	className,
	innerRef,
	disabled,
	invalid,
	allowsRemovingSelection,
	onClickRemoveSelection,
	isOpen,
	items,
	placeholderAsLabel,
	icon,
	defaultScrollToNthItem,
	id,
}: ISelectRootProps) => (
	<div ref={innerRef} className={className}>
		<SelectMain
			getInputProps={getInputProps}
			getToggleButtonProps={getToggleButtonProps}
			getItemDisplayValue={getItemDisplayValue}
			selectedItem={selectedItem}
			disabled={disabled}
			invalid={invalid}
			allowsRemovingSelection={allowsRemovingSelection}
			onClickRemoveSelection={onClickRemoveSelection}
			ariaLabel={ariaLabel}
			ariaLabelledBy={ariaLabelledBy}
			placeholder={placeholder}
			isOpen={isOpen}
			placeholderAsLabel={placeholderAsLabel}
			icon={icon}
			id={id}
		/>
		<DropdownList
			getItemDisplayValue={getItemDisplayValue}
			getSectionHeaderDisplayValue={getSectionHeaderDisplayValue}
			highlightedIndex={highlightedIndex}
			getMenuProps={getMenuProps}
			selectedItem={selectedItem}
			getItemProps={getItemProps}
			getItemKey={getItemKey}
			isOpen={isOpen}
			items={items}
			defaultScrollToNthItem={defaultScrollToNthItem}
		/>
	</div>
);

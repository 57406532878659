import log from 'loglevel';

export function isExternalUrl(link: string, base?: string) {
	let baseHostname;

	if (typeof window !== 'undefined' && !base) {
		// Client-side we will use the window.location for determining the current hostname
		baseHostname = window.location.hostname;
	} else if (base) {
		// Server-side we try to use the provided base URL for determining the current hostname
		try {
			baseHostname = new URL(base).hostname;
		} catch (error) {
			log.warn('Invalid base url', base);
			return undefined;
		}
	} else {
		log.warn(
			'Could not determine whether or not this is an external url',
			link,
		);
		return undefined;
	}

	const baseUrl = base || window.location.href;
	const linkHostname = new URL(link, baseUrl).hostname;

	return linkHostname !== baseHostname;
}

import { IEnrichedFlight } from '../../../shared/flight/domain/models/IEnrichedFlight';

export function createFlightSearchFilter(queryString: string) {
	const query = queryString.toLocaleLowerCase();

	return (flight: IEnrichedFlight) => {
		const searchableStrings: string[] = [];

		const { airlineDetails, viaAirportDetails, operatingAirlineFlightNumber } =
			flight;

		if (airlineDetails) {
			const { iata, name } = airlineDetails;
			const values = [iata, name].filter((value) => value);
			searchableStrings.push(...values);
		}

		if (viaAirportDetails) {
			const { iata, name, city } = viaAirportDetails;
			const values = [iata, name, city].filter((value) => value) as string[];
			searchableStrings.push(...values);
		}

		if (operatingAirlineFlightNumber) {
			searchableStrings.push(operatingAirlineFlightNumber);
		}

		return searchableStrings.some((str: string) =>
			str.toLocaleLowerCase().includes(query),
		);
	};
}

/* eslint-disable no-inner-declarations, @typescript-eslint/no-namespace */
import { useEffect, useState } from 'react';
import {
	breakpoints,
	ITheme,
	IThemedProps,
	useTheme,
	validateTheme,
} from '../themer';
import { IBreakpoint, IBreakpointCondition, IBreakpointName } from '../types';

namespace Responsive {
	export type IValue = string | number | boolean;
	export type IMapper<T = any, P = any> = (value: T, props: P) => string;
	export type IBreakpointValues<ValueType = IValue> = Partial<{
		[key in IBreakpointName]: ValueType;
	}>;
	export type IResponsiveValue<ValueType = IValue> = [
		ValueType,
		IBreakpointValues<ValueType>,
	];
	export type IValueOrResponsiveValue<ValueType = IValue> =
		| ValueType
		| IResponsiveValue<ValueType>;

	type IPropValue<P, K extends keyof P, T> = P[K] & IValueOrResponsiveValue<T>;

	export function getStyles<T extends IValue, P extends IThemedProps>(
		componentPropName: keyof P,
		mapper?: IMapper<T, P>,
	) {
		return (props: P) => {
			const values = props[componentPropName] as IPropValue<
				P,
				typeof componentPropName,
				T
			>;

			return getStylesFromValues<T, P>(values, props, mapper);
		};
	}

	interface IHideProps extends IThemedProps {
		hide?: Responsive.IValueOrResponsiveValue<boolean>;
	}

	export function getHideStyles<TProps extends IHideProps>(
		displayStyle = 'inherit',
	) {
		return getStyles<boolean, TProps>(
			'hide',
			(isHidden) => `display: ${isHidden ? 'none' : displayStyle};`,
		);
	}

	export function getStylesFromValues<
		T extends IValue,
		P extends IThemedProps = IThemedProps,
	>(values: IValueOrResponsiveValue<T>, props: P, mapper?: IMapper<T, P>) {
		if (!Array.isArray(values)) {
			return getStyle(values as IValue, props, mapper);
		}

		const [defaultValue, responsiveValues] = values;

		const defaultStyle = getStyle(defaultValue, props, mapper);
		const responsiveStyles = getResponsiveStyles(
			responsiveValues,
			props,
			mapper,
		);

		return [defaultStyle, ...responsiveStyles].join('\n');
	}

	export function getMediaQueryForBreakpoint(breakPointName: IBreakpointName) {
		const breakpoint = breakpoints.find(
			(breakPoint) => breakPoint.name === breakPointName,
		);

		if (!breakpoint) {
			throw new Error(`No breakpoint found for ${breakPointName}`);
		}

		return breakpoint.condition;
	}

	export function getResponsiveStyles(
		values: IBreakpointValues,
		props: IThemedProps,
		mapper?: IMapper,
	) {
		const theme = validateTheme(props.theme);

		return theme.breakpoints.map((breakpoint) => {
			const { name, condition } = breakpoint;

			if (!(name in values)) {
				return '';
			}

			const value = values[name] as IValue;

			return getStyleWithMediaQuery(condition, value, props, mapper);
		});
	}

	function getStyleWithMediaQuery(
		condition: IBreakpointCondition,
		value: IValue,
		props: any,
		mapper?: IMapper,
	) {
		return `@media (${condition}) {
			${getStyle(value, props, mapper)}
		}`;
	}

	function getStyle(value: IValue, props: IThemedProps, mapper?: IMapper) {
		const computedValue = getValue(value, props, mapper);
		return computedValue ? `${computedValue}` : '';
	}

	function getValue(value: IValue, props: any, mapper?: IMapper) {
		return mapper ? mapper(value, props) : `${value || ''}`;
	}

	export function matchesBreakpoint(
		breakpointName: IBreakpointName,
		theme: ITheme,
	) {
		const breakpoint = theme.breakpoints.find(
			({ name }) => name === breakpointName,
		) as IBreakpoint;

		if (
			typeof window === 'undefined' ||
			typeof window.matchMedia !== 'function'
		) {
			return undefined;
		}

		return window.matchMedia(`(${breakpoint.condition})`).matches;
	}

	export function useMatchesBreakpoint(breakPoint: IBreakpointName) {
		const theme: any = useTheme();
		const [breaks, setBreaks] = useState<boolean | undefined>(undefined);
		useEffect(() => {
			function handleResize() {
				setBreaks(!!Responsive.matchesBreakpoint(breakPoint, theme));
			}
			window.addEventListener('resize', handleResize);
			handleResize();
			return () => window.removeEventListener('resize', handleResize);
		}, []);
		return breaks;
	}
}

export default Responsive;

import { color } from './color';

export const typography = {
	body: {
		fontFamily: `
			-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
			Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
		`,
		lineHeight: '1.667',
		color: color.text.default,
		size: '18px',
	},

	header: {
		lineHeight: '1.388',
		margin: '0 0 16px 0',

		h1: {
			size: '54px',
			color: color.brand,
			fontWeight: '',
		},

		h2: {
			size: '40px',
			color: color.text.default,
			fontWeight: '',
		},

		h3: {
			size: '32px',
			color: color.text.default,
			fontWeight: '',
		},

		h4: {
			size: '24px',
			color: color.text.light,
			fontWeight: '',
		},

		h5: {
			size: '21px',
			color: color.text.default,
			fontWeight: 'normal',
		},

		h6: {
			size: '18px',
			color: color.text.default,
			fontWeight: 400,
		},
	},

	paragraph: {
		margin: '0 0 24px 0',
	},

	link: {
		color: color.highlight,
		textDecoration: '',
		hover: {
			color: color.text.hover,
			textDecoration: '',
		},
		active: {
			color: color.highlight,
			textDecoration: '',
		},
	},

	input: {
		size: '16px',
	},

	small: {
		size: '14px',
		color: '',
	},

	mobile: {
		body: {
			lineHeight: '1.625',
			size: '16px',
		},

		header: {
			lineHeight: '1.388',
			margin: '0 0 0.3em 0',
			h1: {
				size: '32px',
			},
			h2: {
				size: '22px',
			},
			h3: {
				size: '18px',
			},
			h4: {
				size: '18px',
			},
			h5: {
				size: '18px',
			},
			h6: {
				size: '18px',
			},
		},

		paragraph: {
			margin: '0 0 16px 0',
		},

		small: {
			size: '14px',
		},
	},
};

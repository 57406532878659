import { Context, createContext, useContext } from 'react';

interface IHashRouteContext<THashMatches extends {} = {}> {
	hash: string;
	pattern: string;
	matches: THashMatches;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HashRouteContext = createContext<IHashRouteContext<any>>({
	hash: '',
	pattern: '',
	matches: undefined,
});

export const useHashRouteContext = <THashMatches extends {} = {}>() =>
	useContext(HashRouteContext as Context<IHashRouteContext<THashMatches>>);

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled from '@emotion/styled';
import { Css, Gutter } from '../../utils';
import { Grid } from './Grid';
import { BaseGridItem } from './GridItem';
import { ITheme, IThemedProps, validateTheme } from '../../themer';
import { IStyledComponentSelector } from '../../types';
import Responsive from '../../utils/Responsive';

export const FixedGridItem = styled(BaseGridItem)<{}, ITheme>``;

export interface IFixedGridPropsWithTheme
	extends IThemedProps,
		IFixedGridProps {}

export const getFixedGridColumnStyle = (
	columns: number,
	props: IFixedGridPropsWithTheme,
) => {
	const theme = validateTheme(props.theme);

	return `
		width: calc((100% - ${columns} * ${theme.grid.gutter.horizontal.default}) / ${columns});
		width: calc((100% - ${columns} * var(${Gutter.cssVariableName.horizontal})) / ${columns});
	`;
};

export interface IFixedGridProps {
	columns: Responsive.IValueOrResponsiveValue<number>;
	/**
	 * When extending the FixedGridItem one or more references can be passed in order for the responsive column
	 * styles to be applied. Alternatively a regular css selector can be passed in as string.
	 */
	itemSelector?: IStyledComponentSelector;
}

export const FixedGrid = styled(Grid)<IFixedGridProps, ITheme>`
	${({ itemSelector }) => Css.getSelectorForComponents(itemSelector!)} {
		${Responsive.getStyles<number, IFixedGridPropsWithTheme>(
			'columns',
			getFixedGridColumnStyle,
		)};
	}
`;

FixedGrid.defaultProps = {
	itemSelector: FixedGridItem,
};

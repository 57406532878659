import { ButtonHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

export interface IBaseButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	inverted?: boolean;
	secondary?: boolean;
}

export const Base = styled.button<IBaseButtonProps, ITheme>`
	cursor: pointer;
	border-radius: ${themed(({ button }) => button.radius)};
	padding: ${themed(({ button }) => button.padding)};
	height: ${themed(({ button }) => button.height)};
	line-height: ${themed(({ button }) => button.lineHeight)};

	svg {
		margin-left: ${themed(({ button }) => button.iconSeparator)};
	}

	a {
		display: block;
		text-decoration: none;
	}
`;

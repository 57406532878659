import * as Sentry from '@sentry/browser';
import { isProductionBuild } from '../../../../shared/utils/isProductionBuild';
import { isServerSide } from '../../../../shared/utils/isServerSide';

export async function initSentry() {
	if (!isProductionBuild() || isServerSide()) return;

	const version = process.env.VERSION;

	Sentry.init({
		dsn: 'https://50b0a5395ead46438a693138048cc42e@sentry.io/1393225',
		sampleRate: 0.1, // 10% of all errors are sent to Sentry
		release: `clt-airport@${version}`,
		environment: process.env.ENVIRONMENT,
	});
}

import styled, { StyledComponent } from '@emotion/styled';
import React, { FunctionComponent, HTMLAttributes } from 'react';

import { ITheme } from '../../themer';
import themed from '../../themer/themed';

export type IIconVariation =
	| 'inverted'
	| 'highlight'
	| 'brand'
	| 'text'
	| 'error';

export interface IIconStyleProps {
	variation?: IIconVariation;
	alt?: string;
}

export type IApplyColorToProps = ('fill' | 'stroke')[];

export interface IWithIconStylesHoC {
	(Icon: any, style?: IApplyColorToProps): StyledComponent<
		{},
		IIconStyleProps & HTMLAttributes<HTMLOrSVGImageElement>,
		ITheme
	>;
}

function getStylesForProps(props: string[], value: string) {
	return props.map((prop) => `${prop}: ${value};`).join('\n');
}

function getColorForVariation(applyColorToProps: IApplyColorToProps) {
	return (
		{ color }: ITheme,
		{ variation }: IIconStyleProps & HTMLAttributes<HTMLOrSVGImageElement>,
	) => {
		switch (variation) {
			case 'inverted':
				return getStylesForProps(applyColorToProps, color.text.inverted);

			case 'highlight':
				return getStylesForProps(applyColorToProps, color.highlight);

			case 'brand':
				return getStylesForProps(applyColorToProps, color.brand);

			case 'text':
				return getStylesForProps(applyColorToProps, color.text.default);

			case 'error':
				return getStylesForProps(applyColorToProps, color.state.error);

			default:
				// Use the original color as defined in the SVG by default.
				return getStylesForProps(applyColorToProps, '');
		}
	};
}

export const withIconStyles: IWithIconStylesHoC = (
	Icon: React.ComponentType<React.SVGProps<HTMLOrSVGImageElement>>,
	applyColorToProps = ['stroke'],
) => {
	const IconWithRole: FunctionComponent<IIconStyleProps> = (
		props: IIconStyleProps & HTMLAttributes<HTMLOrSVGImageElement>,
	) => {
		const { 'aria-label': ariaLabel, role } = props;
		return (
			<Icon role={role ?? ariaLabel ? 'img' : 'presentation'} {...props} />
		);
	};

	return styled(IconWithRole)<
		IIconStyleProps & HTMLAttributes<HTMLOrSVGImageElement>,
		ITheme
	>`
		vertical-align: middle;

		g {
			${themed(getColorForVariation(applyColorToProps))};
		}
	`;
};

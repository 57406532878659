import { color } from '../../themer/theme/color';

export const theme = {
	item: {
		border: {
			width: '1px',
			color: color.border.default,
		},
		padding: {
			desktop: '25px 0',
			mobile: '15px 0',
		},
	},
};

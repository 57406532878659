'use client';

import React from 'react';
import styled from '@emotion/styled';
import { Link } from '../../../../ui';
import Responsive from '../../../../ui/utils/Responsive';

interface INewsAlertContentProps {
	body: string;
	url?: string;
}

export const NewsAlertContent = ({ body, url }: INewsAlertContentProps) => (
	<Wrapper>{url ? <StyledLink href={url}>{body}</StyledLink> : body}</Wrapper>
);

const Wrapper = styled.span`
	display: block;
	font-size: 18px;
	white-space: nowrap;
	color: rgba(0, 0, 0, 0.87);
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 35px;
	width: 100%;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: inline-block;
		white-space: normal;
		max-height: 80px;
	}
`;

const StyledLink = styled(Link)`
	text-decoration: underline;
	color: rgba(0, 0, 0, 0.87);
`;

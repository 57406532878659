import React, { ImgHTMLAttributes } from 'react';
import { ImageWrap } from './ImageWrap';
import { VideoMarkdownWrapper } from '../Video/VideoMarkdownWrapper';

export const Image = ({
	className,
	...rest
}: ImgHTMLAttributes<HTMLImageElement>) => {
	if (rest.src?.endsWith('.mp4')) {
		return (
			<VideoMarkdownWrapper className={className}>
				<video controls>
					<track kind="captions" />
					<source src={rest.src} type="video/mp4" />
				</video>
			</VideoMarkdownWrapper>
		);
	}
	return (
		<ImageWrap className={className}>
			{/* eslint-disable-next-line jsx-a11y/alt-text */}
			<img {...rest} />
		</ImageWrap>
	);
};

import { background } from '../../themer/theme/background';
import { color } from '../../themer/theme/color';

export const theme = {
	background: {
		default: background.light,
		inverted: color.highlight,
	},
	font: {
		color: {
			default: color.text.default,
			inverted: color.text.inverted,
		},
	},
	padding: '40px',
	mobile: {
		padding: '18px',
	},
};

import {
	IBookingCreateRequestData,
	IBookingRequestAncillary,
} from '../../../../../shared/api/gateway/types/IBookingCreateRequestData';
import { IAncillary } from '../../../ancillary/domain/models/IAncillary';
import { IBooking } from '../../models/IBooking';
import { NewBooking } from '../../models/NewBooking';

export function adaptBookingCreateData(
	booking: NewBooking,
	parkingProductsQuoteKey: string | undefined,
	originalBooking?: IBooking,
): IBookingCreateRequestData | undefined {
	const {
		entryDate,
		exitDate,
		promoCode,
		parkingProduct,
		ancillaries,
		personalDetails,
		emailOptIn,
		vehicleDetails,
		travelDetails,
		paymentDetails,
	} = booking;

	if (
		!entryDate ||
		!exitDate ||
		!parkingProductsQuoteKey ||
		!parkingProduct ||
		!personalDetails ||
		!vehicleDetails ||
		!paymentDetails
	) {
		return undefined;
	}

	return {
		originalBooking: originalBooking
			? {
					reference: originalBooking.reference,
					email: originalBooking.personalDetails.email,
			  }
			: undefined,
		parkingProductsQuote: {
			key: parkingProductsQuoteKey,
			arrivalDateTime: entryDate.toISOString(),
			returnDateTime: exitDate.toISOString(),
			promoCode,
		},
		parkingProduct: {
			id: parkingProduct.id,
			price: parkingProduct.price.value.toString(),
		},
		ancillaries: adaptAncillaries(ancillaries),
		personalDetails,
		emailOptIn,
		vehicleDetails,
		travelDetails,
		paymentDetails: {
			token: paymentDetails.token,
			tokenType: paymentDetails.tokenType,
			total: booking.totalPrice.toString(),
		},
	};
}

function adaptAncillaries(
	ancillaries: IAncillary[],
): IBookingRequestAncillary[] {
	return ancillaries.map((ancillary) => ({
		id: ancillary.id,
		price: ancillary.price?.toString(),
		extras: ancillary.extras
			?.filter((extra) => extra.variants.length)
			.map((extra) => ({
				id: extra.id,
				variants: extra.variants.map((variant) => ({
					id: variant.id,
					price: variant.price.toString(),
				})),
			})),
	}));
}

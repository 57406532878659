import { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { IFormErrors } from './trackFormValidationErrors';

type ITracker<TFormData extends object> = (
	errors: IFormErrors<TFormData>,
) => void;

export function useFormValidationErrorTracker<TFormData extends object>(
	form: UseFormMethods<TFormData>,
	trackValidationErrors: ITracker<TFormData>,
) {
	useEffect(() => {
		const hasErrors = Object.keys(form.formState.errors).length > 0;

		if (hasErrors) {
			trackValidationErrors(form.formState.errors as IFormErrors<TFormData>);
		}
	}, [form.formState.errors]);
}

import React from 'react';
import { ResponsiveIframe } from './ResponsiveIframe';
import { IIframeProps } from './IIframeProps';

const YOUTUBE_EMBED_BASE_URL = 'https://www.youtube.com/embed/';

// Thank you Stack Overflow https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
// tslint:disable-next-line
const YOUTUBE_ID_REGEX =
	/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;

export const YouTubeIframe = (props: IIframeProps) => {
	const { url } = props;

	// Map a URL to a YouTube video to an embed URL
	// Eg. https://youtu.be/v-L9T7ZGw8Y will become https://www.youtube.com/embed/v-L9T7ZGw8Y
	const youtubeUrl = getYouTubeEmbedUrl(url);

	return (
		<ResponsiveIframe
			{...props}
			url={youtubeUrl}
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>
	);
};

export function isYouTubeUrl(url: string) {
	return getYouTubeId(url) !== undefined;
}

export function getYouTubeEmbedUrl(url: string) {
	const id = getYouTubeId(url);

	if (!id) {
		return url;
	}

	return `${YOUTUBE_EMBED_BASE_URL}${id}`;
}

export function getYouTubeId(url: string) {
	const matches = url.match(YOUTUBE_ID_REGEX);

	return matches && matches[1].length === 11 ? matches[1] : undefined;
}

import styled from '@emotion/styled';
import Responsive from '../../utils/Responsive';

export const StyledContactCardPhoneLink = styled.a<{}>`
	display: none;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: block;
	}
`;

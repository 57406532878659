import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';

import { getDropdownOptionState } from './helpers/getDropdownOptionState';

interface IDropdownOptionProps {
	isSelected: boolean;
	isHighlighted: boolean;
	hasSecondaryStyle?: boolean;
	className?: string;
}

export const DropdownOption = styled.div<IDropdownOptionProps, ITheme>`
	cursor: pointer;
	padding: ${themed(({ dropdownList }) => dropdownList.option.padding)};
	font-size: ${themed(({ dropdownList }) => dropdownList.option.fontSize)};
	transition: ${themed(({ dropdownList }) => dropdownList.option.transition)};

	${themed(
		({ dropdownList }, { isSelected, isHighlighted, hasSecondaryStyle }) => {
			const state = getDropdownOptionState(isSelected, isHighlighted);
			const theme = hasSecondaryStyle
				? dropdownList.option.secondaryStyle[state]
				: dropdownList.option[state];

			return `
			background: ${theme.background};
			color: ${theme.color};
		`;
		},
	)};
`;

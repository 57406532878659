import { logCognitoError, MaybeCognitoError } from '@move-frontend/auth';
import {
	ICognitoUserAddress,
	ICognitoUserInfo,
} from '../../../../shared/api/cognito/types/ICognitoUserInfo';
import { IAuthenticatedUser } from '../models/IAuthenticatedUser';
import { cognitoClient } from '../../../../shared/api/cognito';
import { logError } from '../../../../shared/error/logError';

export async function getAuthenticatedUser(): Promise<
	IAuthenticatedUser | undefined
> {
	const userInfo = await getUserInfo();

	if (!userInfo) {
		return undefined;
	}

	return getUserFromCognitoUserInfo(userInfo);
}

async function getUserInfo(): Promise<ICognitoUserInfo | undefined> {
	try {
		return await cognitoClient.getCurrentUserInfo();
	} catch (error) {
		logCognitoError(error as MaybeCognitoError, { customLogError: logError });
	}

	return undefined;
}

function getUserFromCognitoUserInfo(
	info: ICognitoUserInfo,
): IAuthenticatedUser {
	const { id, attributes } = info;
	const address: ICognitoUserAddress = JSON.parse(attributes.address || '{}');

	const {
		given_name: firstName,
		family_name: lastName,
		email,
		'custom:license_plate_number': licensePlateNumber,
	} = attributes;

	return {
		id,
		firstName,
		lastName,
		email,
		address: address.street_address,
		city: address.locality,
		state: address.region,
		zipCode: address.postal_code,
		phoneNumber: attributes.phone_number,
		licensePlateNumber,
	};
}

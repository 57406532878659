export const EVENT_CATEGORY_FLIGHTS = 'Flights';
export const EVENT_ACTION_FLIGHTS_SHOW_MORE = 'Show more';
export const EVENT_LABEL_FLIGHTS_SHOW_MORE_LATER = 'Later';
export const EVENT_LABEL_FLIGHTS_SHOW_MORE_EARLIER = 'Earlier';
export const EVENT_ACTION_FLIGHTS_SCOPE_CHANGE = 'Type';
export const EVENT_LABEL_FLIGHTS_SCOPE_ALL = 'All';
export const EVENT_LABEL_FLIGHTS_SCOPE_ARRIVALS = 'Arrivals';
export const EVENT_LABEL_FLIGHTS_SCOPE_DEPARTURES = 'Departures';
export const EVENT_ACTION_FLIGHTS_DATE_CHANGE = 'Date';
export const EVENT_LABEL_FLIGHTS_DATE_YESTERDAY = 'Yesterday';
export const EVENT_LABEL_FLIGHTS_DATE_TODAY = 'Today';
export const EVENT_LABEL_FLIGHTS_DATE_TOMORROW = 'Tomorrow';
export const EVENT_ACTION_FLIGHTS_QUERY_CHANGE = 'Search';
export const EVENT_ACTION_FLIGHTS_RESULTS_CHANGE = 'Results';
export const EVENT_ACTION_FLIGHTS_SUGGESTION_SELECT = 'Autocomplete';

export const EVENT_CATEGORY_POI = 'Shop, Dine & Relax';
export const EVENT_ACTION_POI_AREA_CHANGE = 'Areas';
export const EVENT_LABEL_POI_AREA_ALL = 'All areas';
export const EVENT_ACTION_POI_KEYWORD_CHANGE = 'Category';
export const EVENT_ACTION_POI_AMENITY_KEYWORD_CHANGE = 'Amenity';
export const EVENT_LABEL_POI_KEYWORD_ALL = 'All keywords';
export const EVENT_ACTION_POI_QUERY_CHANGE = 'Search';
export const EVENT_ACTION_POI_SHOW_MORE = 'Show more';

export const EVENT_CATEGORY_NEWSROOM = 'Newsroom';
export const EVENT_ACTION_NEWSROOM_YEAR_CHANGE = 'Year selector';
export const EVENT_ACTION_NEWSROOM_SHOW_MORE = 'Show more';

export const EVENT_CATEGORY_SECURITY_WAITING_TIMES = 'TSA';
export const EVENT_ACTION_SECURITY_WAITING_TIMES_FILTER_CHANGE = 'Filter';

export const EVENT_CATEGORY_AUTH = 'Authentication';
export const EVENT_ACTION_AUTH_SIGN_OUT = 'Sign out';

export const EVENT_CATEGORY_PARKING_BOOKING = 'Parking booking';
export const EVENT_ACTION_RETRIEVE_BOOKING_AS_GUEST_ERROR =
	'Retrieve booking as guest error';

export const EVENT_CATEGORY_PARKING_AVAILABILITY = 'Parking Availability';
export const EVENT_ACTION_PARKING_AVAILABILITY_SELECTED_ENTRY_DATE =
	'Selected Entry date';
export const EVENT_ACTION_PARKING_AVAILABILITY_SELECTED_EXIT_DATE =
	'Selected Exit date';

export const EVENT_CATEGORY_PARKING_OPTIONS = 'Parking Options';
export const EVENT_ACTION_PARKING_OPTIONS_PROMOTION_SUCCESS =
	'Promotion success';
export const EVENT_ACTION_PARKING_OPTIONS_PROMOTION_INVALID =
	'Promotion invalid';

export const EVENT_CATEGORY_PARKING_PRODUCTS = 'Parking Products';
export const EVENT_ACTION_PARKING_PRODUCTS_SELECTED_PRODUCT =
	'Selected Product';
export const EVENT_ACTION_PARKING_PRODUCTS_ADDED_UPGRADE = 'Added Upgrade';
export const EVENT_ACTION_PARKING_PRODUCTS_REMOVED_UPGRADE = 'Removed Upgrade';
export const EVENT_ACTION_PARKING_PRODUCTS_EXTRAS_MORE_INFO =
	'Extras more info';

export const EVENT_CATEGORY_PARKING_PERSONAL_DETAILS =
	'Parking Personal Details';
export const EVENT_ACTION_PARKING_PERSONAL_DETAILS_REASON_FOR_TRAVEL =
	'Reason for travel';
export const EVENT_ACTION_PARKING_PERSONAL_DETAILS_MAILING_LIST_OPT_IN =
	'Mailing list opt-in';
export const EVENT_ACTION_PARKING_PERSONAL_DETAILS_REMEMBER_DETAILS =
	'Remember details';
export const EVENT_ACTION_PARKING_PERSONAL_DETAILS_CREATE_ACCOUNT =
	'Create account';
export const EVENT_ACTION_PARKING_PERSONAL_DETAILS_VALIDATION_ERROR =
	'Validation error';

export const EVENT_CATEGORY_PARKING_PAYMENT = 'Parking Payment';
export const EVENT_ACTION_PARKING_PAYMENT_ADD_NEW = 'Add new card';
export const EVENT_ACTION_PARKING_PAYMENT_REMEMBER_CARD = 'Remember card';

export const EVENT_CATEGORY_PARKING_BOOKING_DETAILS = 'Parking Booking Details';
export const EVENT_ACTION_PARKING_BOOKING_DETAILS_CANCEL_CONFIRMATION =
	'Cancel confirmation';

export const EVENT_CATEGORY_ACCOUNT_REGISTRATION = 'Account Registration';
export const EVENT_ACTION_ACCOUNT_REGISTRATION_VALIDATION_ERROR =
	'Validation error';

import React from 'react';
import { StyledHeaderTitle } from '../../../../ui';
import { IPageHeaderProps, PageHeader } from './PageHeader';
import { StyledUserHeaderTitleWrap } from './UserHeader';
import { TierPointsInfoTag } from '../../../account/ui/shared/TierPointsInfoTag';

export const UserPageHeader = (props: IPageHeaderProps) => {
	const { page } = props;

	return (
		<PageHeader {...props} hasCustomTitle>
			<StyledUserHeaderTitleWrap>
				<StyledHeaderTitle>{page.fields.title}</StyledHeaderTitle>
				<TierPointsInfoTag />
			</StyledUserHeaderTitleWrap>
		</PageHeader>
	);
};

import {
	FlightEnrichStore,
	FlightFilterStore,
	FlightSortStore,
	FlightStore,
} from '@move-frontend/dap';
import {
	IEnrichedFlight,
	IEnrichedFlightProps,
} from '../../../shared/flight/domain/models/IEnrichedFlight';
import { IFlight } from '../../../shared/flight/domain/models/IFlight';
import { FlightListStore } from './FlightListStore';

export function createFlightListStore(
	flightStore: FlightStore<IFlight>,
	flightEnrichStore: FlightEnrichStore<IFlight, IEnrichedFlightProps>,
) {
	const flightFilterStore = new FlightFilterStore<IEnrichedFlight>();
	const flightSortStore = new FlightSortStore<IEnrichedFlight>();

	return new FlightListStore(
		flightStore,
		flightEnrichStore,
		flightFilterStore,
		flightSortStore,
	);
}

import { useCallback, useEffect, useRef, useState } from 'react';
import { isServerSide } from '../../utils/isServerSide';
import { addHashListener, removeHashListener } from './hashHistory';

const getInitialHash = () => (isServerSide() ? '' : window.location.hash);

export function useHashChange() {
	const [hash, setHash] = useState(getInitialHash);
	const [previous, setPrevious] = useState<string | undefined>(undefined);
	const hashRef = useRef(getInitialHash());

	// @ts-ignore
	const update = useCallback((newHash) => {
		if (newHash !== hashRef.current) {
			setPrevious(hashRef.current);
			setHash(newHash);
			hashRef.current = newHash;
		}
	}, []);

	useEffect(() => {
		addHashListener(update);
		return () => {
			removeHashListener(update);
		};
	}, [update]);

	return { hash, previous };
}

import React from 'react';
import { LinkText, StyledIconLink } from '../Link';
import { QuickLinkItem } from './QuickLinkItem';
import { MailIcon } from '../Icon';

export interface IQuickLinkEmailProps {
	title: string;
	address: string;
}

export const QuickLinkEmail = ({ title, address }: IQuickLinkEmailProps) => (
	<QuickLinkItem>
		<StyledIconLink href={`mailto:${address}`} target="_blank">
			<LinkText>{title}</LinkText>
			<MailIcon variation="highlight" />
		</StyledIconLink>
	</QuickLinkItem>
);

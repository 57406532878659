import React from 'react';
import { ArrowBackIcon, StyledIconLink } from '../../../../ui';
import illustration from '../../../../../assets/page-not-found.svg?reference';
import { StyledIllustratedErrorMessage } from './IllustratedErrorMessage';
import { withTranslate } from '../../i18n/translate';

interface INotFoundMessage {
	linkUrl?: string;
	linkTitle?: string;
}

export const NotFoundMessage = withTranslate<INotFoundMessage>(
	({ t, linkUrl, linkTitle }) => (
		<StyledIllustratedErrorMessage>
			<StyledIconLink href={linkUrl || '/'}>
				<ArrowBackIcon aria-hidden variation="highlight" />
				<> </>
				{linkTitle || t('web_not_found_go_to_home')}
			</StyledIconLink>
			<img src={illustration} alt={t('web_not_found_image_alt')} />
		</StyledIllustratedErrorMessage>
	),
);

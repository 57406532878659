import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { ITextFieldProps, TextField } from '../../../../ui';

export const FirstName = (props: Partial<ITextFieldProps>) => {
	const { t } = useTranslation();
	const { register, errors } = useFormContext();

	return (
		<TextField
			name="firstName"
			type="text"
			label={t('user_form_first_name_label')}
			placeholder={t('user_form_first_name_placeholder')}
			ref={register()}
			invalid={!!errors.firstName}
			error={errors.firstName?.message}
			autoComplete="given-name"
			{...props}
		/>
	);
};

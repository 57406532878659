import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { ITheme, PropsOf } from '../../../../ui';
import {
	DIALOG_CONTENT_MAX_WIDTH,
	WIDE_DIALOG_CONTENT_MAX_WIDTH,
} from './constant';
import Responsive from '../../../../ui/utils/Responsive';

export interface IDialogSizeProps {
	wideContent?: boolean;
}

export function getDialogMaxWidth({ wideContent }: IDialogSizeProps) {
	return wideContent ? WIDE_DIALOG_CONTENT_MAX_WIDTH : DIALOG_CONTENT_MAX_WIDTH;
}

export function getDialogPadding({ wideContent }: IDialogSizeProps) {
	return wideContent ? '40px' : '24px';
}

export function getDialogPaddingMobile() {
	return '16px';
}

export const StyledDialogMain = styled.div<IDialogSizeProps, ITheme>`
	max-width: calc(${getDialogMaxWidth} + (${getDialogPadding} * 2));
	padding: 24px ${getDialogPadding};
	margin-left: auto;
	margin-right: auto;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		max-width: calc(${getDialogMaxWidth} + (${getDialogPaddingMobile} * 2));
		padding: 16px ${getDialogPaddingMobile};
	}
`;

export interface IDialogMainProps extends PropsOf<typeof StyledDialogMain> {
	wideContent?: boolean;
}

export const DialogMain = (props: PropsWithChildren<IDialogMainProps>) => (
	<StyledDialogMain {...props} />
);

import { makeAutoObservable, observable } from 'mobx';
import { defaultLocale } from '../../../../shared/i18n/locale/constants/default';
import { IAncillary } from '../../ancillary/domain/models/IAncillary';
import { IParkingProduct } from '../../parking-product/models/IParkingProduct';
import { getTotalNewBookingPrice } from './helpers/getTotalNewBookingPrice';
import { INewBooking } from './INewBooking';
import { IPersonalDetails } from './IPersonalDetails';
import { ITravelDetails } from './ITravelDetails';
import { IVehicleDetails } from './IVehicleDetails';
import { IPaymentDetails } from './IPaymentDetails';

export class NewBooking implements INewBooking {
	public locale = defaultLocale.code;

	public daysInTotal: number | undefined = undefined;

	public entryDate: Date | undefined = undefined;

	public exitDate: Date | undefined = undefined;

	public promoCode: string | undefined = undefined;

	public parkingProduct: IParkingProduct | undefined = undefined;

	public ancillaries = observable<IAncillary>([]);

	public personalDetails: IPersonalDetails | undefined = undefined;

	public emailOptIn: boolean | undefined = undefined;

	public vehicleDetails: IVehicleDetails | undefined = undefined;

	public travelDetails: ITravelDetails | undefined = undefined;

	reference: string | undefined = undefined;

	token: string | undefined = undefined;

	public paymentDetails: IPaymentDetails | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	get isSubmitted() {
		return typeof this.reference === 'string';
	}

	get totalPrice() {
		return getTotalNewBookingPrice(this);
	}
}

import React from 'react';
import { HashDialog } from '../../../shared/components/dialog/HashDialog';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { FORGOT_PASSWORD_CONFIRMATION_DIALOG_HASH } from './confirmation/ForgotPasswordConfirmationDialog';
import { ForgotPasswordDialogContent } from './ForgotPasswordDialogContent';

export const FORGOT_PASSWORD_DIALOG_HASH = '#forgot-password';

export const ForgotPasswordDialog = () => {
	const { t } = useTranslation();

	const title = t('user_forgot_password_title');

	return (
		<HashDialog ariaLabel={title} hash={FORGOT_PASSWORD_DIALOG_HASH}>
			<ForgotPasswordDialogContent
				confirmationDialogHash={FORGOT_PASSWORD_CONFIRMATION_DIALOG_HASH}
				title={title}
				description={t('user_forgot_password_description')}
			/>
		</HashDialog>
	);
};

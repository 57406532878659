import { makeAutoObservable } from 'mobx';

export class ForgotPasswordStore {
	private _email: string | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	get email() {
		return this._email;
	}

	set email(email: string | undefined) {
		this._email = email;
	}
}

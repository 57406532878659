import React from 'react';
import { css, Global } from '@emotion/core';
import { ITheme } from '../../themer';
import { Gutter as GutterUtil } from '../../utils';

interface IProps {
	theme: ITheme;
}

export const Gutter = ({ theme }: IProps) => {
	const styles = css`
		:root {
			${GutterUtil.getResponsiveValuesFromTheme(theme, 'horizontal')}
			${GutterUtil.getResponsiveValuesFromTheme(theme, 'vertical')}
		}
	`;

	return <Global styles={styles} />;
};

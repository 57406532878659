import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useLoaderStore } from './store/LoaderStoreContext';
import { PageLoaderBar } from './PageLoaderBar';

export const PageLoader: FC<{ message?: string }> = observer(({ message }) => {
	const loaderStore = useLoaderStore();

	return <PageLoaderBar message={message} isActive={loaderStore.isLoading} />;
});

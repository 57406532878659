import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from '../../../features/shared/i18n/translate/useTranslation';

interface ICarouselItemProps {
	children?: React.ReactNode;
	className?: string;
	ariaSlideIndex: number;
	ariaTotalNumberOfSlides: number;
	ariaSlideTitle: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function CarouselItem({
	children,
	className,
	ariaSlideIndex,
	ariaTotalNumberOfSlides,
	ariaSlideTitle,
	...rest
}: ICarouselItemProps) {
	const { t } = useTranslation();

	return (
		<StyledCarouselItem
			className={className}
			{...rest}
			data-carousel-card
			aria-roledescription="slide"
			aria-label={t('carousel_slide_aria_label', {
				current: ariaSlideIndex + 1,
				total: ariaTotalNumberOfSlides,
				title: ariaSlideTitle,
			})}
		>
			{children}
		</StyledCarouselItem>
	);
}

export const StyledCarouselItem = styled.li`
	list-style: none;
	padding: 0;
	margin: 0;

	display: flex;

	/* Prevent drag interference */
	user-select: none;
`;

export const ACCORDION_ITEM_TYPE_ID = 'accordion-item';
export const ACCORDION_SECTION_TYPE_ID = 'accordion-section';
export const AIRLINE_SECTION_TYPE_ID = 'airline-section';
export const AIRLINE_TYPE_ID = 'airline';
export const AIRPORT_TYPE_ID = 'airport';
export const ALERT_SECTION_TYPE_ID = 'alert-section';
export const ASSET_LINK_TYPE_ID = 'asset-link';
export const BLOG_ITEM_TYPE_ID = 'blog-item';
export const CALL_TO_ACTION_SECTION_TYPE_ID = 'call-to-action-section';
export const CARD_ITEM_TYPE_ID = 'card-item';
export const CARD_SECTION_TYPE_ID = 'card-section';
export const CONTACT_ITEM_TYPE_ID = 'contact-item';
export const CONTACT_SECTION_TYPE_ID = 'contact-section';
export const CONTENT_CATEGORY_TYPE_ID = 'content-category';
export const EMAIL_LINK_TYPE_ID = 'email-link';
export const EXTERNAL_LINK_TYPE_ID = 'external-link';
export const FOOTER_TYPE_ID = 'footer';
export const HIGHLIGHT_SECTION_TYPE_ID = 'highlight-section';
export const IFRAME_SECTION_TYPE_ID = 'iframe-section';
export const MENU_ITEM_TYPE_ID = 'menu-item';
export const NEWS_SECTION_TYPE_ID = 'news-section';
export const NEWS_ALERT_TYPE_ID = 'news-alert';
export const NEWS_ITEM_TYPE_ID = 'news-item';
export const NEWS_STORY_TYPE_ID = 'news-story';
export const NEWS_CARD_TYPE_ID = 'news-card';
export const PARKING_WIDGET_TYPE_ID = 'parking-widget';
export const PHONE_LINK_TYPE_ID = 'phone-link';
export const QUICK_LINKS_SECTION_TYPE_ID = 'quick-links-section';
export const TEXT_SECTION_TYPE_ID = 'text-section';
export const BANNER_SECTION_ID = 'banner-section';
export const PARKING_INFO_TYPE_ID = 'parking-info';
export const PARKING_AVAILABILITY_SECTION_TYPE_ID =
	'parking-availability-section';
export const PRESS_RELEASE_TYPE_ID = 'press-release';
export const YOUTUBE_VIDEO_COLLECTION_ID = 'youtubeVideoCollection';
export const YOUTUBE_VIDEO_ID = 'youtubeVideo';
export const LOYALTY_TIER_TYPE_ID = 'loyalty-tier';
export const LOYALTY_TIER_BENEFIT_TYPE_ID = 'benefit-item';
export const INSTRUCTION_SECTION_TYPE_ID = 'instruction-section';
export const INSTRUCTION_ITEM_TYPE_ID = 'instruction-item';
export const ROYALTY_TIER_SECTION_TYPE_ID = 'royalty-tier-section';
export const LOYALTY_TIER_ITEM_TYPE_ID = 'loyalty-tier';
export const LOYALTY_TIER_BENEFIT_ITEM_TYPE_ID = 'benefit-item';

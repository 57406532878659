import { ButtonHTMLAttributes, ReactNode } from 'react';
import styled from '@emotion/styled';
import { StyledButton } from './Button';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import { theme } from '../../../features/shared/styles';

export interface IIconButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	inverted?: boolean;
	secondary?: boolean;
	children: ReactNode;
}

function getStrokeColor(state: 'hover' | 'default') {
	return themed<IIconButtonProps>(({ button }, { secondary, inverted }) => {
		const type = secondary ? 'secondary' : 'primary';
		const baseTheme = button[type];
		const variationTheme = inverted ? baseTheme.inverted : baseTheme;
		const stateTheme =
			state === 'hover' ? variationTheme.hover : variationTheme;

		return stateTheme.font.color;
	});
}

export const StyledIconButton = styled(StyledButton)<IIconButtonProps, ITheme>`
	display: inline-flex;
	align-items: center;
	font-family: ${themed(({ typography }) => typography.body.fontFamily)};

	svg {
		flex-grow: 0;
		flex-shrink: 0;
		height: ${themed(({ button }) => button.iconSize)};
		margin-left: ${themed(({ button }) => button.iconSeparator)};
	}

	g {
		stroke: ${getStrokeColor('default')};
	}

	&:hover {
		g {
			stroke: ${getStrokeColor('hover')};
		}
	}

	&:focus-visible {
		outline: ${theme.button?.primary?.focus?.outline};
		z-index: 1;
	}
`;

import * as Sentry from '@sentry/browser';
import log from 'loglevel';
import { isProductionBuild } from '../utils/isProductionBuild';

export function logError(error: Error, info?: Record<string, unknown>) {
	if (isProductionBuild()) {
		logErrorToSentry(error, info);
	}

	log.error(error, info);
}

export function logErrorToSentry(error: Error, info?: Record<string, unknown>) {
	if (!info) {
		Sentry.captureException(error);
		return;
	}

	Sentry.withScope((scope) => {
		Object.keys(info).forEach((key) => {
			scope.setExtra(key, info[key]);
		});

		Sentry.captureException(error);
	});
}

import React, { ReactNode } from 'react';
import { HashRouteContext } from './HashRouteContext';
import { useHashMatch } from '../../router/hash/useHashMatch';

export interface IHashRouteProps {
	hash: string;
	children?: ReactNode;
}

export const HashRoute = ({ hash: pattern, children }: IHashRouteProps) => {
	const { hash, matches } = useHashMatch(pattern);

	return hash && matches ? (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<HashRouteContext.Provider value={{ hash, pattern, matches }}>
			{children}
		</HashRouteContext.Provider>
	) : null;
};

import { AbstractService } from './AbstractService';
import { ICaptureContextResponse } from '../types/ICaptureContextResponse';
import {
	CaptureContextType,
	ISessionRequestData,
} from '../types/ISessionRequestData';
import { getBaseUrl } from '../../../router/getBaseUrl';

export class CaptureContextSessionService extends AbstractService {
	create(
		type: CaptureContextType = 'microform',
	): Promise<ICaptureContextResponse> {
		return this.client.post<ISessionRequestData, ICaptureContextResponse>(
			'/payment/session',
			{
				type,
				targetOrigin: getBaseUrl(),
			},
		);
	}
}

import React, { MouseEventHandler, ReactNode } from 'react';
import styled from '@emotion/styled';
import themed from '../../themer/themed';
import { CancelIcon } from '../Icon';
import Responsive from '../../utils/Responsive';
import ContentWrap from '../Layout/ContentWrap';
import { ITheme } from '../../themer';

interface IStyledAlertBannerProps {
	hasCloseButton: boolean;
	isEmergency?: boolean;
}

export const StyledAlertBanner = styled.div<IStyledAlertBannerProps, ITheme>`
	background: ${({ isEmergency }) =>
		themed(({ alertBanner }) =>
			isEmergency ? alertBanner.emergencyBackground : alertBanner.background,
		)};
	padding: 18px 0;

	p:last-of-type {
		margin-bottom: 0;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: 10px 0;

		${ContentWrap} {
			margin-top: 0;
			margin-bottom: 0;
		}

		${({ hasCloseButton }) =>
			hasCloseButton &&
			`
			${StyledAlertBannerTitle} {
				margin-top: 1em;
			}
		`}
	}
`;

export const StyledAlertBannerHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
`;

export const StyledAlertBannerTitle = styled.div<{}, ITheme>`
	font-weight: bold;
	font-size: ${themed(({ alertBanner }) => alertBanner.title.fontSize)};
	flex-grow: 1;
`;

const StyledAlertBannerCloseButton = styled.div<{}, ITheme>`
	flex-shrink: 0;
	cursor: pointer;

	${CancelIcon} {
		max-width: 24px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		span {
			display: none;
			font-weight: bold;
		}
	}
`;

interface IAlertBannerProps {
	title: string;
	children: ReactNode;
	closeLabel?: string;
	onClickClose?: MouseEventHandler;
}

export const AlertBanner = ({
	title,
	children,
	closeLabel,
	onClickClose,
}: IAlertBannerProps) => (
	<StyledAlertBanner hasCloseButton={onClickClose !== undefined} role="alert">
		<ContentWrap>
			<StyledAlertBannerHeader>
				<StyledAlertBannerTitle>{title}</StyledAlertBannerTitle>
				{onClickClose && (
					<StyledAlertBannerCloseButton onClick={onClickClose}>
						{closeLabel && <span>{closeLabel}</span>}
						<CancelIcon />
					</StyledAlertBannerCloseButton>
				)}
			</StyledAlertBannerHeader>
			{children}
		</ContentWrap>
	</StyledAlertBanner>
);

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ILinkProps, IStyledLinkProps, Link } from './Link';
import { ITheme, IThemedProps } from '../../themer';
import themed from '../../themer/themed';

export interface IIconLinkProps extends ILinkProps {
	children: ReactNode;
}

const getTextColor = themed<IStyledLinkProps>(
	({ color, typography }, { inverted }) =>
		inverted ? color.text.inverted : typography.link.color,
);

const getTextColorHover = themed<IStyledLinkProps>(
	({ color, typography }, { inverted }) =>
		inverted ? color.text.inverted : typography.link.hover.color,
);

export function getIconLinkHoverStyle(props: IStyledLinkProps & IThemedProps) {
	return `
		text-decoration: none;
		color: ${props.customColor ?? getTextColorHover(props)};


		&:after {
			width: 100%;
			background: ${props.customColor ?? getTextColorHover(props)};
			transition: .2s width .05s, .2s background .05s;
		}

		g {
			stroke: ${props.customColor ?? getTextColorHover(props)};
		}
	`;
}

const IconLink = (props: IIconLinkProps) => (
	<Link hideExternalIndicator {...props} />
);

export const StyledIconLink = styled(IconLink)<IIconLinkProps, ITheme>`
	position: relative;
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	text-decoration: none;
	color: ${getTextColor};
	${({ customColor }) => customColor && `color: ${customColor};`}

	svg {
		flex-grow: 0;
		flex-shrink: 0;
		height: ${themed(({ button }) => button.iconSize)};
		margin-left: ${themed(({ button }) => button.iconSeparator)};
	}

	g {
		stroke: ${getTextColor};
		${({ customColor }) => customColor && `stroke: ${customColor};`}
	}

	&:after {
		content: '';
		position: absolute;
		bottom: -0.15em;
		left: 0;
		// Needs to be at least 1px to prevent iOS from trigger the hover state before trigger a click
		// See: https://css-tricks.com/annoying-mobile-double-tap-link-issue/
		width: 0.1%;
		height: 2px;
		background: transparent;
		transition: 0.2s width 0.05s, 0s background 0.25s;
	}

	&:hover {
		${getIconLinkHoverStyle}
	}
`;

import { RemoteDataStore } from '@move-frontend/utils';
import { action, makeObservable, observable } from 'mobx';
import { BookingService } from '../../../../shared/api/gateway/services/BookingService';
import { IGetBookingByReferenceQueryParams } from '../../../../shared/api/gateway/types/IGetBookingByReferenceQueryParams';
import { IGetBookingByReferenceResponse } from '../../../../shared/api/gateway/types/IGetBookingByReferenceResponse';
import { IDetailsFormData } from '../../../ui/booking-steps/details/types/IDetailsFormData';
import { IBooking } from '../models/IBooking';
import { adaptDetailsFormDataToPatchRequest } from './adapters/adaptDetailsFormDataToPatchRequest';
import { adaptResponseToBooking } from './adapters/adaptResponseToBooking';

export interface IGuestBookingParams {
	reference: string;
	email: string;
}

export class GuestBookingStore extends RemoteDataStore<
	IGuestBookingParams,
	IGetBookingByReferenceResponse
> {
	private _guestBooking: IBooking | undefined = undefined;

	constructor(private readonly bookingService: BookingService) {
		super();

		makeObservable<
			GuestBookingStore,
			'_guestBooking' | 'handleResponse' | 'handleUpdateBookingDetailsSuccess'
		>(this, {
			_guestBooking: observable,
			handleResponse: action,
			clear: action,
			handleUpdateBookingDetailsSuccess: action,
		});
	}

	get guestBooking() {
		return this._guestBooking;
	}

	protected performRequest(params: IGetBookingByReferenceQueryParams) {
		return this.bookingService.getGuestBooking(params);
	}

	protected handleResponse(response: IGetBookingByReferenceResponse) {
		const guestBooking = adaptResponseToBooking(response, true);

		this._guestBooking = guestBooking;
	}

	clear() {
		this._guestBooking = undefined;
	}

	async cancelBooking(params: IGuestBookingParams) {
		await this.bookingService.cancelBookingByReference(params);
	}

	async updateBookingDetails(booking: IBooking, data: IDetailsFormData) {
		const requestData = adaptDetailsFormDataToPatchRequest(data);

		const response = await this.bookingService.patchBookingByReference(
			{
				reference: booking.reference,
				email: booking.personalDetails.email,
			},
			requestData,
		);

		const updatedBooking = adaptResponseToBooking(response, true);

		this.handleUpdateBookingDetailsSuccess(updatedBooking);
	}

	private handleUpdateBookingDetailsSuccess(updatedBooking: IBooking) {
		this._guestBooking = updatedBooking;
	}
}

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { SignUpErrorMessage } from './form/SignUpErrorMessage';
import { DialogMain } from '../../../shared/components/dialog/DialogMain';
import { DialogFooter } from '../../../shared/components/dialog/DialogFooter';
import { SIGN_UP_CONFIRMATION_DIALOG_HASH } from './confirmation/SignUpConfirmationDialog';
import { setHash } from '../../../shared/router/hash/hashHistory';
import { SignUpForm } from './form/SignUpForm';
import { SignInRegisterDialogHeaderGradient } from '../../shared/sign-in-register/SignInRegisterDialogHeaderGradient';
import { SIGN_UP_DIALOG_HASH } from './SignUpDialog';
import { GrayMarketingDoneList } from '../../../shared/components/marketing-list';
import Responsive from '../../../../ui/utils/Responsive';

export const SignUpDialogContent = () => {
	const { t } = useTranslation();
	const [signUpError, setSignUpError] = useState<Error | undefined>(undefined);

	const onSignUpSuccess = () => {
		setSignUpError(undefined);
		setHash(SIGN_UP_CONFIRMATION_DIALOG_HASH, { replace: true });
	};

	const onSignUpFailure = (error: Error) => {
		setSignUpError(error);
	};

	return (
		<>
			<SignInRegisterDialogHeaderGradient type={SIGN_UP_DIALOG_HASH} />
			<StyledSignUpDialogMain>
				<GrayMarketingDoneList
					title={t('user_create_account_to')}
					items={[
						t('marketing_become_royalty_member'),
						t('marketing_earn_points'),
						t('marketing_use_points_discounts'),
					]}
				/>
				<SignUpForm onSuccess={onSignUpSuccess} onFailure={onSignUpFailure} />
			</StyledSignUpDialogMain>
			{signUpError && (
				<DialogFooter variation="error">
					<SignUpErrorMessage error={signUpError} />
				</DialogFooter>
			)}
		</>
	);
};

const StyledSignUpDialogMain = styled(DialogMain)`
	padding-top: 0;
	position: relative;
	margin-top: -20px;
	display: flex;
	flex-direction: column;
	gap: 32px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding-top: 0;
		position: relative;
		margin-top: -50px;
	}
`;

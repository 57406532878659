import React, { ReactNode } from 'react';
import { IResponsiveImageSource } from '../Image';
import { HighlightContent } from './HighlightContent';
import { IStyledHighlightProps, StyledHighlight } from './StyledHighlight';
import { HighlightImage } from './HighlightImage';
import { useOnClickCardHandler } from '../Card/useOnClickCardHandler';

interface IHighlightProps extends IStyledHighlightProps {
	className?: string;
	children?: ReactNode;
	title: string;
	linkTitle?: string;
	linkHref?: string;
	imageSource: IResponsiveImageSource;
	imageAlt?: string;
	showLinkAsButton?: boolean;
}

export const Highlight = ({
	className,
	elevated,
	orientation,
	imagePosition,
	imageSource,
	imageAlt,
	linkHref,
	fullyClickable,
	withBackgroundColor,
	...rest
}: IHighlightProps) => {
	const onClickUrl = fullyClickable ? linkHref : undefined;
	const onClick = useOnClickCardHandler(onClickUrl);

	return (
		<StyledHighlight
			fullyClickable={fullyClickable}
			className={className}
			elevated={elevated}
			orientation={orientation}
			imagePosition={imagePosition}
			onClick={onClick}
			withBackgroundColor={withBackgroundColor}
			usesLinkAsButton={rest.showLinkAsButton}
		>
			<HighlightImage source={imageSource} alt={imageAlt} />
			<HighlightContent linkHref={linkHref} {...rest} />
		</StyledHighlight>
	);
};

import { GatewayErrorResponseCode } from './GatewayErrorResponseCode';
import { isGatewayError } from './isGatewayError';

export function isKnownGatewayError(error: Error) {
	if (!isGatewayError(error) || !error.response.data.code) return false;

	return Object.values(GatewayErrorResponseCode).includes(
		error.response.data.code as GatewayErrorResponseCode,
	);
}

import React, { memo } from 'react';
import Head from 'next/head';
import { getAdaConfig } from '../../../analytics/shared/config/getAdaConfig';

export interface IAdaConfig {
	monsidoAdaToken?: string;
}

export const MonsidoAdaScript = memo(() => {
	const { monsidoAdaToken } = getAdaConfig();

	if (!monsidoAdaToken) return null;

	return (
		<>
			<Head>
				<script
					async
					type="text/javascript"
					src="https://app-script.monsido.com/v2/monsido-script.js"
				/>
				<script
					type="text/javascript"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						// eslint-disable-next-line @typescript-eslint/naming-convention
						__html: `
							window._monsido = window._monsido || {
								token: '${monsidoAdaToken}',
								statistics: {
									enabled: true,
									cookieLessTracking: true,
									documentTracking: {
										enabled: false,
										documentCls: "monsido_download",
										documentIgnoreCls: "monsido_ignore_download",
										documentExt: [],
									},
								},
							};
						`,
					}}
				/>
			</Head>
		</>
	);
});

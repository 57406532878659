import { action, computed, makeObservable, observable, set } from 'mobx';
import { FilterStore, SortStore } from '@move-frontend/utils';
import { ISuggestionAdapter } from '../models/ISuggestionAdapter';

export class SuggestionStore<SuggestionObjectType extends {}> {
	public query = '';

	public limit = 20;

	protected _adapters: ISuggestionAdapter<SuggestionObjectType>[] = [];

	constructor(
		protected readonly filterStore: FilterStore<SuggestionObjectType>,
		protected readonly sortStore: SortStore<SuggestionObjectType>,
	) {
		makeObservable<
			SuggestionStore<SuggestionObjectType>,
			| '_adapters'
			| 'adaptedSuggestions'
			| 'filteredSuggestions'
			| 'sortedSuggestions'
		>(this, {
			query: observable,
			limit: observable,
			_adapters: observable,
			setAdapters: action,
			adaptedSuggestions: computed,
			filteredSuggestions: computed,
			sortedSuggestions: computed,
			suggestions: computed,
			reset: action,
		});
	}

	public setAdapters(adapters: ISuggestionAdapter<SuggestionObjectType>[]) {
		set(this._adapters, adapters);
	}

	protected get adaptedSuggestions(): SuggestionObjectType[] {
		const suggestions: SuggestionObjectType[] = [];

		this._adapters.forEach((adapter) => {
			suggestions.push(...adapter());
		});

		return suggestions;
	}

	protected get filteredSuggestions() {
		return this.filterStore.filter(this.adaptedSuggestions);
	}

	protected get sortedSuggestions() {
		return this.sortStore.sort(this.filteredSuggestions);
	}

	public get suggestions() {
		return this.sortedSuggestions.slice(0, this.limit);
	}

	public reset() {
		this.query = '';
	}
}

import { useForm } from '../../../../../shared/form/useForm';
import { useForgotPasswordStore } from '../../../domain/stores/useForgotPasswordStore';
import { IForgotPasswordFormData } from '../../../domain/models/IForgotPasswordFormData';
import { useForgotPasswordSchema } from './useForgotPasswordSchema';

export function useForgotPasswordForm() {
	const { email } = useForgotPasswordStore();
	const validationSchema = useForgotPasswordSchema();
	const form = useForm<IForgotPasswordFormData>({
		mode: 'onBlur',
		validationSchema,
		defaultValues: {
			email,
		},
	});

	return form;
}

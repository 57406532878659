import styled from '@emotion/styled';
import { ITheme } from '../../../../ui';
import themed from '../../../../ui/themer/themed';
import Responsive from '../../../../ui/utils/Responsive';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';

export const SOLID_CONTENT_WRAP_PADDING_DESKTOP = '32px';
export const SOLID_CONTENT_WRAP_PADDING_MOBILE_XL = '16px';
export const SOLID_CONTENT_WRAP_PADDING_MOBILE = '10px';

export const SolidContentWrap = styled(ContentWrap)<{}, ITheme>`
	padding: ${SOLID_CONTENT_WRAP_PADDING_DESKTOP};
	margin-top: 40px;
	margin-bottom: 40px;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
	background: ${themed(({ color }) => color.background.default)};

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: ${SOLID_CONTENT_WRAP_PADDING_MOBILE_XL};
		margin: 24px 16px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		padding: ${SOLID_CONTENT_WRAP_PADDING_MOBILE};
		margin: 10px 8px;
	}
`;

import React, { forwardRef } from 'react';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { PassengersPageId } from '../../../app/pages/domain/constants/PageId';
import { useUrlForPageId } from '../../../app/pages/ui/utils/useUrlForPageId';
import { Checkbox, ICheckboxProps } from '../../../../ui';

export const TermsAndConditionsCheckboxLabel = () => {
	const { t } = useTranslation();

	const termsAndConditionsUrl = useUrlForPageId(
		PassengersPageId.TermsAndConditions,
	);
	const privacyPolicyUrl = useUrlForPageId(PassengersPageId.PrivacyPolicy);

	const html = t('user_sign_up_consent_label', {
		terms_and_conditions_link: `<a style="text-decoration:underline; font-weight: 600" target="_blank" href=${termsAndConditionsUrl}>${t(
			'user_sign_up_terms_and_conditions_label',
		)}</a>`,
		privacy_policy_link: `<a style="text-decoration:underline; font-weight: 600" target="_blank" href=${privacyPolicyUrl}>${t(
			'user_sign_up_privacy_policy_label',
		)}</a>`,
		interpolation: {
			escapeValue: false,
		},
	});

	// eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention
	return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export type ITermsAndConditionsCheckboxProps = Omit<ICheckboxProps, 'label'>;

export const TermsAndConditionsCheckbox = forwardRef<
	HTMLInputElement,
	ITermsAndConditionsCheckboxProps
>((props: ITermsAndConditionsCheckboxProps, ref) => (
	<Checkbox
		label={<TermsAndConditionsCheckboxLabel />}
		{...props}
		ref={ref}
		required
	/>
));

import { ITheme } from '../../themer';

export function getCommonButtonStyles(
	type: 'primary' | 'secondary',
	theme: ITheme,
	inverted?: boolean,
) {
	const style = inverted ? theme.button[type].inverted : theme.button[type];

	return `
		background: ${style.background};
		border: ${style.border};
		box-shadow: ${style.boxShadow};
		font-size: ${style.font.size};
		font-weight: ${style.font.weight};
		font-family: ${style.font.fontFamily};
		color: ${style.font.color};
		transition: ${style.transition};
		transform: ${style.transform};

		a {
			color: ${style.font.color};
		}

		&:disabled {
			opacity: ${style.disabled.opacity};
			background: ${style.disabled.background};
			border: ${style.disabled.border};
			boxShadow: ${style.disabled.boxShadow};
			color: ${style.disabled.font.color};
			cursor: ${style.disabled.cursor};
		}

		&:hover:not(:disabled) {
			background: ${style.hover.background};
			border: ${style.hover.border};
			box-shadow: ${style.hover.boxShadow};
			color: ${style.hover.font.color};
			transition: ${style.hover.transition};
			transform: ${style.hover.transform};

			a {
				color: ${style.hover.font.color};
			}
		}

		&:active:not(:disabled) {
			background: ${style.active.background};
			border: ${style.active.border};
			box-shadow: ${style.active.boxShadow};
			color: ${style.active.font.color};
			transition: ${style.active.transition};
			transform: ${style.active.transform};

			a {
				color: ${style.active.font.color};
			}
		}
	`;
}

import { AbstractService } from './AbstractService';
import { IPublicKeyCreateResponse } from '../types';

export class PublicKeyService extends AbstractService {
	create() {
		return this.client.post<never, IPublicKeyCreateResponse>(
			'/payment/public-keys',
		);
	}
}

import { color } from '../../themer/theme/color';
import { typography } from '../../themer/theme/typography';
import { zIndex } from '../../themer/theme/zIndex';

export const theme = {
	options: {
		maxHeight: '275px',
		background: color.background.default,
		boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1)',
		marginTop: '10px',
		zIndex: zIndex.dropdown,
	},
	option: {
		padding: '16px',
		fontSize: typography.input.size,
		transition: 'color .1s',
		default: {
			background: '',
			color: '',
		},
		selected: {
			background: '#f7f7f7',
			color: '',
		},
		highlighted: {
			background: '#f7f7f7',
			color: color.text.hover,
		},
		secondaryStyle: {
			default: {
				background: '',
				color: '',
			},
			selected: {
				background: '#f7f7f7',
				color: '',
			},
			highlighted: {
				background: '#f7f7f7',
				color: color.text.hover,
			},
		},
		hint: {
			color: color.text.light,
		},
	},
};

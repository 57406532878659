export type SetSessionStorageItem = (key: string, value: string) => void;
export type GetSessionStorageItem = (key: string) => string | null;

export function setSessionStorageItem(key: string, value: string) {
	if (window) {
		window.sessionStorage.setItem(key, value);
	}
}

export function getSessionStorageItem(key: string): string | null {
	if (typeof window === 'undefined') {
		return null;
	}
	return window.sessionStorage.getItem(key);
}

import { CheckpointQueueAccessType } from '../../models/CheckpointQueueAccessType';
import { IAttributesResponse } from '../../models/IAttributesResponse';

export const mapCheckpointQueueAccessTypes = (
	attributes: IAttributesResponse,
): CheckpointQueueAccessType[] =>
	[
		attributes.general ? CheckpointQueueAccessType.General : null,
		attributes.preCheck ? CheckpointQueueAccessType.PreCheck : null,
		attributes.priority ? CheckpointQueueAccessType.Priority : null,
	].filter((type): type is CheckpointQueueAccessType => type !== null);

import React, { forwardRef, HTMLProps, ReactNode, Ref } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { ITheme } from '../../themer';
import themed from '../../themer/themed';
import { isRelativeUrl } from './isRelativeUrl';
import { useConfig } from '../Config';
import { isHashFragment } from './isHashFragment';
import { ExternalLinkIcon } from '../Icon';
import { VisuallyHidden } from '../VisuallyHidden';

export interface IStyledLinkProps {
	inverted?: boolean;
	customColor?: string;
}

export interface ILinkProps
	extends HTMLProps<HTMLAnchorElement>,
		IStyledLinkProps {
	children: ReactNode;
	ref?: Ref<HTMLAnchorElement>;
	hideExternalIndicator?: boolean;
	inverted?: boolean;
	screenReaderTitle?: string;
}

export const BaseLink = forwardRef<HTMLAnchorElement, ILinkProps>(
	(
		{
			children,
			href,
			inverted,
			hideExternalIndicator,
			screenReaderTitle,
			...rest
		}: ILinkProps,
		ref,
	) => {
		const { RouterLinkComponent, translations } = useConfig();

		return RouterLinkComponent && href && shouldUseRouterLink(href) ? (
			<RouterLinkComponent href={href} ref={ref} {...rest}>
				{children}
			</RouterLinkComponent>
		) : (
			<a href={href} ref={ref} {...rest}>
				{!screenReaderTitle ? (
					children
				) : (
					<>
						<span aria-hidden>{children}</span>
						<VisuallyHidden>{screenReaderTitle}</VisuallyHidden>
					</>
				)}
				{rest.target === '_blank' && !hideExternalIndicator && (
					<StyledExternalLinkIcon
						variation={inverted ? 'inverted' : 'highlight'}
						aria-label={translations.externalLinkLabel}
					/>
				)}
			</a>
		);
	},
);

const shouldUseRouterLink = (href: string) =>
	!isHashFragment(href) && isRelativeUrl(href);

export const Link = styled(BaseLink, {
	shouldForwardProp: (prop) =>
		prop === 'getProps' ||
		prop === 'inverted' ||
		isPropValid(prop) ||
		prop === 'hideExternalIndicator',
})<IStyledLinkProps, ITheme>`
	color: ${themed<IStyledLinkProps>(({ typography, color }, { inverted }) =>
		inverted ? color.text.inverted : typography.link.color,
	)};

	&:hover {
		color: ${themed<IStyledLinkProps>(({ typography, color }, { inverted }) =>
			inverted ? color.text.inverted : typography.link.hover.color,
		)};
	}
`;

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
	padding-bottom: 2px;
	width: 24px;
`;

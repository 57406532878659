import { CognitoErrorCode, withErrorLogger } from '@move-frontend/auth';
import { cognitoClient } from '../../../../shared/api/cognito';
import { logError } from '../../../../shared/error/logError';
import { ISendRecoveryLinkData } from '../models/ISendRecoveryLinkData';
import { ISendRecoveryLinkResult } from '../models/ISendRecoveryLinkResult';

async function performSendRecoveryLink(
	data: ISendRecoveryLinkData,
): Promise<ISendRecoveryLinkResult> {
	const result = await cognitoClient.sendRecoveryLink(data);

	if (!result) {
		throw new Error('Invalid send recovery link response');
	}

	return result;
}

export const sendRecoveryLink = withErrorLogger(performSendRecoveryLink, {
	ignoreErrorCodes: [CognitoErrorCode.UserNotFoundException],
	customLogError: logError,
});

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ITheme } from '../../themer';
import { ChevronDownIcon, ChevronUpIcon } from '../Icon';
import themed from '../../themer/themed';

interface IStyledAccordionTitleProps {
	isActive: boolean;
}

export const StyledAccordionTitle = styled.div<
	IStyledAccordionTitleProps,
	ITheme
>`
	color: ${themed<IStyledAccordionTitleProps>(({ color }, { isActive }) =>
		isActive ? color.highlight : '',
	)};

	justify-content: space-between;
	cursor: pointer;
	width: 100%;
	display: flex;
	align-items: center;
`;

const StyledIconContainer = styled.div<{ reverseIcon?: boolean }>`
	margin-left: 16px;
	display: flex;
	gap: 24px;

	${({ reverseIcon }) =>
		reverseIcon &&
		css`
			svg {
				transform: rotate(180deg);
			}
		`}
`;

const StyledTitleWrapper = styled.div`
	width: 100%;
`;

interface IAccordionTitleProps {
	title: string | ReactNode;
	isActive: boolean;

	onClick?(): void;

	icon?: ReactNode;
	reverseIcon?: boolean;
}

export const AccordionTitle = ({
	title,
	isActive,
	onClick,
	icon,
	reverseIcon = false,
}: IAccordionTitleProps) => {
	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter' || event.key === ' ') {
			event.preventDefault();
			if (onClick) onClick();
		}
	};

	return (
		<StyledAccordionTitle
			isActive={isActive}
			onClick={onClick}
			tabIndex={0}
			role="button"
			aria-expanded={isActive}
			onKeyDown={handleKeyDown}
		>
			<StyledTitleWrapper>{title}</StyledTitleWrapper>
			<StyledIconContainer reverseIcon={reverseIcon}>
				{icon && icon}
				{isActive ? (
					<ChevronUpIcon variation="highlight" aria-hidden="true" />
				) : (
					<ChevronDownIcon variation="highlight" aria-hidden="true" />
				)}
			</StyledIconContainer>
		</StyledAccordionTitle>
	);
};

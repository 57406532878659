import React, { KeyboardEvent, ReactNode } from 'react';
import { ControllerStateAndHelpers } from 'downshift';
import {
	DropdownList,
	IDropdownItem,
	IGetItemDisplayValue,
	IGetItemKey,
} from '../DropdownList';
import { AutoSuggestMain } from './AutoSuggestMain';

interface IAutoSuggestRootProps
	extends ControllerStateAndHelpers<IDropdownItem> {
	placeholder: string;
	ariaLabel?: string;
	ariaDescribedBy?: string;
	getItemDisplayValue: IGetItemDisplayValue;
	getItemKey: IGetItemKey;
	items: IDropdownItem[];
	innerRef: () => void;
	onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	invalid?: boolean;
	required?: boolean;
	placeholderAsLabel?: boolean;
	onClearSearch?: () => void;
	onFocus?: () => void;
	icon?: ReactNode;
	autoSuggestId?: string;
}

export const AutoSuggestRoot = ({
	highlightedIndex,
	getMenuProps,
	getItemProps,
	getInputProps,
	selectedItem,
	clearSelection,
	getItemDisplayValue,
	getItemKey,
	innerRef,
	isOpen,
	placeholder,
	ariaLabel,
	ariaDescribedBy,
	items,
	onKeyUp,
	disabled,
	invalid,
	required,
	placeholderAsLabel,
	onClearSearch,
	onFocus,
	icon,
	autoSuggestId,
}: IAutoSuggestRootProps) => (
	<div ref={innerRef}>
		<AutoSuggestMain
			getInputProps={getInputProps}
			isOpen={isOpen}
			placeholder={placeholder}
			ariaLabel={ariaLabel}
			ariaDescribedBy={ariaDescribedBy}
			clearSelection={clearSelection}
			onKeyUp={onKeyUp}
			disabled={disabled}
			invalid={invalid}
			required={required}
			placeholderAsLabel={placeholderAsLabel}
			onClearSearch={onClearSearch}
			onFocus={onFocus}
			icon={icon}
			autoSuggestId={autoSuggestId}
		/>

		<DropdownList
			getItemDisplayValue={getItemDisplayValue}
			highlightedIndex={highlightedIndex}
			getMenuProps={getMenuProps}
			selectedItem={selectedItem}
			getItemProps={getItemProps}
			getItemKey={getItemKey}
			isOpen={isOpen}
			items={items}
		/>
	</div>
);

import styled from '@emotion/styled';
import { Grid } from '../../../../ui';
import Responsive from '../../../../ui/utils/Responsive';

export const SearchBarWrap = styled(Grid)`
	margin-bottom: 32px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 24px;
	}
`;

import { SyntheticEvent, useCallback } from 'react';

export type CustomEventHandler<TEvent extends SyntheticEvent> = (
	e: TEvent,
) => void;

export function useCustomEventHandler<TEvent extends SyntheticEvent>(
	handler: CustomEventHandler<TEvent>,
	userHandler?: CustomEventHandler<TEvent>,
) {
	return useCallback(
		(e: TEvent) => {
			if (userHandler) {
				userHandler(e);
			}

			handler(e);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userHandler],
	);
}

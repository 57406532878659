import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { ITextFieldProps, TextField } from '../../../../ui';

export const ConfirmPassword = (props: Partial<ITextFieldProps>) => {
	const { t } = useTranslation();
	const { register, errors } = useFormContext();

	return (
		<TextField
			name="confirmPassword"
			type="password"
			label={t('user_form_confirm_password_label')}
			autoComplete="new-password"
			ref={register()}
			invalid={!!errors.confirmPassword}
			error={errors.confirmPassword?.message}
			{...props}
		/>
	);
};

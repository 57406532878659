import React from 'react';
import { FormProvider } from 'react-hook-form';
import styled from '@emotion/styled';
import { IOnFailure } from '@move-frontend/auth';
import { AuthFormRow as Row } from '../../shared/form-layout/AuthFormRow';
import { SignInFormActions } from './SignInFormActions';
import { Email } from '../../shared/form-fields/Email';
import { Password } from '../../shared/form-fields/Password';
import { AuthFormSection } from '../../shared/form-layout/AuthFormSection';
import { useSignInForm } from './helpers/useSignInForm';
import { useSignInSubmit } from './helpers/useSignInSubmit';
import { ISignInOnSuccess } from '../domain/models/ISignInOnSuccess';
import Responsive from '../../../../ui/utils/Responsive';

export interface ISignInFormProps {
	onSuccess: ISignInOnSuccess;
	onFailure: IOnFailure;
}

export const SignInForm = ({ onSuccess, onFailure }: ISignInFormProps) => {
	const form = useSignInForm();
	const onSubmit = useSignInSubmit(onSuccess, onFailure);

	return (
		<FormProvider {...form}>
			<form noValidate onSubmit={form.handleSubmit(onSubmit)}>
				<Section>
					<Row>
						<Email />
					</Row>
					<Row>
						<Password />
					</Row>
				</Section>

				<SignInFormActions />
			</form>
		</FormProvider>
	);
};

const Section = styled(AuthFormSection)`
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 16px;
	}
`;

import React from 'react';
import { css, Global } from '@emotion/core';

import avenirNextLtProRegularEot from './fonts/avenir-next-lt-pro-regular.eot';
import avenirNextLtProRegularWoff2 from './fonts/avenir-next-lt-pro-regular.woff2';
import avenirNextLtProRegularWoff from './fonts/avenir-next-lt-pro-regular.woff';
import avenirNextLtProRegularTtf from './fonts/avenir-next-lt-pro-regular.ttf';

import avenirNextLtProMediumEot from './fonts/avenir-next-lt-pro-medium.eot';
import avenirNextLtProMediumWoff2 from './fonts/avenir-next-lt-pro-medium.woff2';
import avenirNextLtProMediumWoff from './fonts/avenir-next-lt-pro-medium.woff';
import avenirNextLtProMediumTtf from './fonts/avenir-next-lt-pro-medium.ttf';

import avenirNextLtProBoldEot from './fonts/avenir-next-lt-pro-bold.eot';
import avenirNextLtProBoldWoff2 from './fonts/avenir-next-lt-pro-bold.woff2';
import avenirNextLtProBoldWoff from './fonts/avenir-next-lt-pro-bold.woff';
import avenirNextLtProBoldTtf from './fonts/avenir-next-lt-pro-bold.ttf';

import avenirNextLtProHeavyEot from './fonts/avenir-next-lt-pro-heavy.eot';
import avenirNextLtProHeavyWoff2 from './fonts/avenir-next-lt-pro-heavy.woff2';
import avenirNextLtProHeavyWoff from './fonts/avenir-next-lt-pro-heavy.woff';
import avenirNextLtProHeavyTtf from './fonts/avenir-next-lt-pro-heavy.ttf';

export const FONT_FAMILY_AVENIR_NEXT_LT_PRO = 'Avenir Next LT Pro';

export const Fonts = () => {
	const styles = css`
		body {
			-webkit-font-smoothing: antialiased;
		}

		@font-face {
			font-family: ${FONT_FAMILY_AVENIR_NEXT_LT_PRO};
			src: url('${avenirNextLtProRegularEot}');
			src: url('${avenirNextLtProRegularEot}?#iefix')
					format('embedded-opentype'),
				url('${avenirNextLtProRegularWoff2}') format('woff2'),
				url('${avenirNextLtProRegularWoff}') format('woff'),
				url('${avenirNextLtProRegularTtf}') format('truetype');
			font-display: swap;
			font-weight: normal; // 400
		}

		@font-face {
			font-family: ${FONT_FAMILY_AVENIR_NEXT_LT_PRO};
			src: url('${avenirNextLtProMediumEot}');
			src: url('${avenirNextLtProMediumEot}?#iefix') format('embedded-opentype'),
				url('${avenirNextLtProMediumWoff2}') format('woff2'),
				url('${avenirNextLtProMediumWoff}') format('woff'),
				url('${avenirNextLtProMediumTtf}') format('truetype');
			font-display: swap;
			font-weight: 500;
		}

		@font-face {
			font-family: ${FONT_FAMILY_AVENIR_NEXT_LT_PRO};
			src: url('${avenirNextLtProBoldEot}');
			src: url('${avenirNextLtProBoldEot}?#iefix') format('embedded-opentype'),
				url('${avenirNextLtProBoldWoff2}') format('woff2'),
				url('${avenirNextLtProBoldWoff}') format('woff'),
				url('${avenirNextLtProBoldTtf}') format('truetype');
			font-display: swap;
			font-weight: 700;
		}

		@font-face {
			font-family: ${FONT_FAMILY_AVENIR_NEXT_LT_PRO};
			src: url('${avenirNextLtProHeavyEot}');
			src: url('${avenirNextLtProHeavyEot}?#iefix') format('embedded-opentype'),
				url('${avenirNextLtProHeavyWoff2}') format('woff2'),
				url('${avenirNextLtProHeavyWoff}') format('woff'),
				url('${avenirNextLtProHeavyTtf}') format('truetype');
			font-display: swap;
			font-weight: 900;
		}
	`;

	return <Global styles={styles} />;
};

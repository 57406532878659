import { color } from '../../themer/theme/color';
import { typography } from '../../themer/theme/typography';

export const theme = {
	main: {
		background: '#fff',
		color: '',
		boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1)',
		borderRadius: '',
		border: '',
		padding: '16px',
		fontSize: typography.input.size,
		lineHeight: '24px',
	},
	focus: {
		background: '',
		color: '',
		boxShadow: `0 1px 5px 0 ${color.text.hover}`,
	},
	disabled: {
		background: '',
		color: color.background.light,
		boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.05)',
	},
	current: {
		color: '',
	},
	placeholder: {
		color: color.text.light,
	},
	floatingLabel: {
		fontSize: typography.small.size,
	},
};

import { Dap } from '../../../shared/api/dap';
import { orderBusStops } from '../use-cases';
import { mapStopResponse } from './adapters';
import { BusWaitingTimesStore } from './BusWaitingTimesStore';
import { filterResponse } from './filters';

export function createBusWaitingTimesStores(dap: Dap) {
	const busWaitingTimesStore = new BusWaitingTimesStore(
		dap,
		false,
		filterResponse,
		mapStopResponse,
		orderBusStops,
	);

	return {
		busWaitingTimesStore,
	};
}
export function createEmployeeLotsStores(dap: Dap) {
	const employeeLotsStore = new BusWaitingTimesStore(
		dap,
		true,
		filterResponse,
		mapStopResponse,
		orderBusStops,
	);

	return {
		employeeLotsStore,
	};
}

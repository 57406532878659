import { useEffect } from 'react';
import { useStores } from '../../../app/root/domain/stores/useStores';

/**
 * Start loading remote config as soon as possible, to reduce the risk of UI flickers
 */
export const useLoadRemoteConfig = () => {
	const { remoteConfigStore } = useStores();

	useEffect(() => {
		remoteConfigStore.load();
	}, []);
};

export interface ISwitchTheme {
	backgroundColor: string;
	borderColor: string;
	toggleColor: string;
	active: {
		color?: string;
	};
	disabled: {
		color?: string;
		opacity: string;
		active: {
			color?: string;
		};
	};
}

export const theme: ISwitchTheme = {
	backgroundColor: '#f3f3f3',
	borderColor: '#ababab',
	toggleColor: '#aaa',
	active: {
		/** Defaults to color.highlight */
		color: undefined,
	},
	disabled: {
		/** Defaults to color.border.default */
		color: undefined,
		opacity: '0.3',
		active: {
			/** Defaults to color.highlight */
			color: undefined,
		},
	},
};
